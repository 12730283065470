<template>
  <div>
    <highcharts
        :id="'chart-container-1'"
        class="mt-2"
        :options="chartOptions"
        ref="lineChartSummary"
        :constructor-type="'chart'"
    ></highcharts>
  </div>
</template>
<script>
import {Chart} from "highcharts-vue";
import {
  formatDataChartWithStep,
  getXColumnsByStep,
  lineChartOption,
} from "@/commons/charts";
import EventBus from "@/commons/event_bus";
import { listKeywordClient } from "@/commons/constants";

export default {
  props: {
    changeStepChart: String,
    seriesData: Array,
    allSeriesData: Array,
    selectedId: String,
    step: Number,
    part: Number,
    column: Number,
    maxColumn: Number,
    nameChart: String,
    isArea: Boolean,
    reportScreening: [Object, Array],
    answerValueIdSelected: Array,
    selectedAnswerId: [Number, String],
    questionIdSelected: [Number, String],
    answerValueSelected: Array,
    dataTrendWord: Array,
  },
  components: {
    highcharts: Chart,
  },
  watch: {
    step: function (newValue) {
      this.chartOptions.xAxis.grid = {
        columns: getXColumnsByStep(newValue, 0),
      };
      this.prevSelected = this.currentSelected;
      this.currentSelected = 0;
      this.chartOptions.xAxis.min = 1;
      this.chartOptions.xAxis.max = (newValue * this.maxColumn) + 0.5;
      
      this.initSeriesData();
    },
    part: function (newValue, oldValue) {
      this.prevPart = oldValue;
      this.prevSelected = this.currentSelected;
      this.currentSelected = (newValue - 1) * this.step * this.column;
      this.chartOptions.xAxis.min = ((newValue - 1) * this.step * this.column) + 1;
      this.chartOptions.xAxis.max =
          ((newValue - 1) * this.step * this.column + this.step * this.maxColumn) + 0.5;
      this.chartOptions.xAxis.grid = {
        columns: getXColumnsByStep(this.step, (newValue - 1) * this.step * this.column),
      };
      this.initSeriesData();
    },
    seriesData: {
      handler: function () {
        this.initSeriesData();
        while (this.chart.series.length > 0)
          this.chart.series[0].remove(false);
        this.chartOptions.series.forEach((serie) => {
          this.chart.addSeries(serie, false);
        });
        this.$nextTick(() => {
          this.chart.redraw();
          if (this.selectedId) {
            this.loaded(this.chart)
          }
        })
      },
      deep: true,
    },
    selectedId: function () {
      this.initSeriesData();
      this.chart.series.forEach((serie) => {
        serie.update(
            this.chartOptions.series.find((e) => e.name == serie.name),
            false
        );
      });
      this.$nextTick(() => {
        this.chart.redraw();
      })
      this.loaded(this.chart);
    },
  },
  computed: {
    tick: {
      get: function () {
        return this.chartOptions.xAxis.tickInterval;
      },
      set: function (value) {
        this.chartOptions.xAxis.tickInterval = value;
      },
    },
  },
  data() {
    let currentSelected = 0;
    let prevSelected = 0;
    let prevStep = 1;
    let tooltipEl, tooltipEl2;
    let line, image, image2, clickX, clickY;
    let currentWidth;
    let chartOptions = JSON.parse(JSON.stringify(lineChartOption));
    let vm = this;

    chartOptions = Object.assign(chartOptions, {
      chart: {
        animation: false,
        height: "340px",
        events: {
          load: function (e) {
            vm.load(e);
          },
          redraw: function (e) {
            vm.redraw(e);
          },
        },
      },
      yAxis: [{
        ...chartOptions.yAxis,
        max: 101,
        tickInterval: 20,
        endOnTick: false,
        labels: {
          ...chartOptions.yAxis.labels,
          formatter: function () {
            if (this.value != 0) {
              return this.value + '%';
            }
          },
          style: {
            fontSize: "8px",
            fontFamily: "Avenir Next",
            fontWeight: 500,
            lineHeight: "12px",
            color: "#999999",
          }
        },
      }, {
        ...chartOptions.yAxis,
        min: 0,
        ignoreMaxPadding: true,
        endOnTick: false,
        labels: {
          ...chartOptions.yAxis.labels,
          style: {
            fontSize: "8px",
            fontFamily: "Avenir Next",
            fontWeight: 500,
            lineHeight: "12px",
            color: "#999999",
          }
        },
        opposite: true,
        visible: true,
      }],
      xAxis: {
        ...chartOptions.xAxis,
        labels: {
          ...chartOptions.xAxis.labels,
          formatter: function () {
              return this.value + 'd';
          },
          style: {
            fontSize: "8px",
            fontFamily: "Avenir Next",
            fontWeight: 500,
            lineHeight: "12px",
            color: "#999999",
          }
        },
        tickInterval: 1,
      },
      tooltip: {
        useHTML: true,
        enabled: true,
        backgroundColor: "#ebebeb",
        borderColor: "transparent",
        shadow: false,
        outside: false,
        style: {
          color: "#49ab94",
          fontSize: "10px",
          lineHeight: "16px",
          fontFamily: "Noto Sans JP",
          fontWeight: "400",
        },
        formatter() {
          if(this.series.name.includes('trend-word')) {
            return `<span>${this.y}</span>`
          }
          return `<span>&nbsp;${Math.round(this.y * 100) / 100}%</span>`;
        },
      },
      plotOptions: {
        series: {
          clip: false,
          animation: false,
        },
        line: {
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },
      },
    })

    return {
      chart: null,
      currentSelected,
      prevSelected,
      prevStep,
      tooltipEl,
      tooltipEl2,
      line,
      currentWidth,
      image,
      image2,
      clickX,
      clickY,
      prevPart: 1,
      chartOptions: chartOptions,
      positionTooltip: 0,
    };
  },
  methods: {
    selectedChart(name) {
      EventBus.$emit('selectedChart', name);
    },
    hoverLine(e) {
      if (e.target.series) {
        let name = e.target.series.name;
        this.chart.series.forEach(serie => {
          if (serie.name == name) {
            serie.setState('hover')
          } else {
            serie.setState('')
          }
        })
        if (name) {
          this.$emit("addClassActive", name);
        }
      }
    },
    mouseOut(e) {
      if (e.target.series) {
        let name = e.target.series.name;
        if (name) {
          //remove class active
          this.$emit("removeClassActive", name);
        }
      }
    },
    initSeriesData() {
      let series = [];
      let vm = this;
      this.seriesData.forEach((seriesData) => {
        let id = seriesData.id;
        let active = this.selectedId == id;
        let data = [0];
        for(let i = 0; i < seriesData.data.length; i++){
          data.push(seriesData.data[i])
        }

        let options = {
          id,
          ...(seriesData.id == "avg" ? {
            dashStyle : "dash",
          }: {}),
          color: "#333333",
          name: seriesData.id,
          data: formatDataChartWithStep(data),
          zoneAxis: "x",
          zones: [
            {
              value: this.step * this.part * this.column,
            },
            {
              dashStyle: "ShortDash",
            },
          ],
          opacity: active ? 1 : 0.25,
          findNearestPointBy: 'xy',
          states: {
            hover: {
              opacity: 1,
              lineWidth: 2,
            },
            ...(active
                ? {
                  inactive: {
                    opacity: 1,
                  },
                }
                : {}),
          },
          point: {
            events: {
              mouseOver: this.hoverLine,
              mouseOut: this.mouseOut,
            },
          },
          events: {
            click: function () {
              let ids ;
              if (this.name == 'avg') {
                ids = this.name
              } else {
                ids = this.name.split("-")[1];
              }
              vm.selectedChart(ids)
            }
          },
          marker: {
            fillColor: "#FFFFFF",
            lineWidth: 2,
            lineColor: null,
            symbol: "circle",
            radius: 5,
            enabled: false,
          },
          zIndex: 1,
        };
        series.push(options);
      });

      if(this.chart) {
        this.chart.series.forEach((serie) => {
          serie.data.forEach(function (point) {
            point.update({
              dataLabels: {
                enabled: false,
              },
            }, false);
          });
        })
      }

      const seriesDataTrendWord = this.setTrendWordChart(this.$props.dataTrendWord)
      this.$emit('updateVisibleTrendWordChart', seriesDataTrendWord.length > 0)
      if(seriesDataTrendWord.length) {
        let maxY = 0
        const lastColumn = this.chartOptions.xAxis.grid.columns.slice(-1)[0]
        seriesDataTrendWord.forEach((seriesData) => {
          const dataLabels = {
              enabled: true,
              useHTML: true,
              align: "center",
              verticalAlign: "middle",
              format: `<span class='label-inline-chart' style="background-color: ${listKeywordClient.twitter.color}">${listKeywordClient.twitter.label}<span>`
          }
          if(seriesData.data[lastColumn - vm.step]) {
            seriesData.data[lastColumn - vm.step] = {
              dataLabels: JSON.parse(JSON.stringify(dataLabels)),
              x: lastColumn - vm.step,
              y: seriesData.data[lastColumn - vm.step].y
            }
          } else {
            const lastPointSeries = seriesData.data[seriesData.data.length - 1]
            seriesData.data[lastPointSeries.x] = {
              dataLabels: JSON.parse(JSON.stringify(dataLabels)),
              x: lastPointSeries.x,
              y: seriesData.data[lastPointSeries.x].y
            }
          }
          maxY = Math.max(maxY, ...seriesData.data.map(item => item.y))
        })

        this.chartOptions.yAxis[1].visible = true
        this.chartOptions.yAxis[1].max = maxY
        series = [...series, ...seriesDataTrendWord]
      } else {
          this.chartOptions.yAxis[1].visible = false
      }

      this.chartOptions.series = series;
    },
    load(e) {
      let chart = e.target;
      this.chart = chart;
      this.loaded(chart);
    },
    loaded() {
      this.currentSelected = this.step * this.column * (this.part - 1)
    },
    redraw(e) {
      let chart = e.target;
      this.chart = chart;
      if (chart.oldChartWidth && chart.chartWidth != this.oldChartWidth) {
        this.oldChartWidth = chart.chartWidth;
      } else if (
          this.prevStep != this.step ||
          (this.prevPart && this.prevPart != this.part)
      ) {
        this.prevStep = this.step;
        this.prevPart = this.part;
        this.loaded(chart);
      }
    },
    setTrendWordChart(dataTrendWord) {
      let seriesData = [];
      let vm = this;
      if (dataTrendWord.length) {
        dataTrendWord.forEach(function (data) {
          if (data.twitter && data.video_id) {
            let active = vm.selectedId === 'video-' + data.video_id;
            let newData = Object.values(data.twitter[0])
            let checkData = newData.filter(item => item > 0)
            if(checkData.length) {
              newData.unshift(0)
              seriesData.push({
                name: 'trend-word-' + data.video_id,
                data: formatDataChartWithStep(newData),
                color: listKeywordClient.twitter.color,
                zoneAxis: "x",
                dashStyle: "dash",
                zones: [
                  {
                    value: vm.step * vm.part * vm.column,
                  },
                  {
                    dashStyle: "ShortDash",
                  },
                ],
                opacity: 1,
                findNearestPointBy: 'xy',
                states: {
                  hover: {
                    opacity: 1,
                    lineWidth: 2,
                  },
                },
                zIndex: 2,
                visible: active,
                yAxis: 1,
              })
            }
          }
        })
        return seriesData;
      } else {
        return []
      }
    },
  },
  mounted() {
    this.step == 1 ? this.chartOptions.xAxis.min = 1 : this.chartOptions.xAxis.min = (this.part - 1) * this.step * this.column;
    this.chartOptions.xAxis.tickInterval = this.step;
    this.chartOptions.xAxis.max =
        ((this.part - 1) * this.step * this.column + this.step * this.maxColumn) + 0.5;
    this.chart = this.$refs.lineChartSummary.chart;
    this.$emit("setChart", this.chart);
    this.initSeriesData();
    this.chart.redraw();
  },
  created() {
    this.initSeriesData();
  },
};
</script>
<style lang="scss">

@media screen and (max-width: 1188px) {

  .row .col-9{
    max-width: 75.2307692308%;
    flex: 0 0 75.2307692308%;
  }
  
  .row-3 .row-3-col-1{
    max-width: 26.3333%;
  }

  .max-capture-cut{
    max-width: 33.3333333333% !important;
  }

  .row-3 .row-3-col-2{
    max-width: 72.8888%;
    flex: 0 0 72.8888%;
  }

  .row .col-4{
    max-width: 25.7692307692%;
    flex: 0 0 25.7692307692%;
  }

  .video-report-area .video-list .list-checkbox .list-item{
    box-sizing: border-box;
    height: auto !important;
    padding: 10px 10px 10px 0;
  }

}

.label-inline-chart {
  font-size: 8px;
  font-family: "Avenir Next", "Noto Sans JP";
  @media screen and (max-width: 1440px) {
    transform: translateX(-5px);
  }
}
</style>
