export default {
    state: {
        lists: [], // videos
        video_images: [],
        report_summary_avg: [],
        report_summary: {
            0: [], // number
            1: [], // percentage
        },
        report_cognition: {
            0: [],
            1: []
        },
        summary_norm: [],
        buyer_norm: [],
        report_segement_ranks: [],
        video_segments: [],
        video_comments: {},
        report_segement: [],
        interaction_top: [],
        interaction_rank: [],
        interaction_color: [],
        videos_images : [] ,//all video images,
        report_summary_ad : [],
        chart_type_value : 1,
        stack_bar_value : true,
        home_videos: {},
        selected_video_ids_from_home: [],
        list_in_report_top: [],
        report_summary_step3: [],
        report_screening: {},
        interaction_comments: {},
        report_trend_word: {},
        report_video_trend: {},
        report_intention: {},
        report_intention_second: {},
        comment_intention: {},
        list_video_comments: {},
        report_intention_count: {},
        report_segment_attribute: [],
    },
    getters: {
        videos: state => state.lists,
        videoById: (state, getters) => (buyer_id, ad_id, id) => {
            let videos = getters.videosByKey(buyer_id, ad_id);
            return videos.find(video => video.id == id);
        },
        videosByKey: state => (buyer_id, ad_id) => {
            let videos = state.lists.find(e => e.buyer_id = buyer_id && e.ad_id == ad_id);
            return videos ? videos.items : []
        },
        videoReportSummary: state => {
            return state.report_summary;
        },
        videoImagesById: state => videoId => {
            return state.video_images.find(e => e.video_id == videoId);
        },
        summaryAvgById: state => (buyer_id) => {
            return state.report_summary_avg.find(e => e.buyer_id == buyer_id)
        },
        videoReportSummaryById: state => (video_id, type) => {
            return state.report_summary[type].find(report => report.video_id == video_id);
        },
        cachedReports: state => (buyer_id, video_id, type) => {
            return state.report_summary[type].filter(report => report.buyer_id == buyer_id && report.video_id == video_id);
        },
        videoReportCognition: state => type => {
            return state.report_cognition[type];
        },
        videoReportCognitionById: state => (video_id, type) => {
            return state.report_cognition[type].find(report => report.video_id == video_id);
        },
        cachedChartReportSummary: state => (buyer_id, ad_id, type) => {
            return state.chart_report_summary[type].filter(report => report.buyer_id == buyer_id && report.ad_id == ad_id);
        },
        cachedChartSummaryNorm: state => (video_id) => {
            return state.summary_norm.find(data => data.video_id == video_id);
        },
        cachedChartBuyerNorm: state => (buyer_id) => {
            return state.buyer_norm.find(data => data.buyer_id == buyer_id);
        },
        cachedReportSegmentRanks: state => (video_id) => {
            return state.report_segement_ranks.find(data => data.video_id == video_id);
        },
        getVideoSegments: state => {
            return state.video_segments;
        },
        getVideoComments: state => (params) => {
            let key = '';
            for (const param in params) {
                if (params[param]) {
                    key += `${param}-${params[param].toString()}`;
                }
            }
            return state.video_comments[key]
        },
        getInteractionComments: state => (params) => {
            let key = params.video_ids
            if (params.type) {
                key += '-' + params.type
            }
            if(Object.prototype.hasOwnProperty.call(state.interaction_comments, key)) {
                return state.interaction_comments[key]
            } else return {}
        },
        videoReportSegmentById: state => (video_id) => {
            return state.report_segement.find(report => report.video_id == video_id);
        },
        videoReportScreeningById: state => (params) => {
            return state.report_screening[params];
        },
        cachedReportSegments: state => {
            return state.report_segement;
        },
        getReportInteractionTop: state => {
            return state.interaction_top[0]
        },
        getReportInteractionRank: state => {
            return state.interaction_rank[0]
        },
        interactioncolors: state => state.interaction_color,
        videoImages: state => {
            return state.report_segement;
        },
        getVideoImages : state => ad_id => {
            return state.videos_images.find(item => item.ad_id == ad_id);
        },
        getReportSummary : state => {
            return state.report_summary_ad[0]
        },
        getIntentionReport : state => (params) => {
            if(Object.prototype.hasOwnProperty.call(state.report_intention, params)) {
                return state.report_intention[params]
            } else return null
        },
        getIntentionSecondReport : state => (params) => {
            if(Object.prototype.hasOwnProperty.call(state.report_intention_second, params)) {
                return state.report_intention_second[params]
            } else return null
        },
        getIntentionComment : state => (params) => {
            if(Object.prototype.hasOwnProperty.call(state.comment_intention, params)) {
                return state.comment_intention[params]
            } else return null
        },
        getChartTypeValue: state => state.chart_type_value,
        getStackBarValue: state => state.stack_bar_value,
        getSelectedVideoIdsFromHome: state => state.selected_video_ids_from_home,
        getVideosListInReportTop: state => state.list_in_report_top,
        getHomeVideos: state => (key) => {
            return state.home_videos[key]
        },
        getReportSummaryStep3 : state => (params) => {
            if(Object.prototype.hasOwnProperty.call(state.report_summary_step3, params.video_ids)) {
                return state.report_summary_step3[params.video_ids]
            } else return null
        },
        getReportTrendWord : state => (params) => {
            if(Object.prototype.hasOwnProperty.call(state.report_trend_word, params.video_id)) {
                return state.report_trend_word[params.video_id]
            } else return null
        },
        getReportVideoTrend : state => (params) => {
            if(Object.prototype.hasOwnProperty.call(state.report_video_trend, params.video_ids)) {
                return state.report_video_trend[params.video_ids]
            } else return null
        },
        getListVideoComments : state => (params) => {
            if(Object.prototype.hasOwnProperty.call(state.list_video_comments, params)) {
                return state.list_video_comments[params]
            } else return null
        },
        getListVideoIntentionCount : state => (params) => {
            if(Object.prototype.hasOwnProperty.call(state.report_intention_count, params)) {
                return state.report_intention_count[params]
            } else return null
        },
        getReportSegmentAttribute: state => (params) => {
            if(
              Object.prototype.hasOwnProperty.call(state.report_segment_attribute, params.video_id) &&
              Object.prototype.hasOwnProperty.call(state.report_segment_attribute[params.video_id], params.attribute_value_ids)
            ) {
                return state.report_segment_attribute[params.video_id][params.attribute_value_ids]
            } else return null
        },
    },
    mutations: {
        addSummaryAvg(state, summaryAvg) {
            state.report_summary_avg.push(summaryAvg)
        },
        addVideoImage(state, videoImage){
            state.video_images.push(videoImage)
        },
        updateVideosProject(state, payload) {
            let {buyer_id, ad_id, items} = payload;
            let videoProject  = state.lists.find(e => e.buyer_id = buyer_id && e.ad_id == ad_id);
            if (videoProject) {
                videoProject.items = items
            } else {
                state.lists.push(payload);
            }

        },
        // setReportSummary(state, report) {
        //     state.report_summary = report;
        // },
        setReportSummary(state, { formatedReports, type }) {
            state.report_summary[type] = formatedReports;
        },
        addReportSummary(state, { formatedReports, type }) {
            if (formatedReports instanceof Array) {
                state.report_summary[type].push(...formatedReports);
            } else {
                state.report_summary[type].push(formatedReports);
            }
        },
        setReportCognition(state, { formatedReports, type }) {
            state.report_cognition[type] = formatedReports;
        },
        addReportCognition(state, { formatedReports, type }) {
            if (formatedReports instanceof Array) {
                state.report_cognition[type].push(...formatedReports);
            } else {
                state.report_cognition[type].push(formatedReports);
            }
        },
        addChartSummaryNorm(state, payload) {
            state.summary_norm.push(payload);
        },
        updateChartSummaryNorm(state, payload) {
            let reportToUpdate = state.summary_norm.find(report => report.video_id == payload.video_id);
            Object.assign(reportToUpdate, payload);
        },
        addChartBuyerNorm(state, payload) {
            state.buyer_norm.push(payload);
        },
        updateChartBuyerNorm(state, payload) {
            let reportToUpdate = state.buyer_norm.find(data => data.buyer_id == payload.buyer_id);
            Object.assign(reportToUpdate, payload);
        },
        addReportSegmentRanks(state, payload) {
            state.report_segement_ranks.push(payload);
        },
        updateReportSegmentRanks(state, payload) {
            let reportToUpdate = state.report_segement_ranks.find(data => data.ad_id == payload.ad_id);
            Object.assign(reportToUpdate, payload);
        },
        setVideoSegments(state, payload) {
            state.video_segments = payload;
        },
        setVideoComments(state, payload) {
            let key = '';
            for (const param in payload.params) {
                if (payload.params[param]) {
                    key += `${param}-${payload.params[param].toString()}`;
                }
            }
            state.video_comments[key] = payload.data ?? []
        },
        setInteractionComments(state, payload) {
            let key = payload.params.video_ids
            if (payload.params.type) {
                key += '-' + payload.params.type
            }
            state.interaction_comments[key] = payload.data ?? {}
        },
        setReportSegment(state, formatedReports) {
            state.report_segement = formatedReports;
        },
        addReportScreening(state, payload) {
            state.report_screening[payload?.params] = payload.data;
        },
        addReportSegment(state, formatedReports) {
            if (formatedReports instanceof Array) {
                state.report_segement.push(...formatedReports);
            } else {
                state.report_segement.push(formatedReports);
            }
        },
        updateReportInteractionTop(state, formatedReports) {
            state.interaction_top = [formatedReports];
        },
        updateReportInteractionRank(state, report) {
            state.interaction_rank = [report];
        },
        setInteractionColor(state, data) {
            state.interaction_color = data;
        },
        addAllVideoImage (state, data) {
            state.videos_images.push(data);
        } ,
        updateVideoList(state, adId) {
            let list = state.lists.find(item => item.ad_id == adId);
            if (list) {
                list.items.forEach(item => {
                    if (item.checked) {
                        item.checked = false;
                    }
                })
            }
        },
        addREportSummaryAd(state, report) {
            state.report_summary_ad.push(report)
        },
        addIntentionReport(state, payload){
            state.report_intention[payload.params] = payload.data
        },
        addIntentionSecondReport(state, payload){
            state.report_intention_second[payload.params] = payload.data
        },
        addIntentionComment(state, payload){
            state.comment_intention[payload.params] = payload.data
        },
        updateChartTypeValue(state, payload) {
            state.chart_type_value = payload
        },
        updateStackBarValue(state, payload) {
            state.stack_bar_value = payload
        },
        updateSelectedVideoIdsFromHome(state, videoIds) {
            state.selected_video_ids_from_home = videoIds
        },
        updateVideosListInReportTop(state, videos) {
            state.list_in_report_top = videos
        },
        setHomeVideos(state, payload) {
            state.home_videos[payload.key] = payload.data.items ?? []
        },
        resetReportSummaryAd(state) {
            state.report_summary_ad = []
        },
        resetVideosImages(state) {
            state.videos_images = []
        },
        resetReportSummaryAvg(state) {
            state.report_summary_avg = []
        },
        resetVideoImages(state) {
            state.video_images = []
        },
        resetInteractionRank(state) {
            state.interaction_rank = []
        },
        resetInteractionTop(state) {
            state.interaction_top = []
        },
        addReportSummaryStep3(state, report) {
            state.report_summary_step3[report.params.video_ids] = report.data;
        },
        addReportTrendWord(state, report) {
            state.report_trend_word[report.params.video_id] = report.data.report_data;
        },
        addReportVideoTrend(state, report) {
            state.report_video_trend[report.params.video_ids] = report.data;
        },
        addListVideoComments(state, report) {
            state.list_video_comments[report.params] = report.data;
        },
        addListVideoIntentionCount(state, report) {
            state.report_intention_count[report.params] = report.data;
        },
        addReportSegmentAttribute(state, report) {
            if(!Object.prototype.hasOwnProperty.call(state.report_segment_attribute, report.params.video_id)) {
                state.report_segment_attribute[report.params.video_id] = {}
            }
            state.report_segment_attribute[report.params.video_id][report.params.attribute_value_ids] = report.data;
        }
    },
    actions: {}
}