<template>
    <div class="row align-items-center justify-content-center">
        <div class="col-11">
            <client-list  v-if="currentUser.role=='admin'" :selected="buyer_id" :clients="clients" @select="updateBuyerId" class="mb-2"></client-list>
            <div class="header">
                <div class="header-left">
                    <div class="label">連携数</div>
                    <div class="number">{{ integrateYoutube.all }}</div>
                </div>
                <div class="header-right">
                    <div class="search">
                        <i class="icon icon-search"></i>
                        <input v-on:keyup="updateIntegrateYoutube(false)" v-model="params.text" type="text" placeholder="検索">
                    </div>
                    <div class="btn-youtube" v-if="currentUser.role=='buyer'" >
                        <button class="btn btn-primary" @click="redirectToRegisterPage">
                            YouTube連携
                        </button>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="content-noti">
                    <span class="text-small">表示数：{{ pages }}件</span>
                    <span v-if="hasFailedRecord" class="text-color-error pl-5">YouTube連携に失敗しているチャンネルがあります。再度YouTube連携を行ってください。
                    </span>
                </div>
                <div class="content-table">
                    <table class="table table-scroll">
                        <tr>
                            <th>チャンネル名</th>
                            <th>チャンネルID</th>
                            <th>
                                <div class="icon-arrow" @click="doSort('status')">
                                    YouTube連携ステータス
                                    <div class="icon-sort" v-if="params.sort == 'id'">
                                        <i class="icon icon-table-arrow-bottom ml-1"></i>
                                        <i class="icon icon-table-arrow-top ml-1"></i>
                                    </div>
                                    <i
                                        v-else
                                        class="icon ml-1"
                                        :class="{
                                        'icon-table-arrow-bottom': params.order == 'asc',
                                        'icon-table-arrow-top': params.order == 'desc'
                                        }"
                                    ></i>
                                </div>
                            </th>
                        </tr>
                        <tr v-for="data in integrateYoutube.items" :key="data.id">
                            <td>
                                {{ data.channel_name }}
                            </td>
                            <td>
                                {{ data.channel_id }}
                            </td>
                            <td v-bind:class="data.status == 1 ? 'text-green' : 'text-red'">
                                {{ statusMap[data.status] }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <scroll-loader :loader-method="loadMoreData" :loader-disable="loadDisable" :loader-color="'#49ab94'"></scroll-loader>
        </div>
    </div>

</template>

<script>
import ClientList from '../../components/Select/ClientList.vue';
import router from '../../routes';
import http2 from '../../commons/http2'
import {
  listBuyersActive
} from '../../commons/api_service';

export default {
    components: {ClientList},
    name: 'IntegrateYoutube',
    props: {},
    data() {
        return {
            buyer_id: 0,
            clients: [],
            currentUser: {},
            statusMap: {
                0: 'エラー',
                1: '連携済み'   
            },
            params: {
                page: 1,
                limit: 20,
                text: '',
                sort: 'id',
                order: 'asc',
                status: 1,
                buyer_id: 21
            },
            integrateYoutube: {
                items: []
            },
            loadDisable: false
        }
    },
    computed: {
        pages: function() {
            if(this.integrateYoutube.all == undefined || this.integrateYoutube.all == 0) {
                return 0
            } else {
                return Math.floor(this.integrateYoutube.all / this.params.limit) + 1
            }
        },
        hasFailedRecord: function() {
            return this.integrateYoutube.has_failed_record == 1
        }
    },
    watch: {
        clients: function(clients) {
            this.buyer_id = clients[0].id;
        },
        buyer_id: function() {
            this.updateIntegrateYoutube(false)
        }
    },
    methods: {
        updateBuyerId: function(selectedId) {
            this.buyer_id = selectedId
        },
        updateIntegrateYoutube: function(isContinous) {
            if(this.buyer_id == 0) {
                return;
            }
            this.params.buyer_id = this.buyer_id
            this.loadDisable = false;
            if(!isContinous) {
                this.params.page = 1;
            } else {
                this.params.page += 1;
            }
            let url = router.resolve({
                name: 'api.youtube-analytics'
            }).href;
            http2.getList(url, this.params, (data) => {
                if(!isContinous) {
                    this.integrateYoutube = data;
                } else {
                    this.integrateYoutube.items = [...this.integrateYoutube.items, ...data.items];
                }
                if(this.params.limit > data.items.length) {
                    this.loadDisable = true;
                }
            }, () => {
                this.loadDisable = true;
            })
        },
        redirectToRegisterPage: function() {
            router.push({name: 'integrate-youtube-register'})
        }, 
        loadMoreData: function() {
            this.updateIntegrateYoutube(true)
        },
        doSort(sortColumn) {
            if (sortColumn == this.params.sort) {
                let currentOrder = this.params.order;
                this.params.order = currentOrder == "asc" ? "desc" : "asc";
            } else {
                this.params.sort = sortColumn;
                this.params.order = "desc";
            }
            this.updateIntegrateYoutube(false);
        },
    },
    mounted() {
        let query = this.$route.query;
        if (query.callback !== undefined && query.code) {
            let params = {
                authorization_code: query.code
            }
            let url = router.resolve({name: 'api.youtube-analytics'}).href;
            http2.post(url, params, () => {
                router.replace('integrate-youtube')
            }, () => {
                router.replace('integrate-youtube')
            })
        } else {
            let currentUser = this.$store.getters.currentUser;
            this.currentUser = currentUser;
            if(currentUser && this.$store.getters.isClient) {
                this.buyer_id = currentUser.owner_id;
            } else {
                listBuyersActive(buyerActive => {
                    this.clients = buyerActive;
                }, true)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_integrate-youtube.scss';
</style>