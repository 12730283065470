
import Router from 'vue-router';
import Vue from 'vue';

import { api_router } from './api';
import { web_router } from './web';
import store from '../store';
import { plan } from '../commons/plan_const';


const routes = [
    ...web_router,
    ...api_router
];
  
Vue.use(Router);

const router =  new Router({
    mode: 'history',
    routes,
    
});
  
router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    let user = store.getters.currentUser;
    if (to.meta.requiredRoles && to.meta.requiredRoles.length == 0) {
        if (to.name == 'login' && user && user.account_id != null) {
            return next({
                name: 'home'
            })
        }
        return next();
    } else if (to.meta.requiredRoles && user && user.account_id != null) {
        if (user.plan != null) {
            let checkRole = false;
            let curentPlan;
            if (user.plan != null) {
                curentPlan = plan[user.plan].map((text) => {
                    return user.role + '.' + text
                });
            }

            curentPlan.unshift(user.role)

            curentPlan.forEach(function(value){
                if (to.meta.requiredRoles.includes(value)) {
                    checkRole = true
                }
            })

            if (checkRole) {
                return next(); 
            }
        }
        if (user.plan == null && to.meta.requiredRoles.includes(user.role)) {
            return next();
        }

        return next({
            name: 'home'
        })
    } else {
        if (from.name !== 'login') {
            next({
                path: '/login'
            })
        }
    }
});
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title + ' | PlayAds';
    });
})

export default router;