<template>
  <div>
    <select class="form-control" :name="element" :id="element"></select>
  </div>
</template>
<script>
import { setSelect2Data, formatDataSelect2 } from "../../commons/helpers";
const jQuery = require('jquery');
export default {
  props: {
    data: Array,
    idSelected: Number,
    isSelected: Boolean,
    element: String,
    isHasIcon : Boolean
  },
  watch: {
    data: function (data) {
      this.initClientsSelect(formatDataSelect2(data));
    },
  },
  methods: {
    initClientsSelect(data) {
      let vm = this;
      setSelect2Data(
        vm.element,
        data,
        false,
        this.handleSelectedClient,
        {
          selected: vm.idSelected,
          minimumResultsForSearch: 0,
          width: "308px",
          height: "52px",
          dropdownCssClass: "dropdown-select2-custom",
        },
        this.isSelected,
        this.isHasIcon
      );
    },
    handleSelectedClient(selectedId) {
      this.$emit("select", selectedId);
    },
    select2Custom() {
      jQuery(".select2-option-icon").on("mouseout", function () {
        jQuery(this).attr(
          "src",
          "./images/svg/icon_highlight_option_hover.svg"
        );
      });
    },
  },
  mounted() {
    this.initClientsSelect(formatDataSelect2(this.data));
  },
};
</script>
<style lang="scss" scoped></style>
