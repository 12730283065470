<template>
  <div class="video-detail" :class="{ checked: checkedVideo}">
    <div class="row">
      <div class="col-3 video-info d-flex flex-column justify-content-between mb-3">
        <div class="info-top">
          <div class="video-thumbnail">
            <img :src="video.image_url ? video.image_url + '2.jpg' : '/images/img/no-image.png'" alt="">
          </div>
          <div class="d-flex flex-wrap align-items-center">
            <span class="video-status" v-if="videoStatus(video)">{{ videoStatus(video) }}</span>
            <span class="video-date">{{ formatDateTime(video.start_at) }}  ~ {{ formatDateTime(video.end_at) }} </span>
          </div>
          <p class="video-name">{{ video.name }}</p>
        </div>
        <div class="video-average">
          <span class="average-title">Average</span>
          <div class="row" v-if="dataAverageReport.length">
            <div class="col-4" v-for="(question, key) in dataAverageReport" :key="key">
              <p class="average-type">{{ question.question_name }}</p>
              <p class="average-number">{{ question.value ? Number(question.value).toFixed(1) : "0.0"}}%</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-10 chart-list d-flex flex-column justify-content-between">
        <div class="change-video-type form-group w-100 d-flex justify-content-between">
          <div class="d-flex">
            <div class="list-radio mr-3">
              <div
                class="form-radio-box mr-5"
                v-for="(type, key) in videoChartType"
                :key="'video-chart-type-' + key"
              >
                <input
                  class="form-radio-input"
                  type="radio"
                  :value="type.value"
                  v-model="currentChartType"
                />
                <span class="btn-radio"></span>
                <label class="label-radio">{{ type.name }}</label>
              </div>
            </div>
            <div class="change-date-range d-flex">
              <button class="change-date-btn" @click="prevDateRange" :disabled="!isPrevDateAvailable"><img
                class="icon-change-date" src="/images/svg/icon_prev_hover.svg" alt=""></button>
              <span class="date-value">{{ currentStartDateString }}</span>
              <button class="change-date-btn" @click="nextDateRange" :disabled="!isNextDateAvailable"><img
                class="icon-change-date" src="/images/svg/icon_next_hover.svg" alt=""></button>
            </div>
          </div>
          <i class="icon icon-checkbox icon-checkbox-white" :class="{ checked: checkedVideo}" v-if="isCheckboxAvailable"
             @click="checkedVideo = !checkedVideo"></i>
        </div>
        <div class="list-chart-content row-3">
          <div
            class="row-3-col-1 mb-3"
            v-for="(question, key) in dataAverageReport"
            :key="'video-chart-type-' + key"
          >
            <div class="chart-item">
              <div class="d-flex justify-content-between flex-wrap">
                <label class="label-chart-name">{{ question.question_name }}</label>
                <div class="list-range-color d-flex align-items-center"
                     v-if="currentChartType === videoChartType.column.value">
                  <span>高</span>
                  <span class="item-color-chart first-item" :style="{ backgroundColor : '#49AB94'}"></span>
                  <span class="item-color-chart" :style="{ backgroundColor : '#6EBCA9'}"></span>
                  <span class="item-color-chart" :style="{ backgroundColor : '#92CDBF'}"></span>
                  <span class="item-color-chart" :style="{ backgroundColor : '#B6DDD4'}"></span>
                  <span class="item-color-chart last-item" :style="{ backgroundColor : '#DBEEEA'}"></span>
                  <span>低</span>
                </div>
              </div>
              <line-top
                v-if="currentChartType === videoChartType.line.value"
                :currentDateIndex="currentStartDateIndex"
                :dataReport="dataLineChart.filter(data => data.question_id == question.question_id)[0]"
                :id="key"
              ></line-top>
              <column-top
                v-else
                :currentDateIndex="currentStartDateIndex"
                :dataReport="dataColumnChart.filter(data => data.question_id == question.question_id)[0]"
                :id="key"
              ></column-top>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineTop from "@/components/Charts/Step3/LineTop";
import ColumnTop from "@/components/Charts/Step3/ColumnTop";
import moment from "moment/moment";

const hasOwnProperty = Object.prototype.hasOwnProperty;
export default {
  components: {
    ColumnTop,
    LineTop
  },

  props: {
    video: Object,
    selectedVideo: Array,
  },
  data() {
    return {
      checkedVideo: false,
      videoChartType: {
        line: {
          name: '反応推移',
          value: 1,
        },
        column: {
          name: '反応分布',
          value: 2,
        }
      },
      currentChartType: 1,
      listQuestion: [],
      dataLineChart: {},
      dataColumnChart: {},
      dataAverageReport: [],
      currentStartDateIndex: null,
      currentStartDateString: "",
    }
  },
  methods: {
    getCheckedVideo() {
      this.checkedVideo = this.selectedVideo.includes(this.video.id)
    },
    formatDateTime(val) {
      return moment(val).format("YY/M/D")
    },
    videoStatus(video) {
      let dateNow = moment().format("YYYY-MM-DD")
      if (moment(dateNow).isBefore(video.start_at)) {
        return '検証予定'
      } else if (moment(dateNow).isBetween(video.start_at, video.end_at, 'day', '[]')) {
        return '検証中'
      } else {
        return ''
      }
    },
    prevDateRange() {
      this.currentStartDateIndex = this.currentStartDateIndex - 7;
    },
    nextDateRange() {
      this.currentStartDateIndex = this.currentStartDateIndex + 7;
    },
    updateDataReport() {
      if (hasOwnProperty.call(this.video, "report_data")) {
        this.currentStartDateIndex = 0;
        if (hasOwnProperty.call(this.video.report_data, "average_report")) {
          this.dataAverageReport = this.video.report_data.average_report
        }

        if (hasOwnProperty.call(this.video.report_data, "daily_change_report")) {
          this.dataLineChart = this.video.report_data.daily_change_report
        }

        if (hasOwnProperty.call(this.video.report_data, "daily_distribution_report")) {
          this.dataColumnChart = this.video.report_data.daily_distribution_report
        }
      }
    }
  },
  computed: {
    isPrevDateAvailable() {
      return moment(this.video.start_at, "YYYY-MM-DD") <= moment(this.video.start_at, "YYYY-M-DD").add(this.currentStartDateIndex - 6, 'days')
    },
    isNextDateAvailable() {
      return moment(this.endDate, "YYYY-MM-DD") > moment(this.video.start_at, "YYYY-M-DD").add(this.currentStartDateIndex + 6, 'days')
    },
    endDate() {
      if(moment(this.video.end_at, "YYYY-MM-DD") > moment()) {
        return moment().format("YYYY-MM-DD")
      } else return this.video.end_at
    },
    isCheckboxAvailable() {
      return moment(this.video.start_at, "YYYY-MM-DD") <= moment()
    }
  },
  watch: {
    checkedVideo(val) {
      this.$emit('selectVideo', {videoId: this.video.id, checkedVideo: val})
    },
    selectedVideo() {
      this.getCheckedVideo()
    },
    currentStartDateIndex(newValue) {
      let start_date_string = moment(this.video.start_at, "YYYY-MM-DD").add(newValue, 'days').format("YYYY/M/D")
      let end_date = moment(start_date_string, "YYYY/MM/DD").add(6, 'days')
      if(moment() < moment(this.video.start_at, "YYYY-MM-DD")) {
        end_date = moment(this.video.end_at, "YYYY-MM-DD")
      } else if(moment(this.endDate, "YYYY-MM-DD") < end_date) {
        end_date = moment(this.endDate, "YYYY-MM-DD")
      }
      this.currentStartDateString = start_date_string + "~" + end_date.format("YYYY/M/D")
    },
  },
  created() {
    this.updateDataReport()
  },
  mounted() {
    this.getCheckedVideo()
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables/_colors.scss";

.video-detail {
  padding: 28px 16px 28px 28px;
  box-shadow: 0 2px 8px rgba(51, 51, 51, 0.1);
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid transparent;

  &.checked {
    border: 1px solid $green;
  }

  .video-info {
    padding: 0 28px;
    @media screen and (max-width: 1400px) {
      padding: 0 10px;
    }

    .video-status {
      display: inline-block;
      border: 1px solid $green;
      border-radius: 12px;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 700;
      color: #49AB94;
      padding: 5px 7px;
      margin-right: 20px;
      font-size: 12px;
      line-height: 12px;
      @media screen and (max-width: 1400px) {
        margin-right: 8px;
      }
      white-space: nowrap;
    }

    .video-date {
      font-family: 'Avenir Next';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $middle-black;
      @media screen and (max-width: 1400px) {
        font-size: 10px;
      }
    }

    .video-name {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: $black;
      white-space: initial;
      word-wrap: break-word;
      @media screen and (max-width: 1400px) {
        font-size: 16px;
        margin: 10px 0 12px;
      }
    }

    .list-label {
      margin-bottom: 24px;

      .label-icon {
        height: 15px;
      }

      .video-label {
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        color: $middle-black;
        margin-left: 5px;
        display: inline-block;
      }

      @media screen and (max-width: 1400px) {
        margin-bottom: 20px;
        .video-label {
          font-size: 12px;
        }
        .label-icon {
          height: 13px;
        }
      }
    }

    .video-thumbnail {
      margin-bottom: 30px;
      @media screen and (max-width: 1400px) {
        margin-bottom: 20px;
      }

      img {
        display: block;
        width: 100%;
        max-height: 200px;
        border-radius: 8px;
        margin: 0 auto;
        object-fit: cover;
        @media screen and (max-width: 1400px) {
          max-height: 125px;
        }
      }
    }

    .video-average {
      background: #EBEBEB;
      border-radius: 8px;
      padding: 24px 16px 16px;

      .row {
        flex-wrap: wrap;
      }

      @media screen and (max-width: 1400px) {
        padding: 16px;
      }

      .average-title {
        font-family: 'Avenir Next';
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        color: $black;
        display: inline-block;
        margin-bottom: 10px;
        @media screen and (max-width: 1400px) {
          font-size: 12px;
          margin-bottom: 0;
        }
      }

      .average-type {
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: $black;

        @media screen and (max-width: 1700px) {
          font-size: 12px;
          margin: 7px 0 5px;
        }

        @media screen and (max-width: 1024px) {
          font-size: 8px;
        }
      }

      .average-number {
        font-family: 'Avenir Next';
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        color: $black;
        @media screen and (max-width: 1700px) {
          font-size: 12px;
          margin: 0;
        }
      }
    }
  }

  .chart-list {
    position: relative;

    .icon-checkbox-white {
      background-image: url('/images/svg/icon_checkbox_white.svg');

      &.checked {
        background-image: url('/images/svg/icon_checkbox_white_checked.svg');
      }
    }

    .list-radio {
      margin-right: 10px;
    }

    .label-radio {
      font-size: 14px;
      margin-right: 0.75rem;
      @media screen and (max-width: 1400px) {
        font-size: 12px;
      }
    }

    .change-date-range {
      margin-top: -2px;
      .icon-change-date {
        cursor: pointer;
      }

      .date-value {
        line-height: 32px;
        margin: 0 10px;
        @media screen and (max-width: 1400px) {
          font-size: 12px;
        }
      }
    }

    .change-date-btn {
      background-color: unset;
      border: unset;

      &:disabled {
        opacity: 0.5;
      }
    }

    .list-chart-content {
      margin-left: 0;

      div {
        padding-left: 0 !important;
      }

      .chart-item {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border: 1px solid #EBEBEB;
        border-radius: 8px;
        padding-top: 20px;
        padding-right: 10px;
        padding-bottom: 5px;

        .label-chart-name {
          color: #49AB94;
          font-family: 'Noto Sans JP';
          font-weight: 800;
          font-size: 20px;
          line-height: 24px;
          margin-left: 10px;
          @media screen and (max-width: 1400px) {
            font-size: 15px;
          }
        }

        .trend-word-label {
          font-family: 'Avenir Next';
          font-style: normal;
          font-weight: 500;
          line-height: 12px;
          color: #1D9BF0;
          @media screen and (max-width: 1400px) {
            font-size: 12px;
          }
        }
      }

      .list-range-color {
        max-width: 50%;
        flex: 0 0 50%;
        font-size: 14px;
        color: $middle-black;
        @media screen and (max-width: 1400px) {
          font-size: 10px;
        }

        .item-color-chart {
          width: 37px;
          height: 15px;
          display: inline-block;
          @media screen and (max-width: 1400px) {
            width: 16px;
            height: 8px;
          }

          &.first-item {
            border-radius: 30% 0 0 30%;
            margin-left: 5px;
          }

          &.last-item {
            border-radius: 0 30% 30% 0;
            margin-right: 5px;
          }
        }
      }
    }

    .change-video-type {
      margin-bottom: 17px;

      @media screen and (max-width: 1400px) {
        margin-bottom: 13px;
      }
    }

  }
}

.circle {
  width: 14px;
  height: 14px;
  display: inline-block;
  border-radius: 50%;
  border: 1px dashed #1D9BF0;
  stroke-dasharray: 8, 8.5;
  @media screen and (max-width: 1400px) {
    width: 8px;
    height: 8px;
  }
}
</style>
