<template>
    <setting-password :isCreate="true"></setting-password>
    
</template>

<script>
import SettingPassword from './SettingPassword.vue';


export default {
  name: "CreatePassword",
  components: {
    SettingPassword
  },

};
</script>

<style lang="scss" scoped>
@import "@/styles/_login.scss"; 
</style>

