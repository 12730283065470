<template lang="">
    <div class="row justify-content-center align-items-center">
        <div class="col-11">
            <menu-tab :menuList="menuList" :selected="selectedMenu" @change="changeMenu"></menu-tab>
            <member-list v-if="selectedMenu == 1"></member-list>
            <company-info v-if="selectedMenu == 2"></company-info>
            <user-person v-if="selectedMenu == 3"></user-person>
        </div>
    </div>
</template>
<script>
import { menuListUserPage, menuListUserPageAdmin } from '@/commons/helpers';
import MenuTab from '@/components/Commons/MenuTab';
import MemberList from '@/components/Users/MemberList';
import CompanyInfo from '@/components/Users/CompanyInfo';
import UserPerson from '@/components/Users/UserPerson';
// import ModalStatusOn from '@/components/Users/ModalStatusOn';
export default {
    components:{
        MenuTab,
        MemberList,
        CompanyInfo,
        UserPerson
        // ModalStatusOn
    },
    data() {
        return {
            menuList: [],
            selectedMenu: 1,
        }
    },
    methods: {
        changeMenu(id) {
            this.selectedMenu = id;
        },
        displayCompanyMenu() {
            let vm = this;
            if (this.$store.getters.isAdmin) {
                vm.menuList = menuListUserPageAdmin;
            } else {
                vm.menuList = menuListUserPage;
            }
        }
    },
    mounted() {
        this.displayCompanyMenu();
    },
    created() {
    }
}
</script>
<style lang="">
</style>