/* eslint-disable */

import Vue from 'vue';
import Toasted from 'vue-toasted';
import router from '../routes/index';
import i18n from '../i18n';
import store from '../store';
const jQuery = require('jquery');

Vue.use(Toasted, {duration: 3000, position: "top-right"});

/**
 * Setup language
 */

let isExistError = false;

const processError = (status, data) => {
    switch (status) {
        case 400:
            showToastError(data.Message)
            break;
        case 401:
            showToastError(data.Message)
            if (!isExistError) {
                showToastError(i18n.t('messages.authenticate_failed'))
                localStorage.removeItem("user");
                localStorage.removeItem('token');
                store.commit('setCurrentUser', {});
                router.push({name: 'login'});
            }
            isExistError = true;
            break;
        case 403:
            showToastError(data.Message ? data.Message :  ('status' + ': ' + i18n.t('messages.authority_failed')))
            break;
        case 404:
            router.push({name: '404'});
            break;
        case 500:
            showToastError(status + ': ' + i18n.t('messages.server_error'))
            router.push({name: '500'});
            break;
        default:
            showToastError(i18n.t('messages.default_error'))
            break;
    }
}
const showToastError = (message) => {
    let toasts = Vue.toasted.toasts;
    if (!toasts.find(e => e.el.innerText == message)) {
        if (toasts.length > 0) {
            setTimeout(() => {
                Vue.toasted.clear();
                setTimeout(() => {
                    Vue.toasted.error(message);
                }, 1000)
            }, 1000)
        } else {
            Vue.toasted.error(message);
        }
    }
}
const configRequest = {
    headers: {
        'Content-Type': 'application/json'
    }
}

const http2 = {
    get(url, params, success, fail) {
        params = params || {};
        params.t = Date.now();
        Vue.http.get(url, {params: params}).then((data) => {
            if (data.status == 200) {
                success && success(data.body);
            }
        }).catch((error) => {
            // processError(error.status, error.body);
            fail && fail(error);
        });
    },
    getReportFile(url, options, success, fail) {
        Vue.http.get(url, {params: {t: Date.now()}}, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((data) => {
            let tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = data.body.download_url;
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            // window.open(data.body.download_url, '_blank');
        }).catch((error) => {
            processError(error.status, error.body);
            fail && fail(error);
        });
    },
    downloadCSV(url, options, success, fail) {
        Vue.http.get(url, {params: {t: Date.now()}}, {
            // responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((data) => {
            let universalBOM = "\uFEFF";
            // for IE 11
            if (window.navigator.msSaveOrOpenBlob) {
                var blob = new Blob([decodeURIComponent(encodeURIComponent(universalBOM + data.body.body))], {
                    type: "text/csv;charset=utf-8;"
                });
                window.navigator.msSaveOrOpenBlob(blob, options.filename);
            } else {
                const link = document.createElement('a');
                link.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + data.body.body));
                link.setAttribute('download', options.filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }).catch((error) => {
            processError(error.status, error.body);
            fail && fail(error);
        });
    },
    downloadCommentCSV(url, options, success, fail, msg = '') {
        Vue.http.get(url, {params: {t: Date.now()}}, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((data) => {
            let tempLink = document.createElement('a');
            if (data.body.download_url) {
                tempLink.style.display = 'none';
                tempLink.href = data.body.download_url;
                document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);
            } else {
                if (msg) Vue.toasted.error(msg)
            }
        }).catch((error) => {
            fail && fail(error);
        });
    },
    downloadFileS3(url) {
        let link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
    downloadSegmentCSV(url, options, success, fail) {
        Vue.http.get(url, {params: {t: Date.now()}}, {
            // responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((data) => {
			let tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = data.body.url;
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
        }).catch((error) => {
            processError(error.status, error.body);
            fail && fail(error);
        });
    },
    getList(url, params, success, fail) {
        params = params || {};
        params.t = Date.now();
        Vue.http.get(url, {params: params}).then((data) => {
            if (data.status == 200) {
                success && success(data.body);
            }
        }).catch((error) => {
            if (error.status && error.body) processError(error.status, error.body);
            fail && fail(error);
        });
    },
    post(url, params, success, fail) {
        Vue.http.post(url, params, configRequest).then((data) => {
            if (data.status == 200) {
                success && success(data.data);
            }
        }).catch((error) => {
            fail && fail(error);
            processError(error.status, error.body);
        });
    },
    postFile(url, params, success, fail) {
        Vue.http.post(url, params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((data) => {
            if (data.status == 200) {
                success && success();
            }
        }).catch((error) => {
            fail && fail(error);
            processError(error.status, error.body);
        });
    },
    put(url, params, success, fail) {
        Vue.http.put(url, params, configRequest).then((data) => {
            if (data.status == 200 || data.status == 202 || data.status == 204) {
                success && success();
            }
        }).catch((error) => {
            // Vue.toasted.show(error.status + ': ' + i18n.t('messages.saveFail'));
            processError(error.status, error.body);
            fail && fail(error);
        });
    },
    delete(url, params, success, fail) {
        if (typeof params == 'object' && Object.prototype.hasOwnProperty.call(params, "ids")) {
            params.ids = params.ids.map(function (item) {
                return parseInt(item);
            });
        }

        Vue.http.delete(url, {body: params}, configRequest).then((data) => {
            if (data.status == 200) {
                // Vue.toasted.success(i18n.t('messages.hide_success'));
                success && success();
            }
        }).catch((error) => {
            // Vue.toasted.show(error.status + ': ' + i18n.t('messages.saveFail'));
            processError(error.status, error.body);
            fail && fail(error);
        });
    },
    uploadFileS3(url, file, success, fail) {
        let formData = new FormData();
        formData.append('file', file);
        // let contentType = file.type;
        if(url.includes("video_tmp_capture")){
            jQuery.ajax({
                type: 'PUT',
                url: url,
                // dataType: 'html',
                processData: false,
                // headers: {'Content-Type': contentType},
                contentType: 'image/jpeg',
                // crossDomain: true,
                data: file,
                success: function (data) {
                    success && success(data);
                },
                error: function (error) {
                    Vue.toasted.error(error.status + ': ' + i18n.t('messages.upload_s3_failed'));
                    fail && fail();
                }
            });
        } else {
            jQuery.ajax({
                type: 'PUT',
                url: url,
                // dataType: 'html',
                processData: false,
                // headers: {'Content-Type': contentType},
                contentType: 'binary/octet-stream',
                // crossDomain: true,
                data: file,
                success: function (data) {
                    success && success(data);
                },
                error: function (error) {
                    Vue.toasted.error(error.status + ': ' + i18n.t('messages.upload_s3_failed'));
                    fail && fail();
                }
            });
        }
    },
    getExternalAPI(url, success, fail) {
        jQuery.ajax({
            type: 'GET',
            url: url,
            processData: false,
            success: function (data) {
                success && success(data);
            },
            error: function (error) {
                fail && fail();
            }
        });
    },
    downloadPDF(url, label, success, fail) {
        jQuery.ajax({
            type : 'GET',
            url: url,
            success: function (data) {
              var link=document.createElement('a');
              link.href=url;
              link.download= label;
              document.body.append(link);
              link.click();
              document.body.removeChild(link);
              success && success(data);
            },
            error: function (error) {
                fail && fail();
            }
          });
    },
};

export default {
    ...http2,
    showToastError
};
