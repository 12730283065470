import en from './locales/en.json'
import jp from './locales/jp.json'
import Vue from 'vue'
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export default new VueI18n({
    locale: 'jp',
    messages: {
        en,
        jp
    }
})