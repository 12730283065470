export const plan = {
    1: ['trial'], // not used
    2: ['basic'], // not used
    3: ['private'],
    4: ['free'],
    5: ['market'],
    6: ['market', 'private'],
    7: ['advance'],
};

export const plan_sidebar = [
    {
        position: 'top',
        items: [
            {
                router: 'home',
                icon_name: 'home',
                class: 'mt-8',
                plans: ['admin', 'private', 'free', 'advance'],
                icon_text : 'ホーム',
                routes: [
                    'top-detail-step-1',
                    'top-detail',
                    'home',
                    'top-compare',
                    'top-compare-step-1',
                    'top-compare-step-3',
                    'step-3'
                ]
            },
            // {
            //     router: 'top-compare',
            //     icon_name: 'compare',
            //     class: 'mt-8',
            //     plans: ['admin', 'private', 'free'],
            //     icon_text : '全体比較'
            // },
            // {
            //     router: 'project',
            //     icon_name: 'project',
            //     class: 'mt-8',
            //     plans: ['admin', 'private', 'free'],
            //     icon_text : '動画一覧'
            // },
            {
                router: 'market-research',
                icon_name: 'market',
                class: 'mt-8 h-60',
                plans: ['admin','free','private', 'advance'],
                icon_text : "マーケット \n リサーチ",
                routes: [
                    "market-research",
                ]
            },
            {
                router: 'tableau-report',
                icon_name: 'tableau',
                class: 'mt-8',
                plans: ['admin','free','private', 'advance'],
                icon_text : "TARGET",
                routes: [
                    "tableau-report",
                ]
            },
            {
                router: 'clients',
                class: 'mt-8',
                icon_name: 'panel',
                plans: ['admin'],
                icon_text : '企業一覧',
                routes: [
                    "clients",
                ]
            }
        ]

    },
    {
        position: 'bottom',
        items: [
            {
                router: 'add',
                icon_name: 'add',
                class: 'mb-8',
                plans: ['admin', 'free', 'private', 'advance'],
                icon_text : 'CM動画追加',
                routes: [
                    "video-check",
                    "video-complete",
                    "add",
                ]
            },
            {
                router: 'integrate-youtube',
                icon_name: 'youtube',
                class: 'mb-8',
                plans: ['admin', 'private', 'advance'],
                icon_text : 'YouTube連携',
                routes: [
                    "integrate-youtube",
                ]
            },
            {
                router: 'users',
                class: 'mb-8',
                icon_name: 'user',
                plans: ['admin', 'free', 'private', 'advance'],
                icon_text : 'メンバー一覧',
                routes: [
                    "users",
                ]
            }

        ]
    }
]

export const planTypes = {
    trial: 1, // not used
    basic: 2, // not used
    private: 3,
    free: 4,
    market: 5,
    market_private: 6,
    advance: 7,
}

export const role = {
    admin: 1,
    buyer: 2
}
