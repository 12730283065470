<template>
  <div class="row justify-content-center mt-3">
    <div class="col-11">
      <div class="form-group required" v-if="currentUser.role == 'admin'">
        <label>クライアント</label>
        <div class="form-control buyer-select">
          {{ buyerSelected.name }}
        </div>
      </div>
      <div class="form-group required">
        <label>動画名</label>
        <input
          class="form-control input-disabled"
          type="text"
          placeholder="案件名を入れてください"
          :value="video.name"
          disabled
        />
      </div>
      <div class="form-group required" v-show="video.deliver_period_type === listStep.step3.id">
        <label>媒体</label>
        <div class="list-radio">
          <div
              class="form-radio-box mr-5" :class="{'radio-disabled': video.commercial_media_type !== type.id}"
              v-for="(type, key) in listMediaType"
              :key="'platform-' + key"
          >
            <input
                class="form-radio-input"
                type="radio"
                :value="type.id"
                :checked="type.id == video.commercial_media_type"
                disabled
            />
            <span class="btn-radio"></span>
            <label class="label-radio">{{ type.name }}</label>
          </div>
        </div>
      </div>
      <div class="form-group form-group-label w-full">
        <label>ラベル</label>
        <div class="group-tag-list pt-0 border-0">
          <div
              class="tag tag-two cursor-default"
              :class="{
              active: checkExistSelectedLabel(label.id),
            }"
              v-for="label in labels"
              :key="'label-' + label.id"
          >
            <div>{{ label.name }}</div>
          </div>
        </div>
      </div>
      <div class="d-flex datetime-content">
        <div class="form-group required">
          <label>検証期間</label>
          <input
            class="form-control datetimepicker input-disabled cursor-default"
            type="text"
            name="daterange"
            placeholder="検証期間を選択"
            disabled
          />
        </div>
        <div class="form-group small required"
             v-show="video.deliver_period_type === listStep.step2.id"
        >
          <label>{{ $t("texts.video.start_time") }}</label>
          <input
            class="form-control input-disabled cursor-default input-time"
            type="text"
            disabled
            :value="video.start_time_at"
          />
        </div>
      </div>
      <div class="form-group required">
        <label>調査対象国</label>
        <Select
          :data="countries"
          :idSelected="video.country_id"
          :isSelected="true"
          :element="'countries'"
        ></Select>
      </div>
      <div class="form-group required large">
        <label>検証方法</label>
        <div class="list-radio">
          <div class="form-radio-box mr-5" :class="{'radio-disabled': video.video_url_type !== 1}">
            <input
              id="video-file"
              class="form-radio-input"
              type="radio"
              value="1"
              name="type"
              disabled
            />
            <span class="btn-radio"></span>
            <label class="label-radio">動画ファイルで調査</label>
          </div>
          <div class="form-radio-box mr-5" :class="{'radio-disabled': video.video_url_type !== 2}">
            <input
              id="youtube-url"
              class="form-radio-input"
              type="radio"
              value="2"
              name="type"
              disabled
            />
            <span class="btn-radio"></span>
            <label class="label-radio">YouTubeURLで調査</label>
          </div>
        </div>
      </div>
      <div
        class="
          file-upload-wrapper
          mb-2
          row
          justify-content-center
          align-items-center
        "
        v-if="video.video_url_type == videoURLTypes.NORMAL.value"
      >
        <label class="color-primary d-flex align-items-center">
          <i class="icon icon-check mr-2"></i>
          {{ video.video_file_text }}
        </label>
        <div class="input-file">
          <label class="color-primary" for="file-upload">
            <i class="icon icon-download mr-1"></i>
            ファイルを選択
          </label>
          <input type="file" id="file-upload" disabled />
        </div>
      </div>
      <div
        class="d-flex mb-2 align-items-center"
        v-if="video.video_url_type == videoURLTypes.YOUTUBE.value"
      >
        <label class="color-black mr-4"> YouTube動画ID </label>
        <div class="form-group custom mb-0">
          <input
            class="form-control input-disabled"
            placeholder="https://www.youtube.com/feijogrv="
            v-model.trim="video.video_url"
            disabled
          />
        </div>
      </div>
      <div class="title-small color-black-middle">
        <template v-if="video.video_url_type == videoURLTypes.NORMAL.value">{{
            videoURLTypes.NORMAL.desc
          }}</template>
        <template v-else>{{ videoURLTypes.YOUTUBE.desc }}</template>
      </div>
      <div class="form-group mt-7">
        <div class="form-check-box radio-disabled">
          <input
            id="youtube-analytics"
            class="form-check-input cursor-default"
            type="checkbox"
            name="youtube-channel"
            disabled
          />
          <span class="btn-check"> </span>
          <label class="label-check">YouTubeの視聴率を取り込む</label>
        </div>
      </div>
      <div class="form-group required" v-show="isYoutubeAnalytics">
        <label>チャンネル</label>
        <Select
          :data="youtubeChannels"
          @select="selectChannel"
          :idSelected="video.youtube_analytic_id"
          :isSelected="true"
          :element="'channels'"
        ></Select>
      </div>
      <div class="form-group custom " v-show="isYoutubeAnalytics">
        <label>YouTube動画ID</label>
        <input
          class="form-control input-disabled"
          type="text"
          placeholder="https://www.youtube.com/watch?v="
          :value="video.youtube_video_id"
          disabled
        />
      </div>
      <div class="form-group w-full flex flex-col" v-show="video.deliver_period_type === listStep.step2.id">
        <label>反応値の検証方法</label>
        <div class="radio-list flex flex-row flex-wrap">
          <div
              class="form-radio-box mr-5"
              :class="{'radio-disabled': video.deliver_flg != type.value}"
              v-for="(type, key) in verifyMethods"
              :key="'verify-methods-' + key"
          >
            <input
                class="form-radio-input"
                type="radio"
                :value="type.value"
                :checked="type.value === video.deliver_flg"
                disabled
            />
            <span class="btn-radio"></span>
            <label class="label-radio">{{ type.text }}</label>
          </div>
        </div>
        <div class="verification-methods-description">
          2種類の反応値を検証：1回の再生で2種類の反応値ボタンを設置して検証 <br>
          2種類の反応値を交互に検証：2回の再生で1種類の反応値ボタンを交互に設置して検証 <br>
          1種類の反応値を検証：1回の再生で1種類の反応値ボタンを設置して検証 <br>
        </div>
      </div>

      <div class="form-group large flex flex-col" v-show="video.deliver_period_type !== listStep.step3.id">
        <label>反応値種別</label>
        <template v-if="video.deliver_period_type === listStep.step2.id">
          <div class="radio-list flex flex-row flex-wrap" v-if="video.deliver_flg != 3">
            <div
                class="form-radio-box mr-5"
                :class="{'radio-disabled': video.interaction_btn_1_index != type.index}"
                v-for="(type, key) in interactionTypes"
                :key="'interaction-type' + key"
            >
              <input
                  class="form-radio-input"
                  type="radio"
                  :value="type.index"
                  :checked="type.index == video.interaction_btn_1_index"
                  disabled
              />
              <span class="btn-radio"></span>
              <label class="label-radio mb-3">{{ type.text }}</label>
            </div>
          </div>
          <div class="radio-list flex flex-row flex-wrap" v-else>
            <div
                class="form-radio-box mr-5"
                :class="{'radio-disabled': video.interaction_btn_2_index != type.index}"
                v-for="(type, key) in interactionTypeVerifyOne"
                :key="'interaction-type' + key"
            >
              <input
                  class="form-radio-input"
                  type="radio"
                  :value="type.index"
                  :checked="type.index == video.interaction_btn_2_index"
                  disabled
              />
              <span class="btn-radio"></span>
              <label class="label-radio">{{ type.text }}</label>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="radio-list flex flex-row flex-wrap">
            <div
                class="form-radio-box mr-5"
                :class="{'radio-disabled': video.interaction_btn_3_index != type.index}"
                v-for="(type, key) in interactionTypesStep1"
                :key="'interaction-type' + key"
            >
              <input
                  class="form-radio-input"
                  type="radio"
                  :value="type.index"
                  :checked="type.index == video.interaction_btn_3_index"
                  disabled
              />
              <span class="btn-radio"></span>
              <label class="label-radio">{{ type.text }}</label>
            </div>
          </div>
        </template>
      </div>

      <div class="form-group w-full"
           v-if="currentUser.role === 'admin' &&
           video.deliver_period_type === listStep.step2.id &&
           !video.id"
      >
        <label>RE社修正対応フラグ</label>
        <div class="list-radio">
          <div
            class="form-radio-box mr-5"
            v-for="(type, key) in manualTrafficStatus"
            :key="'status-type-' + key"
            :class="{'radio-disabled': video.is_admin_change != type.value}"
          >
            <input
              class="form-radio-input"
              type="radio"
              :value="type.value"
              :checked="video.is_admin_change === type.value"
              disabled
            />
            <span class="btn-radio"></span>
            <label class="label-radio">{{ type.text }}</label>
          </div>
        </div>
        <div class="describe-segment">
          ON:手動入稿を行いたいときに選択してください（サンプル数の変更・特殊なセグメントの利用など）<br>
          &nbsp; &nbsp; &nbsp; &nbsp; 自動連携対象外の案件については自動的にONが選択されます。
        </div>
      </div>

      <div
        v-if="
          video.deliver_period_type !== listStep.step3.id &&
          buyerSelected.plan !== planTypes.FREE_PLAN.buyer_plan
        "
        class="form-group sub-form-group flex flex-col w-full"
      >
        <div class="screening-options">
          <p>スクリーニングオプション</p>
          <button @click="openFormScreeningOption" class="d-flex" :class="{'screening-options-done': video.screening}">
            <i v-if="video.screening" class="icon icon-check-small mr-1"></i>
            {{ video.screening ? 'スクリーニングオプション設定済み' : 'スクリーニングオプション設定' }}
          </button>
        </div>
      </div>
      <div class="form-group" v-if="video.deliver_period_type === listStep.step2.id">
        <label>認知度調査</label>
        <div class="list-radio">
          <div class="form-radio-box mr-5" :class="{'radio-disabled': video.cognition_survey != 1}">
            <input
              class="form-radio-input implement"
              type="radio"
              value="1"
              name="cognition_survey"
              disabled
            />
            <span class="btn-radio"></span>
            <label class="label-radio">実施する</label>
          </div>
          <div class="form-radio-box mr-5" :class="{'radio-disabled': video.cognition_survey != 0}">
            <input
              class="form-radio-input not-implement"
              type="radio"
              value="0"
              name="cognition_survey"
              disabled
            />
            <span class="btn-radio"></span>
            <label class="label-radio">実施しない</label>
          </div>
        </div>
      </div>
      <div
         class="form-group"
         v-if="video.deliver_period_type === listStep.step2.id"
      >
        <label>コメントディスカバリーAI</label>
        <div class="list-radio">
          <div
            class="form-radio-box mr-5"
            v-for="(type, key) in commentDiscoveryStatus"
            :key="'comment-discovery-status-' + key"
            :class="{'radio-disabled': rsCDAIStatus != type.value}"
          >
            <input
              class="form-radio-input"
              type="radio"
              :value="type.value"
              :checked="type.value === rsCDAIStatus"
              disabled
            />
            <span class="btn-radio"></span>
            <label class="label-radio">{{ type.text }}</label>
          </div>
        </div>
      </div>
      <!-- start video capture  -->
      <div class="form-group w-full"
           v-if="
            video.deliver_period_type === listStep.step2.id &&
            video.deliver_flg != verifyMethods.ONE_TYPE.value &&
            !video.id
           "
      >
        <div v-show="video.is_rs_cdai == commentDiscoveryStatus.ON.value">
          <label>{{ $t('texts.video.cdai_label') }}</label>
          <div class="default-question-cdai" v-if="defaultQuestionCDAI.length">
            <div
                class="question-item intention-item-checkbox"
                v-for="(question, index) in defaultQuestionCDAI"
                :key="'question-item-' + index"
            >
              <i class="icon icon-checkbox"
                 :class="{ checked: checkedQuestionCDAI.default[video.deliver_flg].includes(index) }"
              ></i>
              <span class="intention-item-name" v-html="question.text"></span>
            </div>
          </div>
          <label
            v-show="checkedQuestionCDAI.admin_custom.length ||
            checkedQuestionCDAI.buyer_custom.length ||
            currentUser.role === 'admin'"
          >
            {{ $t('texts.video.custom_question_cdai_label') }}
          </label>
          <div class="buyer-question-cdai" v-show="checkedQuestionCDAI.buyer_custom.length">
            <div
                class="question-item intention-item-checkbox w-full"
                v-for="(question, index) in checkedQuestionCDAI.buyer_custom"
                :key="'question-item-buyer' + index"
            >
              <i class="icon icon-checkbox"
                 :class="{ checked: question.is_checked }"
              ></i>
              <span class="intention-item-name">設問：</span>
              <span class="intention-item-name" v-html="question.text"></span>
            </div>
          </div>
          <div class="admin-question-cdai">
            <div class="list-question-cdai-admin">
              <div
                  class="question-item intention-item-checkbox  w-full"
                  v-for="(question, index) in checkedQuestionCDAI.admin_custom"
                  :key="'question-item-admin-' + index"
              >
                <template v-if="question.text.length">
                  <i class="icon icon-checkbox"
                     :class="{ checked: question.is_checked }"
                  ></i>
                  <span class="intention-item-name">設問：</span>
                  <span class="intention-item-name" v-html="question.text"></span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div
          class="list-image-capture"
          v-show="
            videoCaptureData[video.video_url_type].duration > 0
          "
        >
          <span class="capture-label">{{ $t('texts.video.capture_label') }}</span>
          <span class="count-image-capture">
            {{ $t('texts.video.count_capture_label') }}：{{ videoCaptureData[video.video_url_type].video_comment_scene.length }}
          </span>
          <div class="list-image-content">
            <div
              class="image-capture"
              v-for="index in videoCaptureData[video.video_url_type].duration"
              :key="'image-capture-' + index"
            >
              <div class="image-capture-item">
                <div class="image-wrapper">
                  <img :src="videoCaptureData[video.video_url_type].url + (index) + '.jpg' + '?t=' + new Date().getTime()"
                       :alt="'video-image' + (index)"
                       :data-url-type="videoCaptureData[video.video_url_type].url"
                       :data-index="index"
                  />
                </div>
              </div>
              <div class="image-capture-item-checkbox">
                <i
                  class="icon icon-checkbox"
                  :class="{ checked: videoCaptureData[video.video_url_type].video_comment_scene.includes(index) }"
                >
                </i>
                <span class="second-text">{{ index }}s</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group w-full"
           v-else-if="
              video.deliver_period_type === listStep.step2.id &&
              video.deliver_flg != verifyMethods.ONE_TYPE.value &&
              video.id && (video.cdai_question.length || video.image_data)
             "
      >
        <div class="default-question-cdai" v-if="video.cdai_question.length">
          <label>{{ $t('texts.video.cdai_label') }}</label>
          <div
            class="question-item intention-item-checkbox"
            v-for="(question, index) in video.cdai_question"
            :key="'question-item-' + index"
          >
            <i class="icon icon-checkbox checked"></i>
            <span class="intention-item-name" v-html="question.question"></span>
          </div>
        </div>
        <div
          class="list-image-capture"
          v-show="video.image_data"
        >
          <span class="capture-label">{{ $t('texts.video.capture_label') }}</span>
          <div class="list-image-content">
            <div
              class="image-capture"
              v-for="(second, index) in video.scene"
              :key="'image-capture-' + index"
            >
              <div class="image-capture-item">
                <div class="image-wrapper">
                  <img :src="convertAccessUrl(video.image_data) + second + '.jpg'"
                       :alt="'video-image' + second"
                  />
                </div>
              </div>
              <div class="image-capture-item-checkbox">
                <span class="second-text">{{ second }}s</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end video capture  -->

      <div class="form-group w-full" v-show="video.is_rs_cdai && video.deliver_period_type == 2">
        <label>生成AIを利用して結果の示唆を算出</label>
        <div class="radio-list flex flex-row flex-wrap">
          <div class="form-radio-box mr-5"
               :class="{'radio-disabled': video.is_chatgpt != 1}"
          >
            <input class="form-radio-input" type="radio" value="1" v-model="video.is_chatgpt" disabled/>
            <span class="btn-radio"></span>
            <label class="label-radio mb-3 label-text">利用する</label>
          </div>
          <div class="form-radio-box mr-5"
               :class="{'radio-disabled': video.is_chatgpt != 0}"
          >
            <input class="form-radio-input" type="radio" value="0" v-model="video.is_chatgpt" disabled/>
            <span class="btn-radio"></span>
            <label class="label-radio mb-3 label-text">利用しない</label>
          </div>
        </div>
        <div class="wrap-chatgpt">
          <label>算出イメージ ></label>
        </div>
      </div>

      <!-- intention start -->
      <div class="form-group w-full intention-content"
           v-show="video.deliver_period_type === listStep.step2.id && intentionList.length > 0 &&
            video.deliver_flg != verifyMethods.ONE_TYPE.value"
      >
        <label class="w-full">{{ $t('texts.video.setting_intention') }}</label>
        <div class="group-intention-list">
          <div
              class="intention-item"
              v-for="(intentionDataItem, index) in intentionData.select_data"
              :key="'intention-data-' + index"
          >
            <div class="intention-item-checkbox">
              <i class="icon icon-checkbox"
                 :class="{
                      checked: videoIntentionIds.includes(intentionDataItem.id_selected),
                      disabled: !isBeforeDate && video.id
                   }"
                 @click="isBeforeDate && video.id || !video.id ? checkedIntention(intentionDataItem.id_selected) : () => {}"></i>
              <span class="intention-item-name">{{ $t('texts.video.intention_option', { number: index + 1 }) }}</span>
            </div>
            <div class="intention-item-select">
              <SelectCustom
                  class="select-intention"
                  :data="intentionDataItem.intention_items"
                  :isSelected="true"
                  :id-selected="intentionDataItem.id_selected"
                  :element="'intention-' + index"
                  :enable-search="false"
                  :index="index"
                  @select="selectIntention"
                  :disabled="!isBeforeDate && video.id"
              ></SelectCustom>
            </div>
          </div>
        </div>
        <div
            v-show="
              !(intentionData.select_data.length === intentionList.length) && !video.id ||
              isBeforeDate && video.id
            "
            class="btn-plus-intention"
            @click="addIntention"
        >
          {{"＋&nbsp;&nbsp;&nbsp;" + $t('texts.video.add_intention') }}
        </div>
      </div>
      <!-- intention end -->

      <!-- segment start -->
      <div v-if="video.deliver_period_type != listStep.step3.id" class="form-group sub-form-group flex flex-col w-full">
        <label>セグメントを設定</label>
        <div class="radio-list flex flex-row flex-wrap">
          <div
            class="form-radio-box mr-5"
            :class="{'radio-disabled': video.has_custom_segment != hasCustomSegment.value}"
            v-for="(hasCustomSegment, key) in hasCustomSegmentList"
            :key="'has-custom-segment-' + key"
          >
            <input
              class="form-radio-input"
              type="radio"
              :checked="video.has_custom_segment == hasCustomSegment.value"
              :value="hasCustomSegment.value"
              disabled
            />
            <span class="btn-radio"></span>
            <label class="label-radio">{{ hasCustomSegment.text }}</label>
          </div>
        </div>
        <template v-if="video.has_custom_segment == CUSTOM_SEGMENT_ON_VALUE">
          <div class="preview-group-segment list-attribute flex flex-col my-2" v-show="groupsSegment.length > 0 && groupsSegment[0].length > 0">
            <div class="attributes text-left px-5">
              <div
                v-for="(groupAttributes, index) in groupsSegment"
                :key="'group' + index"
                class="list-item py-5"
                :class="{
                    'list-item-selected': groupAttributes.length > 0,
                    'border-none': index + 1 == groupsSegment.length
                  }"
              >
                <div class="title-item pb-3">{{ `セグメント項目 ${index + 1}` }}</div>
                <div class="groups-segment">
                  <div class="describe-wrapper" v-if="groupAttributes.length != 0">
                    <div class="describe-item-selected">
                      <div class="scroll-tab">
                        <div
                          class="att-selected mb-2"
                          v-for="(attribute, index) in groupAttributes"
                          :key="'att-selected-' + index"
                        >
                          <div class="name">{{ attribute.name }}</div>
                          <div class="value inline-flex">
                              <span
                                v-for="(attributeValue, index) in attribute.list"
                                :key="'index' + index + 'value-selected-' + attributeValue.id"
                              >
                                {{ attributeValue.value }}
                              </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="describe-panel">
          {{ segmentSwich.OFF.key }} : {{ segmentSwich.OFF.desc }}
        </div>
        <div v-if="buyerSelected.plan == planTypes.FREE_PLAN.buyer_plan" class="describe-segment">
          スクリーニングオプションはADVANCEプラン、BASICプランの訴求検証・演出検証でご利用いただけます
        </div>
      </div>
      <!-- segment end -->

      <div class="form-group" v-if="video.deliver_period_type === listStep.step2.id || video.id">
        <label>ステータス</label>
        <div class="list-radio">
          <div class="form-radio-box mr-5" :class="{'radio-disabled': video.status != 1}">
            <input
              class="form-radio-input status-active"
              type="radio"
              value="1"
              name="status"
              disabled
            />
            <span class="btn-radio"></span>
            <label class="label-radio">有効</label>
          </div>
          <div class="form-radio-box mr-5" :class="{'radio-disabled': video.status != 0}">
            <input
              class="form-radio-input status-inactive"
              type="radio"
              value="0"
              name="status"
              disabled
            />
            <span class="btn-radio"></span>
            <label class="label-radio">無効</label>
          </div>
        </div>
      </div>
      <div class="form-group" v-show="video.deliver_period_type == listStep.step2.id && video.id">
        <label>入稿形式</label>
        <div class="list-radio">
          <div
            class="form-radio-box mr-5" :class="{'radio-disabled': rsLinkageStatus !== type.value}"
            v-for="(type, key) in rsLinkageStatusInput"
            :key="'rs-linkage-status-' + key"
          >
            <input
              class="form-radio-input"
              type="radio"
              :value="type.value"
              :checked="rsLinkageStatus === type.value"
              disabled
            />
            <span class="btn-radio"></span>
            <label class="label-radio">{{ type.text }}</label>
          </div>
        </div>
      </div>

      <div class="form-group form-group-label w-full" v-show="video.deliver_period_type == listStep.step3.id">
        <label v-show="video.twitter_keywords && video.twitter_keywords.length">トレンドワード <ToolTip :text="$t('texts.tooltip.keyword')"></ToolTip></label>
        <div class="group-tag-list">
          <div
              class="tag tag-two tag-keyword"
              v-for="(keyword, index) in video.twitter_keywords"
              :key="'keyword-' + index"
          >
            <div>{{ keyword }}</div>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="form-group">
          <button class="btn btn-primary btn-submit" @click="isShowModalConfirm = true">
            登録
          </button>
          <div class="btn button-prev" @click="prevPage">前に戻る</div>
        </div>
      </div>
      <modal-confirm
        v-if="isShowModalConfirm"
        :message="modalMessage"
        @confirm="register"
        @closeModalConfirm="isShowModalConfirm = false"
      ></modal-confirm>

      <FormScreeningOption
          :questions="questions"
          :isShown="showModalName == 'screening-option'"
          :readOnly="true"
          @closeModal="closeModalScreeningOption"
      ></FormScreeningOption>
    </div>
  </div>
</template>
<script>
import ToolTip from "@/components/Commons/ToolTip.vue";
const jQuery = require("jquery");
import flatpickr from "flatpickr";
import moment from "moment";
import { Japanese } from "flatpickr/dist/l10n/ja.js";
import i18n from "../../i18n";
import EventBus from "../../commons/event_bus";

import Select from "../../components/Video/Select.vue";
import ModalConfirm from "./../../components/Modal/ModalConfirm.vue";
import FormScreeningOption from "@/components/Project/FormScreeningOption";
import {
  videoURLTypes,
  verifyMethods,
  interactionTypes,
  interactionTypeVerifyOne,
  planTypes,
  segmentSettingList,
  SETTING_SEGMENT_SAME_AS_PREVIOUS_VALUE,
  SETTING_SEGMENT_CUSTOM_VALUE,
  hasCustomSegmentList,
  CUSTOM_SEGMENT_ON_VALUE,
  segmentSwich,
  panelTypes,
  stepListInfo,
  listMediaType,
  interactionTypesStep1,
  RS_LINKAGE_STATUS,
  NOT_IMPLEMENT_COGNITION_SURVEY,
  manualTrafficStatus,
  commentDiscoveryStatus,
  rsLinkageStatusInput,
} from "@/commons/constants";
import { listLabels } from "@/commons/api_service";
import SelectCustom from "@/components/Video/SelectCustom.vue";
import {intentionFunction} from "@/commons/mixins";

export default {
  components: {
    SelectCustom,
    ToolTip,
    Select,
    ModalConfirm,
    FormScreeningOption
  },
  mixins: [intentionFunction],
  data() {
    let video = JSON.parse(localStorage.getItem("video_register"));
    if (video.video_url_type == videoURLTypes.YOUTUBE.value) {
      video.video_url = "https://www.youtube.com/watch?v=" + video.video_url;
      video.youtube_video_id =
          "https://www.youtube.com/watch?v=" + video.youtube_video_id;
    }
    let buyerId = parseInt(localStorage.getItem("buyer_id"));
    let layers = JSON.parse(localStorage.getItem("layers"));
    let groupsSegment = JSON.parse(localStorage.getItem("groupsSegment"));
    let interactionTypesCustom = JSON.parse(localStorage.getItem("interactionTypesCustom"));
    let interactionTypesCustomStep1 = JSON.parse(localStorage.getItem("interactionTypesCustomStep1"));
    let interactionTypeVerifyOneCustom = JSON.parse(localStorage.getItem("interactionTypeVerifyOneCustom"));
    let questions = JSON.parse(localStorage.getItem("screening_option"))
    let segmentSettingModel = JSON.parse(localStorage.getItem("segmentSettingModel"))
    let previousVideoId = JSON.parse(localStorage.getItem("previousVideoId"))
    let intentionList = JSON.parse(localStorage.getItem("intentionList"))
    let intentionData = JSON.parse(localStorage.getItem("intentionData"))
    let videoCaptureData = JSON.parse(localStorage.getItem("videoCaptureData"))
    let checkedQuestionCDAI = JSON.parse(localStorage.getItem("checkedQuestionCDAI"))

    return {
      currentUser: {},
      countries: [],
      clients: [],
      youtubeChannels: [],
      video,
      isYoutubeAnalytics: false,
      fileName: "動画ファイルをドラッグ",
      modalMessage: i18n.t("messages.confirm_register"),
      registerSuccessMessage: i18n.t("messages.register_success"),
      updateSuccessMessage: i18n.t("messages.update_success"),
      buyerId,
      video_file: null,
      panelTypes: panelTypes,
      isShowModalConfirm: false,
      videoURLTypes: videoURLTypes,
      layers,
      labels: [],
      selectedLabels: [],
      verifyMethods: verifyMethods,
      interactionTypes: interactionTypesCustom ?? interactionTypes,
      interactionTypesStep1: interactionTypesCustomStep1 ?? interactionTypesStep1,
      interactionTypeVerifyOne : interactionTypeVerifyOneCustom?? interactionTypeVerifyOne,
      planTypes,
      buyerSelected: {},
      hasCustomSegmentList,
      CUSTOM_SEGMENT_ON_VALUE,
      segmentSwich: segmentSwich,
      groupsSegment: groupsSegment,
      showModalName: '',
      questions: questions ?? [{
        id: 1, name: 'Q1', value: '', isChecked: {}, options: []
      }],
      // new feature
      segmentSettingList,
      SETTING_SEGMENT_SAME_AS_PREVIOUS_VALUE,
      SETTING_SEGMENT_CUSTOM_VALUE,
      segmentSettingModel: segmentSettingModel,
      previousVideoId: previousVideoId,
      listMediaType: JSON.parse(JSON.stringify(listMediaType)),
      listStep : JSON.parse(JSON.stringify(stepListInfo)),
      intentionData: intentionData,
      intentionList: intentionList,
      commentDiscoveryStatus,
      manualTrafficStatus,
      rsLinkageStatusInput,
      // for video capture
      videoCaptureData: videoCaptureData,
      checkedQuestionCDAI: checkedQuestionCDAI,
    };
  },
  computed: {
    rsLinkageStatus: function () {
      let currentStatus = this.video.rs_linkage;
      if (this.isBeforeDate) {
        currentStatus = rsLinkageStatusInput.OFF.value;
      }

      return currentStatus;
    },
    defaultQuestionCDAI: function () {
      return this.getDefaultQuestionCDAI()
    },
    videoTmpIdParams: function () {
      if (
        this.video.deliver_period_type != this.listStep.step2.id ||
        this.video.deliver_flg == verifyMethods.ONE_TYPE.value
      ) {
        return null
      }
      if (this.videoCaptureData[this.video.video_url_type].duration > 0) {
        return this.videoCaptureData[this.video.video_url_type].video_tmp_id
      }
      return null
    },
    videoCommentSceneParams: function () {
      if (
        this.video.deliver_period_type != this.listStep.step2.id ||
        this.video.deliver_flg == verifyMethods.ONE_TYPE.value
      ) {
        return null
      }
      let copyCaptureData = JSON.parse(JSON.stringify(this.videoCaptureData))
      if (copyCaptureData[this.video.video_url_type].duration > 0) {
        return copyCaptureData[this.video.video_url_type].video_comment_scene.sort((a, b) => a - b).join(',')
      }
      return null
    },
    cdaiQuestionParams: function () {
      return this.getCdaiQuestionParams()
    },
    hasCustomCDAIQuestion: function () {
      let hasCustomQuestionChecked = false
      // find question in admin custom is checked
      this.checkedQuestionCDAI.admin_custom.forEach(item => {
        if (item.is_checked) {
          hasCustomQuestionChecked = true
        }
      })
      // find question in buyer custom is checked
      this.checkedQuestionCDAI.buyer_custom.forEach(item => {
        if (item.is_checked) {
          hasCustomQuestionChecked = true
        }
      })

      return hasCustomQuestionChecked
    },
    rsCDAIParams: function () {
      let param = this.video.is_rs_cdai
      if (
        this.video.is_rs_cdai == commentDiscoveryStatus.ON.value &&
        this.video.deliver_period_type == this.listStep.step2.id &&
        this.video.deliver_flg != verifyMethods.ONE_TYPE.value
      ) {
        if(this.video.deliver_flg == verifyMethods.ALTERNATELY_TWO_TYPES.value) {
          if(
            this.checkedQuestionCDAI.default[this.video.deliver_flg].length == 2 && !this.hasCustomCDAIQuestion
          ) {
            param = 1
          } else if (
            this.hasCustomCDAIQuestion || this.checkedQuestionCDAI.default[this.video.deliver_flg].length < 2
          ) {
            param = 2
          }
        } else if (this.video.deliver_flg == verifyMethods.TWO_TYPES.value) {
          if (this.checkedQuestionCDAI.default[this.video.deliver_flg].length && !this.hasCustomCDAIQuestion) {
            param = 1
          } else if (this.hasCustomCDAIQuestion || !this.checkedQuestionCDAI.default[this.video.deliver_flg].length) {
            param = 2
          }
        }
      } else if (this.video.deliver_flg == verifyMethods.ONE_TYPE.value) {
        param = this.video.is_rs_cdai
      } else {
        param = 0
      }

      return param
    },
    rsCDAIStatus: function () {
      if (this.video.is_rs_cdai === commentDiscoveryStatus.OFF.value) {
        return commentDiscoveryStatus.OFF.value;
      } else {
        return commentDiscoveryStatus.ON.value;
      }
    },
    videoStartTime() {
      if (this.video.deliver_period_type == this.listStep.step2.id) {
        return this.video.start_at + " " + this.video.start_time_at + ":00"
      } else {
        return moment(this.video.start_at).format("YYYY-MM-DD")
      }
    },
    isBeforeDate() {
      let dateNow = moment().format("YYYY-MM-DD HH:mm")
      return this.video.start_at && moment(dateNow).isBefore(this.video.start_at);
    },
  },
  watch: {
    clients(val) {
      this.buyerSelected = val.filter(data => data.id === this.buyerId)[0]
    }
  },
  methods: {
    disabledSelect() {
      jQuery("#clients").attr("disabled", "disabled");
      jQuery("#countries").attr("disabled", "disabled");
      jQuery("#channels").attr("disabled", "disabled");
    },
    prevPage() {
      if (this.video.id) {
        this.$router.push({
          name: "video-edit",
          params: { video_id: this.video.id },
        });
      } else {
        this.$router.push({
          name: "add",
          params: { video_file:this.$route.params.video_file },
        });
      }
    },
    checkExistSelectedLabel(id) {
      return this.selectedLabels.includes(id)
    },
    getListLabel() {
      listLabels((labels) => {
        this.selectedLabels = []
        this.labels = labels
        if (this.video && this.video.label_ids) {
          let label_ids = this.video.label_ids.split(',').map(Number)
          this.labels.map(item => {
            if (label_ids.includes(item.id)) this.selectedLabels.push(item.id)
          })
        }
      }, this.buyerId)
    },
    handleSegment(video) {
        if (video.deliver_period_type == this.listStep.step2.id &&
          video.feasibility_ids &&
          video.feasibility_ids.length &&
          video.cognition_survey == NOT_IMPLEMENT_COGNITION_SURVEY &&
          !('screening' in video) &&
          video.is_admin_change == manualTrafficStatus.OFF.value
        )
        {
          video.rs_linkage = RS_LINKAGE_STATUS.ON
        } else {
          video.rs_linkage = RS_LINKAGE_STATUS.OFF
          video.feasibility_ids = []
        }
      return video
    },
    getDefaultQuestionCDAI() {
      if (
        this.video.deliver_period_type != this.listStep.step2.id ||
        this.video.deliver_flg == verifyMethods.ONE_TYPE.value
      ) {
        return []
      }
      let interactionType
      Object.keys(this.interactionTypes).forEach(key => {
        if (this.interactionTypes[key].index == this.video.interaction_btn_1_index) {
          interactionType = this.interactionTypes[key]
        }
      })

      if (this.video.deliver_flg == verifyMethods.TWO_TYPES.value) {
        return [
          {
            text: this.$t('texts.video.default_question_deliver_flg1',
                {
                  btn_1: interactionType.value.interaction_btn_1_text,
                  btn_2: interactionType.value.interaction_btn_2_text,
                }
              ),
          }
        ]
      } else {
        return [
          {
            text: this.$t('texts.video.default_question_deliver_flg2',
                {
                  btn: interactionType.value.interaction_btn_1_text,
                }
              ).replace(/<\/?b>/g, ''),
          },
          {
            text: this.$t('texts.video.default_question_deliver_flg2',
                {
                  btn: interactionType.value.interaction_btn_2_text,
                }
              ).replace(/<\/?b>/g, ''),
          }
        ]
      }
    },

    getCdaiQuestionParams() {
      if (
        this.video.deliver_period_type != this.listStep.step2.id ||
        this.video.deliver_flg == verifyMethods.ONE_TYPE.value ||
        this.video.is_rs_cdai == commentDiscoveryStatus.OFF.value
      ) {
        return null
      }
      let cdaiQuestion = []
      let number = 1
      if (this.checkedQuestionCDAI.default[this.video.deliver_flg].length) {
        if (this.checkedQuestionCDAI.default[this.video.deliver_flg].includes(0)) {
          cdaiQuestion.push({
            number: number,
            question: this.defaultQuestionCDAI[0].text,
            default_flg: 0,
          })
          number++
        }

        if (this.checkedQuestionCDAI.default[this.video.deliver_flg].includes(1)) {
          cdaiQuestion.push({
            number: number,
            question: this.defaultQuestionCDAI[1].text,
            default_flg: 0,
          })
          number++
        }
      }

      if (this.checkedQuestionCDAI.buyer_custom.length) {
        this.checkedQuestionCDAI.buyer_custom.forEach(item => {
          if (item.is_checked) {
            cdaiQuestion.push({
              number: number,
              question: item.text,
              default_flg: 1,
            })
            number++
          }
        })
      }

      if (this.checkedQuestionCDAI.admin_custom.length) {
        this.checkedQuestionCDAI.admin_custom.forEach(item => {
          if (item.is_checked && item.text.length) {
            cdaiQuestion.push({
              number: number,
              question: item.text,
              default_flg: 1,
            })
            number++
          }
        })
      }

      return cdaiQuestion
    },

    convertAccessUrl(urlText) {
      if (!urlText) {
        return ''
      }
      let accessUrlS3 = process.env.VUE_APP_URL_ASSET
      if (accessUrlS3[accessUrlS3.length - 1] === '/') {
        accessUrlS3 = accessUrlS3.slice(0, -1)
      }
      urlText = urlText.split('/')
      urlText[0] = accessUrlS3
      return urlText.join('/')
    },

    register() {
      let vm = this;
      let submit = this.$http2.post;
      let update = this.$http2.put;
      if (this.video.id) {
        let apiUrl =  process.env.VUE_APP_API_URL_S3
        let query = {
          id: this.video.id,
        };
        let saveVideo = (filename) => {
          let url = this.$router.resolve({
            name: "api.videos.update",
            params: query,
          }).href;
          let params = {
            name: this.video.name,
            panel_type: panelTypes.JCP.value,
            buyer_plan: this.buyerSelected.plan,
            label_ids: this.video.label_ids,
            ...(this.video.deliver_period_type == this.listStep.step2.id ? {
              deliver_flg: this.video.deliver_flg,
            } : {}),
            ...(this.video.deliver_period_type == this.listStep.step1.id ? {
              deliver_flg: 4,
            } : {}),
            has_custom_segment: this.video.has_custom_segment,
            status: this.video.status,
            start_at: this.videoStartTime,
            end_at: this.video.end_at,
            country_id: this.video.country_id,
            video_url_type: this.video.video_url_type,
            twitter_keywords: this.video.twitter_keywords,
            ...(this.video.deliver_period_type != this.listStep.step3.id ? {
              screening: this.video.screening,
            } : {}),
            ...(this.isYoutubeAnalytics
                ? {
                  youtube_analytic_id: this.video.youtube_analytic_id,
                  youtube_video_id: this.video.youtube_video_id.slice(32),
                }
                : {}),
            ...(this.isYoutubeAnalytics && this.video.youtube_analytic_id
                ? { youtube_analytic_id: this.video.youtube_analytic_id }
                : {}),
            ...(this.isYoutubeAnalytics && this.video.youtube_video_id
                ? { youtube_video_id: this.video.youtube_video_id }
                : {}),
            ...(this.isYoutubeAnalytics && this.video.youtube_video_id && this.video.video_url_type == 2
                ? { youtube_video_id: this.video.youtube_video_id.slice(32) }
                : {}),
            ...(this.video.deliver_period_type != this.listStep.step3.id
                ? { cognition_survey: this.video.cognition_survey }
                : { cognition_survey: 0 }),
            deliver_period_type: this.video.deliver_period_type,
            ...(this.video.deliver_period_type == this.listStep.step3.id ? {
              commercial_media_type: this.video.commercial_media_type,
            } : {}),
            ...(this.intentionData.intention_items_checked
            && this.intentionData.intention_items_checked.length
            && this.video.deliver_period_type == this.listStep.step2.id
            && this.video.deliver_flg != verifyMethods.ONE_TYPE.value ? {
              is_intention: 1,
              intention_id: this.intentionData.intention_items_checked.map(item => item.id),
            } : {
              is_intention: 0,
            }),
            rs_linkage: this.rsLinkageStatus,
            is_rs_cdai: this.video.is_rs_cdai,
            is_admin_change: this.video.is_admin_change,
            is_chatgpt: (this.video.is_rs_cdai && this.video.deliver_period_type == 2) ? parseInt(this.video.is_chatgpt) : 0
          };
          if (this.video_file) {
            params = {...params, ...{
              video_url: filename ? filename : this.video.video_url.slice(32)
            }}
          } else {
            params = {...params, ...{
              video_url: this.video.video_url_type == 2 ? this.video.video_url.slice(32) : this.video.video_url.substr(apiUrl.length)
            }}
          }
          if (this.video.deliver_period_type != this.listStep.step3.id) {
            if (this.video.deliver_flg != 3) {
              params = {
                ...params, ...{
                  interaction_btn_1_text: this.video.interaction_btn_1_text,
                  interaction_btn_1_icon_id: this.video.interaction_btn_1_icon_id,
                  interaction_btn_1_explain: this.video.interaction_btn_1_explain,
                  interaction_btn_2_text: this.video.interaction_btn_2_text,
                  interaction_btn_2_icon_id: this.video.interaction_btn_2_icon_id,
                  interaction_btn_2_explain: this.video.interaction_btn_2_explain,
                },
                ...(this.video.deliver_period_type == this.listStep.step1.id ? {
                  interaction_btn_3_text: this.video.interaction_btn_3_text,
                  interaction_btn_3_icon_id: this.video.interaction_btn_3_icon_id,
                  interaction_btn_3_explain: this.video.interaction_btn_3_explain,
                } : {}),
              }
            } else {
              params = {
                ...params, ...{
                  interaction_btn_1_text: this.video.interaction_btn_1_text,
                  interaction_btn_1_icon_id: this.video.interaction_btn_1_icon_id,
                  interaction_btn_1_explain: this.video.interaction_btn_1_explain,
                }
              }
            }
          }
          if (this.layers.length) {
            params = {...params, ...{
                attribute_layers : this.layers
              }
            }
          }
          update(
              url,
              params,
              () => {
                this.$toasted.success(this.$t(this.updateSuccessMessage));
                localStorage.removeItem("video_register");
                localStorage.removeItem("buyer_id");
                localStorage.removeItem("layers");
                localStorage.removeItem("groupsSegment");
                localStorage.removeItem("segmentSelected");
                localStorage.removeItem("interactionTypesCustom");
                localStorage.removeItem("interactionTypesCustomStep1");
                localStorage.removeItem("interactionTypeVerifyOneCustom");
                localStorage.removeItem("screening_option")
                localStorage.removeItem("intentionData")
                localStorage.removeItem("videoCaptureData")
                localStorage.removeItem("checkedQuestionCDAI")
                this.isShowModalConfirm = false;
                vm.$router.push({
                  name: 'video-edit',
                  params: {
                    video_id: this.video.id
                  }
                });
              },
              () => {
                this.isShowModalConfirm = false;
                EventBus.$emit("handleDone");
              }
          );
        }
        if (this.video.video_url_type == 1 && this.video_file) {
          this.uploadFileS3(saveVideo, () => {});
        } else {
          saveVideo();
        }
      } else {
        let saveVideo = (filename) => {
          let url = this.$router.resolve({
            name: "api.videos.register",
          }).href;
          this.video = this.handleSegment(this.video)
          let params = {
            name: this.video.name,
            buyer_id: this.buyerId,
            panel_type: panelTypes.JCP.value,
            buyer_plan: this.buyerSelected.plan,
            label_ids: this.video.label_ids,
            ...(this.video.deliver_period_type == this.listStep.step2.id ? {
              deliver_flg: this.video.deliver_flg,
            } : {}),
            ...(this.video.deliver_period_type == this.listStep.step1.id ? {
              deliver_flg: 4,
            } : {}),
            has_custom_segment: this.video.has_custom_segment,
            status: this.video.status,
            start_at: this.videoStartTime,
            end_at: this.video.end_at,
            video_url: filename ? filename : this.video.video_url.slice(32),
            country_id: this.video.country_id,
            video_url_type: this.video.video_url_type,
            twitter_keywords: this.video.twitter_keywords,
            ...(this.video.deliver_period_type != this.listStep.step3.id ? {
              screening: this.video.screening,
            } : {}),
            ...(this.isYoutubeAnalytics
                ? {
                  youtube_analytic_id: this.video.youtube_analytic_id,
                  youtube_video_id: this.video.youtube_video_id.slice(32),
                }
                : {}),
            ...(this.isYoutubeAnalytics && this.video.youtube_analytic_id
                ? { youtube_analytic_id: this.video.youtube_analytic_id }
                : {}),
            ...(this.isYoutubeAnalytics && this.video.youtube_video_id
                ? { youtube_video_id: this.video.youtube_video_id }
                : {}),
            ...(this.isYoutubeAnalytics && this.video.youtube_video_id && this.video.video_url_type == 2
                ? { youtube_video_id: this.video.youtube_video_id.slice(32) }
                : {}),
            ...(this.video.deliver_period_type != this.listStep.step3.id
                ? { cognition_survey: this.video.cognition_survey }
                : { cognition_survey: 0 }),
            deliver_period_type: this.video.deliver_period_type,
            ...(this.video.deliver_period_type == this.listStep.step3.id ? {
              commercial_media_type: this.video.commercial_media_type,
            } : {}),
            rs_linkage: this.video.rs_linkage,
            ...(this.video.rs_linkage == RS_LINKAGE_STATUS.ON ? {
              feasibility_ids: this.video.feasibility_ids,
            } : {}),
            ...(this.intentionData.intention_items_checked
            && this.intentionData.intention_items_checked.length
            && this.video.deliver_period_type == this.listStep.step2.id
            && this.video.deliver_flg != verifyMethods.ONE_TYPE.value ? {
              is_intention: 1,
              intention_id: this.intentionData.intention_items_checked.map(item => item.id),
            } : {
              is_intention: 0,
            }),
            is_rs_cdai: this.rsCDAIParams,
            is_admin_change: this.video.is_admin_change,
            ...(this.video.deliver_period_type == this.listStep.step2.id ? {
              video_tmp_id: this.videoTmpIdParams,
              video_comment_scene: this.videoCommentSceneParams,
              cdai_question: this.cdaiQuestionParams,
            } : {}),
            is_chatgpt: (this.video.is_rs_cdai && this.video.deliver_period_type == 2) ? parseInt(this.video.is_chatgpt) : 0
          };

          if (this.video.deliver_period_type != this.listStep.step3.id) {
            if (this.video.deliver_flg != 3) {
              params = {
                ...params, ...{
                  interaction_btn_1_text: this.video.interaction_btn_1_text,
                  interaction_btn_1_icon_id: this.video.interaction_btn_1_icon_id,
                  interaction_btn_1_explain: this.video.interaction_btn_1_explain,
                  interaction_btn_2_text: this.video.interaction_btn_2_text,
                  interaction_btn_2_icon_id: this.video.interaction_btn_2_icon_id,
                  interaction_btn_2_explain: this.video.interaction_btn_2_explain,
                },
                ...(this.video.deliver_period_type == this.listStep.step1.id ? {
                  interaction_btn_3_text: this.video.interaction_btn_3_text,
                  interaction_btn_3_icon_id: this.video.interaction_btn_3_icon_id,
                  interaction_btn_3_explain: this.video.interaction_btn_3_explain,
                } : {}),
              }
            } else {
              params = {
                ...params, ...{
                  interaction_btn_1_text: this.video.interaction_btn_1_text,
                  interaction_btn_1_icon_id: this.video.interaction_btn_1_icon_id,
                  interaction_btn_1_explain: this.video.interaction_btn_1_explain,
                }
              }
            }
          }

          if (this.layers.length) {
            params = {...params, ...{
                attribute_layers : this.layers
              }
            }
          }
          submit(
              url,
              params,
              () => {
                this.$toasted.success(this.$t(this.registerSuccessMessage));
                localStorage.removeItem("video_register");
                localStorage.removeItem("buyer_id");
                localStorage.removeItem("layers");
                localStorage.removeItem("groupsSegment");
                localStorage.removeItem("segmentSelected");
                localStorage.removeItem("interactionTypesCustom");
                localStorage.removeItem("interactionTypesCustomStep1");
                localStorage.removeItem("interactionTypeVerifyOneCustom");
                localStorage.removeItem("screening_option")
                localStorage.removeItem("intentionData")
                localStorage.removeItem("videoCaptureData")
                localStorage.removeItem("checkedQuestionCDAI")
                this.isShowModalConfirm = false;
                vm.$router.push("add");
              },
              () => {
                this.isShowModalConfirm = false;
                EventBus.$emit("handleDone");
              }
          );
        };
        if (this.video.video_url_type == 1) {
          this.uploadFileS3(saveVideo, () => {});
        } else {
          saveVideo();
        }
      }
    },
    uploadFileS3(success, fail) {
      let url = this.$router.resolve({
        name: "api.videos.upload_url",
      }).href;
      this.$http2.get(
        url,
        {},
        (data) => {
          // let urlS3 = data.upload_url.split('?');
          let urlS3 = data.upload_url;
          this.$http2.uploadFileS3(urlS3, this.video_file, () => {
            success(data.file_name);
          });
        },
        () => {
          fail && fail();
        }
      );
    },
    selectChannel(id) {
      this.video.youtube_analytic_id = id;
    },
    initFlatPickr() {
      let vm = this;
      let currentDate;
      let now = moment();
      currentDate = now.format("YYYY-MM-DD");
      flatpickr(".datetimepicker", {
        locale: Japanese,
        enableTime: false,
        showMonths: 2,
        mode: "range",
        minDate: currentDate,
        dateFormat: "Y/m/d",
        defaultDate: [vm.video.start_at, vm.video.end_at],
        time_24hr: true,
        position: "below",
        nextArrow:
          "<div class='btn button-next flatpickr-next-month'><img src='./images/svg/icon_next.svg'></div>",
        prevArrow:
          "<div class='btn button-prev flatpickr-prev-month'><img src='./images/svg/icon_prev.svg'></div>",
        onDayCreate: (dObj, dStr, fp, dayElem) => {
          let dateObj = dayElem.dateObj;
          if (dateObj.toDateString().substr(0, 3) == "Sat") {
            jQuery(dayElem).addClass("is-saturday");
          }
          if (dateObj.toDateString().substr(0, 3) == "Sun") {
            jQuery(dayElem).addClass("holidays");
          }
          if (vm.holidays) {
            if (
              vm.holidays.indexOf(moment(dateObj).format("YYYY-MM-DD")) !== -1
            ) {
              if (jQuery(dayElem).hasClass("is-saturday")) {
                jQuery(dayElem).removeClass("is-saturday");
              }
              jQuery(dayElem).addClass("holidays");
            }
          }
        },
        onReady() {
          let startAt = vm.video.start_at;
          let endAt = vm.video.end_at;
          let startFormat = moment(startAt).format("YYYY/MM/DD");
          let endFormat = moment(endAt).format("YYYY/MM/DD");
          if (startAt != null || endAt != null) {
            jQuery(".flatpickr-input").val(startFormat + " ~ " + endFormat);
          }
        },
      });
    },
    initData() {
      this.getListChannelYoutube();
      this.sortVideoIntention();
    },
    eventHoverButtonPrevPage() {
      jQuery(".button-prev").mouseover(function () {
        jQuery(this).css("opacity", 0.7);
      });
      jQuery(".button-prev").mouseout(function () {
        jQuery(this).css("opacity", 1);
      });
    },
    // get data api
    getListBuyer() {
      let url = this.$router.resolve({
        name: "api.buyers",
      }).href;
      let params = {
        approve_status: 1,
        status: 1,
        sort: "id",
        order: "asc",
      };
      this.$http2.get(url, params, (data) => {
        this.clients = data.items;
        this.initData();
      });
    },
    getListCountry() {
      let url = this.$router.resolve({
        name: "api.countries",
      }).href;

      this.$http2.get(url, {}, (data) => {
        this.countries = data.items;
      });
    },
    getListChannelYoutube() {
      let url = this.$router.resolve({
        name: "api.youtube.analytics",
      }).href;
      let params = {
        buyer_id: this.buyerId,
        sort: "id",
        order: "asc",
        limit: 1000,
      };
      this.$http2.get(url, params, (data) => {
        this.youtubeChannels = data.items;
      });
    },
    toggleModal(modalName) {
      this.showModalName = modalName;
    },
    openFormScreeningOption() {
      EventBus.$emit("toggleModal", "screening-option");
    },
    closeModalScreeningOption() {
      this.showModalName = ''
    },

    sortVideoIntention() {
      if (this.video.intention_items_checked && this.video.intention_items_checked.length > 0) {
        this.video.intention_items_checked.sort((a, b) => a.index - b.index);
      }
    },
  },
  mounted() {
    this.getListLabel()
    this.initFlatPickr();
    this.disabledSelect();
    this.eventHoverButtonPrevPage();
    let vm = this;
    let type = jQuery("input:radio[name=type]");
    let cognition_survey = jQuery("input:radio[name=cognition_survey]");
    let status = jQuery("input:radio[name=status]");
    if (type.is(":checked") === false) {
      type.filter(`[value=${vm.video.video_url_type}]`).prop("checked", true);
    }
    if (cognition_survey.is(":checked") === false) {
      cognition_survey
        .filter(`[value=${vm.video.cognition_survey}]`)
        .prop("checked", true);
    }
    if (status.is(":checked") === false) {
      status.filter(`[value=${vm.video.status}]`).prop("checked", true);
    }
    if (vm.video.youtube_analytic_id) {
      jQuery("#youtube-analytics").prop("checked", true);
      vm.isYoutubeAnalytics = true;
    }
  },
  created() {
    let currentUser = this.$store.getters.currentUser;
    this.currentUser = currentUser;
    this.video_file = this.$route.params.video_file;

    if (currentUser && currentUser.role == "admin") {
      this.getListBuyer();
    } else {
      this.buyerId = this.currentUser.owner_id;
      this.buyerSelected = this.currentUser
      this.initData();
    }
    this.getListCountry();
    EventBus.$on("toggleModal", this.toggleModal)
  },
  destroyed() {
    EventBus.$off("toggleModal", this.toggleModal)
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/_add.scss";
.datetimepicker:disabled {
  background-color: #edf2f9;
  opacity: 1;
}

.group-tag-list {
  padding-top: 0;
  border-top: none;

  .tag-keyword {
    cursor: unset;
    user-select: none;
  }
}

.verification-methods-description{
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  color: #999999;
}

.describe-panel {
  margin-top: 8px;
  color: $middle-black;
  @include font(s);
}

.describe-segment {
  margin-top: 8px;
  color: $middle-black;
  font-size: 12px;
  line-height: 19px;
}

.screening-options {
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #333333;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  
  button {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    color: #49AB94;
    padding: 5px 11px;
    background-color: #FFFFFF;
    border: 1px solid #49AB94;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }

  .screening-options-done {
    color: #FFFFFF;
    background-color: #49AB94;
  }
}

.preview-group-segment {
  &.list-attribute {
    padding: 10px 0;
    background-color: $light-green;
    border-radius: 8px;
    height: 294px;
    display: flex;
    flex-direction: column;
    position: relative;

    .btn-delete {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
      @include font(s);
      color: $green;
    }

    .attributes {
      margin: 0;
      font-weight: bold;
      direction: rtl;
      overflow-y: auto;

      .list-item {
        border-bottom: 1px solid $middle-black;

        .title-item {
          color: #333333;
          font-size: 14px;
          line-height: 24px;
        }

        &:first-child {
          padding-top: 10px;
        }

        &:last-child {
          padding-bottom: 10px;
          border: none;
        }

        .describe-item-selected {
          .att-selected {
            &:last-child {
              margin-bottom: 0;
            }

            .name {
              @include font(m);
              color: $middle-black;
              margin-bottom: 4px;
              font-weight: bold;
            }

            .value {
              @include font(s);
              color: $black;
              display: inline-flex;
              flex-wrap: wrap;
              flex-direction: row-reverse;
              justify-content: left;
              gap: 12px;

              span {
                padding: 6px 12px;
                margin-right: 4px;
                border: 1px solid $black;
                border-radius: 8px;
                direction: ltr;
              }
            }
          }
        }
      }

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $black;
      }
    }
  }
}

.input-disabled {
  background-color: #EBEBEB !important;
}

.radio-disabled {
  opacity: 0.5;
}

.cursor-default {
  cursor: default !important;

}

::v-deep .tooltip-content {
  transform: translate(3px, 4px);
  width: 0;
  height: 0;
  margin: 0 auto;
}


.intention-content {

  .select-intention {
    margin-bottom: 20px;
  }

  .intention-item-checkbox {
    margin-right: 20px;
    margin-bottom: 20px;
    display: inline-flex;
    align-items: center;

    .icon-checkbox {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 10px;

      &.disabled {
        opacity: 0.5;
      }
    }

    .intention-item-name {
      font-size: 12px;
      line-height: 24px;
      color: #333333;
      margin-top: 4px;
    }
  }

  .btn-plus-intention {
    cursor: pointer;
    font-size: 10px;
    line-height: 16px;
    color: #49AB94;
    padding: 5px 11px;
    border-radius: 8px;
    border: 1px solid #49AB94;
    font-weight: 700;
    font-family: "Noto Sans JP";
    display: inline-block;
    user-select: none;

    &.disabled {
      opacity: 0.5;
    }
  }

  .btn-plus-intention:hover {
    background-color: #49AB94;
    color: #FFFFFF;
  }
}

.intention-item-checkbox {
  margin-right: 20px;
  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;

  .icon-checkbox {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .intention-item-name {
    font-size: 12px;
    line-height: 24px;
    color: #333333;
    margin-top: 4px;
  }
}

.label-text {
  margin-bottom: 12px;
}

.default-question-cdai {
  .question-item {
    display: flex;
  }
}

.question-admin-cdai {
  width: 440px;
}

.wrap-chatgpt {

  label {
    color : #49ab94 !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 22px !important;
  }
}

.list-image-capture {
  background: $light-green;
  padding: 30px 30px 10px;
  margin-top: 10px;

  .capture-label {
    font-family: Noto Sans JP;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .count-image-capture {
    font-family: Noto Sans JP;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: $green;
  }

  .list-image-content {
    max-height: 510px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 20px;
    padding-bottom: 20px;

    .image-capture {
      width: 13%;
      .image-capture-item {
        overflow: hidden;
        position: relative;
        display: inline-flex;
        border-radius: 8px;

        .image-wrapper {
          background: black;
          display: inline-flex;
          border-radius: 10px;

          img {
            object-fit: contain;
            width: 100%;
            aspect-ratio: 295/164;

            &.loading {
              opacity: 0.5;
            }
          }
        }

        .icon-switch-capture {
          width: 24px;
          height: 24px;
          position: absolute;
          top: 4px;
          right: 4px;
          cursor: pointer;
          z-index: 1;
          background-image: url('/images/svg/icon_switch_capture.svg');
        }

        &-checkbox {
          margin-top: 10px;
          display: flex;
          align-items: center;
          .icon-checkbox {
            display: inline-block;
          }
          .second-text {
            color: $green;
            font-family: Noto Sans JP;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-left: 10px;
            user-select: none;
          }
        }

        img {
          object-fit: contain;
          width: 100%;
          aspect-ratio: 295/164;

          &.loading {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.datetime-content {
  display: flex;
  gap: 20px;
  .input-time {
    width: 45px;
    text-align: center;
  }
}
</style>
