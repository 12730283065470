<template>
  <div>
    <div class="d-flex justify-content-between mt-5">
      <div class="d-flex align-items-center">
        <span class="number-of-video mr-4">動画数：{{ videos.length }}件</span>
        <div class="d-flex align-items-center">
          <i
            class="icon icon-checkbox"
            :class="{ checked: checkedAllVideos }"
            @click="checkAllVideos"
          ></i>
          <div class="item-name pl-2">全選択</div>
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex align-items-center" @click="changeGridListVideo(1)">
          <i
            class="icon icon-grid-video"
            :class="{ checked: grid_list_video === 1 }"
          ></i>
          <div
            class="item-name item-name-default pl-1 cursor-pointer"
            :class="{ 'item-name-active': grid_list_video === 1 }"
          >
            タイル
          </div>
        </div>
        <div class="ml-4 mr-4 my-auto border-left-default"></div>
        <div class="d-flex align-items-center" @click="changeGridListVideo(2)">
          <i
            class="icon icon-list-video"
            :class="{ checked: grid_list_video === 2 }"
          ></i>
          <div
            class="item-name item-name-default pl-1 cursor-pointer"
            :class="{ 'item-name-active': grid_list_video === 2 }"
          >
            リスト
          </div>
        </div>
      </div>
    </div>
    <div v-if="grid_list_video === 1" class="row mt-5 video-grid">
      <video-info-grid
        :stepSelected="stepSelected"
        v-for="video in videos"
        :key="video.id"
        :video="video"
        :selectedVideo="selectedVideo"
        @selectVideo="selectVideo"
      ></video-info-grid>
    </div>
    <div v-else class="mt-3">
      <video-info-list
        :stepSelected='stepSelected'
        :videos="videos"
        :selectedVideo="selectedVideo"
        :sortVideo="sort"
        :orderVideo="order"
        :disabledEdit="
          currentUser.role !== 'admin' &&
          clientIdSelected !== currentUser.owner_id
        "
        @selectVideo="selectVideo"
        @doSort="doSort"
      >
      </video-info-list>
    </div>
  </div>
</template>
<script>
import VideoInfoGrid from "@/components/Top/VideoInfoGrid.vue";
import VideoInfoList from "@/components/Top/VideoInfoList.vue"
export default {
  props: {
    videos: { type: Array, default: () => ([]) },
    checkAllVideos: {type: Function, default: ()=> {}},
    selectedVideo: {type: Array, default: () => ([])},
    sort: {type: String, default: ''},
    order: {type: String, default: ''},
    clientIdSelected: {type: Number, default: 0},
    selectVideo: {type: Function, default: ()=> {}},
    doSort: {type: Function, default: ()=> {}},
    checkedAllVideos: {type: Boolean, default: false},
    grid_list_video: {type: Number, default: 1},
    changeGridListVideo: {type: Function, default: ()=> {}},
    stepSelected: {
      type: Number,
      default: 2
    },
  },
  components: {
    VideoInfoGrid,
    VideoInfoList
  },
  data() {
    return {
      currentUser: {},
    };
  },
  mounted(){
    let currentUser = this.$store.getters.currentUser
    this.currentUser = currentUser
  }
};
</script>
<style lang="scss" scoped>
.video-grid {
  flex-wrap: wrap;
  grid-gap: 20px;
  margin-left: 0;
  margin-right: 0;
}
</style>