/* eslint-disable */
import { helpers } from 'vuelidate/lib/validators'
import moment from "moment";
export const checkPass = value => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*/.test(value) || value.length == 0;
export const minViewYoutube = (value) => (value > 0 && value.length != 0) || !value;
export const maxViewYoutube = (value) => (value <= 2147483647) || !value;
export const xssCheck = helpers.regex('xssCheck', /^[^<>&":;]+$/);
export const videoNameXssCheck = helpers.regex('xssCheck', /^[^\/,<>":;*?|\\]+$/);
export const cdaiquestionXssCheck = helpers.regex('xssCheck', /^[^,<>&":;]+$/);
export const xssCheckFull = helpers.regex('xssCheck', /^[^<>&":;']+$/);
export const postalCode = helpers.regex('postalCode', /^[0-9]{3}-[0-9]{4}$/);
export const halfwidthNumeric = helpers.regex('halfwidthNumeric', /^[0-9]*$/);
export const halfWidth = helpers.regex('halfwidth', /^[a-zA-Z0-9]+$/);
export const halfWidthExcludeXss = helpers.regex('halfWidthExcludeXss',/^[a-zA-Z0-9-_^<>&":;]+$/)
export const lt = (selector) =>
    (value) => {
        let end = selector
        if (!end) {
            return true;
        }
        let start = new Date(value);
        end = new Date(end);
        return (start < end);
    }

export const gt = (selector) =>
    (value) => {
        let start = selector;
        if (!start) {
            return true;
        }
        start = new Date(start);
        let end = new Date(value);
        return start < end;
    }

export const extension = (extensions) =>
    (value) => {
        if (value == undefined) {
            return true;
        }

        let ext = value.name.split('.').pop();
        return extensions.indexOf(ext.toLowerCase()) != -1;
    }

export const accept = (accepts) =>
    (value) => {
        if (value == undefined) {
            return true;
        }
        let type = value.type;
        return accepts.indexOf(type.toLowerCase()) != -1;
    }

export const requiredArr = (value) => value.length > 0;

export const url = (value) => {
    let regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
    return (regex.test(value))
};

export const lineBreakCheck = (value) => {
    let lineMax = 2;
    value = value.split("\n");
    let countLine = value.filter(word => word.trim().length > 0).length;
    if (countLine > lineMax) {
        return false;
    }
    return true;
};
export const emails = (value) => {
    value = value.split("\n");
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isError = true;
    value.forEach(function (elem) {
        if (!re.test(elem.trim()) && elem.trim().length > 0) {
            isError = false;
        }
    });
    return isError;
};

export const nt = (value) => {
    let now = new Date();
    value = new Date(value);
    return (value <= now);
}

export const ntl = (value) => {
    if (value.length == 0) {
        return false;
    }
    let now = moment().format('YYYY/MM/DD');
    value = moment(value).format('YYYY/MM/DD');
    return (value >= now);
}

export const tnl = (selector) =>
    (value) => {
        if (value.length == 0) {
            return false;
        }
        let now = moment()
        let dateformat = moment(selector)
        if (dateformat.format('YYYY/MM/DD') > now.format('YYYY/MM/DD')) {
            return true;
        }
        let time = value.split(':');
        let hour = now.hour();
        let minute = now.minutes()
        if (minute > 50) {
            hour = hour + 2
        } else {
            hour = hour + 1
        }
        return parseInt(time[0]) >= hour;
    }
