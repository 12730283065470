<template>
    <div class="tag-client-list">
        <div @click="switchTab(tabs[0].routeNames[0])" :class="['tag-client tag-one', isActive(tabs[0].routeNames) ? 'active' : '']">{{ tabs[0].label }}</div>
        <div @click="switchTab(tabs[1].routeNames[0])" :class="['tag-client tag-two', isActive(tabs[1].routeNames) ? 'active' : '']">{{ tabs[1].label }}</div>
    </div>
</template>
<script>
import router from '../../routes';
export default {
    props: {},
    data() {
        return {
            tabs: [
                {
                    label: 'クライアント一覧',
                    routeNames: ['clients', 'client_users']
                },
                {
                    label: '資料ダウンロード一覧',
                    routeNames: ['whitepaper-subscribers']
                }
            ]
        }
    },
    methods: {
        isActive(routeNames) {
            return routeNames.includes(this.$route.name)
        },
        switchTab(routeName) {
            router.push({name: routeName});
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_client_tab.scss'
</style>