<template>
   <div class="login-container">
        <side-left></side-left>
         <div class="side-right">
            <div class="form-group-login">
                <form @submit.prevent="handleSubmit" novalidate>
                    <div class="title-form mb-4">{{$t('texts.forgot-pw.title')}}</div>
                    <div class="form-message mb-15"> {{$t('texts.forgot-pw.form_message')}}</div>
                    <div class="form-control mb-5" :class="{'error': submitted && $v.email.$error}">
                        <input class="input pl-5 pr-5" type="email" v-model="email" :placeholder="$t('placeholder.email')"/>
                        <template v-if="submitted && $v.email.$error">
                            <span class="error error-message" v-if="!$v.email.required">{{$t('validation.login.email.required')}}</span>
                            <span class="error error-message" v-else-if="!$v.email.email">{{$t('validation.login.email.email')}}</span>
                            <span class="error error-message" v-else-if="!$v.email.maxLength">{{$t('validation.login.email.max-length')}}</span>
                        </template>
                    </div>
                    <div class="form-control button mb-7">
                        <button class="btn btn-submit btn-primary" type="submit" >{{$t('texts.forgot-pw.send_mail_btn')}}</button>
                    </div>
                </form>
            </div>
        </div>
   </div>
</template>

<script>
import {required,email, maxLength} from "vuelidate/lib/validators";
import SideLeft from "../../components/Login/SideLeft.vue";

export default {
  name: 'ForgotPassword',
  components: {
    SideLeft
    
  },
  data() {
    return {
      email: "",

      submitted: false,
      isLoading: false
    };
  },
  methods: {
    handleSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;

      let url = this.$router.resolve({
        name: "api.forgot.password"
      }).href;

      this.$http2.put(
        url,
        { email: this.email },
        () => {
          this.isLoading = false;
          this.$router.push({
            name: "sent-email-forgot-password",
            params: { email: this.email }
          }).catch(()=>{});
        },
        () => {
          this.isLoading = false;
        }
      );
    }
  },
  validations: {
    email: { required, email, maxLength: maxLength(255) }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_login.scss"; 
</style>

