<template>
  <div class="d-flex align-items-center">
    <div class="interaction">
      <div v-for="(question, index) in interactionQuestions" :key="index" @click="selectInteractionQuestion(question)"
           :class="{'type-selected': selected == question.id}">
        {{ question.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    interactionQuestions: Array,
    selected: Number
  },
  data() {
    return {}
  },
  methods: {
    selectInteractionQuestion(question) {
      this.$emit('selectInteractionQuestion', {
        question : question
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.interaction {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 17px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 152px;
    height: 52px;
    padding: 0 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    color: #999999;
    border: 1px solid #999999;
    border-right: 0;

    &:hover {
      opacity: 0.75;
    }
  }

  div:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  div:last-child {
    border-right: 1px solid #999999;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .type-selected {
    color: #FFFFFF;
    background-color: #49AB94;
    border: 1px solid #49AB94;
  }
}
</style>