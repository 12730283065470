<template>
  <div >
    <select class="form-control" name="steps" id="steps" :disabled="disabled">

    </select>
  </div>
</template>
<script>

import {
  setSelect2Data,
  setSelect2Value
} from '../../commons/helpers';

export default {
  props: {
    steps: Array,
    selected: [Number, String],
    disabled: {type: Boolean, default: false},
  },
  watch: {
    steps: function (steps) {
      this.initStepsSelect(steps)
    }
  },
  data() {
    return {
      selectedId: null
    }
  },
  methods: {
    initStepsSelect(steps) {
      let vm = this;
      setSelect2Data('steps', steps, false, this.handleSelectedStep, {
        selected : vm.selected,
        minimumResultsForSearch: 0,
        width: '308px',
        height: '52px',
        dropdownCssClass: 'dropdown-select2-custom'
      },true);
      if (this.selected) {
        setSelect2Value('steps', this.selected)
      }
    },
    handleSelectedStep(selectedId) {
      if (this.selectedId != selectedId) {
        this.selectedId = selectedId;
        this.$emit('select', selectedId);
      }

    }
  },
  mounted() {
    this.initStepsSelect(this.steps)
  }
};
</script>
<style lang="scss" scoped>
</style>