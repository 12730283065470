<template>
  <div class="radio-list flex flex-row flex-wrap">
    <div
      class="form-radio-box mt-2 mr-5"
      v-for="(radioElement, key) in radioList"
      :key="`${keyName}_${key}`"
    >
      <input
        class="form-radio-input"
        type="radio"
        :value="radioElement.value"
        v-model="radioModel"
      />
      <span class="btn-radio"></span>
      <label class="label-radio mb-0">{{ radioElement.text }}</label>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    radioList: [Array, Object],
    idSelected: [Number, String],
    keyName: String,
  },

  data() {
    return {
      radioModel: this.idSelected
    }
  },

  methods: {

  },

  watch: {
    radioModel: {
      handler(newValue) {
        this.$emit('changeIdSelected', newValue)
      },
      deep: true
    }
  },

  created() {

  },

  destroyed() {

  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/root";

.edit-segment-link {
  display: flex;
  align-items: center;
  color: #49ab94;
  font-size: 12px;
  margin-top: 8px;
  cursor: pointer;
  .icon-arrow-right {
    margin-left: 2px;
  }
}
.text-link-setting-interaction-button{
  color : #49ab94;
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.text-link-setting-interaction-button::after {
  content : "";
  display: block;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #49AB94;
  margin-left : 4px;
}
.verification-methods-description{
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  color: #999999;
}
</style>
