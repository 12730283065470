<template>
  <div class="d-flex align-items-center">
    <div class="interaction" :class="{'step1-interaction': deliverPeriodType == 1}">
      <div v-for="(interaction, index) in interactions" :key="index" @click="selectedInteraction(interaction, index)"
           :class="{'interaction-selected': (deliverPeriodType == 1 && interactionTypeStep1 === index) || (deliverPeriodType == 2 && interactionType === index)}" class="interaction-box">
        {{ interaction.interaction_button_1_text }}
        <span v-if="interaction.interaction_button_2_text">/{{ interaction.interaction_button_2_text }}</span>
        <span v-if="interaction.interaction_button_3_text">/{{ interaction.interaction_button_3_text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {interactionTypes} from "@/commons/constants";
export default {
  props: {
    interactions: Array,
    interactionType: Number,
    interactionTypeStep1: Number,
    clientPlan: Number,
    clientIdSelected: Number,
    interactionClicked: Array,
    deliverPeriodType: [Number, String]
  },
  data() {
    return {}
  },
  methods: {
    selectedInteraction(interaction, index) {
      if(this.deliverPeriodType == 2){
        this.$emit('selectedInteraction', {
          interaction: interaction,
          type: index
        })
      } else {
        this.$emit('selectedInteractionStep1', {
          interaction: interaction,
          type: index
        })
      }
    },
    changeClient(){
      if(this.clientIdSelected == process.env.VUE_APP_MARKET_RESEARCH_BUYER_ID && this.interactionClicked.length == 1){
        for(let i = 0; i < this.interactions.length; i++){
          if(this.interactions[i].interaction_button_1_text == interactionTypes.UNDERSTAND_LOVE.value.interaction_btn_1_text && this.interactions[i].interaction_button_2_text == interactionTypes.UNDERSTAND_LOVE.value.interaction_btn_2_text){
            this.$emit('selectedInteraction', {
              interaction: this.interactions[i],
              type: i
            })
          }
        }
      }
    }
  },
  computed: {},
  watch: {},
  created(){
    this.changeClient();
  }
};
</script>
<style lang="scss" scoped>

.step1-interaction{

  .interaction-box:last-child{
    margin-right: 30px;
  }
}

.interaction {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -3px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 152px;
    height: 50px;
    padding: 0 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    color: #999999;
    border: 1px solid #999999;
    border-right: 0;
    width: max-content;

    &:hover {
      opacity: 0.75;
    }
  }

  div:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  div:last-child {
    border-right: 1px solid #999999;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-right: 30px;
  }

  .interaction-selected {
    color: #FFFFFF;
    background-color: #49AB94;
    border: 1px solid #49AB94;
  }
}

.video-type {
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999999;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    width: 85px;

    span {
      cursor: pointer;
    }
    .none-display{
      display: none;
    }
  }

  .video-type-active {
    color: #49AB94;
    font-weight: 700;

    span {
      border-bottom: 2px solid #49AB94;
    }
  }

  .border-x {
    border-left: 1px solid #EBEBEB;
  }
}
</style>
