<template>
  <div>
    <select class="form-control" :name="element" :id="element" :disabled="disabled"></select>
  </div>
</template>
<script>
import {
  setSelect2Data,
  formatDataSelect2,
} from "../../commons/helpers";

export default {
  props: {
    data: Array,
    idSelected: [Number, String],
    isSelected: Boolean,
    element: String,
    disabled: {
      type: Boolean,
      default: false
    },
    enableSearch: {
      type: Boolean,
      default: true
    },
    index: Number
  },
  watch: {
    data: function (data) {
      this.initClientsSelect(formatDataSelect2(data));
    },
  },
  methods: {
    initClientsSelect(data) {
      let vm = this;
      setSelect2Data(
        vm.element,
        data,
        false,
        this.handleSelectedClient,
        {
          selected: vm.idSelected,
          minimumResultsForSearch: vm.enableSearch ? 0 : -1,
          width: "308px",
          height: "52px",
          dropdownCssClass: "dropdown-select2-custom",
          dropdownPosition: 'below'
        },
        this.isSelected
      );
    },
    handleSelectedClient(selectedId) {
      this.$emit("select", {
        id: selectedId,
        index: this.index
      });
    },
  },
  mounted() {
    this.initClientsSelect(formatDataSelect2(this.data));
  },
};
</script>
<style lang="scss" scoped>
::v-deep select:disabled ~ .select2.select2-container--disabled .select2-selection {
  cursor: default;
  background-color: #EBEBEB;
}

::v-deep {
  .select2-container {
    min-width: 440px;

    .select2-selection {
      background-color: #EDF7F4;
      border: none;
      border-radius: 10px;
    }

    &--open {
      .select2-selection {
        border: 1px solid;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-color: #49AB94 #49AB94 #EBEBEB #49AB94;
      }
    }
  }
}

</style>