import { render, staticRenderFns } from "./ForgotSent.vue?vue&type=template&id=d3fbda70&scoped=true&"
import script from "./ForgotSent.vue?vue&type=script&lang=js&"
export * from "./ForgotSent.vue?vue&type=script&lang=js&"
import style0 from "./ForgotSent.vue?vue&type=style&index=0&id=d3fbda70&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3fbda70",
  null
  
)

export default component.exports