<template>
  <div class="modal add-option modal-show" v-show="isShown" ref="modal">
    <div class="modal-content">
      <div class="header">
        <div class="btn-close" @click="closeModal()">
          <i class="icon icon-close"></i>
        </div>
      </div>
      <div class="body">
        <p class="name mb-0">{{ addOptionInfo.name }}.あなたの{{ addOptionInfo.value }}</p>
        <div class="form-group w-100 mb-0">
          <label>選択肢</label>
          <input
              type="text"
              class="form-control"
              :class="{ error: $v.optionName.$error || !$v.optionName.xssCheckFull }"
              placeholder="入力してください"
              maxlength="255"
              v-model.trim="optionName"
          />
          <span class="error error-message" v-if="!$v.optionName.xssCheckFull">
            {{ $t("validation.xss_check_full", {field: $t("選択肢")}) }}
          </span>
          <div class="btn-submit" :class="[$v.optionName.$error || !$v.optionName.xssCheckFull ? 'mt-6' : 'mt-10']">
            <button
                class="btn btn-primary button-download-pdf"
                @click="showConfirm()"
                :disabled="!optionName || $v.optionName.$error || !$v.optionName.xssCheckFull"
            >
              登録
            </button>
          </div>
        </div>
      </div>
      <modal-confirm
          :message="message"
          @confirm="save()"
          v-if="isShowModalConfirm"
          @closeModalConfirm="isShowModalConfirm = false"
      ></modal-confirm>
    </div>
  </div>
</template>
<script>
import ModalConfirm from "../Modal/ModalConfirm.vue";
import {maxLength} from "vuelidate/lib/validators";
import {xssCheckFull} from "@/commons/rules";
import EventBus from "@/commons/event_bus";

export default {
  props: {
    isShown: Boolean,
    addOptionInfo: Object
  },
  components: {
    ModalConfirm,
  },
  data() {
    return {
      optionName: '',
      message: "登録します、よろしいですか？",
      submitted: false,
      isShowModalConfirm: false
    };
  },
  validations() {
    return {
      optionName: {
        maxLength: maxLength(255), xssCheckFull
      }
    }
  },
  methods: {
    showConfirm() {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.isShowModalConfirm = true
    },
    closeModal() {
      this.optionName = ''
      EventBus.$emit("toggleModal", "screening-option");
    },
    save() {
      this.$emit('addAnswerToQuestion', {
        qId: this.addOptionInfo.id,
        valueOption: this.optionName
      })
      this.isShowModalConfirm = false
      this.closeModal();
    }
  }
};
</script>
<style lang="scss" scoped>
.add-option {
  .modal-content {
    min-height: 0;

    .body {
      color: #333333;

      .name {
        font-family: 'Noto Sans JP';
        font-weight: 700;
        font-size: 24px;
        line-height: 38px;
        margin-top: -8px;
        margin-bottom: 20px;
      }
    }

    .form-group {
      label {
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
      }

      input {
        max-width: 266px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        padding-top: 13px;
        padding-bottom: 13px;

        &::placeholder {
          color: #999999;
        }

        &.error {
          border: 1px solid #ce6060;
          background-color: #fef4f4;

          &::placeholder {
            color: #ce6060;
          }
        }
      }

      .btn {
        line-height: 20px;
      }
    }
  }
}
</style>
