<template>
    <div class="row">
        <div class="col-1 col-lg-1"></div>
        <div class="col-11 col-lg-11 mr-0 outline-area">
            <!-- <div class="outline-area"> -->
                <client-tab></client-tab>
                <div class="table-filter-search">
                    <div class="show">
                        <div class="number-display mr-5">
                            表示数：{{ clientCount }}件
                        </div>
                        <div class="select-hide">
                            <i :class="[params.status == 0 ? 'checked' : '', 'icon icon-checkbox mr-2']" @click="switchStatus"></i>
                            非表示一覧
                        </div>
                    </div>
                    <div class="search">
                        <i class="icon icon-search"></i>
                        <input
                            class="input input-search pl-8 pr-5"
                            type="text"
                            placeholder="検索"
                            v-model="params.text"
                        />
                    </div>
                </div>
                <div class="table-wrapper">
                    <table class="table table-scroll">
                        <tr>
                            <th class="w-5">
                                <div class="icon-arrow" @click="doSort('id')">
                                    ID
                                    <div class="icon-sort" v-if="params.sort != 'id'">
                                        <i class="icon icon-table-arrow-bottom ml-1"></i>
                                        <i class="icon icon-table-arrow-top ml-1"></i>
                                    </div>
                                    <i
                                        v-else
                                        class="icon ml-1"
                                        :class="{
                                        'icon-table-arrow-bottom': isSortDesc('id'),
                                        'icon-table-arrow-top': isSortAsc('id'),
                                        }"
                                    ></i>
                                </div>
                            </th>
                            <th>
                                <div class="icon-arrow" @click="doSort('name')">
                                    クライアント名
                                    <div class="icon-sort" v-if="params.sort != 'name'">
                                        <i class="icon icon-table-arrow-bottom ml-1"></i>
                                        <i class="icon icon-table-arrow-top ml-1"></i>
                                    </div>
                                    <i
                                        v-else
                                        class="icon ml-1"
                                        :class="{
                                        'icon-table-arrow-bottom': isSortDesc('name'),
                                        'icon-table-arrow-top': isSortAsc('name'),
                                        }"
                                    ></i>
                                </div>
                            </th>
                            <th>
                                <div class="icon-arrow" @click="doSort('plan')">
                                    料金プラン1
                                    <div class="icon-sort" v-if="params.sort != 'plan'">
                                        <i class="icon icon-table-arrow-bottom ml-1"></i>
                                        <i class="icon icon-table-arrow-top ml-1"></i>
                                    </div>
                                    <i
                                        v-else
                                        class="icon ml-1"
                                        :class="{
                                        'icon-table-arrow-bottom': isSortDesc('plan'),
                                        'icon-table-arrow-top': isSortAsc('plan'),
                                        }"
                                    ></i>
                                </div>
                            </th>
                            <th>
                                <div class="icon-arrow" @click="doSort('plan')">
                                    料金プラン2
                                    <div class="icon-sort" v-if="params.sort != 'plan'">
                                        <i class="icon icon-table-arrow-bottom ml-1"></i>
                                        <i class="icon icon-table-arrow-top ml-1"></i>
                                    </div>
                                    <i
                                        v-else
                                        class="icon ml-1"
                                        :class="{
                                        'icon-table-arrow-bottom': isSortDesc('plan'),
                                        'icon-table-arrow-top': isSortAsc('plan'),
                                        }"
                                    ></i>
                                </div>
                            </th>
                            <th class="w-15" @click="doSort('approve_status')">
                                <div class="icon-arrow">
                                    承認状況
                                    <div class="icon-sort" v-if="params.sort != 'approve_status'">
                                        <i class="icon icon-table-arrow-bottom ml-1"></i>
                                        <i class="icon icon-table-arrow-top ml-1"></i>
                                    </div>
                                    <i
                                        v-else
                                        class="icon ml-1"
                                        :class="{
                                        'icon-table-arrow-bottom': isSortDesc('approve_status'),
                                        'icon-table-arrow-top': isSortAsc('approve_status'),
                                        }"
                                    ></i>
                                </div>
                            </th>
                            <th class="w-15" @click="doSort('admin_name')">
                                <div class="icon-arrow sale-staff">
                                    営業担当
                                    <div class="icon-sort" v-if="params.sort != 'admin_name'">
                                        <i class="icon icon-table-arrow-bottom ml-1"></i>
                                        <i class="icon icon-table-arrow-top ml-1"></i>
                                    </div>
                                    <i
                                        v-else
                                        class="icon ml-1"
                                        :class="{
                                        'icon-table-arrow-bottom': isSortDesc('admin_name'),
                                        'icon-table-arrow-top': isSortAsc('admin_name'),
                                        }"
                                    ></i>
                                </div>
                            </th>
                            <th></th>
                        </tr>
                        <tr v-for="client in clients" :key="client.id">
                            <td>{{ client.id }}</td>
                            <td v-if="client.approve_status == 1"><router-link :to="{ name: 'client_users', params: { id: client.id }}">{{ client.name }}</router-link></td>
                            <td v-else>{{ client.name }}</td>
                            <td>{{ plan1Map[client.plan] }}</td>
                            <td>{{ plan2Map[client.plan] }}</td>
                            <td :class="client.approve_status == 1 ? 'text-green' : client.approve_status == 2 ? 'text-red' : ''" v-if="client.approve_status != 0">
                                <div class="approve">
                                    <div>{{ statusMap[client.approve_status] }}</div>
<!--                                    <i class="icon icon-approve" v-if="client.approve_status == 1" @click="clientEditId = client.id; isApprove = false; showEditModal = true;"></i>-->
                                </div>
                            </td>
                            <td v-else>
                                <div class="approve-client" @click="clientEditId = client.id; isApprove = true; showEditModal = true;">
                                    <i class="icon icon-approve-client mr-1"></i>
                                    承認する
                                </div>
                            </td>
                            <td>
                                <select-sales-staff
                                    :data="salesStaff"
                                    :idSelected="client.admin_id"
                                    :isSelected="true"
                                    :element="`select_sales_staff_${client.id}`"
                                    @select="changeAdminId(client.id, $event)"
                                ></select-sales-staff>
                            </td>
                            <td>
                                <div class="approve edit-info">
                                    <i class="icon icon-approve" v-if="client.approve_status == 1" @click="clientEditId = client.id; isApprove = false; showEditModal = true;"></i>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <scroll-loader :loader-method="loadMoreData" :loader-disable="loadDisable" :loader-color="'#49ab94'"></scroll-loader>
            <!-- </div> -->
        </div>
        <div class="col-1 col-lg-1"></div>
        <client-edit-modal :clientId="clientEditId" :isApprove="isApprove" v-if="showEditModal" @closeModal="showEditModal = false" @successEdit="afterEdit"></client-edit-modal>
    </div>
</template>

<script>
import router from '../../routes';
import http2 from '../../commons/http2';
import i18n from '../../i18n';
import ClientTab from '../../components/Client/ClientTab.vue';
import ClientEditModal from '../../components/Client/ClientEditModal.vue';
import SelectSalesStaff from "../../components/Client/SelectSalesStaff.vue";
export default {
    name: 'CLientList',
    components: {ClientTab, ClientEditModal,SelectSalesStaff},
    props: {},
    data() {
        return {
            params: {
                page: 1,
                limit: 20,
                status: 1,
                text: '',
                sort: 'id',
                order: 'asc'
            },
            clientCount: 0,
            clients: [],
            statusMap: {
                0: i18n.t('status.wait'),
                1: i18n.t('status.approve_done'),
                2: i18n.t('status.reject')
            },
            plan1Map: {
                3: i18n.t('plans.private'),
                4: i18n.t('plans.free'),
                5: i18n.t('plans.market'),
                6: i18n.t('plans.market'),
                7: i18n.t('plans.advance')
            },
            plan2Map: {
                3: '',
                4: '',
                5: '',
                6: i18n.t('plans.private')
            },
            loadDisable: false,
            showEditModal: false,
            clientEditId: 0,
            isApprove: false,
            salesStaff: []
        }
    },
    watch: {
        'params.text': function() {
            this.getClients(false);
        }
    },
    methods: {
        getClients(isContinous, limit = 20) {
            if(!isContinous) {
                this.params.page = 1;
            } else {
                this.params.page += 1;
            }
            this.loadDisable = true;
            let url = router.resolve({name: 'api.buyers'}).href;
            http2.getList(url, {
                ...this.params,
                limit: limit
            }, (data) => {
                this.clientCount = data.all;
                if(!isContinous) {
                    this.clients = data.items;
                } else {
                    this.clients = [...this.clients, ...data.items];
                }
                if(this.clients.length == this.clientCount) {
                    this.loadDisable = true;
                } else {
                    this.loadDisable = false
                }

            }, ()  => {
                this.loadDisable = true;
            })
        },
        getAdmins() {
            let url = router.resolve({name: 'api.admins'}).href;
            http2.getList(
                url,
                {limit: 10000},
                (data) => {
                    this.salesStaff = [...data.items]
                },
                ()  => {}
            )
        },
        changeAdminId(clientId, adminId) {
            let pathParams = {
                id: clientId
            }
            let url = router.resolve({name: 'api.buyer-sales.update', params: pathParams}).href
            http2.put(
                url,
                {
                    admin_id: adminId
                },
                () => {
                    this.$toasted.success(this.$t("messages.update_success"))
                },
                ()  => {}
            )
        },
        switchStatus() {
            if(this.params.status == 0) {
                this.params.status = 1;
            } else {
                this.params.status = 0;
            }
            this.getClients(false);
        },
        loadMoreData() {
            this.getClients(true)
        },
        doSort(sortColumn) {
            if(sortColumn == this.params.sort) {
                let currentOrder = this.params.order;
                this.params.order = currentOrder == 'asc' ? 'desc' : 'asc'
            } else {
                this.params.sort = sortColumn;
                this.params.order = 'desc';
            }
            this.getClients(false);
        },
        isSortDesc(sortColumn) {
            return this.params.sort == sortColumn && this.params.order == 'desc';
        },
        isSortAsc(sortColumn) {
            return this.params.sort == sortColumn && this.params.order == 'asc';
        },
        afterEdit() {
            this.showEditModal = false;
            let currentPage = this.params.page;
            let newLimit = currentPage * this.params.limit
            this.getClients(false, newLimit);
        }
    },
    mounted() {
        this.getClients(false);
        this.getAdmins()
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_client.scss';

.icon-sort {
    position: relative;
    min-height: 15px;
    .icon-table-arrow-bottom {
        position: absolute;
        bottom: 0;
    }
    .icon-table-arrow-top {
        position: absolute;
        top: 0;
    }
}
</style>