// common functions
import {listAgent} from "@/commons/constants";

const jQuery = require("jquery");
jQuery.select2 = require("select2");
import router from "../routes/index";
import moment from 'moment';

export const panelTypes = {
  JCP: {
    name: "JCP",
    value: 1,
    key: "JCP",
    desc: "PlayAdsに登録している全てのユーザーを対象に検証するサービスです。",
  },
  OP: {
    name: "オリジナルパネル",
    value: 2,
    key: "OP",
    desc: "調査対象者を特定して検証するサービスです。",
  },
};

export const interactionTypes = {
  UNDERSTAND_LOVE: {
    text: "スキ/ナットク",
    value: 0,
  },
  GOOD_BAD: {
    text: "Good/Bad",
    value: 1,
  },
};

export const isObjectEmpty = (object) => {
  return object != null
    ? Object.values(object).every((x) => x === null || x === "")
    : false;
};

/**
 * config message select2 with japanese
 */
const select2_language = {
  errorLoading: function() {
    return "結果が読み込まれませんでした";
  },
  inputTooLong: function(args) {
    let overChars = args.input.length - args.maximum;
    let message = overChars + " 文字を削除してください";
    return message;
  },
  inputTooShort: function(args) {
    let remainingChars = args.minimum - args.input.length;
    let message = "少なくとも " + remainingChars + " 文字を入力してください";
    return message;
  },
  loadingMore: function() {
    return "読み込み中…";
  },
  maximumSelected: function(args) {
    let message = args.maximum + " 件しか選択できません";
    return message;
  },
  noResults: function() {
    return "対象が見つかりません";
  },
  searching: function() {
    return "検索しています…";
  },
  removeAllItems: function() {
    return "すべてのアイテムを削除";
  },
};

/**
 *
 * format data select2
 * @param {Array} data
 * @returns
 */
export const formatDataSelect2 = (data) => {
  if (data) {
    return data.map((item) => ({
      id: item.id,
      text: item.name ? item.name : item.channel_name,
    }));
  }
};

/**
 *
 * @param {*} id
 * @param {*} data
 * @param {*} setPlaceholder
 * @param {*} select2Options
 * @returns
 */
export const formatState = (state) => {
  if (!state.id) {
    return state.text;
  }
  let $option = jQuery(
    '<div style="display:flex;justify-content:space-between"></div>'
  );
  let $preview = jQuery(
    "<div class='select-icon' data-id=" + state.id + "></div>"
  );
  $option.text(state.text);
  $option.append($preview);
  $preview.prop("href", state.id);
  $preview.on("mouseup", function(evt) {
    evt.stopPropagation();
  });

  $preview.on("click", function() {
    router.push({ name: "project", query: { edit_project: state.id } });
  });
  return $option;
};

export const initSelect2 = (
  id,
  data,
  setPlaceholder = false,
  select2Options = {},
  isSelected = false,
  isHasIcon = false
) => {
  let selectEle = jQuery("#" + id);
  selectEle.empty().trigger("change");

  if (isHasIcon) {
    selectEle.select2({
      language: select2_language,
      data: data,
      ...select2Options,
      templateResult: formatState,
    });
  } else {
    selectEle.select2({
      language: select2_language,
      data: data,
      ...select2Options,
    });
  }
  if (setPlaceholder) {
    selectEle.prepend('<option selected="" value="">&nbsp;</option>');
    selectEle.val("");
  }
  if (isSelected) {
    selectEle.val(select2Options.selected).trigger("change");
  }
  if (select2Options && select2Options.multiple) {
    jQuery('.select2-selection--multiple').append('<span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span>')
    selectEle.trigger('change')
  }
  return selectEle;
};

/**
 *
 * @param {*} id
 * @param {*} data
 * @param {*} setPlaceholder
 * @param {*} handleSelect
 * @param {*} select2Options
 */
export const setSelect2MultiSelectData = (
  id,
  data,
  setPlaceholder,
  handleSelect,
  select2Options = {}
) => {
  let selectEle = initSelect2(id, data, setPlaceholder, select2Options);
  selectEle
    .off("select2:select")
    .off("select2:unselect")
    .on("select2:select", (e) => {
      let selectedIds = jQuery(e.target).val();
      handleSelect(selectedIds);
    })
    .on("select2:unselect", (evt) => {
      let selectedIds = jQuery("#" + id).val();
      handleSelect(selectedIds);
      if (!evt.params.originalEvent) {
        return;
      }
      evt.params.originalEvent.stopPropagation();
    });
};

/**
 *
 * @param {*} id
 * @param {*} data
 * @param {*} setPlaceholder
 * @param {*} handleSelect
 * @param {*} select2Options
 */
export const setSelect2Data = (
  id,
  data,
  setPlaceholder,
  handleSelect,
  select2Options = {},
  isSelected,
  isHasIcon
) => {
  let selectEle = initSelect2(
    id,
    data,
    setPlaceholder,
    select2Options,
    isSelected,
    isHasIcon
  );
  selectEle.on("select2:select", (e) => {
    let selectedId = jQuery(e.target).val();
    handleSelect(selectedId);
  });
};

/**
 * set select2 value
 * @param {*} id
 * @param {*} value
 * @param {*} initEvent
 */
export const setSelect2Value = (id, value, initEvent = ["change"]) => {
  let element = jQuery("#" + id);
  if (!value) {
    element.empty();
  } else {
    element.val(value);
  }
  for (let event of initEvent) {
    element.trigger(event);
  }
};

/**
 *
 * @param {*} id
 */
export const emptySelect2Value = (id) => {
  jQuery("#" + id)
    .empty()
    .trigger("change");
};

export const numberWithCommas = (number) => {
  if (number) {
    return number.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
  } else {
    return "0";
  }
};

export const roundDecimal = (number, decimals) => {
  return +(Math.round(number + "e+" + decimals) + "e-" + decimals);
};

export const formatTallyResult = (tally_result, totalTime) => {
  let totalSeconds = totalTime + 1;
  let condition = Math.floor(totalSeconds / 4);
  let segmentLength = Math.floor(totalSeconds / 4);
  segmentLength = segmentLength ? segmentLength : 1;
  let mod = totalSeconds % 4;
  let result = groupBySegment(tally_result, condition, mod, segmentLength, totalTime);
  return result;
};

export const number0Hyphen = (number) => {
  if (number == 0 || number == null) {
    return "- ";
  } else {
    return number;
  }
};

export const groupBySegment = (tally_result, condition, mod, segmentLength, totalTime) => {
  let result;
  if (condition == 0) {
    if (mod == 1) {
      result = [{ left: 0, right: 0, start: 0, end: 0 }]
    } else if (mod == 2) {
      result = [
        { left: 0, right: 0, start: 0, end: 0 },
        { left: 0, right: 0, start: 1, end: 1 }]
    } else if (mod == 3) {
      result = [
        { left: 0, right: 0, start: 0, end: 0 },
        { left: 0, right: 0, start: 1, end: 1 },
        { left: 0, right: 0, start: 2, end: 2 }]
    }
  } else {
    if (mod == 0) {
      result = [
        { left: 0, right: 0, start: 0, end: segmentLength - 1 },
        { left: 0, right: 0, start: segmentLength, end: segmentLength * 2 - 1 },
        { left: 0, right: 0, start: segmentLength * 2, end: segmentLength * 3 - 1 },
        {
          left: 0,
          right: 0,
          start: segmentLength * 3,
          end: totalTime,
        }, 
      ];
    } else if (mod == 1) {
      result = [
        { left: 0, right: 0, start: 0, end: segmentLength},
        { left: 0, right: 0, start: segmentLength + 1, end: segmentLength * 2},
        { left: 0, right: 0, start: segmentLength * 2 + 1, end: segmentLength * 3 },
        {
          left: 0,
          right: 0,
          start: segmentLength * 3 + 1,
          end: totalTime,
        },
      ];
    } else if (mod == 2) {
      result = [
        { left: 0, right: 0, start: 0, end: segmentLength},
        { left: 0, right: 0, start: segmentLength + 1, end: segmentLength * 2 + 1},
        { left: 0, right: 0, start: segmentLength * 2 + 2, end: segmentLength * 3 + 1 },
        {
          left: 0,
          right: 0,
          start: segmentLength * 3 + 2,
          end: totalTime,
        },
      ];
    } else if (mod == 3) {
      result = [
        { left: 0, right: 0, start: 0, end: segmentLength},
        { left: 0, right: 0, start: segmentLength + 1, end: segmentLength * 2 + 1},
        { left: 0, right: 0, start: segmentLength * 2 + 2, end: segmentLength * 3 + 2 },
        {
          left: 0,
          right: 0,
          start: segmentLength * 3 + 3,
          end: totalTime,
        },
      ];
    }
  }
  
  if (!tally_result) return result;
  return tally_result.reduce(function(acc, obj) {
    if (obj.second <= totalTime) {
      var idx = getIdxIfChartIs25Percent(mod, obj.second, condition)
      idx = idx <= 3 ? idx : 3;
      acc[idx].left += obj.left;
      acc[idx].right += obj.right;
    }
    return acc;
  }, result);
};

export const getIdxIfChartIs25Percent = (mod, objSecond, condition) => {
  if (
      (mod == 0 && 0 <= objSecond && objSecond < condition)
      || (
          mod == 1 || mod == 2 || mod == 3
      ) && 0 <= objSecond && objSecond <= condition
  ) {
    return 0
  } else if (
      (mod == 0 && condition <= objSecond && objSecond < condition * 2)
      || (mod == 1 && condition < objSecond && objSecond <= condition * 2)
      || (mod == 2 && condition < objSecond && objSecond <= condition * 2 + 1)
      || (mod == 3 && condition < objSecond && objSecond <= condition * 2 + 1)
  ) {
    return 1
  } else if (
      (mod == 0 && condition * 2 <= objSecond && objSecond < condition * 3)
      || (mod == 1 && condition * 2 < objSecond && objSecond <= condition * 3)
      || (mod == 2 && condition * 2 + 1 < objSecond && objSecond <= condition * 3 + 1)
      || (mod == 3 && condition * 2 + 1 < objSecond && objSecond <= condition * 3 + 2)
  ) {
    return 2
  } else {
    return 3
  }
}

export const sum = (array) => {
  return array.reduce((sume, el) => sume + el, 0);
};

export const menuListUserPage = [
  {
    id: 1,
    name: "メンバー一覧",
  },
  {
    id: 2,
    name: "会社情報",
  },
  {
    id: 3,
    name: "ユーザー情報",
  },
];

export const menuListUserPageAdmin = [
  {
    id: 1,
    name: "メンバー一覧",
  },
  {
    id: 3,
    name: "ユーザー情報",
  },
];

export const getLabelStatus = (status, id) => {
  if (status) {
    return `<button class="body status-${status} id-${id}" ><i class="icon icon-checkbox checked mr-1"></i>有効</button>`;
  } else {
    return `<button class="body status-${status} id-${id}" ><i class="icon icon-checkbox mr-1"></i>有効</button>`;
  }
};
export const textEllipsis = (text) => {
  return `<span class="ellipsis">${text}</span>`;
};

export const btnResendPassword = (id, email) => {
  return (
    '<a href="javascript:" data-id="' +
    id +
    '" data-email="' +
    email +
    '" class="resend-btn icon-mail-confirm"> <i class="icon icon-mail mr-2"></i>送信</a>'
  );
};

export const interactionMax = (array) => {
  return roundDecimal(Math.max(...array), 1);
};
export const interactionAvg = (array) => {
  return roundDecimal(sum(array) / array.length, 1);
};

export const getImageSecond = (image_data, second) => {
  let urlS3 =
    process.env.VUE_APP_URL_S3 || "https://s3-ap-northeast-1.amazonaws.com/";
  let data = [urlS3, image_data, second, ".jpg"];

  return data.join("");
};

export const getUrlQuery = () => {
  let urlQuery = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
    m,
    key,
    value
  ) {
    /* eslint-disable */
    if (urlQuery.hasOwnProperty(key)) {
      /* eslint-enable */
      urlQuery[key] = [...urlQuery[key], value];
    } else {
      urlQuery[key] = value;
    }
  });
  return urlQuery;
};

export const addUrlParamToPath = (params, currentUrlQuery) => {
  // params = { key: value}
  let currentPathWithoutQuery = location.origin + location.pathname;
  if (isObjectEmpty(params) && isObjectEmpty(currentUrlQuery)) {
    history.pushState({}, null, currentPathWithoutQuery);
  }
  let paramsMerge = { ...currentUrlQuery, ...params };
  let urlParam = Object.keys(paramsMerge)
    .map((key) => {
      return (
        encodeURIComponent(key) + "=" + encodeURIComponent(paramsMerge[key])
      );
    })
    .join("&");
  let path = currentPathWithoutQuery + "?" + urlParam;
  history.pushState({}, null, path);
};

export const deleteInputDefault = (classElement) => {
  let defaultText = "https://www.youtube.com/watch?v=";
  var test = document.querySelector("." + classElement);
  var val = defaultText;
  listenEvent("keydown", test, replaceVal);
  listenEvent("keyup", test, replaceVal);
  listenEvent("click", test, replaceVal);
  jQuery(window).on("click", replaceVal);

  function replaceVal() {
    var tempVal = test.value;
    if (tempVal.indexOf(val) === -1) {
      tempVal = val;
      test.value = val;
    }
  }
};

export const listenEvent = (event, elem, func) => {
  if (elem.addEventListener) {
    elem.addEventListener(event, func, false);
  } else if (elem.attachEvent) {
    elem.attachEvent("on" + event, func);
  }
};

export const calendarEventsUrl = (year) => {
  let calendarId = 'ja.japanese#holiday@group.v.calendar.google.com';
  let key = 'AIzaSyAs2F9rd1zOddzcV7o5gO99mtmqyAEKmn0';
  let timeMin = moment(year + '-01-01').format('YYYY-MM-DD') + 'T00:00:00Z';
  let timeMax = moment(year + '-12-31').format('YYYY-MM-DD')  + 'T23:59:59Z';

  let url = 'https://www.googleapis.com/calendar/v3/calendars/' + encodeURIComponent(calendarId) + '/events?key='+ key + '&timeMin=' + encodeURIComponent(timeMin) + '&timeMax=' + encodeURIComponent(timeMax);
  return url;
};
export const textMailDefault = {
  title: '【PlayAds】（案件名）の動画クリエイティブ調査のご協力',
  body: 'お疲れ様です。{user_name}です。\t\t\t\t\t\t\t\n' +
      '表題の件につきましてご協力頂きありがとうございます。\t\t\t\t\t\t\t\n' +
      '今回の依頼背景と依頼期日は以下のとおりとなります。\t\t\t\t\t\t\t\n' +
      '\t\t\t\t\t\t\t\n' +
      '■依頼背景(自由記載)\t\t\t\t\t\t\t\n' +
      '・(例)〇〇商品～\t\t\t\t\t\t\t\n' +
      '\t\t\t\t\t\t\t\n' +
      '■依頼期日(自由記載)\t\t\t\t\t\t\t\n' +
      '・YYYY/MM/DD\t\t\t\t\t\t\t\n' +
      '\t\t\t\t\t\t\t\n' +
      '■依頼担当者(自由記載)\t\t\t\t\t\t\t\n' +
      '・(例)〇〇部～\t\t\t\t\t\t\t\n' +
      '\t\t\t\t\t\t\t\n' +
      '■調査対象動画\t\t\t\t\t\t\t\n' +
      '{link_url}\t\t\t\t\t\t\t\n' +
      '\t\t\t\t\t\t\t\n' +
      'お忙しい中かとは思いますがご協力の程よろしくお願い致します。',
};

export const roundPercent= (percent) => {
  if(typeof percent == "number" & percent % 1 != 0) return percent.toFixed(1)
  return percent;
}

export const addPointZero = (numberString) => {
  let numberStringLength = numberString.length;
  if (numberString == "100") {
    return numberString + ".0";
  } else if (numberStringLength >= 3) {
    return numberString;
  } else if (numberStringLength <= 2) {
    return numberString + ".0";
  }
}

export const forceArrowDownSelect2 = () => {
  (function ($) {
    const Defaults = $.fn.select2.amd.require('select2/defaults');

    $.extend(Defaults.defaults, {
      dropdownPosition: 'auto'
    });

    const AttachBody = $.fn.select2.amd.require('select2/dropdown/attachBody');

    AttachBody.prototype._positionDropdown = function () {
      const $window = $(window);

      const isCurrentlyAbove = this.$dropdown.hasClass('select2-dropdown--above');
      const isCurrentlyBelow = this.$dropdown.hasClass('select2-dropdown--below');

      let newDirection = null;

      const offset = this.$container.offset();

      offset.bottom = offset.top + this.$container.outerHeight(false);

      const container = {
        height: this.$container.outerHeight(false)
      };

      container.top = offset.top;
      container.bottom = offset.top + container.height;

      const dropdown = {
        height: this.$dropdown.outerHeight(false)
      };

      const viewport = {
        top: $window.scrollTop(),
        bottom: $window.scrollTop() + $window.height()
      };

      const enoughRoomAbove = viewport.top < (offset.top - dropdown.height);
      const enoughRoomBelow = viewport.bottom > (offset.bottom + dropdown.height);

      const css = {
        left: offset.left,
        top: container.bottom
      };

      // Determine what the parent element is to use for calciulating the offset
      let $offsetParent = this.$dropdownParent;

      // For statically positoned elements, we need to get the element
      // that is determining the offset
      if ($offsetParent.css('position') === 'static') {
        $offsetParent = $offsetParent.offsetParent();
      }

      const parentOffset = $offsetParent.offset();

      css.top -= parentOffset.top;
      css.left -= parentOffset.left;

      const dropdownPositionOption = this.options.get('dropdownPosition');

      if (dropdownPositionOption === 'above' || dropdownPositionOption === 'below') {
        newDirection = dropdownPositionOption;
      } else {
        if (!isCurrentlyAbove && !isCurrentlyBelow) {
          newDirection = 'below';
        }

        if (!enoughRoomBelow && enoughRoomAbove && !isCurrentlyAbove) {
          newDirection = 'above';
        } else if (!enoughRoomAbove && enoughRoomBelow && isCurrentlyAbove) {
          newDirection = 'below';
        }
      }

      if (newDirection === 'above' || (isCurrentlyAbove && newDirection !== 'below')) {
        css.top = container.top - parentOffset.top - dropdown.height;
      }

      if (newDirection != null) {
        this.$dropdown
        .removeClass('select2-dropdown--below select2-dropdown--above')
        .addClass('select2-dropdown--' + newDirection);
        this.$container
        .removeClass('select2-container--below select2-container--above')
        .addClass('select2-container--' + newDirection);
      }

      this.$dropdownContainer.css(css);
    };
  })(jQuery);
}

export const roundNumberLabelChart = (maxValue) => {
  let interval;
  let param = 1;
  if(maxValue < 0) {
    maxValue = maxValue * -1;
    param = -1;
  }
  if (maxValue <= 1) {
    interval = 0.5;
  } else if (maxValue <= 4) {
    interval = 1;
  } else if (maxValue <= 10) {
    interval = 2;
  } else if (maxValue <= 25) {
    interval = 5;
  } else if (maxValue <= 50) {
    interval = 10;
  } else if (maxValue <= 100) {
    interval = 25;
  } else {
    interval = 25;
  }

  const max = (Math.round(maxValue / interval) * interval + interval) * param;

  return { max: max, interval };
}

export const getUserAgent = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (window.navigator.userAgent.indexOf("MSIE") !== -1 || userAgent.match(/Trident.*rv:11\./)) {
    return listAgent.IE
  } else if (window.navigator.userAgent.indexOf("Edg") !== -1 || userAgent.indexOf('edge') != -1) {
    return listAgent.EDGE
  } else if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
    return listAgent.CHROME
  } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
    return listAgent.SAFARI
  } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
    return listAgent.FIREFOX
  } else if (window.navigator.userAgent.indexOf("Opera") !== -1 || window.navigator.userAgent.indexOf("OPR") !== -1) {
    return listAgent.OPERA
  } else {
    return null;
  }
}
