import { isObjectEmpty } from '../../commons/helpers';
import { planTypes } from '../../commons/plan_const';

export default {
    state: {
        currentUser: {
            name: "",
            role: "",
            plan: null,
            owner_id: null,
            account_id: null,
            user_id: null,
            is_market_research: null
        },
        token: "",
        isLogged: false,
    },
    getters: {
        currentUser: state => {
            let localCredential = localStorage.getItem('user');
            if (isObjectEmpty(state.currentUser) && localCredential) {
                state.currentUser = JSON.parse(localCredential);
            }
            return state.currentUser;
        },
        token: state => {
            return state.token || localStorage.getItem('token');
        },
        isLogged: state => {
            return state.isLogged;
        },
        isAdmin: (state, getters) => {
            return getters.currentUser.role == 'admin';
        },
        isClient: (state, getters) => {
            return getters.currentUser.role == "buyer";
        },
        checkPlanType: (state, getters) => plan_type => {
            return getters.currentUser.plan == planTypes[plan_type];
        }
    },
    mutations: {
        setCurrentUser(state, user) {
            state.currentUser.name = user.owner_name;
            state.currentUser.role = user.role;
            state.currentUser.plan = user.plan;
            state.currentUser.owner_id = user.owner_id;
            state.currentUser.account_id = user.account_id;
            state.currentUser.user_id = user.user_id;
            state.currentUser.is_market_research = user.is_market_research;
            localStorage.setItem('user', JSON.stringify(state.currentUser));
            localStorage.setItem('user_id', user.user_id);
        },
        setToken(state, token) {
            state.token = token;
            localStorage.setItem('token', token);
        },

        setLogged(state, logged) {
            state.isLogged = logged;
        },
        clearCurrentUser(state) {
            state.currentUser.name = "";
            state.currentUser.role = "";
            localStorage.removeItem('user');
        },
        clearToken() {
            localStorage.removeItem('token');
        }
    },
    actions: {

    }
}
