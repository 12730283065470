var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select-step"},[_c('div',{staticClass:"font-bold title-page"},[_vm._v("CM動画追加")]),_c('div',{staticClass:"description-page"},[_vm._v("検証方法を選んでください。")]),(_vm.currentUser.role == 'admin')?_c('div',{staticClass:"form-group required"},[_c('label',[_vm._v("クライアント")]),_c('Select',{attrs:{"data":_vm.data,"idSelected":_vm.idSelected,"isSelected":_vm.isSelected,"element":_vm.element},on:{"select":_vm.selectBuyer,"initSelectedId":_vm.initSelectedBuyer}})],1):_vm._e(),_c('div',{staticClass:"row-9"},[_c('div',{staticClass:"row-9-col-3 step-item"},[_c('div',{staticClass:"step-content"},[_c('div',{staticClass:"step-name"},[_vm._v(_vm._s(_vm.listStep.step1.name))]),_c('div',{staticClass:"step-description"},[_vm._v(" 制作するCM動画の特長を区分するときや多くの反応種別を取得したいときに適しています。反応種別は個別に設定可能です。 ")]),_vm._m(0),_c('button',{staticClass:"btn-change",class:{
                  'btn-changed': _vm.stepChanged == _vm.listStep.step1.id,
                  'btn-disabled': _vm.isDisabled,
                },on:{"click":function($event){return _vm.changeStep(_vm.listStep.step1.id)}}},[_vm._v("選択 ")])])]),_c('div',{staticClass:"row-9-col-3 step-item"},[_c('div',{staticClass:"step-content"},[_c('div',{staticClass:"step-name"},[_vm._v(_vm._s(_vm.listStep.step2.name))]),_c('div',{staticClass:"step-description"},[_vm._v(" 最もベーシックな検証方法です。反応種別は個別に設定可能です。 ")]),_vm._m(1),_c('button',{staticClass:"btn-change",class:{
                  'btn-changed': _vm.stepChanged == _vm.listStep.step2.id,
                },on:{"click":function($event){return _vm.changeStep(_vm.listStep.step2.id)}}},[_vm._v("選択 ")])])]),_c('div',{staticClass:"row-9-col-3 step-item"},[_c('div',{staticClass:"step-content"},[_c('div',{staticClass:"step-name"},[_vm._v(_vm._s(_vm.listStep.step3.name))]),_c('div',{staticClass:"step-description"},[_vm._v(" メディアの変数を横軸で固定し、純粋なクリエイティブの影響力が測定可能です。 ")]),_vm._m(2),_c('button',{staticClass:"btn-change",class:{
                  'btn-changed': _vm.stepChanged == _vm.listStep.step3.id,
                  'btn-disabled': _vm.isDisabled,
                },on:{"click":function($event){return _vm.changeStep(_vm.listStep.step3.id)}}},[_vm._v("選択 ")])])])]),_c('div',{staticClass:"row d-flex justify-content-center"},[_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-primary btn-submit",attrs:{"disabled":_vm.stepChanged == null},on:{"click":_vm.submitStep}},[_vm._v(" 次へ ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chart-images d-flex justify-content-center align-items-center"},[_c('img',{attrs:{"src":"/images/img/charts/radar-chart.png","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chart-images d-flex justify-content-center align-items-center"},[_c('img',{attrs:{"src":"/images/img/charts/circle-chart.png","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chart-images d-flex justify-content-center align-items-center"},[_c('img',{attrs:{"src":"/images/img/charts/line-chart.png","alt":""}})])}]

export { render, staticRenderFns }