<template lang="">
    <div class="style">
        <div class="form-company">
            <div class="form-group w-35">
                <label>会社名 <span class="require">必須</span></label>
                <input
                    class="form-control"
                    type="text"
                    v-model.trim="company_name"
                    :class="{'error-input': (submitted && $v.company_name.$error)}"
                >
                <div v-if="submitted && $v.company_name.$error">
                    <span class="error error-message" v-if="!$v.company_name.required">{{ $t('validation.required', {field: '会社名'}) }}</span>
                    <span class="error error-message" v-else-if="!$v.company_name.xssCheck">{{ $t('validation.xss_check', {field: '会社名'}) }}</span>
                    <span class="error error-message" v-else-if="!$v.company_name.maxLength">{{ $t('validation.max_length', {field: '会社名', length: '30'}) }}</span>
                </div>
            </div>
            <div class="form-group w-35">
                <label>部署名</label>
                <input
                    class="form-control"
                    type="text"
                    v-model.trim="department_name"
                    :class="{'error-input': (submitted && $v.department_name.$error)}"
                >
                <div v-if="submitted && $v.department_name.$error">
                    <span class="error error-message" v-if="!$v.department_name.xssCheck">{{ $t('validation.xss_check', {field: '部署名'}) }}</span>
                </div>
            </div>
            <div class="address">
                <div class="form-group w-30 mr-5">
                    <label>郵便番号 <span class="require">必須</span></label>
                <input
                    class="form-control"
                    type="text"
                    v-model.trim="postal_code"
                    :class="{'error-input': (submitted && $v.postal_code.$error)}"
                >
                <div v-if="submitted && $v.postal_code.$error">
                    <span class="error error-message" v-if="!$v.postal_code.required">{{ $t('validation.required', {field: '郵便番号'}) }}</span>
                    <span class="error error-message" v-else-if="!$v.postal_code.minLength">{{ $t('validation.postal_code') }}</span>
                    <span class="error error-message" v-else-if="!$v.postal_code.maxLength">{{ $t('validation.postal_code') }}</span>
                    <span class="error error-message" v-else-if="!$v.postal_code.xssCheck">{{ $t('validation.postal_code') }}</span>
                </div>
                </div>
                <div class="form-group w-70">
                    <label>都道府県区市町村番地 <span class="require">必須</span></label>
                <input
                    class="form-control"
                    type="text"
                    v-model.trim="address1"
                    :class="{'error-input': (submitted && $v.address1.$error)}"
                >
                <div v-if="submitted && $v.address1.$error">
                    <span class="error error-message" v-if="!$v.address1.required">{{ $t('validation.required', {field: '都道府県区市町村番地'}) }}</span>
                    <span class="error error-message" v-else-if="!$v.address1.xssCheck">{{ $t('validation.xss_check', {field: '都道府県区市町村番地'}) }}</span>
                </div>
                </div>
            </div>
            <div class="form-group w-35">
                <label>ビル名・マンション名</label>
                <input
                    class="form-control"
                    type="text"
                    v-model.trim="address2"
                    :class="{'error-input': (submitted && $v.address2.$error)}"
                >
                <div v-if="submitted && $v.address2.$error">
                    <span class="error error-message" v-if="!$v.address2.xssCheck">{{ $t('validation.xss_check', {field: 'ビル名・マンション名'}) }}</span>
                </div>
            </div>
            <div class="form-group w-35">
                <label>担当者 <span class="require">必須</span></label>
                <input
                    class="form-control"
                    type="text"
                    v-model.trim="contact_name"
                    :class="{'error-input': (submitted && $v.contact_name.$error)}"
                >
                <div v-if="submitted && $v.contact_name.$error">
                    <span class="error error-message" v-if="!$v.contact_name.required">{{ $t('validation.required', {field: '担当者'}) }}</span>
                    <span class="error error-message" v-else-if="!$v.contact_name.xssCheck">{{ $t('validation.xss_check', {field: '担当者'}) }}</span>
                </div>
            </div>
            <div class="form-group w-30">
                <label>電話番号 <span class="require">必須</span></label>
                <input
                    class="form-control"
                    type="text"
                    v-model.trim="phone_number"
                    :class="{'error-input': (submitted && $v.phone_number.$error)}"
                >
                <div v-if="submitted && $v.phone_number.$error">
                    <span class="error error-message" v-if="!$v.phone_number.required">{{ $t('validation.required', {field: '電話番号'}) }}</span>
                    <span class="error error-message" v-else-if="!$v.phone_number.xssCheck">{{ $t('validation.xss_check', {field: '電話番号'}) }}</span>
                    <span class="error error-message" v-else-if="!$v.phone_number.maxLength">{{ $t('validation.max_length', {field: '電話番号', length: '13'}) }}</span>
                </div>
            </div>

            <div class="plan-name">料金プラン</div>
                <div v-if="plan == 1">
                    <div class="plan-value">{{ $t('plans.trial') }}</div>
                </div>
                <div v-else-if="plan == 2">
                    <div class="plan-value">{{ $t('plans.basic') }}</div>
                </div>
                <div v-else-if="plan == 3">
                    <div class="plan-value">{{ $t('plans.private') }}</div>
                </div>
                <div v-else-if="plan == 4">
                    <div class="plan-value">{{ $t('plans.free') }}</div>
                </div>
            <div class="plan-content">
                <div>料金プランを変更する場合は下記メールアドレスまでお問い合わせください。</div>
                <div> cs@playads.jp</div>
            </div>
            <div class="button-submit">
                <button class="btn btn-primary" @click="updateConfirm">
                    更新
                </button>
            </div>
        </div>
        <modal-confirm 
            v-if="isShowModalConfirm"
            :message="modalMessage"
            @confirm="updateOK"
            @closeModalConfirm="isShowModalConfirm = false"
        ></modal-confirm>
    </div>
</template>

<script>
const jQuery = require("jquery");
jQuery.DataTable = require("datatables.net");
import ModalConfirm from "./../Modal/ModalConfirm.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { xssCheck, postalCode } from "../../commons/rules";

export default {
  data() {
    return {
      company_name: "",
      department_name: "",
      postal_code: "",
      address1: "",
      address2: "",
      contact_name: "",
      phone_number: "",
      plan: "",
      submitted: false,
      modalMessage: "",
      isShowModalConfirm : false
    };
  },
  validations: {
    company_name: { required, xssCheck, maxLength: maxLength(30) },
    department_name: { xssCheck },
    postal_code: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(8),
      postalCode,
    },
    address1: { required, xssCheck },
    address2: { xssCheck },
    contact_name: { required, xssCheck },
    phone_number: { required, maxLength: maxLength(13), xssCheck },
  },
  components: {
    ModalConfirm,
  },
  methods: {
    getCompanyInfo() {
      let currentUser = this.$store.getters.currentUser;
      let buyer_id = currentUser.owner_id;
      let url = this.$router.resolve({
        name: "api.buyers.company-info",
        params: {
          id: buyer_id,
        },
      }).href;
      this.$http2.get(url, {}, (data) => {
        this.company_name = data.name;
        this.department_name = data.department_name;
        this.postal_code = data.postal_code;
        this.address1 = data.address1;
        this.address2 = data.address2;
        this.contact_name = data.contact_name;
        this.phone_number = data.phone_number;
        this.plan = data.plan;
      });
    },
    openModal() {
      let vm = this;
      vm.modalMessage = "更新します、よろしいですか？";
      vm.isShowModalConfirm = true;
    },
    updateConfirm() {
      this.submitted = true;
      this.$v.$touch();
      let vm = this;
      let error = vm.$v.$invalid;
      if (!error) {
        this.openModal();
      }
    },
    updateOK() {
      let currentUser = this.$store.getters.currentUser;
      let buyer_id = currentUser.owner_id;
      let url = this.$router.resolve({
        name: "api.buyers.company-info",
        params: {
          id: buyer_id,
        },
      }).href;
      let putData = {
        name: this.company_name,
        department_name: this.department_name,
        postal_code: this.postal_code,
        address1: this.address1,
        address2: this.address2,
        contact_name: this.contact_name,
        phone_number: this.phone_number,
      };
      this.$http2.put(url, putData, () => {
        this.isShowModalConfirm = false;
        this.$toasted.success(this.$t("messages.update_success"));
      });
    },
  },
  mounted() {
    this.getCompanyInfo();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_user";
</style>
