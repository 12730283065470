<template>
  <div class="d-flex options">
    <label>選択肢</label>
    <div class="d-flex flex-wrap gap-4">
      <div v-for="item in options" :key="'option-' + item.id"
           class="d-flex justify-content-between align-items-center item-option" :class="{'opacity-7': readOnly}">
        <i class="icon icon-remove-option" :class="{'cursor-default': readOnly}" @click="removeOption(item.id)"></i>
        <input v-model.trim="item.value" :ref="qId + '-op-' + item.id" @blur="editOption(item.id, item.value)"
               @keyup.enter="$refs[qId + '-op-' + item.id][0].blur()" :disabled="!item.isEdit"
               :size="item.value.length < 13 ? item.value.length - 1 > 0 ? item.value.length - 1 : 1 : 11"/>
        <i class="icon icon-edit-option" :class="{'cursor-default': readOnly}" @click="enabledEditOption(item.id)"></i>
      </div>
      <button
          class="tag btn-plus"
          @click="openModalAddOption()"
          :disabled="disabled || readOnly"
      >
        <i class="icon icon-white-plus"></i>
      </button>
    </div>
  </div>
</template>
<script>
import EventBus from "@/commons/event_bus";

export default {
  props: {
    qId: Number,
    options: Array,
    disabled: Boolean,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openModalAddOption() {
      if (!this.disabled) {
        EventBus.$emit("addOption", this.qId)
        EventBus.$emit("toggleModal", "add-option")
      }
    },
    removeOption(id) {
      if (!this.readOnly) {
        EventBus.$emit("removeOption", {id: this.qId, idOption: id})
      }
    },
    enabledEditOption(id) {
      if (!this.readOnly) {
        EventBus.$emit("enabledEditOption", {id: this.qId, idOption: id})
        setTimeout(() => {
          this.$refs[this.qId + '-op-' + id][0].focus()
        }, 0)
      }
    },
    editOption(id, value) {
      EventBus.$emit("editOption", {id: this.qId, idOption: id, valueOption: value})
    }
  },
};
</script>
<style lang="scss" scoped>
.options {
  margin-top: 16px;
  margin-bottom: 40px;
  
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    white-space: nowrap;
    margin-top: 8px;
    margin-right: 34px;
  }
  
  .gap-4 {
    gap: 4px;
  }
  
  .item-option {
    min-width: 78px;
    max-height: 24px;
    text-align: center;
    padding: 8px 12px;
    background-color: #49AB94;
    border-radius: 8px;
    
    input {
      width: fit-content;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
      background-color: #49AB94;
      margin: 0 4px;
      border: none;
      cursor: default;
    }
    
    .icon {
      &:hover {
        cursor: pointer;
      }
    }
    
    .cursor-default {
      &:hover {
        cursor: default;
      }
    }
  }
  
  .btn-plus {
    width: 40px;
    height: 40px;
    padding-left: 0;
    padding-right: 0;
    background-color: #49AB94;
    border-radius: 8px;
    
    &:disabled {
      cursor: default;
      opacity: 0.7;
    }
  }
}

.opacity-7 {
  opacity: 0.7;
}
</style>
