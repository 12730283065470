import { planTypes } from "./../../commons/plan_const";

export default {
    state: {
        lists: [], // buyers
        buyersActive: [], //buyers approve_status=1, status=1
        defaultId: null,
        buyerSelected: null,
    },
    getters: {
        buyers: state => state.lists,
        buyersById: state => id => {
            return state.lists.find(buyer => buyer.id == id);
        },
        checkBuyerPlan: (state, getters) => (id, plan_type) => {
            return getters.buyersById(id).plan == planTypes[plan_type];
        },
        buyersActive: state => state.buyersActive,
        buyersApproved: (status, getters) => getters.lists.filter(buyer => {
            return buyer.approve_status == 1;
        }),
        buyersNotApproved: (status, getters) => getters.lists.filter(buyer => {
            return buyer.approve_status != 1;
        }),
        defaultBuyer: state => {
            return state.lists.find(buyer => buyer.id == state.defaultId);
        },
        buyerSelected: state => state.buyerSelected,
    },
    mutations: {
        setBuyersActive(state, buyersActive) {
            state.buyersActive = buyersActive
        },
        setBuyers(state, buyers) {
            state.lists = buyers;
        },
        setDefaultId(state, id) {
            state.defaultId = id;
        },
        setBuyerSelected(state, buyerSelected){
            state.buyerSelected = buyerSelected;
        }
    },
    actions: {}
}
