var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShown),expression:"isShown"}],ref:"modal",staticClass:"modal add-segment-andor modal-show"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"btn-back"},[_c('i',{staticClass:"icon icon-prev",on:{"click":function($event){return _vm.prevModal()}}})])]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"label"},[_vm._v("AND OR設定")]),_vm._l((_vm.groupsSegment),function(groupAttributes,index){return _c('div',{key:'group' + index,staticClass:"list-item row-3 mb-4",class:{ 'list-item-selected': groupAttributes.length > 0 }},[_c('div',{staticClass:"row-3-col-1 groups-segment"},[(groupAttributes.length == 0)?_c('div',{staticClass:"describe"},[_vm._v(" セグメントを選択すると対象者のプロフィールが作成されます ")]):_c('div',{staticClass:"describe-wrapper"},[_c('div',{staticClass:"describe-item-selected"},[_c('div',{staticClass:"scroll-tab"},[(groupAttributes.length > 0)?_c('div',{staticClass:"image-male-female"},[_c('div',{staticClass:"image-female mr-2"},[_c('img',{staticClass:"card-image",attrs:{"src":'/images/img/image_female_' + (index % 3) + '.svg'}})]),_c('div',{staticClass:"image-female"},[_c('img',{staticClass:"card-image",attrs:{"src":'/images/img/image_male_' + (index % 3) + '.svg'}})])]):_vm._e(),_vm._l((groupAttributes),function(attribute,index){return _c('div',{key:'att-selected-' + index,staticClass:"att-selected"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(attribute.name))]),_c('div',{staticClass:"value"},_vm._l((attribute.list),function(attributeValue,index){return _c('span',{key:'index' +
                        index +
                        'value-selected-' +
                        attributeValue.id},[_vm._v(" "+_vm._s(attributeValue.value)+" "),(index !== attribute.list.length - 1)?_c('span',[_vm._v("or")]):_vm._e()])}),0)])})],2)])])]),_c('div',{staticClass:"row-3-col-2"},[_c('div',{staticClass:"list-attritute"},[(index)?_c('div',{staticClass:"btn-delete",on:{"click":function($event){return _vm.deleteGroup(index)}}},[_vm._v("削除")]):_vm._e(),_c('div',{staticClass:"attributes"},_vm._l((_vm.segmentSelecteds),function(attribute,idx){return _c('div',{key:'attribute-' + idx,staticClass:"attritute-selected"},[_c('div',{staticClass:"name"},[_c('span',[_vm._v(_vm._s(attribute.name))]),(_vm.isSelectedAll(attribute, index))?_c('div',{staticClass:"batch-selection unselected",on:{"click":function($event){return _vm.unSelectedAllValue(attribute, index)}}},[_vm._v(" 一括選択解除 ")]):_c('div',{staticClass:"batch-selection ",on:{"click":function($event){return _vm.selectedAllValue(attribute, index)}}},[_vm._v(" 一括選択 ")])]),_c('div',{staticClass:"attribute"},_vm._l((attribute.list),function(attributeValue){return _c('div',{key:'index-' + index + '-value-' + attributeValue.id,staticClass:"value",class:{
                      active: _vm.checkSelectedInGroup(
                        attribute,
                        attributeValue,
                        index
                      ),
                    },on:{"click":function($event){return _vm.toggleAttributeValue(attribute, attributeValue, index)}}},[_vm._v(" "+_vm._s(attributeValue.value)+" ")])}),0)])}),0)])])])}),_c('div',{staticClass:"add-condition"},[_c('div',{staticClass:"btn-add-condition btn btn-primary-outline",on:{"click":_vm.addCondition}},[_vm._v(" 条件の追加 "),_c('i',{staticClass:"icon icon-add-circle"})])]),_c('div',{staticClass:"btn-submit"},[_c('button',{staticClass:"btn btn-primary button-download-pdf",on:{"click":function($event){return _vm.config()}}},[_vm._v(" 設定 ")])])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }