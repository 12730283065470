<template>
  <div>
    <div class="row justify-content-center mt-3" v-show="video.id">
      <div class="col-11">
        <div class="font-bold title-page" v-if="video.deliver_period_type">{{listStep["step" + video.deliver_period_type].name}}</div>
        <div class="form-group required" v-if="currentUser.role == 'admin'">
          <label>クライアント</label>
            <div class="form-control buyer-select">
              {{ buyerSelected.name }}
            </div>
        </div>
        <div
            class="form-group required"
            :class="{ error: submitted && $v.video.name.$error }"
        >
          <label>{{ $t("texts.video.name") }}</label>
          <input
              class="form-control"
              type="text"
              placeholder="動画名を入れてください"
              v-model.trim="video.name"
          />
          <template v-if="submitted && $v.video.name.$error">
            <span class="error error-message" v-if="!$v.video.name.required">{{
                $t("validation.required", { field: $t("texts.video.name") })
              }}</span>
            <span
                class="error error-message"
                v-else-if="!$v.video.name.maxLength"
            >{{
                $t("validation.max_length", {
                  field: $t("texts.video.name"),
                  length: 30,
                })
              }}</span
            >
            <span
                class="error error-message"
                v-else-if="!$v.video.name.videoNameXssCheck"
            >{{
                $t("validation.registerVideo.video_name.xss_check", { field: $t("texts.video.name") })
              }}</span
            >
          </template>
        </div>
        <div class="form-group required" v-show="video.deliver_period_type === listStep.step3.id">
          <label>媒体</label>
          <div class="list-radio">
            <div
                class="form-radio-box mr-5"
                v-for="(type, key) in listMediaType"
                :key="'media-type-' + key"
                :class="{'radio-disabled': isExpireDate}"
            >
              <input
                  class="form-radio-input"
                  type="radio"
                  :value="type.id"
                  v-model="video.commercial_media_type"
                  :disabled="isExpireDate"
              />
              <span class="btn-radio"></span>
              <label class="label-radio">{{ type.name }}</label>
            </div>
          </div>
        </div>
        <div class="form-group form-group-label w-full">
          <label>ラベル</label>
          <div class="group-tag-list">
            <div
              class="tag tag-two"
              :class="[{
                active: checkExistSelectedLabel(label.id),
              }]"
              v-for="label in labels"
              :key="'label-' + label.id"
              @click="selectLabels(label.id)"
            >
              <div>{{ label.name }}</div>
            </div>
            <div
                class="tag btn-plus"
                @click="addLabel"
            >
              <i class="icon icon-third-plus"></i>
            </div>
          </div>
        </div>
        <div class="d-flex datetime-content">
          <div
              class="form-group required"
              :class="{
            error:
              submitted && ($v.video.start_at.$error || $v.video.end_at.$error),
          }"
          >
            <label>{{ $t("texts.video.start_end_date") }}</label>
            <input
                v-show="isBeforeDate || isValidDate"
                class="form-control datetimepicker"
                type="text"
                name="daterange"
                placeholder="検証期間を選択"
            />
            <input
                v-show="isExpireDate"
                class="form-control input-disabled"
                type="text"
                name="daterange"
                placeholder="検証期間を選択"
                :value="video.start_at && video.end_at ? momentFormatDate(video.start_at) + ' ~ ' + momentFormatDate(video.end_at) : ''"
                disabled
            />
            <template
                v-if="
              submitted && ($v.video.start_at.$error || $v.video.end_at.$error)
            "
            >
            <span
                class="error error-message"
                v-if="!$v.video.start_at.required || !$v.video.end_at.required"
            >{{
                $t("validation.required", {
                  field: $t("texts.video.start_end_date"),
                })
              }}</span
            >
              <span v-else-if="!$v.start_at.lt">{{
                  $t("validation.registerVideo.time.lt")
                }}</span>
            </template>
            <div class="title-small color-red mt-2">
              <template v-if="currentUser.role === 'admin' &&
                  video.deliver_period_type == listStep.step2.id"
              >
                {{ $t("texts.video.start_end_date_desc") }}
              </template>
            </div>
          </div>
          <div
              class="form-group required"
              v-show="video.deliver_period_type === listStep.step2.id"
              :class="{
              error:
                submitted && ($v.video.start_time_at.$error),
            }"
          >
            <label>{{ $t("texts.video.start_time") }}</label>
            <input
                v-show="isBeforeDate"
                class="form-control"
                id="timepicker"
                type="text"
            />
            <input
                v-show="!isBeforeDate"
                class="form-control input-disabled input-time"
                type="text"
                :value="video.start_time_at"
                disabled
            />
            <template v-if="submitted && ($v.video.start_time_at.$error)">
              <span
                  class="error error-message"
                  v-if="!$v.video.start_time_at.required"
              >
                {{
                  $t("validation.required", {
                    field: $t("texts.video.start_time"),
                  })
                }}
              </span>
            </template>
          </div>
        </div>
        <div
          class="form-group required"
          :class="{
            error: submitted && $v.video.country_id.$error,
          }"
        >
          <label>{{ $t("texts.video.country") }}</label>
          <Select
              v-if="video.country_id"
              :data="countries"
              :idSelected="video.country_id"
              :isSelected="true"
              @select="selectCountries"
              :disabled="!isBeforeDate"
              :element="'countries'"
          ></Select>
          <template v-if="submitted && $v.video.country_id.$error">
            <span
              class="error error-message"
              v-if="!$v.video.country_id.required"
              >{{
                $t("validation.required", { field: $t("texts.video.country") })
              }}</span
            >
          </template>
        </div>
        <div class="form-group required large">
          <label>{{ $t("texts.video.video_url_type") }}</label>
          <div class="list-radio">
            <div
              class="form-radio-box mr-5 radio-disabled"
              v-for="(type, key) in videoURLTypes"
              :key="'video-url-type-' + key"
            >
              <input
                  :id="type.id"
                  class="form-radio-input"
                  type="radio"
                  :value="type.value"
                  :checked="video.video_url_type == type.value"
                  disabled
              />
              <span class="btn-radio"></span>
              <label class="label-radio">{{ type.text }}</label>
            </div>
          </div>
        </div>
        <div
          id="dropbox-file-upload"
          class="
            file-upload-wrapper
            mb-2
            row
            justify-content-center
            align-items-center
          "
          v-show="video.video_url_type == videoURLTypes.NORMAL.value"
        >
          <label class="color-primary d-flex align-items-center">
            <i class="icon icon-check mr-2" v-if="isVideoFile"></i>
            {{ fileName }}
          </label>
          <div class="input-file">
            <label class="color-primary cursor-default" for="file-upload">
              <i class="icon icon-download mr-1"></i>
              ファイルを選択
            </label>
            <input
                type="file"
                accept=".mp4"
                disabled
            />
          </div>
        </div>

        <div
          class="d-flex mb-2 align-items-center"
          v-show="video.video_url_type == videoURLTypes.YOUTUBE.value"
        >
          <label class="color-black mr-4">
            {{ $t("texts.video.youtube_id") }}
          </label>
          <div
            class="form-group custom mb-0"
            :class="{
              error:
                submitted &&
                video.video_url_type == videoURLTypes.YOUTUBE.value &&
                $v.video.video_url.$error,
            }"
          >
            <input
                class="form-control input-youtube-url input-disabled"
                :value="youtubeURL"
                placeholder="https://www.youtube.com/watch?v="
                disabled
            />
          </div>
        </div>
        <div class="title-small color-black-middle">
          <template v-if="video.video_url_type == videoURLTypes.NORMAL.value">{{
            videoURLTypes.NORMAL.desc
          }}</template>
          <template v-else>{{ videoURLTypes.YOUTUBE.desc }}</template>
        </div>
        <template
            v-if="submitted && video.video_url_type == 1 && $v.video_file.$error"
            class="invalid-feedback"
        >
        <span class="error error-message" v-if="!$v.video_file.required">{{
            $t("validation.registerVideo.video_file.required")
          }}</span>
          <span
              class="error error-message"
              v-else-if="!$v.video_file.extension"
          >{{ $t("validation.registerVideo.video_file.extension") }}</span
          >
          <span class="error error-message" v-else-if="!$v.video_file.accept">{{
              $t("validation.registerVideo.video_file.accept")
            }}</span>
        </template>
        <template
          v-if="
            submitted && video.video_url_type == 2 && $v.video.video_url.$error
          "
          class="invalid-feedback"
        >
          <span
            class="error error-message"
            v-if="!$v.video.video_url.required"
            >{{ $t("validation.registerVideo.video_url.required") }}</span
          >
          <span
            class="error error-message"
            v-else-if="!$v.video.video_url.maxLength"
          >
            {{
              $t("validation.registerVideo.video_url.max_length")
            }}</span
          >
          <span
            class="error error-message"
            v-else-if="!$v.video.video_url.halfWidthExcludeXss"
          >
            {{
              $t("validation.registerVideo.video_url.half_width_exclude_xss")
            }}</span
          >
        </template>
        <div class="form-group mt-7">
          <div class="form-check-box radio-disabled">
            <input
                class="form-check-input cursor-default"
                type="checkbox"
                name="youtube-channel"
                :checked="video.youtube_analytic_id"
                disabled
            />
            <span class="btn-check"> </span>
            <label class="label-check">YouTubeの視聴率を取り込む</label>
          </div>
        </div>
        <div
          class="form-group required"
          v-show="isYoutubeAnalytics"
          :class="{
            error:
              submitted &&
              $v.video.youtube_analytic_id &&
              $v.video.youtube_analytic_id.$error &&
              isYoutubeAnalytics == true,
          }"
        >
          <label>チャンネル</label>
          <template>
            <Select
                v-if="isBeforeDate"
                :data="youtubeChannels"
                :idSelected="video.youtube_analytic_id"
                :isSelected="isChannel"
                @select="selectChannel"
                :element="'channels'"
            ></Select>
            <Select
                v-else
                :data="youtubeChannels"
                :idSelected="video.youtube_analytic_id"
                :isSelected="true"
                :disabled="true"
                :element="'channels'"
            ></Select>
          </template>
          <template
            v-if="
              submitted &&
              $v.video.youtube_analytic_id &&
              $v.video.youtube_analytic_id.$error &&
              isYoutubeAnalytics == true
            "
            class="invalid-feedback d-block"
          >
            <span
              class="error error-message"
              v-if="!$v.video.youtube_analytic_id.required"
              >{{
                $t("validation.registerVideo.youtube_video_id.required")
              }}</span
            >
          </template>
        </div>
        <div
          class="form-group custom"
          v-show="isYoutubeAnalytics"
          :class="{
            error:
              submitted &&
              $v.video.youtube_video_id &&
              $v.video.youtube_video_id.$error &&
              isYoutubeAnalytics == true,
          }"
        >
          <label>{{ $t("texts.video.youtube_id") }}</label>
          <input
              v-if="isBeforeDate"
              class="form-control input-channel-video"
              v-model.trim="youtubeVideoChannelURL"
              placeholder="https://www.youtube.com/watch?v="
          />
          <input
              v-else
              class="form-control input-channel-video input-disabled"
              :value="youtubeVideoChannelURL"
              placeholder="https://www.youtube.com/watch?v="
              disabled
          />
          <template
            v-if="
              submitted &&
              $v.video.youtube_video_id &&
              $v.video.youtube_video_id.$error &&
              isYoutubeAnalytics == true
            "
            class="invalid-feedback d-block"
          >
            <span
              class="error error-message"
              v-if="!$v.video.youtube_video_id.required"
              >{{
                $t("validation.registerVideo.youtube_video_id.required")
              }}</span
            >
            <span
              class="error error-message"
              v-else-if="!$v.video.youtube_video_id.maxLength"
            >
              {{
                $t("validation.registerVideo.youtube_video_id.max_length")
              }}</span
            >
            <span
              class="error error-message"
              v-else-if="!$v.video.youtube_video_id.halfWidthExcludeXss"
            >
              {{
                $t("validation.registerVideo.youtube_video_id.half_width_exclude_xss")
              }}</span
            >
          </template>
        </div>

        <div class="form-group w-full flex flex-col" v-show="video.deliver_period_type === listStep.step2.id">
          <label>反応値の検証方法</label>
          <div class="radio-list flex flex-row flex-wrap">
            <div
              class="form-radio-box mr-5"
              :class="{'radio-disabled': !isBeforeDate && video.deliver_flg != type.value}"
              v-for="(type, key) in verifyMethods"
              :key="'verify-methods-' + key"
            >
              <input
                  v-if="isBeforeDate"
                  class="form-radio-input"
                  type="radio"
                  :value="type.value"
                  v-model="video.deliver_flg"
              />
              <input
                  v-else
                  class="form-radio-input"
                  type="radio"
                  :value="type.value"
                  :checked="video.deliver_flg === type.value"
                  disabled
              />
              <span class="btn-radio"></span>
              <label class="label-radio">{{ type.text }}</label>
            </div>
          </div>
          <div class="verification-methods-description">
            2種類の反応値を検証：1回の再生で2種類の反応値ボタンを設置して検証 <br>
            2種類の反応値を交互に検証：2回の再生で1種類の反応値ボタンを交互に設置して検証 <br>
            1種類の反応値を検証：1回の再生で1種類の反応値ボタンを設置して検証 <br>
          </div>
        </div>

        <div class="form-group large flex flex-col" v-show="video.deliver_period_type !== listStep.step3.id">
          <label>反応値種別</label>
          <template v-if="video.deliver_period_type === listStep.step2.id">
            <div class="radio-list flex flex-row flex-wrap" v-if="video.deliver_flg != 3">
              <div
                  class="form-radio-box mr-5"
                  :class="{'radio-disabled': !isBeforeDate && video.interaction_btn_1_index != type.index}"
                  v-for="(type, key) in interactionTypes"
                  :key="'interaction-type' + key"
              >
                <input
                    v-if="isBeforeDate"
                    class="form-radio-input"
                    type="radio"
                    :value="type.index"
                    v-model="video.interaction_btn_1_index"
                />
                <input
                    v-else
                    class="form-radio-input"
                    type="radio"
                    :value="type.index"
                    :checked="video.interaction_btn_1_index === type.index"
                    disabled
                />
                <span class="btn-radio"></span>
                <label class="label-radio mb-3">{{ type.text }}</label>
              </div>
            </div>
            <div class="radio-list flex flex-row flex-wrap" v-else>
              <div
                  class="form-radio-box mr-5"
                  :class="{'radio-disabled': !isBeforeDate && video.interaction_btn_2_index != type.index}"
                  v-for="(type, key) in interactionTypeVerifyOne"
                  :key="'interaction-type' + key"
              >
                <input
                    v-if="isBeforeDate"
                    class="form-radio-input"
                    type="radio"
                    :value="type.index"
                    v-model="video.interaction_btn_2_index"
                />
                <input
                    v-else
                    class="form-radio-input"
                    type="radio"
                    :value="type.index"
                    :checked="video.interaction_btn_2_index === type.index"
                    disabled
                />
                <span class="btn-radio"></span>
                <label class="label-radio">{{ type.text }}</label>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="radio-list flex flex-row flex-wrap">
              <div
                  class="form-radio-box mr-5"
                  v-for="(type, key) in interactionTypesStep1"
                  :key="'interaction-type' + key"
                  :class="{'radio-disabled': !isBeforeDate && video.interaction_btn_3_index != type.index}"
              >
                <input
                    v-if="isBeforeDate"
                    class="form-radio-input"
                    type="radio"
                    :value="type.index"
                    v-model="video.interaction_btn_3_index"
                />
                <input
                    v-else
                    class="form-radio-input"
                    type="radio"
                    :value="type.index"
                    :checked="video.interaction_btn_3_index === type.index"
                    disabled
                />
                <span class="btn-radio"></span>
                <label class="label-radio">{{ type.text }}</label>
              </div>
            </div>
          </template>
          <div class="text-link-setting-interaction-button">
            <span v-if="isBeforeDate" style="cursor : pointer" @click="showCustomInteractionModal()">他の反応値を設定 ></span>
            <span v-else class="cursor-default">他の反応値を設定 ></span>
          </div>
        </div>
        <div v-show="[listStep.step1.id, listStep.step2.id].includes(video.deliver_period_type)" class="form-group sub-form-group flex flex-col w-full">
          <label>セグメントを設定</label>
          <div class="radio-list flex flex-row flex-wrap">
            <div
                class="form-radio-box mr-5"
                :class="{'radio-disabled': !isBeforeDate && video.has_custom_segment != hasCustomSegment.value}"
                v-for="(hasCustomSegment, key) in hasCustomSegmentList"
                :key="'has-custom-segment-' + key"
            >
              <input
                  v-if="isBeforeDate"
                  class="form-radio-input"
                  type="radio"
                  v-model="video.has_custom_segment"
                  :value="hasCustomSegment.value"
              />
              <input
                  v-else
                  class="form-radio-input"
                  type="radio"
                  :checked="video.has_custom_segment == hasCustomSegment.value"
                  :value="hasCustomSegment.value"
                  disabled
              />
              <span class="btn-radio"></span>
              <label class="label-radio">{{ hasCustomSegment.text }}</label>
            </div>
          </div>
          <template v-if="video.has_custom_segment == CUSTOM_SEGMENT_ON_VALUE">
            <div class="preview-group-segment list-attribute flex flex-col my-2"
                 v-show="groupsSegment.length > 0 && groupsSegment[0].length > 0">
              <div class="attributes text-left px-5">
                <div
                    v-for="(groupAttributes, index) in groupsSegment"
                    :key="'group' + index"
                    class="list-item py-5"
                    :class="{
                      'list-item-selected': groupAttributes.length > 0,
                      'border-none': index + 1 == groupsSegment.length
                    }"
                  >
                    <div class="title-item pb-3">{{ `セグメント項目 ${index + 1}` }}</div>
                    <div class="groups-segment">
                      <div class="describe-wrapper" v-if="groupAttributes.length != 0">
                        <div class="describe-item-selected">
                          <div class="scroll-tab">
                            <div
                                class="att-selected mb-2"
                                v-for="(attribute, index) in groupAttributes"
                                :key="'att-selected-' + index"
                            >
                              <div class="name">{{ attribute.name }}</div>
                              <div class="value inline-flex">
                                <span
                                    v-for="(attributeValue, index) in attribute.list"
                                    :key="'index' + index + 'value-selected-' + attributeValue.id"
                                >
                                  {{ attributeValue.value }}
                                </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-link-setting-interaction-button" v-show="video.has_custom_segment === 1">
              <span v-if="isBeforeDate" style="cursor : pointer" @click="openFormAddSegment">セグメント選択画面へ ></span>
              <span v-else class="cursor-default">セグメント選択画面へ ></span>
            </div>
          </template>
          <div class="describe-panel">
            {{ segmentSwich.OFF.key }} : {{ segmentSwich.OFF.desc }}
          </div>
          <template>
            <div v-if="video.plan != listPlanVideoType.free.id" class="screening-options">
              <p>スクリーニングオプション</p>
              <button @click="openFormScreeningOption" class="d-flex"
                      :class="{'screening-options-done': settingOptionDone}">
                <i v-if="settingOptionDone" class="icon icon-check-small mr-1"></i>
                {{ settingOptionDone ? 'スクリーニングオプション設定済み' : 'スクリーニングオプション設定' }}
              </button>
            </div>
            <div v-else class="describe-segment">
              スクリーニングオプションはADVANCEプラン、BASICプランの訴求検証・演出検証でご利用いただけます
            </div>
            </template>
          </div>
        <div class="form-group" v-show="video.deliver_period_type == listStep.step2.id">
          <label>認知度調査</label>
          <div class="list-radio">
            <div class="form-radio-box mr-5" :class="{'radio-disabled': !isBeforeDate && video.cognition_survey != 1}">
              <input
                v-if="isBeforeDate"
                class="form-radio-input implement"
                type="radio"
                value="1"
                :checked="video.cognition_survey === 1"
                @click="video.cognition_survey = 1"
              />
              <input
                v-else
                class="form-radio-input implement"
                type="radio"
                value="1"
                :checked="video.cognition_survey === 1"
                disabled
              />
              <span class="btn-radio"></span>
              <label class="label-radio">実施する</label>
            </div>
            <div class="form-radio-box mr-5" :class="{'radio-disabled': !isBeforeDate && video.cognition_survey != 0}">
              <input
                v-if="isBeforeDate"
                class="form-radio-input not-implement"
                type="radio"
                value="0"
                :checked="video.cognition_survey === 0"
                @click="video.cognition_survey = 0"
              />
              <input
                v-else
                class="form-radio-input not-implement"
                type="radio"
                value="0"
                :checked="video.cognition_survey === 0"
                disabled
              />
              <span class="btn-radio"></span>
              <label class="label-radio">実施しない</label>
            </div>
          </div>
        </div>
        <div
          class="form-group"
          v-show="video.deliver_period_type == listStep.step2.id"
        >
          <label>コメントディスカバリーAI</label>
          <div class="list-radio">
            <div
              class="form-radio-box mr-5" :class="{'radio-disabled': rsCDAIStatus !== type.value}"
              v-for="(type, key) in commentDiscoveryStatus"
              :key="'comment-discovery-status-' + key"
            >
              <input
                class="form-radio-input"
                type="radio"
                :value="type.value"
                :checked="rsCDAIStatus === type.value"
                disabled
              />
              <span class="btn-radio"></span>
              <label class="label-radio">{{ type.text }}</label>
            </div>
          </div>
        </div>

        <!-- video capture start -->
        <div class="form-group w-full"
             v-show="
              video.deliver_period_type === listStep.step2.id &&
              video.deliver_flg != verifyMethods.ONE_TYPE.value &&
              (video.cdai_question.length || video.image_data)
             "
        >
          <div class="default-question-cdai" v-if="video.cdai_question.length">
            <label>{{ $t('texts.video.cdai_label') }}</label>
            <div
              class="question-item intention-item-checkbox"
              v-for="(question, index) in video.cdai_question"
              :key="'question-item-' + index"
            >
              <i class="icon icon-checkbox checked"></i>
              <span class="intention-item-name" v-html="question.question"></span>
            </div>
          </div>
          <div
            class="list-image-capture"
            v-show="video.image_data"
          >
            <span class="capture-label">{{ $t('texts.video.capture_label') }}</span>
            <div class="list-image-content">
              <div
                class="image-capture"
                v-for="(second, index) in video.scene"
                :key="'image-capture-' + index"
              >
                <div class="image-capture-item">
                  <div class="image-wrapper">
                    <img :src="convertAccessUrl(video.image_data) + second + '.jpg'"
                         :alt="'video-image' + second"
                    />
                  </div>
                </div>
                <div class="image-capture-item-checkbox">
                  <span class="second-text">{{ second }}s</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- video capture end -->

        <div class="form-group w-full"
           v-show="video.is_rs_cdai && video.deliver_period_type == listStep.step2.id"
        >
          <label>生成AIを利用して結果の示唆を算出</label>
          <div class="radio-list flex flex-row flex-wrap">
            <div class="form-radio-box mr-5">
              <input class="form-radio-input" type="radio" value="1" v-model="video.is_chatgpt"/>
              <span class="btn-radio"></span>
              <label class="label-radio mb-3 label-text">利用する</label>
            </div>
            <div class="form-radio-box mr-5">
              <input class="form-radio-input" type="radio" value="0" v-model="video.is_chatgpt"/>
              <span class="btn-radio"></span>
              <label class="label-radio mb-3 label-text">利用しない</label>
            </div>
          </div>
          <div class="wrap-chatgpt">
            <label style="cursor: pointer" @click="showChatGpt = true">算出イメージ ></label>
          </div>
        </div>

        <div class="form-group w-full intention-content"
             v-show="video.deliver_period_type === listStep.step2.id &&
             intentionList.length > 0 && video.deliver_flg != verifyMethods.ONE_TYPE.value">
          <label class="w-full">{{ $t('texts.video.setting_intention') }}</label>
          <div class="group-intention-list">
            <div
              class="intention-item"
              v-for="(intentionDataItem, index) in intentionData.select_data"
              :key="'intention-data-' + index"
            >
              <div class="intention-item-checkbox">
                <i class="icon icon-checkbox"
                   :class="{
                      checked: videoIntentionIds.includes(intentionDataItem.id_selected),
                      disabled: !isBeforeDate
                   }"
                   @click="checkedIntention(intentionDataItem.id_selected)"></i>
                <span class="intention-item-name">{{ $t('texts.video.intention_option', { number: index + 1 }) }}</span>
              </div>
              <div class="intention-item-select">
                <SelectCustom
                  class="select-intention"
                  :data="intentionDataItem.intention_items"
                  :isSelected="true"
                  :id-selected="intentionDataItem.id_selected"
                  :element="'intention-' + index"
                  :enable-search="false"
                  :index="index"
                  @select="selectIntention"
                  :disabled="!isBeforeDate"
                ></SelectCustom>
              </div>
            </div>
          </div>
          <div
            v-show="!(intentionData.select_data.length === intentionList.length)"
            class="btn-plus-intention"
            :class="{'disabled' : !isBeforeDate}"
            @click="addIntention"
          >
            {{"＋&nbsp;&nbsp;&nbsp;" + $t('texts.video.add_intention') }}
          </div>
        </div>
        <div class="form-group">
          <label>ステータス</label>
          <div class="list-radio">
            <div
              class="form-radio-box mr-5"
              v-for="(type, key) in statusTypes"
              :key="'status-type-' + key"
            >
              <input
                  name="status"
                  class="form-radio-input"
                  type="radio"
                  :value="type.value"
                  v-model="video.status"
              />
              <span class="btn-radio"></span>
              <label class="label-radio">{{ type.text }}</label>
            </div>
          </div>
        </div>
        <div class="form-group" v-show="video.deliver_period_type == listStep.step2.id">
          <label :class="{'color-red': currentUser.role == 'admin'}">入稿形式</label>
          <div class="list-radio">
            <div
              class="form-radio-box mr-5" :class="{'radio-disabled': video.rs_linkage !== type.value}"
              v-for="(type, key) in rsLinkageStatusInput"
              :key="'rs-linkage-status-' + key"
            >
              <input
                class="form-radio-input"
                type="radio"
                :value="type.value"
                :checked="video.rs_linkage === type.value"
                disabled
              />
              <span class="btn-radio"></span>
              <label class="label-radio" :class="{'color-red': currentUser.role == 'admin'}">{{ type.text }}</label>
            </div>
          </div>
        </div>
        <div class="form-group form-group-label w-full" v-show="video.deliver_period_type == listStep.step3.id">
          <label>トレンドワード <ToolTip :text="$t('texts.tooltip.keyword')"></ToolTip></label>
          <div class="flex group-keyword-list group-tag-list">
            <input
                v-for="(keyword, index) in video.twitter_keywords"
                :key="'keyword-' + index"
                class="form-control group-keyword-list-input"
                type="text"
                placeholder=""
                v-model.trim="video.twitter_keywords[index]"
                :disabled="!isBeforeDate"
            />
            <div
                v-if="isBeforeDate"
                class="tag btn-plus"
                @click="addKeyword"
            >
              <i class="icon icon-third-plus"></i>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="form-group">
            <button class="btn btn-primary btn-submit" @click="nextPage">
              次へ
            </button>
          </div>
        </div>
      </div>

      <form-custom-interaction
          :key="keyInteraction"
          :deliverFlg="video.deliver_flg"
          :deliverPeriodType="video.deliver_period_type"
          :isShown="showModalName == 'custom-interaction'"
          @iconsList="getIconsList"
      ></form-custom-interaction>

      <ModalAddLabel
        :clientId="buyerId"
        :isShown="showModalName == 'add-label'"
        @loadPage="getListLabel"
        @closeModal="showModalName = ''"
      ></ModalAddLabel>

      <FormSegmentSelected
          :categories="categories"
          :attributes="attributes"
          @openFormValue="openFormValue"
          :segmentArr="segmentSelected"
          :prevSegmentSelected="JSON.parse(JSON.stringify(segmentSelected))"
          :isShown="showModalName == 'segment-selected'"
          :playAdsLITESegment="video.plan === 1"
          @updateSegmentSelected="updateSegmentSelected"
          @settingAndOrSegment="settingAndOrSegment"
      ></FormSegmentSelected>

      <form-multiple
      :attribute="attribute"
      :attributeStatus="attributeStatus"
      @addSegment="addSegment"
      :prevSelected="segmentSelected"
      :isShown="showModalName == 'attribute-multiple'"
    ></form-multiple>

      <FormSingle
          :attribute="attribute"
          :attributeStatus="attributeStatus"
          :prevSelected="segmentSelected"
          @addSegment="addSegment"
          :isShown="showModalName == 'attribute-single'"
      ></FormSingle>

      <FormAndOr
          :segmentArr="segmentSelected"
          @saveSegmentInfo="getSegment"
          :isShown="showModalName == 'and-or'"
          :groups="groupsSegment"
          :prevGroupsSegment="JSON.parse(JSON.stringify(groupsSegment))"
      ></FormAndOr>

      <FormScreeningOption
          :clientId="buyerId"
          :questions="questions"
          :isShown="showModalName == 'screening-option'"
          :readOnly="!isBeforeDate"
          @addQuestion="addQuestion"
          @removeQuestion="removeQuestion"
          @settingOption="settingOption"
          @resetOption="resetOption"
          @closeModal="closeModalScreeningOption"
      ></FormScreeningOption>

      <ModalAddOption
          :isShown="showModalName == 'add-option'"
          :addOptionInfo="addOptionInfo"
          @addAnswerToQuestion="addAnswerToQuestion"
      ></ModalAddOption>

      <modal-confirm
          :message="alterDateMsg"
          :isEnter="false"
          @confirm="saveModalConfirm()"
          v-if="isShowModalConfirm"
          @closeModalConfirm="closeModalConfirm()"
      ></modal-confirm>

      <div class="modal chat-gpt" v-show="showChatGpt">
        <div class="modal-content">
          <div class="header">
            <div class="btn-close" @click="showChatGpt = false">
              <i class="icon icon-close-no-wrap"></i>
            </div>
          </div>
          <div class="body">
            <h2>生成AIが算出する示唆イメージ</h2>
            <h3>ハーブティーのCM動画を検証した例です。</h3>
            <img src="/images/svg/chat_gpt.svg">
          </div>
        </div>
      </div>
    </div>
    <div class="loader" v-show="!video.id && video.id !== 0"></div>
  </div>
</template>
<script>
import EventBus from "../../commons/event_bus";
import flatpickr from "flatpickr";
import moment from "moment";
import {Japanese} from "flatpickr/dist/l10n/ja.js";
import {maxLength, required} from "vuelidate/lib/validators";
import {accept, extension, gt, halfWidthExcludeXss, lt, videoNameXssCheck} from "@/commons/rules";
import {
  commentDiscoveryStatus,
  CUSTOM_SEGMENT_OFF_VALUE,
  CUSTOM_SEGMENT_ON_VALUE,
  hasCustomSegmentList,
  interactionTypes,
  interactionTypesStep1,
  interactionTypeVerifyOne,
  listMediaType,
  listPlanVideoType,
  panelTypes,
  planTypes,
  rsLinkageStatusInput,
  segmentSettingList,
  segmentSwich,
  SETTING_SEGMENT_CUSTOM_VALUE,
  SETTING_SEGMENT_SAME_AS_PREVIOUS_VALUE,
  statusTypes,
  stepListInfo,
  verifyMethods,
  videoURLTypes,
} from "@/commons/constants";
import {
  getAttributesActive,
  getListIntention,
  getVideoDetail,
  getVideoIntention,
  getVideoScreeningPromise,
  listLabels,
} from "@/commons/api_service";
import Select from "../../components/Video/Select.vue";
import FormCustomInteraction from "@/components/Project/FormCustomInteraction.vue"
import ModalAddLabel from "@/components/Modal/ModalAddLabel.vue"
import FormSegmentSelected from "@/components/Project/FormSegmentSelected.vue"
import FormMultiple from "@/components/Project/FormMultiple.vue";
import FormSingle from "@/components/Project/FormSingle.vue"
import FormAndOr from "@/components/Segment/FormAndOr.vue";
import {calendarEventsUrl, forceArrowDownSelect2} from "@/commons/helpers";
import router from "@/routes";
import FormScreeningOption from "@/components/Project/FormScreeningOption";
import ModalAddOption from "@/components/Modal/ModalAddOption";
import ModalConfirm from "@/components/Modal/ModalConfirm.vue";
import ToolTip from "@/components/Commons/ToolTip.vue";
import SelectCustom from "@/components/Video/SelectCustom.vue";

const jQuery = require("jquery");

export default {
  components: {
    SelectCustom,
    ToolTip,
    Select,
    FormCustomInteraction,
    ModalAddLabel,
    FormSegmentSelected,
    FormMultiple,
    FormSingle,
    FormAndOr,
    FormScreeningOption,
    ModalAddOption,
    ModalConfirm
  },
  validations() {
    let TYPE_YOUTUBE_URL = 2;
    let TYPE_VIDEO_FILE = 1;
    let validate = {
      video: {
        name: { required, maxLength: maxLength(30), videoNameXssCheck },
        start_at: { required, lt: lt(this.end_at) },
        end_at: { required, gt: gt(this.start_at) },
        country_id: { required },
      },
    };
    if (this.isYoutubeAnalytics) {
      (validate.video.youtube_video_id = {
        required,
        maxLength: maxLength(11),
        halfWidthExcludeXss,
      }),
        (validate.video.youtube_analytic_id = { required });
    }
    if (this.isYoutubeAnalytics) {
      if (this.video.youtube_analytic_id) {
        validate.video.youtube_video_id = {
          required,
          maxLength: maxLength(11),
          halfWidthExcludeXss,
        };
      }
      if (this.video.youtube_video_id) {
        validate.video.youtube_analytic_id = { required };
      }
    }
    if (this.video.video_url_type == TYPE_VIDEO_FILE) {
      validate.video_file = {
        extension: extension(["mp4"]),
        accept: accept(["video/mp4"]),
      };
      if (this.video_file_required) {
        validate.video_file = {...validate.video_file, ...{ required }}
      }
    }
    if (this.video.video_url_type == TYPE_YOUTUBE_URL) {
      validate.video.video_url = {
        required,
        maxLength: maxLength(11),
        halfWidthExcludeXss,
      };
    }

    if (this.video.deliver_period_type == stepListInfo.step2.id) {
      validate.video.start_time_at = { required };
    } else {
      validate.video.start_time_at = {};
    }

    return validate;
  },
  data() {
    return {
      selectedLabels: [],
      verifyMethods: verifyMethods,
      interactionTypes: {...interactionTypes},
      interactionTypesStep1: {...interactionTypesStep1},
      interactionTypeVerifyOne : {...interactionTypeVerifyOne},
      planTypes,
      segmentSettingList,
      SETTING_SEGMENT_SAME_AS_PREVIOUS_VALUE,
      SETTING_SEGMENT_CUSTOM_VALUE,
      segmentSettingModel: SETTING_SEGMENT_SAME_AS_PREVIOUS_VALUE,
      previousVideoId: null,
      hasCustomSegmentList,
      CUSTOM_SEGMENT_ON_VALUE,
      showModalName: '',
      keyInteraction : 1,
      categories: [],
      attributes: [],
      segmentSelected: [],
      groupsSegment: [[]],
      attribute: {},
      attributeStatus: {},
      labels: [],
      countries: [],
      clients: [],
      youtubeChannels: [],
      videoURLTypes: videoURLTypes,
      statusTypes: statusTypes,
      commentDiscoveryStatus,
      rsLinkageStatusInput,
      video: {
        id: null,
        name: "",
        start_at: null,
        end_at: null,
        start_time_at: "00:00",
        video_url_type: 1,
        video_url: "",
        country_id: null,
        status: null,
        youtube_analytic_id: null,
        youtube_video_id: "",
        cognition_survey: 0,
        video_file_text: "",
        panel_type: panelTypes.JCP.value,
        plan: null,
        label_ids: '',
        deliver_flg: 1,
        interaction_btn_1_index : null,
        interaction_btn_2_index : null,
        interaction_btn_3_index : null,
        has_custom_segment: CUSTOM_SEGMENT_OFF_VALUE,
        twitter_keywords: ['','','','',''],
        cdai_question: [],
        image_data: null,
        scene: [],
      },
      buyerId: null,
      buyerSelected: {},
      video_file: null,
      isYoutubeAnalytics: false,
      fileName: "動画ファイルをドラッグ",
      youtubeURL: "https://www.youtube.com/watch?v=",
      youtubeVideoChannelURL: "https://www.youtube.com/watch?v=",
      isVideoFile: false,
      submitted: false,
      isChannel: false,
      currentUser: {},
      holidays: null,
      panelTypes: panelTypes,
      segmentSwich: segmentSwich,
      layer: [],
      icons: [],
      prevQuestion: [{
        id: 1, name: 'Q1', value: '', isChecked: {}, options: []
      }],
      questions: [{
        id: 1, name: 'Q1', value: '', isChecked: {}, options: []
      }],
      settingOptionDone: false,
      addOptionInfo: {},
      startDate: null,
      endDate: null,
      prevEndDate: null,
      prevStartDate: null,
      video_file_required: true,
      alterDateMsg: '動画を再生することができるのは検証期間中のみです。<br />' +
          '期日を変更することにより動画が表示されない恐れがありますが問題ないですか？',
      isShowModalConfirm: false,
      listMediaType: JSON.parse(JSON.stringify(listMediaType)),
      listStep : JSON.parse(JSON.stringify(stepListInfo)),
      listPlanVideoType: JSON.parse(JSON.stringify(listPlanVideoType)),
      // for intention
      intentionList: [],
      intentionData: {
        intention_items_selected: [],
        intention_items_checked: [],
        select_data: [],
      },
      showChatGpt: false,
      flatpickrInstance: null,
      flatpickrInstanceTime: null,
    };
  },
  computed: {
    intentionListToSelect: function () {
      let intentionList = [{id: 0, name: this.$t('texts.video.setting_intention_placeholder'), index: -1}]
      const selectedIds = new Set(this.intentionData.intention_items_selected.map(selected => selected.id));
      return intentionList.concat(this.intentionList.filter(item => !selectedIds.has(item.id)));
    },
    videoIntentionIds: function () {
      return this.intentionData.intention_items_checked.map(item => item.id);
    },
    rsCDAIStatus: function () {
      if (this.video.is_rs_cdai === commentDiscoveryStatus.OFF.value) {
        return commentDiscoveryStatus.OFF.value;
      } else {
        return commentDiscoveryStatus.ON.value;
      }
    },
    isBeforeDate: function () {
      let dateNow = moment().format("YYYY-MM-DD HH:mm")
      return this.video && moment(dateNow).isBefore(this.startDate);
    },
    isValidDate: function () {
      let dateNow = moment().format("YYYY-MM-DD HH:mm")
      return moment(dateNow).isBetween(this.startDate, this.endDate, 'minute', '[]');
    },
    isExpireDate: function () {
      let dateNow = moment().format("YYYY-MM-DD HH:mm")
      return this.video && moment(dateNow).isAfter(this.endDate, 'minute');
    },
  },
  watch: {
    clients(val) {
      this.buyerSelected = val.filter(data => data.id === this.buyerId)[0]
    },
  },
  methods: {
    showCustomInteractionModal() {
      this.toggleModal('custom-interaction')
    },

    toggleModal(modalName) {
      this.showModalName = modalName;
    },

    openFormAddSegment() {
      EventBus.$emit("toggleModal", "segment-selected");
    },

    openFormScreeningOption() {
      EventBus.$emit("toggleModal", "screening-option");
    },

    openFormValue(data) {
      let TYPE_MULTIPLE = 2;
      let TYPE_SINGLE = 1;
      let type = data.type;
      let attribute = data.data;
      this.attributeStatus = this.segmentSelected.find(
          (e) => e.id == attribute.id
      );
      if (type == TYPE_MULTIPLE) {
        this.attribute = attribute;
        this.toggleModal("attribute-multiple");
      } else if (type == TYPE_SINGLE) {
        this.attribute = attribute;
        this.toggleModal("attribute-single");
      }
    },

    updateSegmentSelected(data) {
      this.segmentSelected = data
      this.clearDataUnSelected()
    },

    clearDataUnSelected() {
      let newGroups = [];
      for (let i = 0; i < this.groupsSegment.length; i++) {
        let group = this.groupsSegment[i];
        let newGroup = [];
        group.forEach(attribute => {
          let findAttr = this.segmentSelected.find(e => e.id == attribute.id);
          if (findAttr) {
            let values = attribute.list.filter(e => findAttr.list.find(a => a.id == e.id));
            if (values.length) {
              newGroup.push({
                ...attribute,
                list: values
              })
            }
          }
        });
        if (newGroup.length) {
          newGroups.push(newGroup);
        }
      }
      newGroups = newGroups.length ? newGroups : [[]];
      this.$set(this, 'groupsSegment', newGroups)
    },

    getListAttribute() {
      getAttributesActive((data) => {
        this.attributes = data;
        this.categories.forEach((category) => {
          category.attributes = data.filter(
            (attribute) => attribute.category == category.id
          );
        });
        this.$nextTick(() => {
          if (this.video.has_custom_segment && !JSON.parse(localStorage.getItem("groupsSegment"))) {
            this.formatToGroupsSegment()
          }
        })
      });
    },
    getListCategory() {
      let url = this.$router.resolve({
        name: "api.category",
      }).href;
      this.$http2.get(url, {}, (data) => {
        this.categories = data.items;
        this.getListAttribute();
      });
    },

    addSegment(data) {
      let segmentId = data.segment_id;
      let segmentName = data.segment_name;
      let segmentSelectedList = data.segment_list;
      let obj = {
        id: segmentId,
        name: segmentName,
        category: data.category,
        list: segmentSelectedList,
      };
      let index = this.segmentSelected.findIndex((item) => item.id == obj.id);
      if (index != -1) {
        this.$set(this.segmentSelected, index, obj);
      } else {
        this.segmentSelected.push(obj);
      }
      this.toggleModal("segment-selected");
    },

    getSegment(data) {
      this.groupsSegment = data
      this.toggleModal("")
    },

    selectVideoUrlType() {
      let TYPE_YOUTUBE_URL = 2;
      let TYPE_VIDEO_FILE = 1;
      let vm = this;
      jQuery("#youtube-url").on("click", function () {
        vm.video.video_url_type = TYPE_YOUTUBE_URL;
      });
      jQuery("#video-file").on("click", function () {
        vm.video.video_url_type = TYPE_VIDEO_FILE;
      });
    },

    selectStatus() {
      let vm = this;
      let STATUS_ACTIVE = 1;
      let STATUS_INACTIVE = 0;
      jQuery(".status-active").on("click", function () {
        vm.video.status = STATUS_ACTIVE;
      });
      jQuery(".status-inactive").on("click", function () {
        vm.video.status = STATUS_INACTIVE;
      });
    },
    checkYoutubeAnalytics() {
      let vm = this;
      jQuery("#youtube-analytics").on("click", function () {
        let checked = jQuery(this).is(":checked");
        if (checked) {
          vm.isYoutubeAnalytics = true;
          if (vm.youtubeChannels.length > 0) {
            vm.video.youtube_analytic_id = vm.youtubeChannels[0].id;
          } else {
            vm.video.youtube_analytic_id = -1
          }
        } else {
          vm.isYoutubeAnalytics = false;
          vm.video.youtube_analytic_id = null;
          vm.youtubeVideoChannelURL= "https://www.youtube.com/watch?v=";
        }
      });
    },
    changeInputYoutubeChannelVideoId() {
      let vm = this;
      jQuery(".input-channel-video").change(function () {
        vm.video.youtube_video_id = jQuery(this).val().slice(32);
      });
    },
    checkExistSelectedLabel(id) {
      return this.selectedLabels.includes(id)
    },
    selectLabels(id) {
      if (!this.checkExistSelectedLabel(id)) {
        this.selectedLabels.push(id)
      } else {
        this.selectedLabels.splice(this.selectedLabels.indexOf(id), 1)
      }
    },
    addLabel() {
      this.toggleModal('add-label')
    },
    selectCountries(id) {
      if (this.isBeforeDate) {
        this.video.country_id = parseInt(id)
      }
    },
    momentFormatDate(val) {
      return val ? moment(val).format("YYYY/MM/DD") : ''
    },
    getDates(startDate, stopDate) {
      let dateArray = [];
      let currentDate = moment(startDate).add(1, 'days');
      while (currentDate < stopDate) {
        dateArray.push(moment(currentDate).format('YYYY-MM-DD '))
        currentDate = moment(currentDate).add(1, 'days');
      }
      return dateArray;
    },
    selectChannel(id) {
      this.video.youtube_analytic_id = parseInt(id);
    },
    initFlatPickr() {
      if (this.isBeforeDate || this.isValidDate) {
        this.initFlatpickrDate()
        if (this.video.deliver_period_type === this.listStep.step2.id) {
          this.initFlatpickrTime()
        }
      }
    },
    initFlatpickrDate() {
      let vm = this;
      let currentUser = vm.$store.getters.currentUser;
      let now = moment().startOf('day');
      let startDate = new Date(vm.startDate);
      let datesDisabled = vm.getDates(vm.startDate, now)
      if (currentUser && currentUser.role != "admin" && this.isValidDate) {
        datesDisabled = vm.getDates(vm.startDate, moment(vm.endDate).startOf('day'))
      }
      let currentDate = now.format("YYYY-MM-DD");
      let htmlNextButton =
          "<div class='btn button-next flatpickr-next-month'><div class='icon-custom icon-next'></div></div>";
      let htmlPrevButton =
          "<div class='btn button-prev flatpickr-prev-month'><div class='icon-custom icon-prev'></div></div>";
      Japanese.rangeSeparator = " ~ ";
      flatpickr(".datetimepicker", {
        static: true,
        locale: Japanese,
        enableTime: false,
        showMonths: 2,
        mode: "range",
        // minDate: currentDate,
        // allowInput: vm.video.deliver_period_type === vm.listStep.step2.id,
        // altInput: vm.video.deliver_period_type === vm.listStep.step2.id,
        // altFormat: "Y/m/d",
        dateFormat: "Y/m/d",
        defaultDate: [vm.video.start_at, vm.video.end_at],
        time_24hr: true,
        nextArrow: htmlNextButton,
        prevArrow: htmlPrevButton,
        disable: datesDisabled,
        onDayCreate: (dObj, dStr, fp, dayElem) => {
          let dateObj = dayElem.dateObj;
          if (dateObj.toDateString().substr(0, 3) == "Sat") {
            jQuery(dayElem).addClass("is-saturday");
          }
          if (dateObj.toDateString().substr(0, 3) == "Sun") {
            jQuery(dayElem).addClass("holidays");
          }
          if (vm.holidays) {
            if (
                vm.holidays.indexOf(moment(dateObj).format("YYYY-MM-DD")) !== -1
            ) {
              if (jQuery(dayElem).hasClass("is-saturday")) {
                jQuery(dayElem).removeClass("is-saturday");
              }
              jQuery(dayElem).addClass("holidays");
            }
          }
        },
        onChange(selectedDates, dStr, instance) {
          let _this = this;
          let dateArr = selectedDates.map(function (date) {
            return _this.formatDate(date, "Y/m/d");
          });

          let start = dateArr[0] + " " + vm.video.start_time_at;
          let end = dateArr[1];
          let startDate = vm.startDate;
          let endDate = vm.endDate;
          let currentDate = moment().format("YYYY-MM-DD HH:mm");

          if (moment(currentDate).isBetween(startDate, endDate, 'minute', '[]')) {
            let date;
            date = start;
            if (end) {
              date = end;
            }
            if (
                currentUser && currentUser.role == "admin" &&
                moment(date).isBefore(moment(endDate), 'date') &&
                !vm.isInputSameValueAsVideo(dStr)
            ) {
              vm.isShowModalConfirm = true
              vm.prevEndDate = moment(date).format("YYYY-MM-DD")
              instance.close();
            } else {
              if (moment(endDate).isSameOrBefore(moment(date), 'date')) {
                vm.video.end_at = moment(date).format("YYYY-MM-DD");
              }
              vm.initFlatPickr();
              instance.close();
            }
          } else if (vm.video.deliver_period_type === vm.listStep.step2.id) {
            if (end === undefined || start === undefined) {
              return;
            }

            let startDateInput = moment(start, "YYYY/MM/DD");
            let endDateInput = moment(end, "YYYY/MM/DD");

            if (
                startDateInput.isSame(moment(vm.video.start_at), 'date') &&
                endDateInput.isSame(moment(vm.video.end_at), 'date')
            ) {
              return;
            }

            let minEndDate = startDateInput.clone().add(7, "days");
            if (endDateInput.isBefore(minEndDate, "day")) {
              if (currentUser && currentUser.role == "admin") {
                vm.isShowModalConfirm = true
                vm.prevStartDate = moment(start).format("YYYY-MM-DD")
                vm.prevEndDate = moment(end).format("YYYY-MM-DD")
                instance.close();
              } else {
                end = minEndDate.format("YYYY/MM/DD");
                vm.video.start_at = moment(start).format("YYYY-MM-DD");
                vm.video.end_at = moment(end).format("YYYY-MM-DD");
                vm.$toasted.error(vm.$t('texts.video.min_date_range_step_2'))
                instance.setDate([start, end]);
              }
            } else {
              vm.video.start_at = moment(start).format("YYYY-MM-DD");
              vm.video.end_at = moment(end).format("YYYY-MM-DD");
            }
          } else {
            if (end === undefined || start === undefined) {
              vm.video.end_at = null;
              vm.video.start_at = null;
            } else {
              vm.video.start_at = moment(start).format("YYYY-MM-DD");
              vm.video.end_at = moment(end).format("YYYY-MM-DD");
              jQuery(".flatpickr-input").val(moment(vm.video.start_at).format("YYYY/MM/DD") + " ~ " + end);
            }
          }
        },
        onReady() {
          let startAt = vm.video.start_at;
          let endAt = vm.video.end_at;
          vm.prevEndDate = endAt
          let startFormat = moment(startAt).format("YYYY/MM/DD");
          let endFormat = moment(endAt).format("YYYY/MM/DD");
          if (startAt != null || endAt != null) {
            jQuery(".flatpickr-input").val(startFormat + " ~ " + endFormat);
          }
        },
        onOpen(selectedDates, dateStr, instance) {
          if (moment().format("YYYY-MM-DD") >= vm.startDate) {
            this.set("minDate",  startDate)
          } else {
            this.set("minDate",  currentDate)
          }
          vm.getHolidays(instance);
          let startAt = vm.video.start_at;
          let endAt = vm.video.end_at;
          let startFormat = moment(startAt).format("YYYY/MM/DD");
          let endFormat = moment(endAt).format("YYYY/MM/DD");
          if (startAt != null || endAt != null) {
            jQuery(".flatpickr-input").val(startFormat + " ~ " + endFormat);
          }
        },
        onYearChange: (selectedDates, dateStr, instance) => {
          vm.getHolidays(instance);
        },
      });
    },
    initFlatpickrTime() {
      let vm = this;
      if (!this.isBeforeDate) return
      this.flatpickrInstanceTime = flatpickr("#timepicker", {
        static: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        allowInput: true,
        altInput: true,
        altFormat: "H:i",
        defaultDate: vm.video.start_time_at,
        time_24hr: true,
        clickOpens: false,
        altInputClass: "input-time form-control",
        onChange(selectedDates, dateStr, instance) {
          if (dateStr != "") {
            vm.video.start_time_at = dateStr;
          } else {
            vm.video.start_time_at = "00:00";
            instance.setDate("00:00");
          }
        },
      });
    },
    isInputSameValueAsVideo (dateString) {
      let dateArr = dateString.split(' ~ ')
      let startDate = moment(dateArr[0], 'YYYY/MM/DD')
      let endDate = moment(dateArr[1], 'YYYY/MM/DD')
      let videoStartDate = moment(this.video.start_at, 'YYYY/MM/DD')
      let videoEndDate = moment(this.video.end_at, 'YYYY/MM/DD')
      return startDate.isSame(videoStartDate, 'date') && endDate.isSame(videoEndDate, 'date')
    },
    uploadFile(event) {
      let vm = this;
      let files = event.target.files || event.dataTransfer.files;
      if (!files.length) {
        return;
      }
      vm.isVideoFile = true;
      let file = files[0];
      vm.video_file = file;
      vm.fileName = file.name;
      vm.video.video_file_text = file.name;
      vm.video.video_url = URL.createObjectURL(file);
    },

    nextPage() {
      if (this.video.video_url_type === 2) {
        this.video.video_url = this.youtubeURL.slice(32);
      }
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      router.push({
        name: "video-check",
        params: { video_file: this.video.video_url_type === 1 ? this.video_file : undefined },
      });
      if (this.selectedLabels.length) this.video.label_ids = this.selectedLabels.toString()
      else this.video.label_ids = ""

      let interactionBtnParams = {};
      let interactionButtonValue = {};
      let interactionButtonType = this.video.deliver_flg != 3 ? this.interactionTypes : this.interactionTypeVerifyOne;

      if (this.video.deliver_period_type === this.listStep.step1.id) {
        interactionButtonType = this.interactionTypesStep1
      }

      if (this.video.deliver_period_type != this.listStep.step3.id) {
        Object.keys(interactionButtonType).forEach(item => {
          if (this.video.deliver_period_type === this.listStep.step2.id) {
            if (this.video.deliver_flg != 3 && interactionButtonType[item].index == this.video.interaction_btn_1_index) {
              interactionButtonValue = interactionButtonType[item].value;
            } else if (this.video.deliver_flg == 3 && interactionButtonType[item].index == this.video.interaction_btn_2_index) {
              interactionButtonValue = interactionButtonType[item].value;
            }
          } else {
            if (interactionButtonType[item].index == this.video.interaction_btn_3_index) {
              interactionButtonValue = interactionButtonType[item].value;
            }
          }
        })
        if (this.video.deliver_flg != 3) {
          interactionBtnParams = {
            interaction_btn_1_text: interactionButtonValue.interaction_btn_1_text,
            interaction_btn_1_icon_id: interactionButtonValue.interaction_btn_1_icon_id,
            interaction_btn_1_explain: interactionButtonValue.interaction_btn_1_explain,
            interaction_btn_1: this.getIconAndColor(interactionButtonValue.interaction_btn_1_icon_id),
            interaction_btn_2_text: interactionButtonValue.interaction_btn_2_text,
            interaction_btn_2_icon_id: interactionButtonValue.interaction_btn_2_icon_id,
            interaction_btn_2_explain: interactionButtonValue.interaction_btn_2_explain,
            interaction_btn_2: this.getIconAndColor(interactionButtonValue.interaction_btn_2_icon_id),
            ...(this.video.deliver_period_type == this.listStep.step1.id ? {
              interaction_btn_3_text: interactionButtonValue.interaction_btn_3_text,
              interaction_btn_3_icon_id: interactionButtonValue.interaction_btn_3_icon_id,
              interaction_btn_3_explain: interactionButtonValue.interaction_btn_3_explain,
              interaction_btn_3: this.getIconAndColor(interactionButtonValue.interaction_btn_3_icon_id),
            } : {}),
          }
        } else {
          interactionBtnParams = {
            interaction_btn_1_text: interactionButtonValue.interaction_btn_1_text,
            interaction_btn_1_icon_id: interactionButtonValue.interaction_btn_1_icon_id,
            interaction_btn_1_explain: interactionButtonValue.interaction_btn_1_explain,
            interaction_btn_1: this.getIconAndColor(interactionButtonValue.interaction_btn_1_icon_id),
            interaction_btn_2_text: '',
            interaction_btn_2_icon_id: '',
            interaction_btn_2_explain: '',
            interaction_btn_2: {}
          }
        }
      }

      if (this.settingOptionDone && this.video.plan != this.listPlanVideoType.free.id) {
        this.video.screening = this.formatScreeningParams()
        localStorage.setItem("screening_option", JSON.stringify(this.questions))
      } else {
        delete this.video['screening']
        localStorage.removeItem("screening_option")
      }
      // remove empty keywords
      this.video.twitter_keywords = this.video.twitter_keywords.filter(item => item !== '')

      localStorage.setItem("video_register", JSON.stringify({...this.video, ...interactionBtnParams}));
      localStorage.setItem("buyer_id", this.buyerId);
      localStorage.setItem("layers", JSON.stringify(this.formatToAttributeLayer()));
      localStorage.setItem("interactionTypesCustom", JSON.stringify(this.interactionTypes));
      localStorage.setItem("interactionTypesCustomStep1", JSON.stringify(this.interactionTypesStep1));
      localStorage.setItem("interactionTypeVerifyOneCustom", JSON.stringify(this.interactionTypeVerifyOne));
      if (this.video.has_custom_segment) {
        localStorage.setItem("groupsSegment", JSON.stringify(this.groupsSegment));
        localStorage.setItem('segmentSelected', JSON.stringify(this.segmentSelected))
      } else {
        this.groupsSegment = [[]]
        this.segmentSelected = []
        localStorage.removeItem("groupsSegment")
        localStorage.removeItem("segmentSelected")
      }

      localStorage.setItem("segmentSettingModel", JSON.stringify(this.segmentSettingModel));
      localStorage.setItem("previousVideoId", this.previousVideoId);
      localStorage.setItem("intentionData", JSON.stringify(this.intentionData));
      localStorage.setItem("intentionList", JSON.stringify(this.intentionList));
    },
    getIconAndColor(id) {
      let i = 1, iconAndColor = {}
      while (i <= 5) {
        iconAndColor = this.icons[i].filter(item => item.id == id)
        if (iconAndColor.length) break
        i++
      }
      return {
        'btn_icon_url': iconAndColor[0].image_url,
        'color': i
      }
    },
    getIconsList(data) {
      this.icons = data
    },
    formatToAttributeLayer() {
      let id = 1;
      let parent = {
        id: 1,
        operator: "OR",
        sort: 1,
        attribute_value_ids: [],
      };
      let layers = [parent];
      this.groupsSegment.forEach((groupAttributes, index) => {
        let attributeIdsCategory = {};
        let idGroup = ++id;
        layers.push({
          id: idGroup,
          operator: "AND",
          parent_id: parent.id,
          sort: index+1,
          attribute_value_ids: [],
        });
        let sort = 1
        groupAttributes.forEach((attribute) => {
          let listAttrValueIds = attribute.list.map((e) => e.id);
          if (attributeIdsCategory[attribute.category]) {
            attributeIdsCategory[attribute.category].attribute_value_ids = [...attributeIdsCategory[attribute.category].attribute_value_ids, ...listAttrValueIds]
          } else {
            attributeIdsCategory[attribute.category] = {
              parent_id: idGroup,
              id: ++id,
              sort: sort++,
              operator: "OR",
              attribute_value_ids: listAttrValueIds
            };
          }
        });
        layers = [...layers, ...Object.values(attributeIdsCategory)]
      });
      return layers;
    },
    formatScreeningParams() {
      let screening = []
      this.questions.map((q,k) => {
        let answers = [], depend_on_questions = '', depend_on_answers = ''
        q.options.map((a, i) => {
          answers.push({
            "code": i + 1,
            "text": a.value
          })
        })
        let question = {
          "question_code": k + 1,
          "text": q.value,
          "answers": answers
        }
        if (k > 0) {
          Object.values(q.isChecked).map((val, ix) => {
            if (val) depend_on_questions += ix + 1
          })
          question = {...question, ...{"depend_on_questions": depend_on_questions}}
          if (depend_on_questions) {
            this.questions[k - 1].options.map(prevA => {
              if (prevA.isChecked) depend_on_answers += '1'
              else depend_on_answers += '0'
            })
            if (q.q1q2Options && depend_on_questions == '12') {
              depend_on_answers = ''
              this.questions[1].options.map((q2, ix2) => {
                this.questions[0].options.map(q1 => {
                  if (q1.isChecked) {
                    if (q.q1q2Options[q1.value + 'x' + q2.value]) depend_on_answers += '1'
                    else depend_on_answers += '0'
                  }
                })
                if (ix2 < this.questions[1].options.length - 1 && depend_on_answers) depend_on_answers += ','
              })
            } else if (q.q1Options && depend_on_questions == '1') {
              depend_on_answers = ''
              this.questions[0].options.map(q1 => {
                if (q.q1Options[q1.value]) depend_on_answers += '1'
                else depend_on_answers += '0'
              })
            }
            question = {...question, ...{"depend_on_answers": depend_on_answers}}
          } else {
            question = {...question, ...{"depend_on_answers": ""}}
          }
        } else {
          question = {...question, ...{"depend_on_questions": ""}}
          question = {...question, ...{"depend_on_answers": ""}}
        }
        screening.push(question)
      })
      return screening
    },
    formatToGroupsSegment() {
      let categories = []
      let attributes = {}
      let attributes_values = {}

      this.layer.map(data => {
        if (!data.parent_id && data.operator == 'OR' && !categories.includes(data.id)) {
          categories[data.sort] = data.id
        } else if (data.parent_id && data.operator == 'AND') {
          if (!attributes[data.parent_id]) {
            attributes[data.parent_id] = {}
            attributes[data.parent_id][data.sort] = data.id
          } else {
            attributes[data.parent_id][data.sort] = data.id
          }
        } else if (data.parent_id && data.operator == 'OR') {
          if (!attributes_values[data.parent_id]) {
            attributes_values[data.parent_id] = {}
            attributes_values[data.parent_id][data.sort] = data.attribute_values
          } else {
            attributes_values[data.parent_id][data.sort] = data.attribute_values
          }
        }
      })

      let arrGroupsSegment = []
      categories.map(category => {
        if (attributes[category]) {
          Object.values(attributes[category]).map(attr => {
            let attrIdArr = this.formatAttributesValues(attributes_values[attr] ?? [])
            let arrGroupsSegmentTemp = []
            this.attributes.map(ori_attr => {
              let attrList = []
              let index = this.segmentSelected.findIndex((item) => item.id == ori_attr.id)
              ori_attr.values.map(val => {
                if (attrIdArr.includes(val.id)) {
                  attrList.push(val)

                  if (index != -1) {
                    let indexAttr = this.segmentSelected[index].list.findIndex((attrItem) => attrItem.id == val.id)
                    if (indexAttr == -1) {
                      this.segmentSelected[index].list.push(val)
                    }
                  }
                }
              })
              if (attrList.length) {
                let obj = {
                  category: ori_attr.category,
                  id: ori_attr.id,
                  list: attrList,
                  name: ori_attr.name
                }
                arrGroupsSegmentTemp.push(obj)

                if (index == -1) {
                  this.segmentSelected.push(JSON.parse(JSON.stringify(obj)))
                }
              }
            })
            if (arrGroupsSegmentTemp.length) arrGroupsSegment.push(arrGroupsSegmentTemp)
          })
        }
      })
      this.groupsSegment = arrGroupsSegment
    },
    formatAttributesValues(attrs_values) {
      let attrIdArr = []
      Object.values(attrs_values).map(attrValues => {
        attrIdArr = [...attrIdArr, ...attrValues]
      })
      return attrIdArr
    },
    getCustomSegment() {
      let params = {
        video_id: this.$route.params.video_id
      }
      let url = this.$router.resolve({
        name: "api.attributes-layer",
      }).href;
      this.$http2.get(
          url,
          params,
          (data) => {
            this.layer = data
            this.formatToGroupsSegment()
          }
      );
    },
    initData() {
      this.getListLabel();
      this.getListChannelYoutube();
    },
    configNotDeleteInputUrlYoutube() {
      let defaultText = "https://www.youtube.com/watch?v=";
      var test = document.querySelector(".input-youtube-url");
      var val = defaultText;
      this.listen("keydown", test, replaceVal);
      this.listen("keyup", test, replaceVal);
      this.listen("click", test, replaceVal);
      jQuery(window).on("click", replaceVal);

      function replaceVal() {
        var tempVal = test.value;
        if (tempVal.indexOf(val) === -1) {
          tempVal = val;
          test.value = val;
        }
      }
    },
    configNotDeleteInputValue() {
      let defaultText = "https://www.youtube.com/watch?v=";
      var test = document.querySelector(".input-channel-video");
      var val = defaultText;
      this.listen("keydown", test, replaceVal);
      this.listen("keyup", test, replaceVal);
      this.listen("click", test, replaceVal);
      jQuery(window).on("click", replaceVal);
      function replaceVal() {
        var tempVal = test.value;
        if (tempVal.indexOf(val) === -1) {
          tempVal = val;
          test.value = val;
        }
      }
    },
    listen(event, elem, func) {
      if (elem.addEventListener) {
        elem.addEventListener(event, func, false);
      } else if (elem.attachEvent) {
        elem.attachEvent("on" + event, func);
      }
    },
    loadListen() {
      this.listen("load", window, this.configNotDeleteInputUrlYoutube());
      this.listen("load", window, this.configNotDeleteInputValue());
    },
    // get all JP holidays
    getHolidays(instance) {
      let vm = this;
      let url = calendarEventsUrl(instance.currentYear);
      this.$http2.getExternalAPI(
        url,
        (response) => {
          let items = response.items;
          vm.holidays = items.map((item) => {
            return item.start.date;
          });
        },
        (err) => {
          console.log("Fetch Error :", err);
        }
      );
    },
    handleDropFile() {
      let vm = this;
      jQuery("#dropbox-file-upload").on("dragover", function (e) {
        e.preventDefault();
        e.stopPropagation();
      });
      jQuery("#dropbox-file-upload").on("dragenter", function (e) {
        e.preventDefault();
        e.stopPropagation();
      });
      jQuery("#dropbox-file-upload").on("drop", function (e) {
        if (e.originalEvent.dataTransfer) {
          if (e.originalEvent.dataTransfer.files.length) {
            e.preventDefault();
            e.stopPropagation();
            let file = e.originalEvent.dataTransfer.files[0];
            vm.isVideoFile = true;
            vm.video_file = file;
            vm.fileName = file.name;
            vm.video.video_file_text = file.name;
            vm.video.video_url = URL.createObjectURL(file);
          }
        }
      });
    },
    // get data api
    getListBuyer() {
      let url = this.$router.resolve({
        name: "api.buyers",
      }).href;
      let params = {
        approve_status: 1,
        status: 1,
        sort: "id",
        order: "asc",
      };
      this.$http2.get(url, params, (data) => {
        this.clients = data.items;
        this.initData();
      });
    },
    getListCountry() {
      let url = this.$router.resolve({
        name: "api.countries",
      }).href;

      this.$http2.get(url, {}, (data) => {
        this.countries = data.items;
      });
    },
    getListChannelYoutube() {
      let url = this.$router.resolve({
        name: "api.youtube.analytics",
      }).href;
      let params = {
        buyer_id: this.buyerId,
        sort: "id",
        order: "asc",
        limit: 1000,
      };
      this.$http2.get(url, params, (data) => {
        this.youtubeChannels = data.items;
      });
    },

    getListLabel() {
      let video = JSON.parse(localStorage.getItem("video_register"))
      listLabels((labels) => {
        this.selectedLabels = []
        this.labels = labels
        if (video && video.label_ids) {
          let label_ids = video.label_ids.split(',').map(Number)
          this.labels.map(item => {
            if (label_ids.includes(item.id)) this.selectedLabels.push(item.id)
          })
        } else {
          if (this.video.labels.length) {
            let label_ids = this.video.labels.map(label => label.id)
            this.labels.map(item => {
              if (label_ids.includes(item.id)) this.selectedLabels.push(item.id)
            })
          }
        }
      }, this.buyerId)
    },
    customInteractionButton(data) {
      EventBus.$emit("resetFormCustom");
      if (this.video.deliver_period_type === this.listStep.step2.id) {
        if (this.video.deliver_flg != 3) {
          this.interactionTypes['CUSTOM'] = {
            'index': 4,
            'text' : data.interaction_btn_1_text+"/"+data.interaction_btn_2_text,
            'value' : {
              'interaction_btn_1_explain' : data.interaction_btn_1_explain,
              'interaction_btn_1_icon_id' : data.interaction_btn_1_icon_id,
              'interaction_btn_1_text' : data.interaction_btn_1_text,
              'interaction_btn_2_explain' : data.interaction_btn_2_explain,
              'interaction_btn_2_icon_id' : data.interaction_btn_2_icon_id,
              'interaction_btn_2_text' : data.interaction_btn_2_text,
            }
          }
          this.video.interaction_btn_1_index = 4
        } else {
          this.interactionTypeVerifyOne['CUSTOM'] = {
            'index' : 4,
            'text' : data.interaction_btn_1_text,
            'value' : {
              'interaction_btn_1_explain' : data.interaction_btn_1_explain,
              'interaction_btn_1_icon_id' : data.interaction_btn_1_icon_id,
              'interaction_btn_1_text' : data.interaction_btn_1_text,
            }
          }
          this.video.interaction_btn_2_index = 4
        }
      } else {
        this.interactionTypesStep1['CUSTOM'] = {
          'index': 2,
          'text' : data.interaction_btn_1_text + "/" + data.interaction_btn_2_text + "/" + data.interaction_btn_3_text,
          'value' : {
            'interaction_btn_1_explain' : data.interaction_btn_1_explain,
            'interaction_btn_1_icon_id' : data.interaction_btn_1_icon_id,
            'interaction_btn_1_text' : data.interaction_btn_1_text,
            'interaction_btn_2_explain' : data.interaction_btn_2_explain,
            'interaction_btn_2_icon_id' : data.interaction_btn_2_icon_id,
            'interaction_btn_2_text' : data.interaction_btn_2_text,
            'interaction_btn_3_explain' : data.interaction_btn_3_explain,
            'interaction_btn_3_icon_id' : data.interaction_btn_3_icon_id,
            'interaction_btn_3_text' : data.interaction_btn_3_text,
          }
        }
        this.video.interaction_btn_3_index = 2
      }
    },
    selectQuestionToAddOption(id) {
      this.addOptionInfo = this.questions[id - 1]
    },
    q1OptionsSetting() {
      let q1Options = {}
      this.questions[0].options.map(q1 => {
        q1Options[q1.value] = false
      })
      return q1Options
    },
    q1q2OptionsSetting(isUpdate = false) {
      let q1q2Options = {}
      this.questions[0].options.map(q1 => {
        this.questions[1].options.map(q2 => {
          q1q2Options[q1.value + 'x' + q2.value] = false
        })
      })

      if (isUpdate) {
        let currentOptions = this.questions[2]?.q1q2Options
        if (currentOptions) {
          Object.keys(currentOptions).map(function (key) {
            if (Object.prototype.hasOwnProperty.call(q1q2Options, key)) {
              q1q2Options[key] = currentOptions[key]
            }
          })
        }
      }

      return q1q2Options
    },
    addQuestion() {
      if (this.questions.length < 2) {
        this.questions.push({
          id: 2, name: 'Q2', value: '', isChecked: {'Q1': false}, options: []
        })
      } else {
        this.questions.push({
          id: 3, name: 'Q3', value: '', isChecked: {'Q1': false, 'Q2': false},
          q1Options: this.q1OptionsSetting(),
          q1q2Options: this.q1q2OptionsSetting(),
          options: []
        })
      }
    },
    removeQuestion(id) {
      this.questions.splice(id - 1, 1)
      if (id === 2) {
        let question = this.questions[id - 1]
        if (question) {
          this.questions[id - 1] = {
            id: 2,
            name: 'Q2',
            value: question.value,
            isChecked: {'Q1': question.isChecked['Q1']},
            options: question.options
          }
          Object.entries(question.q1Options).forEach(([key, value]) => {
            this.questions[0].options.filter(item => {
              return item.value == key
            })[0].isChecked = value
          })
        }
      }
      this.questions = [...this.questions]
    },
    settingOption(val) {
      this.settingOptionDone = val
      this.prevQuestion = JSON.parse(JSON.stringify(this.questions))
    },
    closeModalScreeningOption() {
      this.showModalName = ''
      this.questions = JSON.parse(JSON.stringify(this.prevQuestion))
    },
    selectQuestionToEditOption(args) {
      this.questions[args.id - 1].options[args.idOption - 1].isEdit = true
    },
    editOption(args) {
      if (args.valueOption) {
        this.questions[args.id - 1].options[args.idOption - 1].value = args.valueOption
        this.questions[args.id - 1].options[args.idOption - 1].isEdit = false

        if (Object.prototype.hasOwnProperty.call(this.questions, 2) && this.questions[2]?.q1q2Options) {
          this.questions[2].q1q2Options = this.q1q2OptionsSetting(true)
        }
      }
      else this.removeOption(args)
    },
    removeOption(args) {
      this.questions[args.id - 1].options.splice(args.idOption - 1, 1)
      this.questions[args.id - 1].options.map((value, index) => {
        value.id = index + 1
      })
      if (args.id - 1 !== 2 && this.questions[2]) {
        this.questions[2].q1Options = this.q1OptionsSetting()
        this.questions[2].q1q2Options = this.q1q2OptionsSetting(true)
      }
    },
    resetOption(index) {
      this.questions[index].options.map(val => {
        val.isChecked = false
      })
      if (index == 1) {
        Object.keys(this.questions[2].q1Options).map(k => {
          this.questions[2].q1Options[k] = false
        })
        Object.keys(this.questions[2].q1q2Options).map(k => {
          this.questions[2].q1q2Options[k] = false
        })
      }
    },
    addAnswerToQuestion(args) {
      let len = this.questions[args.qId - 1].options.length
      this.questions[args.qId - 1].options.push({id: len + 1, value: args.valueOption, isChecked: false, isEdit: false})
    },
    formatToQuestion(questions) {
      this.prevQuestion = []
      this.questions = []
      questions.map((question, key) => {
        let optionTemp = [], isCheckedTemp = {}, questionTemp = {}, id = key + 1
        question.answers.map((answer, ixAnswer) => {
          optionTemp.push({
            id: ixAnswer + 1,
            value: Object.values(answer)[0],
            isChecked: id < 3 && questions[id] && questions[id].depend_on_answers[Object.values(answer)[0]] == "1" ? true : false,
            isEdit: false
          })
        })
        for (let j = 1; j < id; j++) {
          isCheckedTemp['Q' + j] = false
        }
        if (question.depend_on_questions) {
          let questionDepend = question.depend_on_questions.split(',')
          questionDepend.map(key => {
            isCheckedTemp[key] = true
          })
        }
        questionTemp = {
          id: id,
          name: 'Q' + id,
          value: question.question_text,
          isChecked: isCheckedTemp,
          options: optionTemp
        }
        if (id === 3) {
          let q1Options = this.q1OptionsSetting(), q1q2Options = this.q1q2OptionsSetting()
          if (question.depend_on_questions == 'Q1,Q2') {
            Object.entries(question.depend_on_answers).forEach(([key, value]) => {
              q1q2Options[key] = value == "1" ? true :false
            })
          } else if (question.depend_on_questions == 'Q1') {
            Object.entries(question.depend_on_answers).forEach(([key, value]) => {
              q1Options[key] = value == "1" ? true :false
            })
          }
          questionTemp = {...questionTemp, ...{"q1Options": q1Options, "q1q2Options": q1q2Options}}
        }
        this.prevQuestion.push(questionTemp)
        this.questions.push(questionTemp)
      })
    },
    saveModalConfirm() {
      setTimeout(() => {
        this.video.end_at = this.prevEndDate
        if (this.prevStartDate) {
          this.video.start_at = this.prevStartDate
        }
        this.initFlatPickr()
        this.isShowModalConfirm = false
      }, 500)
    },
    closeModalConfirm() {
      this.initFlatPickr()
      this.isShowModalConfirm = false
    },

    addKeyword() {
      this.video.twitter_keywords.push('')
    },

    refactorVideoKeyword() {
      let vm = this;
      const defaultKeywordData = ['','','','','']
      if (!Object.prototype.hasOwnProperty.call(vm.video, 'twitter_keywords')) {
        vm.video.twitter_keywords = defaultKeywordData
      } else if (vm.video.twitter_keywords.length && vm.video.twitter_keywords.length < defaultKeywordData.length) {
        defaultKeywordData.forEach(function (item, index) {
          if (!vm.video.twitter_keywords[index]) {
            vm.video.twitter_keywords[index] = item
          }
        })
      }
    },
    settingAndOrSegment() {
      this.toggleModal("and-or")
    },

    initListIntention(hasEditedValue = false) {
      getListIntention().then(res => {
        if (res.items) {
          this.intentionList = res.items
          // add index for sort
          this.intentionList.forEach((item, index) => {
            item.index = index
          })
        }
        if (!hasEditedValue) {
          this.initVideoIntention()
        }
      })
    },

    initVideoIntention() {
      const video_params = {
        video_ids: this.video.id
      }

      getVideoIntention(video_params).then(res => {
        if (res.items && res.items[0] && res.items[0].intentions) {
          let intentionData = res.items[0].intentions
          // map index to intention
          intentionData.forEach(item => {
            item.index = this.intentionList.findIndex(intention => intention.id == item.id)
          })
          this.intentionData.intention_items_selected = JSON.parse(JSON.stringify(intentionData))
          this.intentionData.intention_items_checked = JSON.parse(JSON.stringify(intentionData))

          // map intention to select_data
          this.intentionData.select_data = intentionData.map(item => {
            let intentionItemToSelect = JSON.parse(JSON.stringify(this.intentionListToSelect.concat(item)))
            // sort intentionItemToSelect
            intentionItemToSelect.sort((a, b) => {
              return a.index - b.index
            })
            return {
              id_selected: item.id,
              intention_items: intentionItemToSelect,
            }
          })
        }
      })
    },

    addIntention() {
      if (!this.isBeforeDate) {
        return
      }
      const intentionToSelect = JSON.parse(JSON.stringify(this.intentionListToSelect))
      this.intentionData.select_data.push({
        id_selected: intentionToSelect[0].id,
        intention_items: intentionToSelect,
      })
    },

    selectIntention(args) {
      if (!this.isBeforeDate) {
        return
      }
      if (!this.intentionData.intention_items_selected.find(item => Number(item.id) === Number(args.id))) {
        // update id_selected
        this.intentionData.select_data[args.index].id_selected = Number(args.id)
        // update intention_items_selected
        const listIdSelected = this.intentionData.select_data.map(item => item.id_selected)
        let dataSelected = JSON.parse(JSON.stringify(this.intentionList.filter(item => listIdSelected.includes(item.id))))
        dataSelected.sort((a, b) => a.index - b.index)
        this.intentionData.intention_items_selected = dataSelected
        // update intention_items in intentionData
        this.intentionData.select_data.forEach(item => {
          const id_selected = item.id_selected
          // check option is default or not, also keep memory address in select2
          let dataIntention = Number(id_selected) !== 0 ? item.intention_items.filter(item => Number(item.id) === Number(id_selected)) : []
          const intentionToSelect = JSON.parse(JSON.stringify(this.intentionListToSelect))
          dataIntention = dataIntention.concat(intentionToSelect)
          // sort intention_items
          dataIntention.sort((a, b) => a.index - b.index)
          item.intention_items = dataIntention
        })

        // update intention_items_checked, remove item not in intention_items_selected
        this.intentionData.intention_items_checked = this.intentionData.intention_items_checked.filter(item => listIdSelected.includes(item.id))

      }
    },

    checkedIntention(id) {
      if (!this.isBeforeDate) {
        return
      }
      if (this.intentionData.intention_items_checked.find(item => item.id == id)) {
        this.intentionData.intention_items_checked.splice(this.intentionData.intention_items_checked.findIndex(item => item.id == id), 1)
      } else {
        const item = this.intentionList.find(item => item.id == id)
        if (item) {
          this.intentionData.intention_items_checked.push(item)
        }
      }
    },

    convertAccessUrl(urlText) {
      if (!urlText) {
        return ''
      }
      let accessUrlS3 = process.env.VUE_APP_URL_ASSET
      if (accessUrlS3[accessUrlS3.length - 1] === '/') {
        accessUrlS3 = accessUrlS3.slice(0, -1)
      }
      urlText = urlText.split('/')
      urlText[0] = accessUrlS3
      return urlText.join('/')
    }
  },

  mounted() {
    this.loadListen();
    this.selectVideoUrlType();
    this.selectStatus();
    this.checkYoutubeAnalytics();
    this.initFlatPickr();
    this.changeInputYoutubeChannelVideoId();
    this.handleDropFile();
    forceArrowDownSelect2();

    let interactionTypesCustom = JSON.parse(localStorage.getItem("interactionTypesCustom"));
    if (interactionTypesCustom) {
      this.interactionTypes = interactionTypesCustom
    }

    let interactionTypesCustomStep1 = JSON.parse(localStorage.getItem("interactionTypesCustomStep1"));
    if (interactionTypesCustomStep1) {
      this.interactionTypesStep1 = interactionTypesCustomStep1
    }

    let interactionTypeVerifyOneCustom = JSON.parse(localStorage.getItem("interactionTypeVerifyOneCustom"));
    if (interactionTypeVerifyOneCustom) {
      this.interactionTypeVerifyOne = interactionTypeVerifyOneCustom
    }
    let segmentSelected = JSON.parse(localStorage.getItem("segmentSelected"));
    if (segmentSelected) {
      this.segmentSelected = segmentSelected
    }
    let groupsSegment = JSON.parse(localStorage.getItem("groupsSegment"));
    if (groupsSegment) {
      this.groupsSegment = groupsSegment
    }
    let questions = JSON.parse(localStorage.getItem("screening_option"))
    if (questions) {
      this.questions = questions
      this.prevQuestion = JSON.parse(JSON.stringify(this.questions))
      this.settingOptionDone = true
    }

    let intentionData = JSON.parse(localStorage.getItem("intentionData"));
    if (intentionData) {
      this.intentionData = intentionData
    }

    let intentionList = JSON.parse(localStorage.getItem("intentionList"));
    if (intentionList) {
      this.intentionList = intentionList
    }

    let video = JSON.parse(localStorage.getItem("video_register"));
    if (!video) {
      let type = jQuery("input:radio[name=type]");
      let status = jQuery("input:radio[name=status]");
      if (type.is(":checked") === false) {
        type.filter(`[value=1]`).prop("checked", true);
      }
      if (status.is(":checked") === false) {
        status.filter(`[value=1]`).prop("checked", true);
      }
    } else {
      let vm = this;
      let type = jQuery("input:radio[name=type]");
      let status = jQuery("input:radio[name=status]");
      if (type.is(":checked") === false) {
        type.filter(`[value=${vm.video.video_url_type}]`).prop("checked", true);
      }
      if (status.is(":checked") === false) {
        status.filter(`[value=${vm.video.status}]`).prop("checked", true);
      }
    }

    this.getListCategory();
  },
  created() {
    let video = JSON.parse(localStorage.getItem("video_register"));
    let buyer_id = localStorage.getItem("buyer_id");
    let currentUser = this.$store.getters.currentUser;
    this.currentUser = currentUser;
    this.getListCountry();

    if (video && buyer_id && video.id == this.$route.params.video_id) {
      this.video = video;
      this.startDate = video.start_at
      this.endDate = video.end_at
      this.buyerId = parseInt(buyer_id);
      this.video.country_id = parseInt(this.video.country_id);
      if (this.video.youtube_analytic_id) {
        this.video.youtube_analytic_id = parseInt(this.video.youtube_analytic_id)
        this.isYoutubeAnalytics = true
        this.isChannel = true
      }
      if (video.video_url_type === 2) {
        this.youtubeURL = this.youtubeURL + video.video_url;
      }
      if (video.youtube_video_id) {
        this.youtubeVideoChannelURL =
            this.youtubeVideoChannelURL + video.youtube_video_id;
      }
      this.fileName = "動画ファイルをドラッグ"

      this.video_file = this.$route.params.video_file;
      if (this.video_file) {
        this.isVideoFile = true;
        this.fileName = video.video_file_text;
      } else {
        this.isVideoFile = false;
        if (video.video_url_type !== 2) {
          this.fileName = video.video_url.split('/').pop()
          this.isVideoFile = true
          this.video_file_required = false
        }
      }
      this.video.video_file_text = this.fileName

      if (currentUser && currentUser.role == "admin") {
        this.getListBuyer();
      } else {
        this.buyerId = this.currentUser.owner_id;
        this.buyerSelected = this.currentUser
        this.initData();
      }
      this.refactorVideoKeyword()

      // for video intention
      if (this.video.deliver_period_type && this.video.deliver_period_type == this.listStep.step2.id) {
        this.initListIntention(true);
      }
    } else {
      let id = this.$route.params.video_id;
      getVideoDetail(id).then(video => {
        this.video = {
          ...video,
          intention_items_checked: [],
          intention_items_selected: [],
        }
        // this.startDate = get date, hour and minute from video.start_at (ex: 2023-11-30 06:15:30 -> 2023-11-30 06:15)
        this.startDate = moment(video.start_at).format('YYYY-MM-DD HH:mm');
        this.endDate = moment(video.end_at).format('YYYY-MM-DD HH:mm');
        // get date from video.start_at (ex: 2023-11-30 06:15 -> 2023-11-30)
        this.video.start_at = moment(video.start_at).format('YYYY-MM-DD');
        // get minute and second from video.start_at (ex: 2023-11-30 06:15:30 -> 06:15)
        this.video.start_time_at = moment(video.start_at).format('HH:mm');
        this.video.end_at = moment(video.end_at).format('YYYY-MM-DD');
        this.buyerId = video.buyer_id
        if (this.video.youtube_analytic_id) {
          this.video.youtube_analytic_id = parseInt(this.video.youtube_analytic_id)
          this.isYoutubeAnalytics = true
          this.isChannel = true
        }
        if (video.video_url_type === 2) {
          this.youtubeURL = this.youtubeURL + video.video_url;
        }
        if (video.youtube_video_id) {
          this.youtubeVideoChannelURL =
              this.youtubeVideoChannelURL + video.youtube_video_id;
        }
        this.fileName = "動画ファイルをドラッグ"
        if (video.video_url_type !== 2) {
          this.fileName = video.video_url.split('/').pop()
          this.isVideoFile = true
          this.video_file_required = false
        }
        this.video.video_file_text = this.fileName

        if (this.video.deliver_flg != 3) {
          if (this.video.deliver_period_type == this.listStep.step2.id) {
            let videoBtnText = this.video.interaction_btn_1.text + '/' + this.video.interaction_btn_2.text
            Object.keys(this.interactionTypes).map(key => {
              if (this.interactionTypes[key].text == videoBtnText) {
                this.video.interaction_btn_1_index = this.interactionTypes[key].index
              }
            })
            if (!this.video.interaction_btn_1_index) {
              let data = {
                'interaction_btn_1_explain': this.video.interaction_btn_1.btn_explain,
                'interaction_btn_1_icon_id': this.video.interaction_btn_1.btn_icon_id,
                'interaction_btn_1_text': this.video.interaction_btn_1.text,
                'interaction_btn_2_explain': this.video.interaction_btn_2.btn_explain,
                'interaction_btn_2_icon_id': this.video.interaction_btn_2.btn_icon_id,
                'interaction_btn_2_text': this.video.interaction_btn_2.text,
              }
              this.customInteractionButton(data)
            }
            this.video = {...this.video, ...{'interaction_btn_2_index': 1}}
            this.video = {...this.video, ...{'interaction_btn_3_index': 1}}
          } else {
            let videoBtnText = this.video.interaction_btn_1.text + '/' + this.video.interaction_btn_2.text + '/' + this.video.interaction_btn_3.text
            Object.keys(this.interactionTypesStep1).map(key => {
              if (this.interactionTypesStep1[key].text == videoBtnText) {
                this.video.interaction_btn_3_index = this.interactionTypesStep1[key].index
              }
            })

            if (!this.video.interaction_btn_3_index) {
              let data = {
                'interaction_btn_1_explain': this.video.interaction_btn_1.btn_explain,
                'interaction_btn_1_icon_id': this.video.interaction_btn_1.btn_icon_id,
                'interaction_btn_1_text': this.video.interaction_btn_1.text,
                'interaction_btn_2_explain': this.video.interaction_btn_2.btn_explain,
                'interaction_btn_2_icon_id': this.video.interaction_btn_2.btn_icon_id,
                'interaction_btn_2_text': this.video.interaction_btn_2.text,
                'interaction_btn_3_explain': this.video.interaction_btn_3.btn_explain,
                'interaction_btn_3_icon_id': this.video.interaction_btn_3.btn_icon_id,
                'interaction_btn_3_text': this.video.interaction_btn_3.text,
              }
              this.customInteractionButton(data)
            }

            this.video = {...this.video, ...{'interaction_btn_1_index': 1}}
            this.video = {...this.video, ...{'interaction_btn_2_index': 1}}
          }
        } else {
          Object.keys(this.interactionTypeVerifyOne).map(key => {
            if (this.interactionTypeVerifyOne[key].text == this.video.interaction_btn_1.text) {
              this.video.interaction_btn_2_index = this.interactionTypeVerifyOne[key].index
            }
          })
          if (!this.video.interaction_btn_2_index) {
            let data = {
              'interaction_btn_1_explain': this.video.interaction_btn_1.btn_explain,
              'interaction_btn_1_icon_id': this.video.interaction_btn_1.btn_icon_id,
              'interaction_btn_1_text': this.video.interaction_btn_1.text
            }
            this.customInteractionButton(data)
          }
          this.video = {...this.video, ...{'interaction_btn_1_index': 1}}
          this.video = {...this.video, ...{'interaction_btn_3_index': 1}}
        }

        // for video intention
        if (this.video.deliver_period_type && this.video.deliver_period_type == this.listStep.step2.id) {
          this.initListIntention();
        }

        if (currentUser && currentUser.role == "admin") {
          this.getListBuyer();
        } else {
          this.buyerId = this.currentUser.owner_id;
          this.buyerSelected = this.currentUser
          this.initData();
          if (this.video.buyer_id !== this.buyerId) {
            this.video.id = 0
          }
        }
        if (video.has_custom_segment) {
          this.getCustomSegment()
        }
        this.initFlatPickr()
        this.refactorVideoKeyword()
      }).catch(() => {
        this.video.id = 0
      })
      getVideoScreeningPromise({video_id: id}).then(questions => {
        if (questions && questions.items && questions.items.length) {
          this.settingOptionDone = true
          this.formatToQuestion(questions.items)
        }
      })
      localStorage.removeItem("video_register");
      localStorage.removeItem("buyer_id");
      localStorage.removeItem("layers");
      localStorage.removeItem("groupsSegment");
      localStorage.removeItem("segmentSelected");
      localStorage.removeItem("interactionTypesCustom");
      localStorage.removeItem("interactionTypesCustomStep1");
      localStorage.removeItem("interactionTypeVerifyOneCustom");
      localStorage.removeItem("screening_option");
      localStorage.removeItem("intentionData");
      localStorage.removeItem("intentionList");
    }

    EventBus.$on("toggleModal", this.toggleModal)
    EventBus.$on("customInteractionButton", this.customInteractionButton)
    EventBus.$on("addOption", this.selectQuestionToAddOption)
    EventBus.$on("editOption", this.editOption)
    EventBus.$on("enabledEditOption", this.selectQuestionToEditOption)
    EventBus.$on("removeOption", this.removeOption)
  },

  destroyed() {
    EventBus.$off("toggleModal", this.toggleModal)
    EventBus.$off("customInteractionButton", this.customInteractionButton)
    EventBus.$off("addOption", this.selectQuestionToAddOption)
    EventBus.$off("editOption", this.editOption)
    EventBus.$off("enabledEditOption", this.selectQuestionToEditOption)
    EventBus.$off("removeOption", this.removeOption)
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables/_colors.scss";
@import "@/styles/_add.scss";

.cursor-default {
  cursor: default !important;
}

.title-page {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 32px;
}

.form-group {
  &.form-group-label {
    label {
      margin-bottom: 0;
    }
  }

  .group-tag-list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0;
    border: none;

    &.group-keyword-list {
      align-items: center;

      .group-keyword-list-input {
        margin-right: 10px;
        margin-top: 10px;
        width: 12%;

        &:disabled {
          background-color: #EBEBEB;
          border-color: #EBEBEB;
        }
      }

      .tag {
        height: 53px;
        width: 53px;
        border: none;
      }
    }

    .tag {
      margin-right: 8px;
      margin-top: 8px;
      height: 36px;
    }

    .btn-plus {
      display: flex;
      background-color: $light-green;
      cursor: pointer;
      padding: 8px 9px;
    }
  }
}

.edit-segment-link {
  display: flex;
  align-items: center;
  color: #49ab94;
  font-size: 12px;
  margin-top: 8px;
  cursor: pointer;
  .icon-arrow-right {
    margin-left: 2px;
  }
}

.text-link-setting-interaction-button{
  color : #49ab94;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
}

.wrap-chatgpt {

  label {
    color : #49ab94 !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 22px !important;
    margin-bottom: 0;
  }
}

.label-text {
  margin-bottom: 12px;
}

.verification-methods-description{
  font-style: normal;
  font-size: 10px;
  line-height: 16px;
  color: #999999;
}

.describe-panel {
  margin-top: 8px;
  color: $middle-black;
  @include font(s);
}

.describe-segment {
  margin-top: 8px;
  color: $middle-black;
  font-size: 12px;
  line-height: 19px;
}

.screening-options {
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #333333;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  button {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
    color: #49AB94;
    padding: 5px 11px;
    background-color: #FFFFFF;
    border: 1px solid #49AB94;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }

  .screening-options-done {
    color: #FFFFFF;
    background-color: #49AB94;
  }
}

.preview-group-segment {
  &.list-attribute {
    padding: 10px 0;
    background-color: $light-green;
    border-radius: 8px;
    height: 294px;
    display: flex;
    flex-direction: column;
    position: relative;

    .btn-delete {
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
      @include font(s);
      color: $green;
    }

    .attributes {
      margin: 0;
      font-weight: bold;
      direction: rtl;
      overflow-y: auto;

      .list-item {
        border-bottom: 1px solid $middle-black;

        .title-item {
          color: #333333;
          font-size: 14px;
          line-height: 24px;
        }

        &:first-child {
          padding-top: 10px;
        }

        &:last-child {
          padding-bottom: 10px;
          border: none;
        }

        .describe-item-selected {
          .att-selected {
            &:last-child {
              margin-bottom: 0;
            }

            .name {
              @include font(m);
              color: $middle-black;
              margin-bottom: 4px;
              font-weight: bold;
            }

            .value {
              @include font(s);
              color: $black;
              display: inline-flex;
              flex-wrap: wrap;
              flex-direction: row-reverse;
              justify-content: left;
              gap: 12px;

              span {
                padding: 6px 12px;
                margin-right: 4px;
                border: 1px solid $black;
                border-radius: 8px;
                direction: ltr;
              }
            }
          }
        }
      }

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $black;
      }
    }
  }
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 3px solid #49ab94;
  border-radius: 50%;
  border-top: 2px solid #ffffff;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin: auto;
}

.input-disabled {
  background-color: #EBEBEB !important;
}

.radio-disabled {
  opacity: 0.5;
}

::v-deep .flatpickr-wrapper {
display: block !important;
}

::v-deep .tooltip-content {
  transform: translate(3px, 4px);
  width: 0;
  height: 0;
  margin: 0 auto;
}

.chat-gpt {
  .modal-content {
    width: 670px;
    height: 456px;
    padding: 0;
    position: relative;
    border-radius: 10px;

    .header {
      position: absolute;
      right: 0;
      top: -42px;
    }

    .body {
      padding: 40px;
      color: rgba(51, 51, 51, 1);

      h2 {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 51.2px;
        margin: 0;
      }

      h3 {
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
}

.intention-content {
  .select-intention {
    margin-bottom: 20px;
  }
}

.btn-plus-intention {
  cursor: pointer;
  font-size: 10px;
  line-height: 16px;
  color: #49AB94;
  padding: 5px 11px;
  border-radius: 8px;
  border: 1px solid #49AB94;
  font-weight: 700;
  font-family: "Noto Sans JP";
  display: inline-block;
  user-select: none;

  &.disabled {
    opacity: 0.5;
  }
}

.btn-plus-intention:hover {
  background-color: #49AB94;
  color: #FFFFFF;
}

.intention-item-checkbox {
  margin-right: 20px;
  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;

  .icon-checkbox {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .intention-item-name {
    font-size: 12px;
    line-height: 24px;
    color: #333333;
    margin-top: 4px;
  }
}

.default-question-cdai {
  .question-item {
    display: flex;
  }
}

.list-image-capture {
  background: $light-green;
  padding: 30px 30px 10px;
  margin-top: 10px;

  .capture-label {
    font-family: Noto Sans JP;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .count-image-capture {
    font-family: Noto Sans JP;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: $green;
  }

  .list-image-content {
    max-height: 510px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 20px;
    padding-bottom: 20px;

    .image-capture {
      width: 13%;
      .image-capture-item {
        overflow: hidden;
        position: relative;
        display: inline-flex;
        border-radius: 8px;

        .image-wrapper {
          background: black;
          display: inline-flex;
          border-radius: 10px;

          img {
            object-fit: contain;
            width: 100%;
            aspect-ratio: 295/164;

            &.loading {
              opacity: 0.5;
            }
          }
        }

        &-checkbox {
          margin-top: 10px;
          display: flex;
          align-items: center;
          .icon-checkbox {
            display: inline-block;
          }
          .second-text {
            color: $green;
            font-family: Noto Sans JP;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-left: 10px;
            user-select: none;
          }
        }
      }
    }
  }
}

.datetime-content {
  display: flex;
  gap: 20px;
}

::v-deep {
  .input-time {
    width: 45px;
    text-align: center;
  }
}

</style>
<style>
.icon-custom {
  width: 32px;
  height: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.icon-next {
  background-image: url('/images/svg/icon_next.svg');
}

.icon-prev {
  background-image: url('/images/svg/icon_prev.svg');
}

.select2-results__option {
  width: unset !important;
  word-break: break-word;
}
</style>