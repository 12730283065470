<template>
  <div class="home-compare-user-form-contain">
    <div class="form-group large targets-for-comparison flex flex-col">
      <label>対象の動画</label>
      <div class="mb-1">
        <Select
          :data="clientsList"
          :idSelected="idSelectedClient"
          :isSelected="isSelectedClient"
          @select="selectClient"
          :element="'clientsList'"
        ></Select>
      </div>
      <RadioList
        :radioList="videosList"
        :idSelected="idSelectedVideo"
        :keyName="'video'"
      ></RadioList>
    </div>
    <div class="form-group large flex flex-col mb-0">
      <label>比較するユーザーを選択</label>
      <div class="form-group sub-form-group large flex flex-col">
        <label class="sub-label mt-2 mb-0">1つ目の比較対象</label>
        <CheckboxList
          :checkBoxList="firstComparisonTargetList"
          :keyName="'firstComparisonTarget'"
          :checkedList="firstComparisonTargetSelectedIds"
          @changeCheckedList="changeFirstComparisonTarget($event)"
        ></CheckboxList>
      </div>
      <div class="form-group sub-form-group large flex flex-col">
        <label class="sub-label mb-0">2つ目の比較対象</label>
        <CheckboxList
          :checkBoxList="secondComparisonTargetList"
          :keyName="'secondComparisonTarget'"
          :checkedList="secondComparisonTargetSelectedIds"
          @changeCheckedList="changeSecondComparisonTarget($event)"
        ></CheckboxList>
      </div>
    </div>
  </div>
</template>
<script>
import {
  required,
} from "vuelidate/lib/validators";
import Select from "@/components/Video/Select.vue";
import RadioList from "@/components/Top/HomeCompare/RadioList.vue"
import CheckboxList from "@/components/Top/HomeCompare/CheckboxList.vue"

export default {
  props: {

  },
  components: {
    Select,
    RadioList,
    CheckboxList,
  },
  data() {
    return {
      idSelectedClient: 1,
      isSelectedClient: true,
      clientsList: [
        {
          id: 1,
          name: 'Client 1'
        },
        {
          id: 2,
          name: 'Client 2'
        },
        {
          id: 3,
          name: 'Client 3'
        },
        {
          id: 4,
          name: 'Client 4'
        },
        {
          id: 5,
          name: 'Client 5'
        }
      ],
      idSelectedVideo: 1,
      videosList: [
        {
          value: 1,
          text: "スマイルゼミ_TVCM『おうち篇』",
        },
        {
          value: 2,
          text: "shozemi",
        },
        {
          value: 3,
          text: "0611_Benesse_offline_15s",
        },
        {
          value: 4,
          text: "Z会_TVCM",
        },
        {
          value: 5,
          text: "スマイルゼミ_TVCM",
        },
        {
          value: 6,
          text: "動画A",
        },
        {
          value: 7,
          text: "動画B",
        },
      ],
      firstComparisonTargetSelectedIds: [],
      firstComparisonTargetList: [
        {
          value: 1,
          text: "反応値が高い",
        },
        {
          value: 2,
          text: "反応値が低い",
        },
        {
          value: 3,
          text: "男性",
        },
        {
          value: 4,
          text: "女性",
        },
        {
          value: 5,
          text: "20代",
        },
        {
          value: 6,
          text: "30代",
        },
        {
          value: 7,
          text: "40代",
        },
        {
          value: 8,
          text: "50代",
        },
        {
          value: 9,
          text: "60代",
        },
        {
          value: 10,
          text: "北海道",
        },
        {
          value: 11,
          text: "東北",
        },
        {
          value: 12,
          text: "関東",
        },
        {
          value: 13,
          text: "近畿",
        },
        {
          value: 14,
          text: "四国",
        },
        {
          value: 15,
          text: "中国",
        },
        {
          value: 16,
          text: "九州",
        },
      ],
      secondComparisonTargetSelectedIds: [],
      secondComparisonTargetList: [
        {
          value: 1,
          text: "反応値が高い",
        },
        {
          value: 2,
          text: "反応値が低い",
        },
        {
          value: 3,
          text: "男性",
        },
        {
          value: 4,
          text: "女性",
        },
        {
          value: 5,
          text: "20代",
        },
        {
          value: 6,
          text: "30代",
        },
        {
          value: 7,
          text: "40代",
        },
        {
          value: 8,
          text: "50代",
        },
        {
          value: 9,
          text: "60代",
        },
        {
          value: 10,
          text: "北海道",
        },
        {
          value: 11,
          text: "東北",
        },
        {
          value: 12,
          text: "関東",
        },
        {
          value: 13,
          text: "近畿",
        },
        {
          value: 14,
          text: "四国",
        },
        {
          value: 15,
          text: "中国",
        },
        {
          value: 16,
          text: "九州",
        },
      ],
    }
  },

  validations() {
    const validate = {
      firstComparisonTargetSelectedIds: {
        required,
      },
      secondComparisonTargetSelectedIds: {
        required,
      },
    }
    return validate
  },

  methods: {
    selectClient(id) {
      console.log('id', id)
    },

    changeFirstComparisonTarget(newValue) {
      this.firstComparisonTargetSelectedIds = newValue
      this.setDisabledButtonCompare()
    },

    changeSecondComparisonTarget(newValue) {
      this.secondComparisonTargetSelectedIds = newValue
      this.setDisabledButtonCompare()
    },

    setDisabledButtonCompare() {
      this.$v.$touch()
      this.$emit('setDisabledButtonCompare', this.$v.$error)
    }
  },

  watch: {
    // firstComparisonTargetSelectedIds: (new)
  },

  created() {
    this.setDisabledButtonCompare()
  },

  mounted() {

  },

  destroyed() {

  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/root";

.home-compare-user-form-contain {
  .form-group {
    .sub-form-group {
      padding-left: 34px;
    }
    .form-check-box {
      .label-check {
        margin-right: 20px;
      }
    }
  }
}
</style>
