<template>
  <div >
    <select class="form-control" name="clients" id="clients">

    </select>
  </div>
</template>
<script>

import {
  setSelect2Data,
  formatDataSelect2,
  setSelect2Value
} from '../../commons/helpers';
import EventBus from "../../commons/event_bus";

export default {
  props: {
    clients: Array,
    selected: [Number, String]
  },
  watch: {
    clients: function (clients) {
      this.initClientsSelect(formatDataSelect2(clients))
    }
  },
  data() {
    return {
      selectedId: null,
      clientPlan: null
    }
  },
  methods: {
    initClientsSelect(clients) {
      let vm = this;
      setSelect2Data('clients', clients, false, this.handleSelectedClient, {
        selected : vm.selected,
        minimumResultsForSearch: 0,
        width: '308px',
        height: '52px',
        dropdownCssClass: 'dropdown-select2-custom'
      },true);
      if (this.selected) {
        setSelect2Value('clients', this.selected)
      }
      EventBus.$emit('clientSelected', this.selected);
    },
    handleSelectedClient(selectedId) {
      if (this.selectedId != selectedId) {
        this.selectedId = selectedId;
        this.$emit('select', selectedId);
      }

    },
    getSelectedClientPlan(){
      for(let n = 0; n < this.clients.length; n++){
        if(this.clients[n].id == this.selected){
          this.clientPlan = this.clients[n].plan
        } 
      }
      this.$emit('clientPlan', this.clientPlan);
    }
  },
  mounted() {
    this.initClientsSelect(formatDataSelect2(this.clients))
    this.getSelectedClientPlan()
  }
};
</script>
<style lang="scss" scoped>
</style>