<template>
  <div class="row">
    <div class="col-13">
      <div class="header">動画再生テスト</div>
      <div class="content mt-3 row">
        <div class="col-5 content-left">
          <div class="title-medium mb-4 color-black">
            対象動画をアンケート配信前に確認いただくテストページです。
          </div>
          <div class="left-content color-black mb-4">
            <div class="number-serial">
              <span>①</span>
              <span>②</span>
            </div>
            <div class="title-large">
              再生ボタンを押すと動画が再生されます。リアクション（スキ/ナットクまたはGood/Badのボタン）を押してください。<br />
              動画の再生が終わりましたら、テストは終了です。
            </div>
          </div>
          <div class="title-medium color-black mb-2">
            もし、エラーメッセージが出た場合はエンコードエラーが発生している場合があります。動画に問題がないかご確認をお願いいたします。
          </div>
          <div class="title-small color-black-middle mb-5">
            ※音声が再生されることがあるので、音量等にご注意ください。<br />
            ※テストページでのリアクションの押下分は集計結果には反映されませんのでご安心ください。
          </div>
          <div class="btn-submit">
            <button class="btn btn-primary-outline" @click="prevPage()">
              修正する
            </button>
          </div>
        </div>
        <div class="col-8 content-right">
          <div class="video">
            <div class="video-detail">
              <video
                id="video"
                class="
                  video-js
                  vjs-default-skin vjs-big-play-centered vjs-nofull
                "
                controls
                controlsList="nodownload"
                preload="metadata"
                height="200"
                disablepictureinpicture
                data-setup='{ "playbackRates": [1,2,4,6,8,10] }'
                v-if="videoRegister.video_url_type == videoUrlUpload"
              >
                <source :src="videoRegister.video_url" type="video/mp4" />
                <p class="vjs-no-js">
                  To view this video please enable JavaScript, and consider
                  upgrading to a web browser that
                  <a
                    href="http://videojs.com/html5-video-support/"
                    target="_blank"
                  >
                    supports HTML5 video
                  </a>
                </p>
              </video>
              <!-- <div
                class="video-icon"
                v-if="videoRegister.video_url_type == videoUrlUpload"
              >
                <i class="icon icon-btn-play"></i>
              </div> -->

              <div
                class="video-react-youtube"
                v-if="videoRegister.video_url_type !== videoUrlUpload"
              >
                <div id="video-youtube"></div>
              </div>
            </div>
          </div>
          <div class="react mt-6">
            <button
              v-if="iconInteraction.rank_2.length"
              class="btn btn-react btn-interaction-left disable mr-5"
              :style="{ backgroundColor : colorInteraction.rank_2}"
              @click="eventReact('left')"
            >
              <div class="btn-icon-text">
                <img class="icon-left" style="margin-right : 8px; width : 24px" :src="iconInteraction.rank_2" alt="">
                {{textInteraction.rank_2}}
              </div>
            </button>
            <button
              v-if="iconInteraction.rank_3.length"
              class="btn btn-react btn-interaction-right disable"
              :style="{ backgroundColor : colorInteraction.rank_3}"
              @click="eventReact('right')"
            >
              <div class="btn-icon-text">
                <img class="icon-right" style="margin-right : 8px; width : 24px" :src="iconInteraction.rank_3" alt="">
                {{textInteraction.rank_3}}
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="form-group wrapper-btn">
        <button class="btn btn-primary btn-submit" @click="showModalConfirm">
          更新
        </button>
      </div>
    </div>
    <modal-confirm
      v-if="isShowModalConfirm"
      :message="confirmMessage"
      @confirm="updateVideo"
      @closeModalConfirm="isShowModalConfirm = false"
    ></modal-confirm>
  </div>
</template>
<script>
const jQuery = require("jquery");

import ModalConfirm from "../../components/Modal/ModalConfirm.vue";
import videojs_cus from "../../components/VideoJsCustom";
import i18n from "../../i18n";
import EventBus from "../../commons/event_bus";
export default {
  components: {
    ModalConfirm,
  },
  data() {
    let videoRegister = this.$route.params.video_info;
    let videoUrl = videoRegister.video_url;
    let videoOriginal = this.$route.params.video_original;
    return {
      videoRegister,
      videoUrl,
      videoUrlUpload: 1,
      isVideoNotFail: false,
      isVideoPlaying: false,
      good: "good",
      bad: "bad",
      suki: "suki",
      nattoku: "nattoku",
      videoFile: null,
      confirmMessage: "更新します、よろしいですか？",
      videoEntity: null,
      messageUpdateSuccess: i18n.t("messages.register_success"),
      videoOriginal,
      isShowModalConfirm: false,
    };
  },
  computed : {
    textInteraction() {
      return {
        rank_2 : this.videoRegister.interaction_btn_1 && Object.keys(this.videoRegister.interaction_btn_1).length ? this.videoRegister.interaction_btn_1.text : '',
        rank_3 : this.videoRegister.interaction_btn_2 && Object.keys(this.videoRegister.interaction_btn_2).length ? this.videoRegister.interaction_btn_2.text : '',
      }
    },
    iconInteraction() {
      return {
        rank_2 : this.videoRegister.interaction_btn_1 && Object.keys(this.videoRegister.interaction_btn_1).length ? this.videoRegister.interaction_btn_1.btn_icon_url : '',
        rank_3 : this.videoRegister.interaction_btn_2 && Object.keys(this.videoRegister.interaction_btn_2).length ? this.videoRegister.interaction_btn_2.btn_icon_url : '',
      }
    },
    colorInteraction(){
      let color_rank_2 = this.videoRegister.interaction_btn_1 && Object.keys(this.videoRegister.interaction_btn_1).length ? this.videoRegister.interaction_btn_1.color : '';
      let color_rank_3 = this.videoRegister.interaction_btn_2 && Object.keys(this.videoRegister.interaction_btn_2).length ? this.videoRegister.interaction_btn_2.color : ''
      return {
        rank_2 : color_rank_2 == 1 ? '#4976ab' : color_rank_2 == 2 ? '#e5bb26' : color_rank_2 == 3 ? '#ce6060' : color_rank_2 == 4 ? '#cf2f99' : '',
        rank_3 : color_rank_3 == 1 ? '#4976ab' : color_rank_3 == 2 ? '#e5bb26' : color_rank_3 == 3 ? '#ce6060' : color_rank_3 == 4 ? '#cf2f99' : '',
      }
    },
  },
  methods: {
    setHeightVideo(){
      let videoDetail = jQuery('.video');
      let width = videoDetail.width()
      let height = (width * 9) / 16;
      if (height > 800) {
        height = 800;
        let widthVideo = (height * 16) / 9;
        videoDetail.width(widthVideo)
      }
      videoDetail.height(height)
    },
    showModalConfirm() {
      if (this.isVideoNotFail) {
        this.isShowModalConfirm = true;
      }
    },
    uploadFileS3(success, fail) {
      let url = this.$router.resolve({
        name: "api.videos.upload_url",
      }).href;
      this.$http2.get(
        url,
        {},
        (data) => {
          // let urlS3 = data.upload_url.split('?');
          let urlS3 = data.upload_url;
          this.$http2.uploadFileS3(urlS3, this.videoFile, () => {
            success(data.file_name);
          });
        },
        () => {
          fail && fail();
        }
      );
    },
    updateVideo() {
      let vm = this;
      let submit = this.$http2.put;
      let update = (filename) => {
        let query = {
          id: this.videoRegister.id,
        };
        let url = this.$router.resolve({
          name: "api.videos.update",
          params: query,
        }).href;

        let params = {
          name: this.videoRegister.name,
          status: this.videoRegister.status,
          start_at: this.videoRegister.start_at,
          end_at: this.videoRegister.end_at,
          video_url: filename ? filename : this.videoRegister.video_url,
          country_id: this.videoRegister.country_id,
          video_url_type: this.videoRegister.video_url_type,
          cognition_survey: this.videoRegister.cognition_survey,
          ...(this.videoRegister.youtube_analytic_id != null
            ? {
                youtube_analytic_id: this.videoRegister.youtube_analytic_id,
                youtube_video_id: this.videoRegister.youtube_video_id,
              }
            : {}),
        };
        submit(
          url,
          params,
          () => {
            this.$toasted.success(this.$t(this.messageUpdateSuccess));
            this.$router
              .push({
                name: "project",
                params: {
                  video_info: this.videoRegister,
                  video_file: this.videoFile,
                  video_original: this.videoOriginal,
                  isOpen: false,
                },
              })
              .catch(() => {});
            this.isShowModalConfirm = false;
            vm.$emit("closeEditVideo");
          },
          () => {
            EventBus.$emit("handleDone");
          }
        );
      };
      if (this.videoRegister.video_url_type == 1) {
        this.uploadFileS3(update, () => {});
      } else {
        update();
      }
    },
    prevPage() {
      this.$router.push({
        name: "project",
        params: {
          video_info: this.videoRegister,
          video_file: this.videoFile,
          video_original: this.videoOriginal,
          isOpen: true,
        },
      });
    },
    initVideo() {
      var $source = jQuery("#video");
      this.videoEntity = videojs_cus($source[0], {
        language: "ja",
        controlBar: {
          fullscreenToggle: false,
        },
      });
    },
    onPlayVideo() {
      let vm = this;
      jQuery("#video").on("play", function () {
        jQuery(".video-icon").css("display", "none");
        jQuery(".react").find(".btn-interaction-left").css("opacity", 1);
        jQuery(".react").find(".btn-interaction-right").css("opacity", 1);

        vm.isVideoPlaying = true;
      });
    },
    onPauseVideo() {
      let vm = this;
      jQuery("#video").on("pause", function () {
        jQuery(".video-icon").css("display", "flex");
        jQuery(".react").find(".btn-interaction-left").css("opacity", 0.7);
        jQuery(".react").find(".btn-interaction-right").css("opacity", 0.7);
        vm.isVideoPlaying = false;
      });
    },
    checkVideoEnded() {
      let vm = this;
      jQuery("#video").on("ended", function () {
        vm.isVideoNotFail = true;
        jQuery(".btn-submit").css("opacity", 1);
      });
    },
    settingDefault() {
      jQuery(".btn-submit").css("opacity", 0.7);
      jQuery(".video-icon").on("click", function () {
        jQuery(this).css("display", "none");
        jQuery("#video").trigger("play");
      });
    },
    videoYoutube() {
      if (window && window.YT && typeof window.YT == "undefined") {
        var tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }
      this.onYouTubePlayer();
    },
    onYouTubePlayer() {
      let video_url = this.videoUrl;
      let wrapper_video_id = "video-youtube";
      let youtube_video_id = video_url;
      this.videoEntity = new window.YT.Player(wrapper_video_id, {
        height: "100%",
        width: "100%",
        videoId: youtube_video_id,
        playerVars: {
          rel: 0,
          controls: 1,
          disablekb: 1,
          showinfo: 0,
          hl: "ja",
          autoplay: 0,
          fs: 0,
          playsinline: 1,
        },
        events: {
          onStateChange: this.onPlayerStateChange,
        },
      });
    },
    onPlayerStateChange(event) {
      let vm = this;
      // let VIDEO_END = 0 ;
      let VIDEO_PLAYING = 1;
      let VIDEO_PAUSE = 2;
      switch (event.data) {
        case VIDEO_PLAYING:
          jQuery(".video-icon").css("display", "none");
          jQuery(".react").find(".btn-interaction-left").css("opacity", 1);
          jQuery(".react").find(".btn-interaction-right").css("opacity", 1);
          jQuery(".btn-submit").css("opacity", 1);

          vm.isVideoNotFail = true;
          vm.isVideoPlaying = true;
          break;
        case VIDEO_PAUSE:
          jQuery(".video-icon").css("display", "flex");
          jQuery(".react").find(".btn-interaction-left").css("opacity", 0.7);
          jQuery(".react").find(".btn-interaction-right").css("opacity", 0.7);
          vm.isVideoPlaying = false;
          break;
      }
    },
    eventReact(e) {
      if (this.isVideoPlaying) {
        let wrapper = jQuery(".video-detail");
        let videoHeight = wrapper.css("height").slice(0, -2);
        let PC_SCREEN_WIDTH = 1200;
        let $originImg = jQuery(`.btn-interaction-${e}`).find(`.icon-${e}`).first();
        for (let index = 0; index < 3; index++) {
          setTimeout(() => {
            let randomNum = Math.floor(Math.random() * 4) + 1;
            let isPcAnim =
              wrapper.find("video").width() > PC_SCREEN_WIDTH ? "_pc" : "";
            let randomAnim = "anim" + randomNum + isPcAnim;
            let $floatingImg = $originImg.clone().css("display", "block");
            wrapper.append($floatingImg);
            wrapper.find(`.icon-${e}`).css("position", "absolute");
            if (this.iconInteraction.rank_2.length && this.iconInteraction.rank_3.length) {
              wrapper.find(`.icon-left`).css("left", "30%");
              wrapper.find(`.icon-right`).css("left", "60%");
            } 
            wrapper.find(`.icon-${e}`).css("top", videoHeight + "px");
            $floatingImg.css(
              "animation",
              "2s ease-in-out " + randomAnim + " normal infinite"
            ).css("width","120px").css("height","120px");
            setInterval(() => {
              $floatingImg.remove();
            }, 2000);
          }, 200 * index);
        }
      }
    },
  },
  mounted() {
    this.setHeightVideo();
    this.settingDefault();
    this.onPlayVideo();
    this.onPauseVideo();
    this.checkVideoEnded();
    if (this.videoRegister.video_url_type == this.videoUrlUpload) {
      this.initVideo();
    } else {
      this.videoYoutube();
    }
  },
  created() {
    this.videoFile = this.$route.params.video_file;
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/_add.scss";
.video-js {
  // min-height: 355px !important;
  height: 100%;
  width: 100%;
}
.video-detail {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .video-icon {
    display: flex;
    position: absolute;
    z-index: 2;
    cursor: pointer;
  }
}
.wrapper-btn {
  position: fixed;
  bottom: 0;
  right: 20px;
}

video::-webkit-media-controls-fullscreen-button {
  display: none;
}
.content {
  height: 70vh;
  .video {
    .video-detail {
      height: 100%;
      .video-react-youtube {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
