<template>
  <div class="row top" v-if="!$store.getters.isLoadingApi">
    <div class="col-9 mr-0">
      <div class="flex items-center">
        <div class="font-bold title-page">比較レポート</div>
        <div class="btn-download"
             @click="downloadManual()">
          <i class="icon icon-small-download mr-1"></i>
          <span>マニュアルダウンロード</span>
        </div>
      </div>
      <interaction-question-list v-show="isLoaded" :interactionQuestions=interactionQuestions
                                 @selectInteractionQuestion="selectedInteractionQuestion"
                                 :selected=interactionQuestionsSelected.id>
      </interaction-question-list>
      <div class="video-report-area mt-4" v-show="isLoaded">
        <summary-chart
            :videos="videos"
            @selectVideo="onSelectVideo"
            :dataReport="dataReport"
            :dataTrendWord="dataTrendWord"
            :checkedProject="checkedProject"
            @initCheckedProject="initCheckedProject"
            :buyerId="clientIdSelected"
            :questionSelected="interactionQuestionsSelected"
            :summaryAvg="summaryAvg"
            :labels="labels"
            :labelsSelected="labelsSelected"
            :videoIds="selectedVideoIdsFomHome"
        ></summary-chart>
      </div>
      <word-cloud-table
          :dataVideoTrend="dataVideoTrend"
      ></word-cloud-table>
    </div>
    <div class="col-4 detail-area" v-show="isLoaded">
      <right-side
          :videos="checkedVideos"
          :fromRouter="'top'"
          :firstVideo="firstVideo"
          :interactionQuestion="interactionQuestionsSelected"
      ></right-side>
    </div>
    <div class="background" :style="styleVideoBackground" v-if="!isLoaded && $store.getters.isLoadingApi">
      <div class="loader"></div>
    </div>
  </div>
  <div class="background" :style="styleVideoBackground" v-else>
    <div class="loader"></div>
  </div>
</template>

<script>
import SummaryChart from "@/components/Top/CompareStep3/SummaryChart.vue";
import RightSide from "@/components/Top/CompareStep3/RightSide.vue";
import InteractionQuestionList from "@/components/Top/CompareStep3/InteractionQuestionList";
import WordCloudTable from "@/components/Top/CompareStep3/WordCloudTable";
import {
  listLabels,
  listVideoPromise,
  reportAvgStep3,
  reportTopStep3,
  reportTrendWord,
  reportVideoTrend,
} from "@/commons/api_service";
import EventBus from "@/commons/event_bus";
import {getUrlQuery} from "@/commons/helpers"
import {LIST_QUESTION_INTERACTION} from "@/commons/constants";

export default {
  components: {
    InteractionQuestionList,
    SummaryChart,
    RightSide,
    WordCloudTable,
  },
  name: "TopCompareStep3",
  props: {},
  data() {
    return {
      interactionQuestions: LIST_QUESTION_INTERACTION,
      interactionQuestionsSelected: LIST_QUESTION_INTERACTION[0],
      currentUser: {},
      videos: [],
      dataReport: {
        report_data: []
      },
      summaryAvg: {
        id: "avg",
        data: [],
      },
      checkedVideos: [],
      firstVideo: {},
      styleVideoBackground: {
        width: 'calc(100% - 66px)',
        height: '100%',
        top: '0px',
        position: 'fixed',
        display: 'flex',
        right: 0
      },
      isLoaded: false,
      disabled: false,
      is_stack_bar: 0,
      checkedProject: false,
      clientIdSelected: null,
      labels: [],
      labelsSelected: [],
      selectedVideoIdsFomHome: null,
      dataTrendWord: [],
      dataVideoTrend: [],
    };
  },
  computed: {},
  watch: {
    checkedVideos : {
      handler: function () {
        this.updateAvgVideoReport()
      },
      deep: true,
    }
  },
  methods: {
    initCheckedProject() {
      this.checkedProject = true;
    },

    onSelectVideo(video) {
      if (video.checked) {
        this.checkedVideos.push(video);
      } else {
        this.checkedVideos = this.checkedVideos.filter(
            (item) => item.id !== video.id
        );
      }
    },
    resetCheckedVideo() {
      this.checkedVideos = [];
    },
    downloadManual() {
      if (!this.disabled) {
        this.disabled = true;
        let url = this.$router.resolve({name: "api.download.manual"}).href;
        let params = {
          user_id: this.currentUser.user_id
        }
        this.$http2.get(url, params, (data) => {
              let url = data.download_url;
              let label = url.substr(url.lastIndexOf("/") + 1);
              this.$http2.downloadPDF(url, label, () => {
                    this.disabled = false
                  },
                  () => {
                    this.disabled = false
                  });
            },
            () => {
              this.disabled = false
            }
        );
      }
    },
    initData() {
      let vm = this;
      vm.$store.commit('setLoadingApi', true)
      let query_videos = {
        buyer_id: vm.clientIdSelected,
        video_ids: vm.selectedVideoIdsFomHome,
        deliver_period_type: 3,
      };
      let query_report = {
        buyer_id: vm.clientIdSelected,
        video_ids: vm.selectedVideoIdsFomHome,
      };

      reportTopStep3(query_report, function (data) {
        vm.dataReport = data;
        vm.$set(vm.dataReport, 'report_data', data.report_data ? data.report_data : [])
        const listVideosPromise = listVideoPromise(query_videos)
        listVideosPromise.then((data) => {
          vm.videos = data;
          vm.firstVideo = data[0]
          vm.isLoaded = true;
        })

        reportTrendWord({ video_id: vm.selectedVideoIdsFomHome }, function (data) {
          vm.dataTrendWord = data.report_data;
        })

        reportVideoTrend({ video_ids: vm.selectedVideoIdsFomHome }, function (data) {
          vm.dataVideoTrend = data;
        })

        listLabels((labels) => {
          vm.labels = [...labels]
          vm.labels.forEach(item => {
            vm.$set(item, "checked", true)
          })
          vm.labelsSelected = [...vm.labels]
          vm.updateAvgReport()
        }, vm.clientIdSelected)
        vm.$store.commit('setLoadingApi', false)
      })
    },
    selectedInteractionQuestion(args) {
      this.interactionQuestionsSelected = args.question
      this.updateAvgReport()
      this.updateAvgVideoReport()
    },
    updateAvgReport() {
      let vm = this;
      let queryReportAvg = {
        buyer_id: vm.clientIdSelected,
        question_id: vm.interactionQuestionsSelected.id,
        label_ids: vm.labelsSelected.map(item => item.id).toString()
      };

      const reportAvg = reportAvgStep3(queryReportAvg)
      reportAvg.then((data) => {
        if(data) {
          vm.$set(vm.summaryAvg, "data", Object.values(data.norm_data))
        }
      })
    },
    updateLabelSelected(data) {
      this.labelsSelected = [...data]
      this.updateAvgReport()
    },
    updateAvgVideoReport() {
      if (this.checkedVideos.length && this.dataReport.report_data.length && this.interactionQuestionsSelected) {
        for(let key in this.checkedVideos) {
          let avg_report = this.dataReport.report_data.filter(item => item.video_id == this.checkedVideos[key].id)[0]
          if(avg_report) {
            let avg_question = avg_report.average_report.filter(item => item.question_id == this.interactionQuestionsSelected.id)[0]
            this.checkedVideos[key].avg_report = avg_question.value
          } else {
            this.checkedVideos[key].avg_report = null
          }
        }
      }
    }
  },
  mounted() {

  },
  created() {
    let vm = this;
    this.currentUser = this.$store.getters.currentUser;
    let {video_ids, buyer_id} = getUrlQuery();
    this.selectedVideoIdsFomHome = video_ids
    this.clientIdSelected = Number(buyer_id)
    this.initData();
    EventBus.$on("resetVideos", this.resetCheckedVideo);
    EventBus.$on("updateLabelSelected", (data) => {
      vm.updateLabelSelected(data);
    });
  },
  beforeDestroy() {
    EventBus.$off("resetVideos");
    EventBus.$off("updateLabelSelected");
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/_variable.scss";

.top::v-deep {
  @import "@/styles/_top.scss";
  padding-top: 12px;

  &.top-before {
    margin: -20px;
    display: block;
    height: 100vh;
    @media screen and (min-width: 992px) {
      display: flex;
    }
  }
}

.title-page {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  color: $black;
}

.video-report-area {
  margin-bottom: 40px;
}

.btn-download {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 12px;
  max-height: 28px;
  background-color: #FFFFFF;
  border: 1px solid #49AB94;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 20px;

  &:hover {
    opacity: 0.75;
  }

  span {
    color: #49AB94;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 16px;
  }

  .icon-action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background-color: #EDF7F4;
    border-radius: 50%;

    div:first-child {
      width: 4px;
      height: 8px;
      background-color: #49AB94;
      border-radius: 1px;
    }

    div:last-child {
      width: 1px;
      height: 6px;
      margin-left: 1px;
      border: 1px solid #49AB94;
      border-radius: 1px;
    }
  }
}

.cloud-tag-title {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  color: $black;
  margin-bottom: 15px;
}

.button-download-pdf {
  color: #fff;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  width: 308px;
  height: 40px;
  background-color: #49ab94;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 40px;
}
</style>
<style type='text/css'>

.background .loader {
  border: 3px solid #49ab94;
  border-radius: 50%;
  border-top: 2px solid #ffffff;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  margin: auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
