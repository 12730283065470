<template>
  <div class="top tableau-top" v-if="!$store.getters.isLoadingApi" id="top">
    <div class="pt-3" v-if="currentUser.role == 'admin' && !loadingApi">
      <div class="d-flex align-items-center">
        <client-list
            :clients="clients"
            @select="onChangeClient"
            :selected="clientIdSelected"
        ></client-list>
      </div>
    </div>
    <template v-if="isTargetAvailable && !loadingApi && !loadingTableau">
      <div class="tableau-content" v-if="tableauToken && tableauData">
        <div class="list-enquete">
          <div class="enquete-title">{{ $t('texts.target.enquete-title') }}</div>
          <div class="form-group">
            <div
                class="form-radio-box mr-5"
                v-for="(item, key) in tableauData"
                :key="'enquete-' + key"
            >
              <input
                  class="form-radio-input"
                  type="radio"
                  :value="item.enquete_id"
                  v-model="enquete_id"
                  :id="'enquete-' + item.enquete_id"
              />
              <span class="btn-radio"></span>
              <label class="label-radio" :for="'enquete-' + item.enquete_id">{{ item.enquete_name }}</label>
            </div>
          </div>
        </div>
        <div class="data-content"
          v-if="tableauToken && tableauDataSelected"
        >
          <tableau-viz
              :src="tableauDataSelected.url"
              :token="tableauToken"
              id="tableau-viz"
              :device="currentTableauDevice"
              toolbar="bottom"
          ></tableau-viz>
        </div>
      </div>
    </template>
    <template v-else-if="!isTargetAvailable && !loadingApi">
      <download-page></download-page>
    </template>
    <template v-else>
      <div class="background" :style="styleVideoBackground">
        <div class="loader"></div>
      </div>
    </template>
    <div class="guide-message" v-if="!isHideGuideMessage && currentAgent == listAgent.SAFARI">
      <img src="/images/svg/icon_waring.svg" alt="">
      <span>{{$t('texts.target.top_description')}}</span>
      <span class="button-close" @click="handleCloseGuide">閉じる</span>
    </div>
  </div>
  <div class="background" :style="styleVideoBackground" v-else>
      <div class="loader"></div>
  </div>
</template>
<script>
import ClientList from "@/components/Select/ClientList.vue";
import {
  getTableauData,
  getTableauToken,
  listBuyerMarket,
  listBuyersActive,
} from "@/commons/api_service";
import {listAgent, targetStatus} from "@/commons/constants";
import DownloadPage from "@/components/Tableau/DownloadPage.vue";
import store from "@/store";
import {getUserAgent} from "@/commons/helpers";

export default {
  components: {
    DownloadPage,
    ClientList,
  },
  name: "Tableau",
  props: {},
  data() {
    return {
      listAgent,
      clients: [],
      currentUser: {},
      clientIdSelected: null,
      tableauToken: null,
      tableauData: null,
      loadingApi: true,
      enquete_id: null,
      loadingTableau: true,
      currentTableauDevice: 'desktop'
    };
  },
  computed: {
    isTargetAvailable() {
      if (!this.clients.length) return true
      if (this.currentUser.role == 'admin') return true
      return !!(this.clients.length && this.clients.find(
          item => item.is_target == targetStatus.enabled
      ));
    },
    clientSelected() {
      return this.clients.find(item => item.id == this.clientIdSelected)
    },
    tableauDataSelected() {
      if (!this.tableauData) return null
      return this.tableauData.find(item => item.enquete_id == this.enquete_id)
    },
    isHideGuideMessage() {
      return store.getters.isHideGuideMessageTableau
    }
  },
  methods: {
    onChangeClient(data) {
      this.clientIdSelected = Number(data);
    },
    getTableauToken(buyer_id) {
      let vm = this;
      getTableauToken(buyer_id, function (data) {
        if (data && data.token) {
          vm.tableauToken = data.token
        }
      })
    },
    getTableauData(buyer_id) {
      let vm = this;
      getTableauData(buyer_id, function (data) {
        if (data && data.items) {
          vm.tableauData = data.items
          vm.enquete_id = data.items[0].enquete_id
        }
      })
    },
    getUserAgent() {
      this.currentAgent = getUserAgent()
    },
    handleCloseGuide() {
      store.commit('setHideGuideMessageTableau', true)
    },
    changeDeviceTableau() {
      const width = window.innerWidth;
      if (width <= 1610) {
        this.currentTableauDevice = 'tablet'
      }
    }
  },
  watch: {
    clientSelected(newVal) {
      if (newVal && newVal.is_target == targetStatus.enabled) {
        this.getTableauToken(this.clientIdSelected)
        this.getTableauData(this.clientIdSelected)
      } else {
        this.tableauToken = null
        this.tableauData = null
        this.enquete_id = null
      }
    },
    tableauToken(newval) {
      if (newval) {
        this.loadingTableau = false
      } else {
        this.loadingTableau = true
      }
    }
  },
  mounted() {
    this.getUserAgent()
    this.changeDeviceTableau()
  },
  created() {
    const currentUser = this.$store.getters.currentUser;
    this.currentUser = currentUser;
    if (currentUser && currentUser.role == "admin") {
      listBuyersActive((buyersActive) => {
        this.clients = buyersActive.filter(item => item.is_target == targetStatus.enabled);
        if (this.clients.length) {
          let clientSelected = this.clients[0];
          // get selected client id in top page
          const clientSelectedTop = this.clients.find(
              item => item.id == store.getters.buyerSelected
          );
          if (clientSelectedTop) {
            clientSelected = clientSelectedTop
          }
          if (clientSelected) {
            this.clientIdSelected = clientSelected.id;
          }
        }
        this.loadingApi = false
      }, true);
    } else {
      listBuyerMarket((buyersMarket) => {
        this.clients = buyersMarket.filter(item => item.id == this.currentUser.owner_id);
          this.clientIdSelected = this.currentUser.owner_id;
          this.loadingApi = false
      }, true);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables/_colors.scss";
@import "src/styles/variables/sidebar";
.top::v-deep {
  min-height: 100%;

  .client-name {
    color: #333333;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 39px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.tableau-content {
  display: inline-flex;
  flex-direction: column;
  padding-bottom: 20px;
  min-width: 1500px;
}

.list-enquete {
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: $light-green;
  padding: 30px;
  border-radius: 10px;
  box-sizing: border-box;
  margin-right: 15px;

  .enquete-title {
    color: $black;
    font-family: "Noto Sans JP";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  .form-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.data-content {
  width: auto;
  border: 1px solid $light-black;
  margin-right: 15px;
  display: inline-block;
  box-sizing: border-box;
}

.guide-message {
  position: fixed;
  bottom: 0;
  z-index: 1;
  border-top: 1px solid #EBEBEB;
  @include cal-left-with-sidebar;
  width: unset !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  gap: 10px;
  font-size: 15px;
  line-height: 24px;
  background-color: $light-red;
  border-top: 1px solid $red;

  .button-close {
    white-space: nowrap;
    color: $red;
    font-weight: 700;
    cursor: pointer;
  }
}

@media screen and (max-width: 1610px) {
  .tableau-content {
    width: 100%;
    min-width: unset;
  }
}

@media screen and (max-width: 1440px)  {
  .target-top {
    width: 80%;
  }

  .list-step {
    margin-top: 30px;

    .step-item {
      img {
        width: 220px;
      }
      .number {
        font-size: 52px;
      }
    }
  }
}
</style>
<style type='text/css'>

.background .loader {
  border: 3px solid #49ab94;
  border-radius: 50%;
  border-top: 2px solid #ffffff;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
