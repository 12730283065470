import jQuery from "jquery";
import {intentionLevels, manualTrafficStatus, videoIntentionStatus} from "@/commons/constants";
import i18n from "@/i18n";

export const intentionWordCloudModify = {
	props: {
		videos: Array,
		selectedIntention: Object,
		selectedLevel: Array,
		intentionReport: Array,
		intentionSecondReport: Object,
		intentionComment: Array,
		reportDataIsLoading: Boolean,
		commentDataIsLoading: Boolean,
		totalReactionFlg: Boolean,
		slider: String,
		isShowImageIntention: Boolean,
		intentionCount: Array,
		videoComments: Array,
	},
	watch: {
		slider() {
			this.setSlider();
		},
		videos() {
			this.$nextTick(() => {
				this.initSetSlider();
			});
		},
		selectedLevel() {
			this.updateContent()
		}
	},
	data() {
		return {
			scrollFlg: false,
			listIntentionLevelId: intentionLevels.map((level) => level.level),
		}
	},
	computed: {
		isOnlyComment() {
			const listVideoHasNoIntention = this.videos.filter((video) => {
				return video.is_intention != videoIntentionStatus.enabled;
			});

			return listVideoHasNoIntention.length === this.videos.length;
		},
		isOnlyReportIntention() {
			const listVideoHasReportIntention = this.videos.filter((video) => {
				return !video.has_cdai;
			});

			const hasReportIntention = this.videos.filter((video) => {
				return video.is_intention === videoIntentionStatus.enabled;
			});

			return listVideoHasReportIntention.length === this.videos.length && hasReportIntention.length > 0;
		},
	},
	methods: {
		gotoDetailVideo(video_id) {
			const top_detail_url = this.$router.resolve({
				name: 'top-detail',
				params: {
					video_id
				}
			});
			window.open(top_detail_url.href, '_blank');
		},
		initSetSlider() {
			const container = document.querySelector('.chart-inner');
			if (container) {
				const scrollDistance = container.scrollWidth - container.offsetWidth;
				this.scrollFlg = scrollDistance !== 0;
				this.$emit('setScrollFlg', this.scrollFlg)
			} else {
				setTimeout(() => {
					this.initSetSlider();
				});
			}
		},
		setSlider() {
			const container = document.querySelector('.chart-inner');
			const slider = document.querySelector('.intention-slider');
			if (container) {
				slider.addEventListener('input', () => {
					const scrollPercentage = parseInt(slider.value) / 99;
					container.scrollLeft = scrollPercentage * (container.scrollWidth - container.offsetWidth);
				});
			}
		},
		emptyListIframe() {
			const listIframe = jQuery('.word-cloud-iframe');
			if (!listIframe.length) {
				setTimeout(() => {
					this.emptyListIframe();
				});
			} else {
				listIframe.empty();
			}
		},
		isVisibleIframe(el) {
			if (!el.length) {
				return;
			}
			const vm = this;
			const container = jQuery('#chart-inner');
			const docViewTop = jQuery(window).scrollTop();
			const docViewBottom = docViewTop + jQuery(window).height();
			const elTop = el.offset().top;
			const elBottom = elTop + el.height();
			if ((elBottom <= docViewBottom) && (elTop >= docViewTop)) {
				const listIframe = jQuery('.word-cloud-iframe')
				if (!listIframe.length) {
					setTimeout(() => {
						vm.isVisibleIframe(el);
					});
				} else {
					listIframe.each(function () {
						if (vm.scrollFlg) {
							if (vm.checkVisibleHorizontallyIframe(container, jQuery(this))) {
								if (jQuery(this).find('iframe').length === 0) {
									jQuery(this).html(jQuery(this).attr('data-html'));
								}
							} else {
								jQuery(this).empty();
							}
						} else {
							if (jQuery(this).find('iframe').length === 0) {
								jQuery(this).html(jQuery(this).attr('data-html'));
							}
						}
					});
				}
			}
		},
		initHorizontallyIframe() {
			const vm = this;
			const container = jQuery('#chart-inner');
			if (!container.length) {
				setTimeout(() => {
					vm.initHorizontallyIframe();
				});
			} else {
				container.on('scroll', function () {
					jQuery('.word-cloud-iframe').each(function () {
						if (vm.checkVisibleHorizontallyIframe(container, jQuery(this))) {
							if (jQuery(this).find('iframe').length === 0) {
								jQuery(this).html(jQuery(this).attr('data-html'));
							}
						} else {
							jQuery(this).empty();
						}
					});
					vm.updateVisibleIframe();
				});
			}
		},
		checkVisibleHorizontallyIframe(container, element) {
			const parent = element.closest('.interaction-chart');
			const docViewLeft = container.offset().left;
			const docViewRight = docViewLeft + container.width();
			const elemLeft = parent.offset().left;
			const elemRight = elemLeft + parent.width();

			return ((elemRight <= docViewRight) && (elemLeft >= docViewLeft));
		},
		initVisibleIframe() {
			const vm = this;
			jQuery('#wrapper-container').on('scroll', function () {
				vm.isVisibleIframe(jQuery('#content-check-bottom'));
			});
			vm.isVisibleIframe(jQuery('#content-check-bottom'));
			vm.initHorizontallyIframe();
			setTimeout(() => {
				jQuery(".word-cloud-iframe-empty iframe").remove();
			}, 200)
		},
		updateVisibleIframe() {
			const container = jQuery('#chart-inner');
			const listIframe = jQuery('.word-cloud-iframe');
			const docViewTop = jQuery(window).scrollTop();
			const docViewBottom = docViewTop + jQuery(window).height();
			const docViewLeft = container.offset().left;
			const docViewRight = docViewLeft + container.width();

			listIframe.each(function () {
				const parent = jQuery(this).closest('.interaction-chart');
				const elTop = parent.offset().top;
				const elBottom = elTop + parent.height();
				const elemLeft = parent.offset().left;
				const elemRight = elemLeft + parent.width();

				const isVisibleHorizontally = (elemRight <= docViewRight) && (elemLeft >= docViewLeft);
				const isVisibleVertically = (elBottom <= docViewBottom) && (elTop >= docViewTop);

				if (isVisibleHorizontally && isVisibleVertically) {
					if (jQuery(this).find('iframe').length === 0) {
						jQuery(this).html(jQuery(this).attr('data-html'));
					}
				} else {
					jQuery(this).empty();
				}
			});
		},
		updateContent() {
			this.$nextTick(() => {
				let element = jQuery('#wrapper-container');
				let currentScrollPosition = element.scrollTop();
				element.scrollTop(currentScrollPosition + 1);
			});
		}
	},
	mounted() {
		const vm = this;
		window.addEventListener('resize', () => {
			vm.initSetSlider();
		});
		this.initSetSlider();
		this.emptyListIframe();
		this.initVisibleIframe();
		this.updateContent()
	},
};

export const segmentAgeModify = {
	data() {
		const segmentAgeIdString = process.env.VUE_APP_LIST_AGE_ATTRIBUTE_ID
		const segmentGenderIdString = process.env.VUE_APP_GENDER_ATTRIBUTE_ID
		const listIdAgeAttribute = segmentAgeIdString ?
			segmentAgeIdString.split(",").map(item => parseInt(item)) : null;

		const idGenderAttribute = segmentGenderIdString ? parseInt(segmentGenderIdString): null;

		return {
			listIdAgeAttribute: listIdAgeAttribute,
			idGenderAttribute: idGenderAttribute,
			attributeAges: null,
			errorSelectAttribute: null
		}
	},
	methods: {
		toggleAgeAttribute() {
			let vm = this;
			if (!this.listIdAgeAttribute ) return;
			const currentAgeAttribute = this.segmentSelected.find(item => vm.listIdAgeAttribute.includes(item.id))
			if (currentAgeAttribute) {
				const categoryId = currentAgeAttribute.category
				const attributeCategory = this.categories.find(item => item.id = categoryId)
				this.attributeAges = attributeCategory.attributes
				// reset disable state for all attribute
				attributeCategory.attributes.map(item => item.is_disabled = false)
				// find another attribute to disable
				let anotherAttribute = attributeCategory.attributes.find(function (item) {
					return item.id != currentAgeAttribute.id && vm.listIdAgeAttribute.includes(item.id)
				})

				if (anotherAttribute) {
					anotherAttribute.is_disabled = true
				}
			} else {
				if (this.attributeAges) {
					this.attributeAges.map(item => item.is_disabled = false)
				}
			}
		},
		checkHasErrorSelectAttribute() {
			this.errorSelectAttribute = null
			if (!this.segmentSelected.length || this.video.is_admin_change == manualTrafficStatus.ON.value) {
				return false
			}
			const listAgeSegment = this.segmentSelected.filter(item => this.listIdAgeAttribute.includes(item.id))
			const genderSegment = this.segmentSelected.filter(item => item.id == this.idGenderAttribute)

			if (listAgeSegment.length && !genderSegment.length) {
				this.errorSelectAttribute = i18n.t('messages.missing_gender_attribute')
				return true
			} else if (!listAgeSegment.length && genderSegment.length) {
				this.errorSelectAttribute = i18n.t('messages.missing_age_attribute')
				return true
			} else if (!listAgeSegment.length && !genderSegment.length) {
				this.errorSelectAttribute = i18n.t('messages.missing_age_and_gender_attribute')
				return true
			}

			return false
		}
	}
}

export const intentionFunction = {
	data() {
		return {
			// for intention
			intentionList: [],
			intentionData: {
				intention_items_selected: [],
				intention_items_checked: [],
				select_data: [],
			},
		}
	},
	computed: {
		videoIntentionIds: function () {
			return this.intentionData.intention_items_checked.map(item => item.id);
		},
		intentionListToSelect: function () {
			let intentionList = [{id: 0, name: this.$t('texts.video.setting_intention_placeholder'), index: -1}]
			const selectedIds = new Set(this.intentionData.intention_items_selected.map(selected => selected.id));
			return intentionList.concat(this.intentionList.filter(item => !selectedIds.has(item.id)));
		},
	},
	methods: {
		checkedIntention(id) {
			if (this.intentionData.intention_items_checked.find(item => item.id == id)) {
				this.intentionData.intention_items_checked.splice(this.intentionData.intention_items_checked.findIndex(item => item.id == id), 1)
			} else {
				const item = this.intentionList.find(item => item.id == id)
				if (item) {
					this.intentionData.intention_items_checked.push(item)
				}
			}
		},
		selectIntention(args) {
			if (!this.intentionData.intention_items_selected.find(item => Number(item.id) === Number(args.id))) {
				// update id_selected
				this.intentionData.select_data[args.index].id_selected = Number(args.id)
				// update intention_items_selected
				const listIdSelected = this.intentionData.select_data.map(item => item.id_selected)
				let dataSelected = JSON.parse(JSON.stringify(this.intentionList.filter(item => listIdSelected.includes(item.id))))
				dataSelected.sort((a, b) => a.index - b.index)
				this.intentionData.intention_items_selected = dataSelected
				// update intention_items in intentionData
				this.intentionData.select_data.forEach(item => {
					const id_selected = item.id_selected
					// check option is default or not, also keep memory address in select2
					let dataIntention = Number(id_selected) !== 0 ? item.intention_items.filter(item => Number(item.id) === Number(id_selected)) : []
					const intentionToSelect = JSON.parse(JSON.stringify(this.intentionListToSelect))
					dataIntention = dataIntention.concat(intentionToSelect)
					// sort intention_items
					dataIntention.sort((a, b) => a.index - b.index)
					item.intention_items = dataIntention
				})

				// update intention_items_checked, remove item not in intention_items_selected
				this.intentionData.intention_items_checked = this.intentionData.intention_items_checked.filter(item => listIdSelected.includes(item.id))

			}
		},
		addIntention() {
			const intentionToSelect = JSON.parse(JSON.stringify(this.intentionListToSelect))
			this.intentionData.select_data.push({
				id_selected: intentionToSelect[0].id,
				intention_items: intentionToSelect,
			})
		},
	}
}
