<template>
  <div>
    <highcharts
        :id="'chart-column-' + id"
        class="mt-2"
        :options="chartOptions"
        ref="columnChart"
        :constructor-type="'chart'"
    ></highcharts>
  </div>
</template>
<script>
import {Chart} from "highcharts-vue";
import {lineChartOption} from "@/commons/charts";

export default {
  props: {
    currentDateIndex: Number,
    dataReport: Object,
    id: Number,
  },
  components: {
    highcharts: Chart,
  },
  watch: {
    currentDateIndex() {
      this.initSeriesData();
    }
  },
  computed: {},
  data() {
    let chartOptions = JSON.parse(JSON.stringify(lineChartOption));

    chartOptions = Object.assign(chartOptions, {
      chart: {
        type: "column",
        marginRight: 20,
        height: "100%",
      },
      tooltip: {
        useHTML: true,
        enabled: true,
        backgroundColor: "#ebebeb",
        borderRadius: "8",
        borderColor: "transparent",
        shadow: false,
        style: {
          color: "#49ab94",
          fontSize: "10px",
          lineHeight: "16px",
          fontFamily: "Noto Sans JP",
          fontWeight: "400"
        },
        hideDelay: 0,
        formatter() {
          return `<span>&nbsp;${Math.round(this.y * 100) / 100}%</span>`;
        },
      },
      legend: {
        enabled: false
      },
      title: {
        text: ""
      },
      xAxis: {
        title: {
          text: "<span class='title-x-chart'>Day</span>",
          align: "high",
          offset: 0,
          x: 20,
          y: 8.5,
          useHTML: true,
          style: {
            color: "#999999",
            fontSize: window.innerWidth >= 1400 ? "11px" : "8px",
            fontFamily: "Avenir Next",
          }
        },
        minPadding: 0,
        categories: ["1d", "2d", "3d", "4d", "5d", "6d", "7d"],
        lineWidth: 0,
        min: 0.25,
        max: 6,
        labels: {
          style: {
            color: "#999999",
            fontSize: window.innerWidth >= 1400 ? "11px" : "8px",
            fontFamily: "Avenir Next",
          }
        },
      },
      yAxis: {
        lineWidth: 0,
        gridLineWidth: 0,
        tickPixelInterval: 50,
        tickInterval: 20,
        title: {
          text: ""
        },
        max: 100,
        labels: {
          formatter: function () {
            if (this.value != 0) {
              return this.value + '%';
            }
          },
          style: {
            color: "#999999",
            fontSize: window.innerWidth >= 1400 ? "11px" : "8px",
            fontFamily: "Avenir Next",
          },
        }
      },
      plotOptions: {
        column: {
          stacking: "normal",
          pointPadding: 0,
          borderWidth: 0,
          pointWidth: window.innerWidth >= 1400 ? 20 : 12,
          states: {
            hover: {
              enabled:false
            }
          }
        },
        series: {
          states: {
            inactive: {
              opacity: 1
            }
          }
        }
      },
    })

    return {
      chart: null,
      chartOptions: {
        ...chartOptions,
      },
      listColorLevelChart: [
        "#DBEEEA",
        "#B6DDD4",
        "#92CDBF",
        "#6EBCA9",
        "#49AB94",
      ]
    };
  },
  methods: {
    initSeriesData() {
      this.chartOptions.series = JSON.parse(JSON.stringify(this.formatDataWithDateIndex(this.currentDateIndex, this.dataReport.report)));
      this.chartOptions.xAxis.categories = this.formatLabelWithDateIndex(this.currentDateIndex)
    },
    formatDataWithDateIndex(index, data) {
      let new_data = [];
      for (let i = 0; i <= 6; i++) {
          new_data[i] = data[index + i]
      }

      let new_data_format = []
      for (let j = 1; j <= 5; j++) {
        new_data_format[j - 1] = {
          data: [
            new_data[0] !== undefined ? new_data[0][j] : 0,
            new_data[1] !== undefined ? new_data[1][j] : 0,
            new_data[2] !== undefined ? new_data[2][j] : 0,
            new_data[3] !== undefined ? new_data[3][j] : 0,
            new_data[4] !== undefined ? new_data[4][j] : 0,
            new_data[5] !== undefined ? new_data[5][j] : 0,
            new_data[6] !== undefined ? new_data[6][j] : 0,
          ],
          color: this.listColorLevelChart[j - 1]
        }
      }
      return new_data_format;
    },
    formatLabelWithDateIndex(index) {
      let new_label = [];
      for (let i = 0; i < 7; i++) {
        new_label[i] = (index + i + 1) + 'd'
      }

      return new_label;
    }
  },
  mounted() {
    this.chart = this.$refs.columnChart.chart;
    this.chart.redraw();
  },
  created() {
    this.initSeriesData();
  },
};
</script>
<style lang="scss">
</style>