
const stepOptionTopPage = [{ step: 1, second: 8 }, { step: 2, second: 16 }, { step: 4, second: 32 }, { step: 8, second: 64 }, { step: 15, second: 120 }]
const chartType = [{ id: 1, type: 'total_interaction' }, { id: 2, type: 'each_interaction' }];
// const stepOptionTopDetailPage = [{ step: 1, second: 15 }, { step: 2, second: 30 }, { step: 4, second: 60 }, { step : 8, second : 120}]
const stepOptionTopDetailPage = [{ step: 1, second: 15 }, { step: 2, second: 30 }, { step: 4, second: 60 }]
const stepOptionTopDetailPageTopDetail = [{ step: 1, second: 8 }, { step: 2, second: 16 }, { step: 4, second: 32 }, { step: 8, second: 64 }]
const videoTimeRange = [{id : 'avg',value : 0},{id : 'avg-0-15',value : 1},{ id : 'avg-16-30',value : 2},{id : 'avg-31-60',value : 3},{id : 'avg-61',value : 4}]
const formatDataChartWithStep = (dataSeries) => {
    let data = []
    if (dataSeries.length > 0) {
        let newData = dataSeries.map((e, i) => ({ x: i, y: e }))
        for (let i = 0; i < newData.length; i++) {
            data.push(newData[i])
        }
    }
    return data;

}

const formatDataRadarChart = (dataSeries, colors) => {
    let data = []
    if (dataSeries.length > 0) {
        data = dataSeries.map((e, index) => ({ color: colors[index], y: e }))
    }
    return data;

}


const getXColumnsByStep = (step, xMin = 0, number_column = 8) => {
    let columns = [];
    for (let i = 0; i <= number_column; i++) {
        columns.push(xMin + i * step);
    }
return columns;
}


const getReactionTagTooltipEl = (project, data, eachReaction = false) => {
    let { left, right } = data;
    if (typeof right == 'number') {
        right = Math.abs(right) >= 1.0e+6 ? (Math.abs(right) / 1.0e+6) + "M" : Math.abs(right)
    }
    if (typeof left == 'number') {
        left = Math.abs(left) >= 1.0e+6 ? (Math.abs(left) / 1.0e+6) + "M" : Math.abs(left)
    }
    let tags = {
        left: null,
        right: null
    }
    let mtClass = "mt-1"
    if (eachReaction) {
        mtClass = ""
    }
    let color_rank_2 = project.interaction_btn_1 && Object.keys(project.interaction_btn_1).length ? project.interaction_btn_1.color : '';
    let color_rank_3 = project.interaction_btn_2 && Object.keys(project.interaction_btn_2).length ? project.interaction_btn_2.color : ''
    let interactionColor =  {
        rank_2 : color_rank_2 == 1 ? 'tag-primary' : color_rank_2 == 2 ? 'tag-nattoku' : color_rank_2 == 3 ? 'tag-danger' : color_rank_2 == 4 ? 'tag-suki' : '',
        rank_3 : color_rank_3 == 1 ? 'tag-primary' : color_rank_3 == 2 ? 'tag-nattoku' : color_rank_3 == 3 ? 'tag-danger' : color_rank_3 == 4 ? 'tag-suki' : '',
    }
    let interactionIcon = {
        rank_2 : project.interaction_btn_1 && Object.keys(project.interaction_btn_1).length ? project.interaction_btn_1.btn_icon_url : '',
        rank_3 : project.interaction_btn_2 && Object.keys(project.interaction_btn_2).length ? project.interaction_btn_2.btn_icon_url : ''
    }
    
    tags.left = interactionIcon.rank_2 ? 
        '<div class="tag-number ' + interactionColor.rank_2 +' tooltip w-100"><img style : "width : 12px" src="' +interactionIcon.rank_2 +'"/><div class="">' + left + '</div></div>' :
        '' ;
    tags.right = interactionIcon.rank_3 ?
        '<div class="tag-number ' + interactionColor.rank_3 +' tooltip w-100 ' + mtClass + '"><img style : "width : 12px" src="' +interactionIcon.rank_3 +'"/><div class="">' + right + '</div></div>' :
        '' ;
    return tags;

}

const getReactionTagTooltipElStep1 = (project, data, labelChart, eachReaction = false) => {
    let interactionIcon = {}
    let tags = {}
    if(labelChart && Object.keys(labelChart).length) {
        for(let i = 0; i < Object.keys(labelChart).length; i++){
            if (typeof data['btn' + (i + 1)] == 'number') {
                data['btn' + (i + 1)] = Math.abs(data['btn' + (i + 1)]) >= 1.0e+6 ? (Math.abs(data['btn' + (i + 1)]) / 1.0e+6) + "M" : Math.abs(data['btn' + (i + 1)])
            }
            interactionIcon[`btn${(i + 1)}`] = labelChart[`btn${(i + 1)}`]?.btn_icon_url ?? ""
        }

        let mtClass = "mt-1"
        if (eachReaction) {
            mtClass = ""
        }

        for(let i = 0; i < Object.keys(labelChart).length; i++){
            tags['btn' + (i + 1)] = '<div style="background-color: ' + labelChart[`btn${(i + 1)}`].color + ' " class="tag-number ' +' tooltip w-100 ' + mtClass + '"><img  src="' +interactionIcon[`btn${i+1}`] +'"/><div>' + data['btn' + (i + 1)] + '</div></div>'
        }
    }

    return tags;
}


const getImageTooltipEl = (imageURL) => {
    return '<div style="border-radius: 8px; margin-bottom:0" ><img style="width: 100%" src="' + imageURL + '" height="82" width="122"/></div>'
}

const getTooltipLineChart = (imageEl, reactionTagsEl) => {
    return '<div style="width: 69px; display: flex; flex-direction: column" class="tootlip-line">' +
      imageEl +
      reactionTagsEl.left +
      reactionTagsEl.right +
      "</div>";
}

const getTooltipLineChartStep1 = (imageEl, reactionTagsEl) => {
    return '<div style="width: 69px; display: flex; flex-direction: column" class="tootlip-line">' +
      imageEl +
      reactionTagsEl?.btn1 +
      reactionTagsEl?.btn2 +
      reactionTagsEl?.btn3 +
      "</div>";
}

const getTooltipTopLineChart = (imageEl, reactionTagsEl) => {
    return '<div style="width: 69px; display: flex; flex-direction: column" class="tootlip-line">' +
      imageEl +
      reactionTagsEl +
      "</div>";
}

const getTooltipBottomLineChart = (reactionTagsEl) => {
    return '<div style="width: 69px; display: flex; flex-direction: column" class="tootlip-line">' +
      reactionTagsEl+
      "</div>";
}
/**
 * reaction mapping
 * @param {*} interaction_type 
 * @returns 
 */
const getInteractionLabel = (interaction_type) => {
    return interaction_type == 0
        ? { left: "ナットク", right: "スキ" }
        : { left: "Good", right: "Bad" };
}

const roundDecimal = (number, decimals) => {
    return +(Math.round(number + "e+" + decimals) + "e-" + decimals);
};

const formatDataChartWithStepBarChart = (dataSeries, step) => {
    let data = []
    let newData = dataSeries.map((e, i) => ({ x: i, y: e }))

    switch(step) {
        case 0:
            for (let i = 0; i < newData.length; i++) {
                if (i % step == 0) {
                    data.push(newData[i])
                }
            }
          break;
        case 2:
            for (let i = 0; i < newData.length; i++) {
                if (i % step == 0) {
                    let dataYFormat = 0;
                    if (newData[i+1] != undefined) {
                        dataYFormat = newData[i+1]['y']
                    }
                    newData[i]['y'] = newData[i]['y'] + dataYFormat
                    newData[i]['y'] = roundDecimal(newData[i]['y'],1)
                    data.push(newData[i])
                }
            }
          break;
        case 4:
            for (let i = 0; i < newData.length; i++) {
                if (i % step == 0) {
                    let dataYFormat1 = 0;
                    let dataYFormat2 = 0;
                    let dataYFormat3 = 0;
                    if (newData[i+1] != undefined) {
                        dataYFormat1 = newData[i+1]['y']
                    }
                    if (newData[i+2] != undefined) {
                        dataYFormat2 = newData[i+2]['y']
                    }
                    if (newData[i+3] != undefined) {
                        dataYFormat3 = newData[i+3]['y']
                    }
                    newData[i]['y'] = newData[i]['y'] + dataYFormat1 + dataYFormat2 + dataYFormat3
                    newData[i]['y'] = roundDecimal(newData[i]['y'],1)
                    data.push(newData[i])
                }
            }
            break;
        case 8:
            for (let i = 0; i < newData.length; i++) {
                if (i % step == 0) {
                    let dataYFormat1 = 0;
                    let dataYFormat2 = 0;
                    let dataYFormat3 = 0;
                    let dataYFormat4 = 0;
                    let dataYFormat5 = 0;
                    let dataYFormat6 = 0;
                    let dataYFormat7 = 0;
                    if (newData[i+1] != undefined) {
                        dataYFormat1 = newData[i+1]['y']
                    }
                    if (newData[i+2] != undefined) {
                        dataYFormat2 = newData[i+2]['y']
                    }
                    if (newData[i+3] != undefined) {
                        dataYFormat3 = newData[i+3]['y']
                    }
                    if (newData[i+4] != undefined) {
                        dataYFormat4 = newData[i+4]['y']
                    }
                    if (newData[i+5] != undefined) {
                        dataYFormat5 = newData[i+5]['y']
                    }
                    if (newData[i+6] != undefined) {
                        dataYFormat6 = newData[i+6]['y']
                    }
                    if (newData[i+7] != undefined) {
                        dataYFormat7 = newData[i+7]['y']
                    }
                    newData[i]['y'] = newData[i]['y'] + dataYFormat1 + dataYFormat2 + dataYFormat3 + dataYFormat4 + dataYFormat5 + dataYFormat6 + dataYFormat7
                    newData[i]['y'] = roundDecimal(newData[i]['y'],1)
                    data.push(newData[i])
                }
            }
            break;
        default:
            for (let i = 0; i < newData.length; i++) {
                if (i % step == 0) {
                    data.push(newData[i])
                }
            }
    }
    return data;
}


const formatDataChartWithStepBarChartStep1 = (dataSeries, step) => {
    let data = []
    if(dataSeries.length){
        for (let i = 0; i < dataSeries.length * 2; i++){
            if(i % 2 == 1){
                let total = 0;
                switch(step){
                    case 1: {
                        total += dataSeries[i - (i + 1)/2] ? Math.abs(dataSeries[i - (i + 1)/2]): 0;
                        data.push({x: i, y: total })
                        break;
                    }
                    case 2: {
                        total += dataSeries[i - 1] ? Math.abs(dataSeries[i - 1]): 0;
                        total += dataSeries[i] ? Math.abs(dataSeries[i]): 0;
                        data.push({x: i, y: total })
                        break;
                    }
                    case 4: {
                        total += dataSeries[2*i + 1] ? Math.abs(dataSeries[2*i + 1]): 0;
                        total += dataSeries[2*i] ? Math.abs(dataSeries[2*i]): 0;
                        total += dataSeries[2*i - 1] ? Math.abs(dataSeries[2*i - 1]): 0;
                        total += dataSeries[2*i - 2] ? Math.abs(dataSeries[2*i - 2]): 0;
                        data.push({x: i, y: total })
                        break;
                    }
                    case 8: {
                        total += dataSeries[4*i + 3] ? Math.abs(dataSeries[4*i + 3]): 0;
                        total += dataSeries[4*i + 2] ? Math.abs(dataSeries[4*i + 2]): 0;
                        total += dataSeries[4*i + 1] ? Math.abs(dataSeries[4*i + 1]): 0;
                        total += dataSeries[4*i] ? Math.abs(dataSeries[4*i]): 0;
                        total += dataSeries[4*i - 1] ? Math.abs(dataSeries[4*i - 1]): 0;
                        total += dataSeries[4*i - 2] ? Math.abs(dataSeries[4*i - 2]): 0;
                        total += dataSeries[4*i - 3] ? Math.abs(dataSeries[4*i - 3]): 0;
                        total += dataSeries[4*i - 4] ? Math.abs(dataSeries[4*i - 4]): 0;
                        data.push({x: i, y: total })
                        break;
                    }
                }
            }
            else data.push({x: i, y: 0})
        }
    }
    return data;
}

/**
 * config common of linechart
 */
const lineChartOption = {
    tooltip: {
        enabled: false,
    },
    title: {
        text: "",
    },
    colors: ["#49AB94"],
    yAxis: {
        title: "",
        gridLineWidth: 0,
        min: 0,
        // max: 10,
        labels: {
            step: 1,
            style: {
              color: '#999999',
              fontSize: '8px',
              fontWeight: 'normal'
            }
        }
    },
    xAxis: {
        gridLineWidth: 1,
        lineWidth: 0,
        tickLength: 0,
        max: 8.5,
        min: 0,
        step: 1,
        tickInterval: 1,
        grid: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8],
            width: 1,
            marker: {
                states: {
                    hover: {
                        gridLineWidth: 2,
                        enabled: true,
                    },
                },
            },
        },
        labels: {
            style: {
              color: '#999999',
              fontSize: '8px',
              fontWeight: 'normal'
            }
        }

    },
    legend: {
        enabled: false,
    },
    plotOptions: {
        series: {
            clip: false,
        },
        line: {
            marker: {
                enabled: false,
                states: {
                    hover: {
                        enabled: false,
                    },
                },
            },
        },
    },
    credits: {
        enabled: false,
    },
    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 500,
                },
                chartOptions: {
                    legend: {
                        layout: "vertical",
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
            },
        ],
    },
}


/**
 * config common of linechart
 */
 const polarRadarChartOption = {
    chart: {
		polar: true,
        height: "390px",
        animation: false,
	},
    pane: {
        startAngle: 300
    },
    title: {
		text: "",
	},
    subtitle: {
		text: "",
	},
    yAxis: {
		visible: false,
        showLastLabel: true,
        endOnTick: false,
        reversedStacks: false
	},
    tooltip: {
        enabled: false
    },

    legend: {
        enabled: false,
    },
    credits: {
        enabled: false,
    },
    plotOptions: {
        series: {
            stickyTracking: false,
            marker: {
                symbol: "circle",
            },
            color: "rgba(51, 51, 51, 0.16)",
            fillOpacity: 0.16,
        },
    },
}

const formatSeriesDataSummaryAvg = (reportData, id) => {
    let data = [];
    let idName = "";
    if (id == videoTimeRange[0].id || !id) {
        if (reportData && reportData.total_detail_norm) {
            data = reportData.total_detail_norm.map(e => e.norm);
        } 
        idName = "avg";
    }
    else if (id == videoTimeRange[1].id) {
        if (reportData && reportData.total_0_15_detail_norm) {
            data = reportData.total_0_15_detail_norm.map(e => e.norm);
        } 
        idName = "avg-0-15";
    }
    else if (id == videoTimeRange[2].id) {
        if (reportData && reportData.total_16_30_detail_norm) {
            data = reportData.total_16_30_detail_norm.map(e => e.norm);
        } 
        idName = "avg-16-30";
    }
    else if (id == videoTimeRange[3].id) {
        if (reportData && reportData.total_31_60_detail_norm) {
            data = reportData.total_31_60_detail_norm.map(e => e.norm);
        } 
        idName = "avg-31-60";
    }
    else if (id == videoTimeRange[4].id) {
        if (reportData && reportData.total_61_detail_norm) {
            data = reportData.total_61_detail_norm.map(e => e.norm);
        } 
        idName = "avg-61";
    }
    return {
        id: idName,
        data: data,
    }

}

const formatSeriesDataSummaryAvgStep1 = (reportData, id) => {
    let data = [];
    let idName = "";
    if (id == videoTimeRange[0].id || !id) {
        if (reportData && reportData.total_avg) {
            for (let item in reportData.total_avg) {
                data.push(reportData.total_avg[item])
            }
        }
        idName = "avg";
    }
    else if (id == videoTimeRange[1].id) {
        if (reportData && reportData["0_15_avg_norm"]) {
            for (let item in reportData["0_15_avg_norm"]) {
                data.push(reportData["0_15_avg_norm"][item])
            }
        }
        idName = "avg-0-15";
    }
    else if (id == videoTimeRange[2].id) {
        if (reportData && reportData["16_30_avg_norm"]) {
            for (let item in reportData["16_30_avg_norm"]) {
                data.push(reportData["16_30_avg_norm"][item])
            }
        }
        idName = "avg-16-30";
    }
    else if (id == videoTimeRange[3].id) {
        if (reportData && reportData["31_60_avg_norm"]) {
            for (let item in reportData["31_60_avg_norm"]) {
                data.push(reportData["31_60_avg_norm"][item])
            }
        }
        idName = "avg-31-60";
    }
    else if (id == videoTimeRange[4].id) {
        if (reportData && reportData["61_avg_norm"]) {
            for (let item in reportData["61_avg_norm"]) {
                data.push(reportData["61_avg_norm"][item])
            }
        }
        idName = "avg-61";
    }
    return {
        id: idName,
        data: data,
    }
        
}

const formatSeriesDataSummaryVideo = (reportData, video) => {
    let rank_2 = video.interaction_btn_1 && Object.keys(video.interaction_btn_1).length ? true : false
    let rank_3 = video.interaction_btn_2 && Object.keys(video.interaction_btn_2).length ? true : false
    let data = [];
    if (reportData && reportData.tally_result) {
        if (rank_2 && rank_3) {
            data = reportData.tally_result.map(e => (e.left + Math.abs(e.right)));
        } else if (!rank_2 && rank_3) {
            data = reportData.tally_result.map(e => (Math.abs(e.right)));
        } else if (rank_2 && !rank_3) {
            data = reportData.tally_result.map(e => (e.left));
        } else {
            data = []
        }
    }
    return {
        id: 'video-' + video.id,
        data
    }
}

const formatSeriesDataSummaryVideoStep3 = (reportData, video, questionId) => {
    let data = [];
    if (reportData && Object.prototype.hasOwnProperty.call(reportData, "daily_change_report") && reportData.daily_change_report) {
        data = Object.values(reportData.daily_change_report.filter(data => data.question_id == questionId)[0].report)
    }

    return {
        id: 'video-' + video.id,
        data
    }
}

const formatSeriesDataSummaryVideoStep1 = (reportData, video) => {
    const NUMBER_BUTTON = 3;
    let data = [];
    if (reportData) {
        for (var i = 1; i <= NUMBER_BUTTON; i++)
        data.push(reportData[`${i}_interaction_total`])
    }
    return {
        id: 'video-' + video.id,
        data
    }
}

const getYMaxSummaryChart = (seriesData) => {
    let maxs = seriesData.map(e => Math.max(...e.data));
    let max = Math.max(...maxs);
    return max ? max : 10
}

const getYMaxSummaryEachInteractionChart = (seriesData) => {
    let maxs = seriesData.map(e => Math.max(...e.data.map(i => Math.max(i[1], i[2]))))
    let max = Math.max(...maxs);
    return max ? max : 10
}

const barChartOption = {
    tooltip:{
        enabled:false
    },
    title: {
      text: "",
    },
    xAxis: {
        grid: {
            columns: [0,1,2,3,4,5,6,7,8,10,11,12,13,14,15],
            width: 1,
            marker: {
                states: {
                    hover: {
                        gridLineWidth: 2,
                        enabled: true
                    }
                }
            }
        },
        title: {
            text: ''
        },
        tickLength: 0,
        tickInterval: 1,
        max: 15.4,
        step: 1,
        gridLineWidth : 0,
        lineWidth: 0,
        labels: {
            style: {
                color: '#999999',
                fontSize: '8px',
                fontWeight: 'normal'
            },
        }
    },
    yAxis: [{
        title:'y-left',
        // tickInterval: 40,
        labels: {
            style: {
                color: '#999999',
                fontSize: '8px',
                fontWeight: 'normal'
            }
        }
    },{
        title:'y-right',
        labels: {
            style: {
                color: '#999999',
                fontSize: '8px',
                fontWeight: 'normal'
            }
        },
        opposite: true
    }],
    legend: {
      enabled: false,
    },
    plotOptions: {
        column: {
            pointWidth: 24,
            cursor: 'pointer',
            groupPadding: window.innerWidth >= 1440 ? 0.2 : 1,
        },

    },
    credits: {
      enabled: false,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "vertical",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
}


/**
 * constant color of each interaction type
 */
const colorsReaction = {
    good: "#4976AB",
    bad: "#CE6060",
    suki: "#CF2F99",
    nattoku: "#E5BB26",
    purchase: "#E5BB26",
}
/**
 * get color of gradient in chart each interaction
 * @param {*} project
 * @param {*} low
 */
const getColorGrandient = (project, low) => {
    let colorLeft = project.interaction_btn_1 && Object.keys(project.interaction_btn_1).length ? project.interaction_btn_1.color : '';
    let colorRight = project.interaction_btn_2 && Object.keys(project.interaction_btn_2).length ? project.interaction_btn_2.color : '';
    let color1, color2;
    color1 = colorLeft == 1 ? colorsReaction.good : colorLeft == 2 ? colorsReaction.nattoku : colorLeft == 3 ? colorsReaction.bad : colorLeft == 4 ? colorsReaction.suki : '' ;
    color2 = colorRight == 1 ? colorsReaction.good : colorRight == 2 ? colorsReaction.nattoku : colorRight == 3 ? colorsReaction.bad : colorRight == 4 ? colorsReaction.suki : '';
    if (low == 'left') {
        return {
            color1 : color2, color2 : color1 
        }
    } else {
        return { 
            color1 : color1, color2 : color2
        }
    }
}

/**
 * format data to area range and line series data chart
 * @param {*} data 
 * @returns 
 */
const formatEachInteractionSeriesData = (data) => {
    let line1 = [],line2 = [],arearange1 = [], arearange2 = [];
    data.forEach(secondData => {
        if(secondData === null) return
        line1.push({x: secondData[0], y: secondData[1]});
        line2.push({x: secondData[0], y: secondData[2]});
        if (secondData[1] >= secondData[2]) {
            arearange1.push(secondData);
        } else {
            arearange2.push([secondData[0], secondData[2], secondData[1]])            
        }
    });
    let intersectionPoints = [];
    if(line1.length > 1) {
        for (let i = 1; i < line1.length; i++ ) {
            if ((line1[i].y <= line2[i].y && line1[i-1].y >= line2[i-1].y)
            || (line1[i].y >= line2[i].y && line1[i-1].y <= line2[i-1].y)) {
                let point = calculateIntersection(line1[i-1], line1[i], line2[i-1], line2[i]);
                if (point) {
                   
                    point = [Number(Number(Math.abs(point.x)).toFixed(2)), Math.abs(point.y), Math.abs(point.y)];
                    intersectionPoints.push(point)
                }
            }
        }
    }
    arearange1 = [...arearange1, ...intersectionPoints];
    arearange2 = [...arearange2, ...intersectionPoints];
    arearange1 = arearange1.sort(function(a, b) { return a[0] - b[0]; });
    arearange2 = arearange2.sort(function(a, b) { return a[0] - b[0]; });
    return {
        line1,
        line2,
        arearange1,
        arearange2
    }
}

const calculateIntersection = (p1, p2, p3, p4) => {
    // down part of intersection point formula
    var d1 = (p1.x - p2.x) * (p3.y - p4.y); // (x1 - x2) * (y3 - y4)
    var d2 = (p1.y - p2.y) * (p3.x - p4.x); // (y1 - y2) * (x3 - x4)
    var d  = (d1) - (d2);

    if (d == 0) {
      return null;
    }

    // upper part of intersection point formula
    var u1 = (p1.x * p2.y - p1.y * p2.x); // (x1 * y2 - y1 * x2)
    var u4 = (p3.x * p4.y - p3.y * p4.x); // (x3 * y4 - y3 * x4)
    
    var u2x = p3.x - p4.x; // (x3 - x4)
    var u3x = p1.x - p2.x; // (x1 - x2)
    var u2y = p3.y - p4.y; // (y3 - y4)
    var u3y = p1.y - p2.y; // (y1 - y2)

    // intersection point formula
    
    var px = (u1 * u2x - u3x * u4) / d;
    var py = (u1 * u2y - u3y * u4) / d;
    
    var p = { x: px, y: py };

    return p;
}

const stateChartActive = {
    hover: {
      enabled: false,
    },
    inactive: {
      enabled: false,
    },
}

const stateChartInActive = {
    hover: {
        enabled: true,
        opacity: 1,
        lineWidth: 0,
        lineWidthPlus: 0,
    },
    inactive: {
        enabled: true,
    },
    stickyTracking: true,
    trackByArea: true
    
}

const areaRangeSerieOptions = {
    type: "arearange",
    opacity: 0.2,
    lineWidth: 0,
    states: {
      hover: {
        enabled: true,
        opacity: 1,
        lineWidth: 0,
        lineWidthPlus: 0,
      },
      inactive: {
        enabled: false,
      },
      stickyTracking: false,
    },
    marker: {
      enabled: false,
      states: {
        hover: {
          enabled: false,
        },
      },
    }
}

const lineSerieOptions = {
    type: "line",
    marker: {
      fillColor: "#FFFFFF",
      lineWidth: 2,
      lineColor: null,
      symbol: "circle",
      radius: 5,
      enabled: false,
    },
    states: {
      hover: {
        enabled: false,
      },
      inactive: {
        enabled: false,
      },
    },
    zoneAxis: "x",
    zones: [
      {
        value: 8,
      },
      {
        dashStyle: "ShortDash",
      },
    ],
}

const formatSeriesDataEachReactionAvg = (summaryAvg, id) => {
    let data = [];
    if (id == videoTimeRange[0].id) {
        data = summaryAvg.total_detail_norm ? summaryAvg.total_detail_norm.map(e => {
            let second = e.second;
            let norm2 = summaryAvg['2_detail_norm'].find(e => e.second == second);
            let norm3 = summaryAvg['3_detail_norm'].find(e => e.second == second);
            return [
                second,
                norm2 ? norm2.norm : 0,
                norm3 ? norm3.norm :0,
            ]
        }) : [];
    }
    if (id == videoTimeRange[1].id) {
        data = summaryAvg.total_0_15_detail_norm ? summaryAvg.total_0_15_detail_norm.map(e => {
            let second = e.second;
            let norm2 = summaryAvg['2_0_15_detail_norm'].find(e => e.second == second);
            let norm3 = summaryAvg['3_0_15_detail_norm'].find(e => e.second == second);
            return [
                second,
                norm2 ? norm2.norm : 0,
                norm3 ? norm3.norm :0,
            ]
        }) : [];
    }
    if (id == videoTimeRange[2].id) {
        data = summaryAvg.total_16_30_detail_norm ? summaryAvg.total_16_30_detail_norm.map(e => {
            let second = e.second;
            let norm2 = summaryAvg['2_16_30_detail_norm'].find(e => e.second == second);
            let norm3 = summaryAvg['3_16_30_detail_norm'].find(e => e.second == second);
            return [
                second,
                norm2 ? norm2.norm : 0,
                norm3 ? norm3.norm :0,
            ]
        }) : [];
    }
    if (id == videoTimeRange[3].id) {
        data = summaryAvg.total_31_60_detail_norm ? summaryAvg.total_31_60_detail_norm.map(e => {
            let second = e.second;
            let norm2 = summaryAvg['2_31_60_detail_norm'].find(e => e.second == second);
            let norm3 = summaryAvg['3_31_60_detail_norm'].find(e => e.second == second);
            return [
                second,
                norm2 ? norm2.norm : 0,
                norm3 ? norm3.norm :0,
            ]
        }) : [];
    }
    if (id == videoTimeRange[4].id) {
        data = summaryAvg.total_61_detail_norm ? summaryAvg.total_61_detail_norm.map(e => {
            let second = e.second;
            let norm2 = summaryAvg['2_61_detail_norm'].find(e => e.second == second);
            let norm3 = summaryAvg['3_61_detail_norm'].find(e => e.second == second);
            return [
                second,
                norm2 ? norm2.norm : 0,
                norm3 ? norm3.norm :0,
            ]
        }) : [];
    }
    return {
        id: id,
        data: data
    }
}

const getAreaRangeChartOption = (numberColumn) => ({
    tooltip: {
        enabled: false,
    },
    title: {
        text: "",
    },
    yAxis: {
        title: "",
        gridLineWidth: 0,
        minTickInterval: 1,
        min: 0,
        max: 10,
        labels: {
            step: 1,
            style: {
              color: '#999999',
              fontSize: '8px',
              fontWeight: 'normal'
            }
        }
    },

    xAxis: {
        gridLineWidth: 1,
        lineWidth: 0,
        max: (numberColumn + 0.5),
        min: 0,
        step: 1,
        tickLength: 0,
        grid: {
            columns: Array.from(Array(numberColumn).keys()),
            width: 1,
            marker: {
                states: {
                    hover: {
                        gridLineWidth: 2,
                        enabled: true,
                    },
                },
            },
        },
        tickInterval: 1,
        labels: {
            step: 1,
            style: {
              color: '#999999',
              fontSize: '8px',
              fontWeight: 'normal'
            }
        }
    },

    legend: {
        enabled: false,
    },

    plotOptions: {
        line: {
            series: {
                clip: false,
            },
            allowPointSelect: 0,
            marker: {
                radius: 1,
                enabled: true,
                states: {
                    hover: {
                        enabled: false,
                    },
                    select: {
                        radius: 4,
                        enabled: false,
                    },
                },
            },
            pointPlacement: 1,
            stickyTracking: true,
            states: {
                hover: {
                    enabled: true,
                    lineWidth: 4,
                },
            },
            pointStart: 0,
            selected: false,
        },
    },

    series: [],
    credits: {
        enabled: false,
    },
    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 500,
                },
                chartOptions: {
                    legend: {
                        layout: "vertical",
                        align: "center",
                        verticalAlign: "bottom",
                    },
                },
            },
        ],
    }
})

const formatSeriesDataSegmentAttr = (reportData, attribute_value_id) => {
    let data = [];
    if (reportData) {
        data = reportData.map(e => (Math.abs(e.left_count) + Math.abs(e.right_count)));
    }
    return {
        id: 'attrValue-' + attribute_value_id,
        data
    }
}

const formatSeriesDataSegmentAttrStep1 = (reportData, attribute_value_id) => {
    let data = [];
    if (reportData) {
        data = reportData.map(e => (Math.abs(e.btn1) + Math.abs(e.btn2) + Math.abs(e.btn3)));
    }
    return {
        id: 'attrValue-' + attribute_value_id,
        data
    }
}

const findYRangeOfSerieByXValue = (series, serie, x, prevX, nextX) => {
    let point = serie.data.find(p => p && Object.prototype.hasOwnProperty.call(p, 'x') && p.x == prevX);
    if (typeof(nextX) == undefined) {
       
        if (point) {
            return {
                yMin: Math.max(point.plotHigh, point.plotLow),
                yMax: Math.min(point.plotHigh, point.plotLow)
            }
        } else {
            return {
                yMin: 0,
                yMax: 0
            }
        }
    } else {
        let point2 = serie.data.find(p => p && Object.prototype.hasOwnProperty.call(p, 'x') && p.x == nextX);
        if (point2) {
            let y1 = ((x - point.plotX)*(point2.plotHigh - point.plotHigh) / (point2.plotX - point.plotX)) + point.plotHigh
            let y2 = ((x - point.plotX)*(point2.plotLow - point.plotLow) / (point2.plotX - point.plotX)) + point.plotLow
            return {
                yMin: Math.min(y1, y2),
                yMax: Math.max(y1, y2)
            }
        } else {
            let serieName = serie.name;
            let serieName2 = serieName.includes("arearange2") ? serieName.replace("arearange2", "arearange1") : serieName.replace("arearange1", "arearange2")
            let serie2 = series.find(e => e.name == serieName2);
            point2 = serie2.data.find(p => p && Object.prototype.hasOwnProperty.call(p, 'x') && p.x == nextX);
            if (point2) {
                let y1 = ((x - point.plotX)*(point2.plotHigh - point.plotLow) / (point2.plotX - point.plotX)) + point.plotLow
                let y2 = ((x - point.plotX)*(point2.plotLow - point.plotHigh) / (point2.plotX - point.plotX)) + point.plotHigh
                return {
                    yMin: Math.min(y1, y2),
                    yMax: Math.max(y1, y2)
                }
            } else {
                return {
                    yMin: 0,
                    yMax: 0
                }
            }
            
        }   
    }
}

const findSerieNameOnClick = (series, point, nearestX) => {
    nearestX = Math.floor(nearestX)
    let x = point.x;
    let seriesHasPoints = series.filter(serie =>
        (
            serie.name.includes("arearange") &&
            serie.data.find(e => e && Object.prototype.hasOwnProperty.call(e, 'x') && e.x == nearestX)
        )
    );
    if (!seriesHasPoints.length) {
        return;
    }
    let nearestPoint = seriesHasPoints[0].data.find(e => e && Object.prototype.hasOwnProperty.call(e, 'x') && e.x == nearestX);
    let positionYOfSeries;
    let prevX, nextX;
    if (nearestPoint.plotX == x) {
        prevX = nearestX;
    } else {
        if (nearestPoint.plotX < x) {
            prevX = nearestX;
            nextX = nearestX + 1
        } else {
            prevX = nearestX-1;
            seriesHasPoints = series.filter(serie =>
                (
                    serie.name.includes("arearange") &&
                    serie.data.find(e => e && Object.prototype.hasOwnProperty.call(e, 'x') && e.x == prevX)
                )
            );
            nextX = nearestX;
        }
    }
    positionYOfSeries = seriesHasPoints.map(serie => ({
        ...findYRangeOfSerieByXValue(series, serie, x, prevX, nextX),
        name: serie.name
    }))
    positionYOfSeries = positionYOfSeries.filter(e => (e.yMin <= point.y && point.y <= e.yMax));
    if (!positionYOfSeries.length) {
        return;
    } else {
        if (positionYOfSeries.length == 1) {
            return positionYOfSeries[0].name;
        } else {
            let min;
            let name;
            positionYOfSeries.forEach(e => {
                if (!min) {
                   name = e.name;
                   min = e.yMax-e.yMin;
                } else {
                    if (min > (e.yMax-e.yMin)) {
                        min = e.yMax-e.yMin;
                        name = e.name
                    }
                }
            })
            return name;
        }
    }

}

export {
    stepOptionTopPage,
    formatDataChartWithStep,
    getXColumnsByStep,
    chartType,
    getReactionTagTooltipEl,
    getImageTooltipEl,
    getInteractionLabel,
    lineChartOption,
    polarRadarChartOption,
    getTooltipLineChart,
    formatSeriesDataSummaryAvg,
    getYMaxSummaryChart,
    formatSeriesDataSummaryVideo,
    stepOptionTopDetailPage,
    barChartOption,
    formatDataChartWithStepBarChart,
    formatDataChartWithStepBarChartStep1,
    colorsReaction,
    getColorGrandient,
    formatEachInteractionSeriesData,
    formatSeriesDataEachReactionAvg,
    areaRangeSerieOptions,
    stateChartActive,
    lineSerieOptions,
    stateChartInActive,
    getAreaRangeChartOption,
    getYMaxSummaryEachInteractionChart,
    getTooltipTopLineChart,
    getTooltipBottomLineChart,
    formatSeriesDataSegmentAttr,
    findSerieNameOnClick,
    formatDataRadarChart,
    stepOptionTopDetailPageTopDetail,
    getReactionTagTooltipElStep1,
    getTooltipLineChartStep1,
    formatSeriesDataSegmentAttrStep1,
    formatSeriesDataSummaryVideoStep3,
    formatSeriesDataSummaryVideoStep1,
    formatSeriesDataSummaryAvgStep1,
}