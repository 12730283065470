<template>
  <div class="side-left">
      <img class="img-logo" src="images/svg/white_logo.svg">
      <div class="title-side-left">
          <div class="title-large">
              Welcome to PlayAds
          </div>
          <div class="title-small mt-6">
              動画コンテンツの反応値を測定して、<br/>
              安全かつ効果的に配信しよう！
          </div>
          <div
            :style="disabled ? styleButton : {}"
            class="btn btn-download-manual btn-primary-outline mt-6"
            @click="downloadManual()"
            >
            <i class="icon icon-small-download mr-1"></i>
            マニュアルダウンロード
            </div>
      </div>
      
  </div>
</template>

<script>
export default {
  name: 'SideLeft',
  props: {
  },
  data (){
      return {
        disabled : false,
        styleButton : {
            opacity : 0.75,
            cursor : 'default'
        }
      }
  },
  methods: {
      downloadManual(){
        if (!this.disabled) { 
            this.disabled = true;
            let url = this.$router.resolve({name: "api.download.manual"}).href;
            this.$http2.get(url,{},(data) => {
                let url = data.download_url;
                let label = url.substr(url.lastIndexOf("/") + 1 );
                this.$http2.downloadPDF(url, label,() => {
                    this.disabled = false;
                },
                ()=> {
                    this.disabled = false;
                });
            },
            () => {
                this.disabled = false;
            }
          );
          }
        }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title-large {
    font-weight: bold;
    font-size: 36px;
    line-height: 38px;
    /* identical to box height, or 107% */

    text-align: center;
}
.title-small {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
}
.side-left {
    display: none;
}
.title-side-left-large{
    font-size: 24px;
    font-weight: 700;
    line-height: 38.4px;
}
.title-side-left-medium {
    font-size : 14px;
    line-height: 23.8px;
    font-weight: 400;
}
.title-side-left-small{
    font-size : 12px;
    line-height: 19.2px;
    font-weight: 400;
    font-style: normal;
}
@media screen and (min-width: 992px) {
    .side-left {
        display: block;
        background-color: #49AB94;
        color: white;
        padding: 20px;
        height: 100%;
        box-sizing: border-box;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 0  41.32vw;
    }
}

.side-left .img-logo {
    position: absolute;
    top: 5px;
    left: 10px;
}
.btn-download-manual {
    display : flex;
    padding : 6px 12px;
    align-items: center;
    width: 180px;
}

</style>
