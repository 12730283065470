import videojs from "video.js";
videojs.addLanguage('ja', {
    "Play": "再生",
    "Pause": "一時停止",
    "Current Time": "現在の時間",
    "Duration": "長さ",
    "Remaining Time": "残りの時間",
    "Stream Type": "ストリームの種類",
    "LIVE": "ライブ",
    "Loaded": "ロード済み",
    "Progress": "進行状況",
    "Fullscreen": "フルスクリーン",
    "Non-Fullscreen": "フルスクリーン以外",
    "Mute": "ミュート",
    "Unmute": "ミュート解除",
    "Playback Rate": "再生レート",
    "Subtitles": "サブタイトル",
    "subtitles off": "サブタイトル オフ",
    "Captions": "キャプション",
    "captions off": "キャプション オフ",
    "Chapters": "チャプター",
    "You aborted the media playback": "動画再生を中止しました",
    "A network error caused the media download to fail part-way.": "ネットワーク エラーにより動画のダウンロードが途中で失敗しました",
    "The media could not be loaded, either because the server or network failed or because the format is not supported.": "サーバーまたはネットワークのエラー、またはフォーマットがサポートされていないため、動画をロードできませんでした",
    "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.": "破損の問題、またはお使いのブラウザがサポートしていない機能が動画に使用されていたため、動画の再生が中止されました",
    "No compatible source was found for this media.": "この動画に対して互換性のあるソースが見つかりませんでした"
});

const videojs_cus = videojs;

export default videojs_cus
