<template>
   <div id="app">
       <side-bar></side-bar>
       <div class="wrapper-container" id="wrapper-container">
          <div class="container">
            <router-view />
          </div>
       </div>
   </div>
</template>

<script>
import SideBar from './Sidebar.vue'
export default {
  name: 'Layout',
  components: {
      SideBar
  },
  props: {
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem('user'));
    if(user && user.user_id) {
      document.getElementById('user_id_input').value = user ? user.user_id : '';
    }
  },
}
</script>
