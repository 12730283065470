<template>
    <div class="row">
        <div class="col-1 col-lg-1"></div>
        <div class="col-11 col-lg-11 mr-0 outline-area">
            <client-tab></client-tab>
            <div class="clients-list">
                <div>
                    <div class="label-select">クライアント</div>
                    <client-list :clients="clients" @select="changeBuyer" class="mb-2"></client-list>
                </div>
                <div class="button-register">
                    <button class="btn btn-primary" @click="openModalAddUser()">
                        新規登録
                    </button>
                </div>
            </div>
            <div class="table-filter-search">
                <div class="show">
                    <div class="number-display mr-5">
                        表示数:{{ usersCount }}件
                    </div>
                    <div class="select-hide">
                        <i :class="[params.status == 0 ? 'checked' : '', 'icon icon-checkbox mr-2']" @click="switchStatus"></i>
                        非表示一覧
                    </div>
                </div>
                <div class="search">
                    <i class="icon icon-search"></i>
                    <input
                        class="input input-search pl-8 pr-5"
                        type="search"
                        placeholder="検索"
                        v-model="params.text"
                    />
                </div>
            </div>
            <div class="table-wrapper">
                <table class="table table-scroll">
                    <tr>
                        <th class="w-20 none-boder">
                            <div class="icon-arrow" @click="doSort('name')">
                                ユーザー名 <i class="icon ml-1" :class="{'icon-table-arrow-bottom' :  isSortDesc('name'), 'icon-table-arrow-top' : isSortAsc('name')}"></i>
                            </div>
                        </th>
                        <th class="w-30 none-boder">
                            <div class="icon-arrow">
                                メールアドレス
                            </div>
                        </th>
                        <th class="w-10 none-boder">
                            <div class="icon-arrow">
                                ステータス
                            </div>
                        </th>
                        <th class="w-40 none-boder">
                            <div class="icon-arrow">
                                パスワード発行メール再送信 
                            </div>
                        </th>
                    </tr>
                    <tr v-for="user in users" :key="user.id">
                        <td>{{ user.name }}</td>
                        <td>{{ user.email }}</td>
                        <td :class="user.status == 1 ? 'text-green' : 'text-red'">
                            {{ statusMap[user.status] }}
                        </td>
                        <td>
                            <div class="icon-mail-confirm" v-if="!user.is_loggedin" @click="sendResetPwMail(user.id)">
                                <i class="icon icon-mail mr-1"></i>
                                送信
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <scroll-loader :loader-method="loadMoreData" :loader-disable="loadDisable" :loader-color="'#49ab94'"></scroll-loader>
        </div>
        <div class="col-1 col-lg-1"></div>
        <add-user-modal v-if="openModalUser" 
         :clients="clients" :hasClientSelect="true"
         @closeModal="closeModalAddUser"
         :clientSelected="parseInt(params.buyer_id)"></add-user-modal>
    </div>
</template>

<script>
import ClientTab from '../../components/Client/ClientTab.vue';
import ClientList from '../../components/Select/ClientList.vue';
import router from '../../routes';
import http2 from '../../commons/http2';
import i18n from '../../i18n';
import {
  listBuyers
} from '../../commons/api_service';
import { 
  setSelect2Value,
} from '../../commons/helpers';
import AddUserModal from '../../components/Users/AddUserModal.vue';

export default {
    name: 'ClientUserList',
    components: {ClientTab, ClientList, AddUserModal},
    data() {
        return {
            clients: [],
            params: {
                page: 1,
                limit: 20,
                status: 1,
                text: '',
                sort: 'name',
                order: 'asc',
                buyer_id: this.$route.params.id
            },
            statusMap: {
                0: i18n.t('status.inactive'),
                1: i18n.t('status.active')
            },
            usersCount: 0,
            users: [],
            loadDisable: false,
            openModalUser: false
        }
    },
    watch: {
        'params.text': function() {
            this.getUsers(false);
        }
    },
    methods: {
        closeModalAddUser: function() {
            this.openModalUser = false;
            if (!this.params.status) {
                this.getUsers(false);
            }
        },
        openModalAddUser: function() {
            this.openModalUser = true
        },
        getUsers: function(isContinous) {
            this.loadDisable = false;
            if(!isContinous) {
                this.params.page = 1;
            } else {
                this.params.page += 1;
            }
            let url = router.resolve({name: 'api.users'}).href;
            http2.getList(url, this.params, (data) => {
                this.usersCount = data.all;
                if(!isContinous) {
                    this.users = data.items;
                } else {
                    this.users = [...this.users, ...data.items];
                }
                if(this.params.limit > data.items.length) {
                    this.loadDisable = true;
                }
            }, ()=> {
                this.loadDisable = true;
            })
        },
        loadMoreData() {
            this.getUsers(true);
        },
        switchStatus() {
            if(this.params.status == 0) {
                this.params.status = 1;
            } else {
                this.params.status = 0;
            }
            this.getUsers(false);
        },
        changeBuyer(selectedId) {
            this.$router.push({name: 'client_users', params: {id: selectedId}})
        },
        sendResetPwMail(userId) {
            let url = router.resolve({name: 'api.users.resend-pw-mail', params: {id: userId}}).href;
            http2.put(url, {}, () => {
                this.$toasted.success(this.$t("messages.resend_password_success"));
            }, () => {
                this.$toasted.error(this.$t("messages.resend_password_fail"));
            })
        },
        doSort(sortColumn) {
            if(sortColumn == this.params.sort) {
                let currentOrder = this.params.order;
                this.params.order = currentOrder == 'asc' ? 'desc' : 'asc'
            } else {
                this.params.sort = sortColumn;
                this.params.order = 'desc';
            }
            this.getUsers(false);
        },
        isSortDesc(sortColumn) {
            return this.params.sort == sortColumn && this.params.order == 'desc';
        },
        isSortAsc(sortColumn) {
            return this.params.sort == sortColumn && this.params.order == 'asc';
        }
    },
    mounted() {
        listBuyers(buyers => {
            this.clients = buyers;
        }, true);
        this.getUsers(false);
    },
    updated() {
        setSelect2Value('clients', this.params.buyer_id);
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_client.scss'
</style>