import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import buyers from './modules/buyers';
import projects from './modules/projects';
import video from './modules/video';
import attributes from './modules/attributes';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLoadingApi: false,
        isLoadingApiVideoComment: false
    },
    getters: {
        isLoadingApi: state => {
            return state.isLoadingApi
        },
        isLoadingApiVideoComment: state => {
            return state.isLoadingApiVideoComment
        }
    },
    mutations: {
        changeLang(state, payload) {
            localStorage.setItem('currentLanguage', payload)
        },
        setLoadingApi(state, payload) {
            state.isLoadingApi = payload
        },
        setLoadingApiVideoComment(state, payload) {
            state.isLoadingApiVideoComment = payload
        },
    },
    actions: {
        setLang({ commit }, payload) {
            commit('changeLang', payload)
        }
    },
    modules: {
        user,
        buyers,
        projects,
        video,
        attributes
    },
});
