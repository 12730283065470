<template>
   <div class="login-container">
        <div class="side-left">
            <img class="img-logo" src="images/svg/white_logo.svg">
            <div class="title-side-left-large">メールの送信が完了しました</div>
            <div class="form-control form-group-login mb-4 mt-1">
                <div class="title-side-left-medium mb-6 mt-1">パスワード再設定申込を受け付けました。</div>
                <div class="title-side-left-medium mb-2">パスワード再設定ページのURLを以下の <br />
                    メールアドレスに送信しました。</div>
                <div class="input-confirm pl-5 mb-3 mt-2"> {{email}} </div>
                <div class="title-side-left-small mb-4">
                    メールが確認できない場合、メールアドレスが誤ってい るまたは迷惑メールに振り分けられている可能性があり ますのでご確認ください。
                </div>
                <div class="form-controlbutton">
                    <button class="btn btn-submit btn-primary btn-primary--outline" @click="redirectLogin()">ログイン画面に戻る</button>
                </div>
            </div>
        </div>
        <div class="side-right bg-primary" >
            <img class="img-logo" src="images/svg/logo_forgot_send.svg">
            <img src="images/svg/bg_login_logo.svg">
            
        </div>
   </div>
</template>

<script>

export default {
  name: 'ForgotSent',
  components: {
  },
 data() {
    return {
        email: this.$route.params.email
    }
 },
 methods: {
    redirectLogin() {
        this.$router.push({name: 'login'});
    }
 },
 created() {
    if (!this.$route.params.email) {
        this.$router.push({name: 'login'});
    }
 }
};
</script>
<style lang="scss" scoped>
@import "@/styles/_login.scss"; 
.title-large {
    font-weight: bold;
    font-size: 36px;
    line-height: 38px;
    /* identical to box height, or 107% */

    text-align: center;
}
.title-small {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 29px;
}
.side-left {
    display: none;
}
.title-side-left-large{
    font-size: 24px;
    font-weight: 700;
    line-height: 38.4px;
}
.title-side-left-medium {
    font-size : 14px;
    line-height: 23.8px;
    font-weight: 400;
}
.title-side-left-small{
    font-size : 12px;
    line-height: 19.2px;
    font-weight: 400;
    font-style: normal;
}
.input-confirm { 
    height: unset !important;
    color: #49AB94;
    box-sizing: border-box;
    padding: 17px 20px;
    width: 100%;
    text-overflow: wrap;
    overflow: hidden;
    word-break: break-word;
    font-weight: bold;
}
 .side-left {
    display: block;
    background-color: #49AB94;
    color: white;
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-width: 450px;
}
.side-right {
    display: none;
    position: relative;
}
.side-right img {
    width: 100%;
}
.side-right img.img-logo {
    width: 70%;
}
@media screen and (min-width: 992px) {
    .side-left {
        flex: 0  41.32vw;
    }
   .side-right {
        position: relative;
        display: flex;
    }
}

.side-left .img-logo {
    position: absolute;
    top: 20px;
    left: 20px;
}
</style>