<template>
  <div class="modal modal-comfirm" :id="confirmId">
    <div class="modal-content">
      <div class="header">
        <div class="btn-close" @click="closeModal">
          <i class="icon icon-close"></i>
        </div>
      </div>
      <div class="body">
        <p v-html="message"></p>
        <div class="btn-submit" style="flex-direction : row">
          <button
            :disabled="isLoading"
            class="btn btn-primary button-ok"
            style="width : 228px;margin-right:8px;display:flex;align-items:center;justify-content:center"
            @click="handleOk()"
          >
            <span v-if="isLoading == false">OK</span>
            <div class="loader" v-else></div>
          </button>
          <button
            class="btn btn-primary-outline button-cancel"
            style="width : 228px"
            :disabled="isLoading"
          >
            キャンセル
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const jQuery = require("jquery");
import EventBus from "../../commons/event_bus";
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  props: {
    confirmId: String,
    message: String,
    isEnter: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleOk() {
      this.isLoading = true;
      jQuery(".button-ok").css("opacity", 0.75);
      this.$emit("confirm");
    },
    handleDone() {
      this.isLoading = false;
      jQuery(".button-ok").css("opacity", 1);
    },
    closeModal() {
      if (!this.isLoading) {
        this.$emit("closeModalConfirm");
      }
    }
  },
  mounted() {
    let vm = this;
    jQuery(".button-cancel").click(function () {
      vm.$emit("closeModalConfirm");
    });
    if (this.isEnter) {
      jQuery(document).keypress(function (e) {
        let keyCode = e.which;
        if (keyCode == 13) {
          vm.handleOk();
        }
      });
    }
  },
  created() {
    EventBus.$on("handleDone", this.handleDone);
  },
  destroyed() {
    EventBus.$off("handleDone", this.handleDone);
  },
};
</script>

<style lang="scss" scoped>
.modal-comfirm {
  .modal-content {
    min-height: 0;
    .body {
      p {
        padding: 20px 0;
        text-align: center;
      }
    }
  }
}
.loader {
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-top: 2px solid #49ab94;
  width: 24px;
  height: 24px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
