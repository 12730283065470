<template>
  <div class="modal add-segment-andor modal-show" v-show="isShown" ref="modal">
    <div class="modal-content">
      <div class="header">
        <div class="btn-back">
          <i class="icon icon-prev" @click="prevModal()"></i>
        </div>
      </div>
      <div class="body">
        <div class="label">AND OR設定</div>
        <div
          v-for="(groupAttributes, index) in groupsSegment"
          :key="'group' + index"
          class="list-item row-3 mb-4"
          :class="{ 'list-item-selected': groupAttributes.length > 0 }"
        >
          <div class="row-3-col-1 groups-segment">
            <div class="describe" v-if="groupAttributes.length == 0">
              セグメントを選択すると対象者のプロフィールが作成されます
            </div>
            <div class="describe-wrapper" v-else>
              <div class="describe-item-selected">
                <div class="scroll-tab">
                  <div
                    class="image-male-female"
                    v-if="groupAttributes.length > 0"
                  >
                    <div class="image-female mr-2">
                      <img
                        class="card-image"
                        :src="
                          '/images/img/image_female_' + (index % 3) + '.svg'
                        "
                      />
                    </div>
                    <div class="image-female">
                      <img
                        class="card-image"
                        :src="'/images/img/image_male_' + (index % 3) + '.svg'"
                      />
                    </div>
                  </div>
                  <div
                    class="att-selected"
                    v-for="(attribute, index) in groupAttributes"
                    :key="'att-selected-' + index"
                  >
                    <div class="name">{{ attribute.name }}</div>
                    <div class="value">
                      <span
                        v-for="(attributeValue, index) in attribute.list"
                        :key="
                          'index' +
                          index +
                          'value-selected-' +
                          attributeValue.id
                        "
                      >
                        {{ attributeValue.value }}
                        <span v-if="index !== attribute.list.length - 1"
                          >or</span
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row-3-col-2">
            <div class="list-attritute">
              <div v-if="index" class="btn-delete" @click="deleteGroup(index)">削除</div>
              <div class="attributes">
                <div
                  class="attritute-selected"
                  v-for="(attribute, idx) in segmentSelecteds"
                  :key="'attribute-' + idx"
                >
                  <div class="name">
                    <span>{{ attribute.name }}</span>
                     <div
                        v-if="isSelectedAll(attribute, index)"
                        class="batch-selection unselected"
                        @click="unSelectedAllValue(attribute, index)"
                      >
                        一括選択解除
                    </div>
                    <div
                      class="batch-selection "
                      v-else
                      @click="selectedAllValue(attribute, index)"
                    >
                      一括選択
                    </div>
                  </div>
                  <div class="attribute">
                    <div
                      class="value"
                      :class="{
                        active: checkSelectedInGroup(
                          attribute,
                          attributeValue,
                          index
                        ),
                      }"
                      v-for="attributeValue in attribute.list"
                      :key="'index-' + index + '-value-' + attributeValue.id"
                      @click="
                        toggleAttributeValue(attribute, attributeValue, index)
                      "
                    >
                      {{ attributeValue.value }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="add-condition">
          <div
            class="btn-add-condition btn btn-primary-outline"
            @click="addCondition"
          >
            条件の追加
            <i class="icon icon-add-circle"></i>
          </div>
        </div>
        <div class="btn-submit">
          <button class="btn btn-primary button-download-pdf" @click="config()">
            設定
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "../../commons/event_bus";

export default {
  props: {
    segmentArr: Array,
    isShown: Boolean,
    groups: Array,
    prevGroupsSegment: Array
  },
  data() {
    return {
      segmentSelecteds: [],
      groupsSegment: [[]],
    };
  },
  methods: {
    isSelectedAll(attribute, index) {
      let currentGroup = this.groupsSegment[index];
      if (currentGroup) {
        let findAttribute = currentGroup.find((e) => e.id == attribute.id);
        if (findAttribute) {
          return findAttribute.list.length == attribute.list.length
        } 
      }
      return false;
    },
    prevModal() {
      this.groupsSegment = JSON.parse(
          JSON.stringify(this.prevGroupsSegment)
      );
      EventBus.$emit("toggleModal", "segment-selected");
    },
    checkSelectedInGroup(attribute, attributeValue, index) {
      let currentGroup = this.groupsSegment[index];
      if (currentGroup) {
        let attributeExists = currentGroup.find((e) => e.id == attribute.id);
        if (attributeExists) {
          let attributeValueExist = attributeExists.list.findIndex(
            (e) => e.id == attributeValue.id
          );
          if (attributeValueExist != -1) {
            return true;
          }
        }
      }
      return false;
    },
    unSelectedAllValue(attribute, index) {
      let currentGroup = this.groupsSegment[index];
      let idx = currentGroup.findIndex((e) => e.id == attribute.id);
      if (idx != -1) {
        let newGroup = JSON.parse(JSON.stringify(currentGroup));
        newGroup.splice(idx, 1);
        this.$set(this.groupsSegment, index, newGroup);
      } 
    },
    selectedAllValue(attribute, index) {
      let currentGroup = this.groupsSegment[index];
      let idx = currentGroup.findIndex((e) => e.id == attribute.id);
      let newAttr = JSON.parse(JSON.stringify(attribute));
      if (idx != -1) {
        this.$set(currentGroup, idx, newAttr);
      } else {
        currentGroup.push(newAttr);
      }
    },
    toggleAttributeValue(attribute, attributeValue, index) {
      let currentGroup = this.groupsSegment[index];
      let attributeExists = currentGroup.find((e) => e.id == attribute.id);
      if (attributeExists) {
        let attributeValueExist = attributeExists.list.findIndex(
          (e) => e.id == attributeValue.id
        );
        if (attributeValueExist == -1) {
          attributeExists.list.push(attributeValue);
          attributeExists.list =  this.sortAttribute(attributeExists.list)
        } else {
          let newList = JSON.parse(JSON.stringify(attributeExists.list));
          newList.splice(attributeValueExist, 1);
          if (newList.length) {
            this.$set(attributeExists, "list", newList);
          } else {
            let newAttributes = JSON.parse(JSON.stringify(currentGroup));
            let idxAttr = newAttributes.findIndex((e) => e.id == attribute.id);
            newAttributes.splice(idxAttr, 1);
            let vm = this;
            this.$set(vm.groupsSegment, index, newAttributes);
          }
        }
      } else {
        currentGroup.push({
          ...attribute,
          list: [attributeValue],
        });
      }
    },
    config() {
      this.$emit("saveSegmentInfo", this.groupsSegment);
    },
    addCondition() {
      if (this.groupsSegment[this.groupsSegment.length - 1].length) {
        this.groupsSegment.push([]);
      }
    },
    clearDataUnSelected() {
      let newGroups = [];
      for(let i = 0; i < this.groupsSegment.length; i++) {
        let group = JSON.parse(JSON.stringify(this.groupsSegment[i]));
        let newGroup = [];
        group.forEach(attribute => {
          let findAttr =  this.segmentSelecteds.find(e => e.id == attribute.id);
          if (findAttr) {
            let values = attribute.list.filter(e => findAttr.list.find(a => a.id == e.id));
            if (values.length) {
              newGroup.push({
                ...attribute,
                list: values
              })
            }
          }
        });
        if (newGroup.length) {
          newGroups.push(newGroup);
        }
      }
      newGroups = newGroups.length ? newGroups: [[]];
      this.$set(this, 'groupsSegment', newGroups)
    },
    deleteGroup(index) {
      let newGroups = JSON.parse(JSON.stringify(this.groupsSegment));
      newGroups.splice(index, 1);
      this.$set(this, 'groupsSegment', newGroups)
    },
    sortAttribute(data) {
      return data.sort((a, b) => a.id - b.id);
    },
  },
  mounted() {
    this.segmentSelecteds = this.segmentArr;
  },
  watch: {
    segmentArr: function (data) {
      this.segmentSelecteds = data;
    },
    groups: function (data) {
      this.groupsSegment = data;
    },
    isShown: function(newValue) {
      if (newValue) {
        this.$refs.modal.scrollTop = 0;
        this.clearDataUnSelected();
      }
     
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/_project.scss";
</style>