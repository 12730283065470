<template>
  <div class="radio-list flex flex-row flex-wrap">
    <div
      v-for="(checkBoxElement, key) in checkBoxList"
      :key="`${keyName}_${key}`"
      class="form-check-box mt-2"
    >
      <input
        :id="`${keyName}_${key}`"
        class="form-check-input"
        type="checkbox"
        name="youtube-channel"
        :value="checkBoxElement.value"
        v-model="checkedIds"
        checked="true"
      />
      <span class="btn-check"> </span>
      <label class="label-check mr-5 mb-0">{{ checkBoxElement.text }}</label>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    checkBoxList: [Array, Object],
    checkedList: Array,
    keyName: String,
  },

  data () {
    return {
      checkedIds: this.checkedList
    }
  },

  methods: {

  },

  watch: {
    checkedIds: {
      handler(newValue) {
        this.$emit('changeCheckedList', newValue)
      },
      deep: true
    }
  },

  created() {

  },

  destroyed() {

  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/root";


</style>
