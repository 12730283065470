<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import "https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.10/css/select2.min.css";
</style>

<style lang="scss">
@import "./styles/_root.scss";
@import "./styles/_toasted.scss";
@import "./assets/css/video-js.css";
@import "./assets/css/flatpickr.min.css";
@import "~flatpickr/dist/plugins/monthSelect/style.css";
@import "./assets/css/flatpickr.css";
@import "./assets/css/style.css";

</style>
