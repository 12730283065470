// api serivce commone

import router from '../routes'
import store from '../store'
import http2 from './http2'

export const getApiUrl = routerName => {
  return router.resolve({
    name: routerName,
  }).href
}

export const getApiUrlWithParam = (routerName, params) => {
  return router.resolve({
    name: routerName,
    params: params,
  }).href
}

function convertVideoParams(params) {
  delete params.t
  return Object.values(params).join(',')
}

/**
 * list buyer has status=1 and approve_status=1
 * @param {*} callback
 * @param {*} isUpdate
 */
export const listBuyersActive = (callback, isUpdate = false) => {
  if (isUpdate || store.getters.buyersActive.length == 0) {
    let params = {
      approve_status: 1,
      status: 1,
      sort: 'id',
      order: 'asc',
    }
    let url = getApiUrl('api.buyers')
    store.commit('setLoadingApi', true)
    http2.getList(url, params, data => {
      store.commit('setLoadingApi', false)
      store.commit('setBuyersActive', data.items)
      callback && callback(data.items)
    })
  } else {
    let items = store.getters.buyersActive
    callback && callback(items)
  }
}

export const listBuyerMarket = callback => {
  let url = getApiUrl('api.buyer-market')
  http2.getList(url, {}, data => {
    callback && callback(data.items)
  })
}

export const listBuyers = (callback, isUpdate = false) => {
  if (isUpdate || store.getters.buyers.length == 0) {
    let params = {
      approve_status: 1,
      sort: 'id',
      order: 'asc',
    }
    let url = getApiUrl('api.buyers')
    http2.getList(url, params, data => {
      store.commit('setBuyers', data.items)
      callback && callback(data.items)
    })
  } else {
    let items = store.getters.buyers
    callback && callback(items)
  }
}
// project
export const listProject = (callback, buyerId) => {
  if (buyerId || store.getters.projects.length == 0) {
    let params = {
      buyer_id: buyerId,
      sort: 'id',
      order: 'asc',
    }
    let url = getApiUrl('api.articles')
    http2.getList(url, params, data => {
      store.commit('setProjects', data.items)
      callback && callback(data.items)
    })
  } else {
    let items = store.getters.projects
    callback && callback(items)
  }
}

// label
export const listLabels = (callback, buyerId) => {
  if (buyerId) {
    let query = {
      buyer_id: buyerId,
    }
    let url = router.resolve({ name: 'api.labels', params: query }).href
    store.commit('setLoadingApi', true)
    http2.getList(
      url,
      {},
      data => {
        store.commit('setLoadingApi', false)
        callback && callback(data.items)
      },
      () => {
        store.commit('setLoadingApi', false)
        callback && callback([])
      },
    )
  }
}

// interaction
export const listInteractions = (callback, buyerId, deliver_period_type) => {
  if (buyerId) {
    let query = {
      buyer_id: buyerId,
    }
    let url = router.resolve({ name: 'api.interactions', params: query }).href
    store.commit('setLoadingApi', true)
    http2.getList(
      url,
      { deliver_period_type },
      data => {
        store.commit('setLoadingApi', false)
        callback && callback(data.items)
      },
      () => {
        store.commit('setLoadingApi', false)
        callback && callback([])
      },
    )
  }
}

// videos
export const listVideo = (callback, params, flg) => {
  let videos = store.getters.getHomeVideos(convertVideoParams(params))
  if (videos && flg) {
    callback && callback(videos)
  } else {
    let url = getApiUrl('api.videos')
    store.commit('setLoadingApi', true)
    http2.getList(
      url,
      params,
      data => {
        store.commit('setHomeVideos', { data: data, key: convertVideoParams(params) })
        store.commit('setLoadingApi', false)
        callback && callback(data.items)
      },
      () => {
        store.commit('setLoadingApi', false)
        callback && callback([])
      },
    )
  }
}

export const videosDetail = (callback, videoId) => {
  let query = {
    id: videoId,
  }
  if (videoId) {
    let url = getApiUrl('api.videos.detail')
    http2.getList(url, query, data => {
      callback && callback(data)
    })
  } else {
    // let items = store.getters.videos;
    // callback && callback(items);
  }
}
export const videoImages = (callback, params) => {
  let { videoId, interactionTally } = params
  let query = {
    video_ids: videoId,
    interaction_tally: interactionTally || 0,
  }
  let videoImage = store.getters.videoImagesById(videoId)
  if (videoId && !videoImage) {
    let url = getApiUrl('api.videos.images')
    http2.getList(url, query, data => {
      store.commit('addVideoImage', data.length ? data[0] : {})
      callback && callback(data[0])
    })
  } else {
    callback && callback(videoImage)
  }
}
// report
export const report = (callback, query) => {
  let buyerId = query.buyer_id
  let projectId = query.ad_id
  let videoId = query.video_id
  let interactionTally = query.interaction_tally
  let params = {
    project_id: projectId,
    buyer_id: buyerId,
  }
  if (videoId) params.video_id = videoId
  if (interactionTally) params.interaction_tally = interactionTally
  // if (buyerId && projectId || store.getters.videoReportSummary.length == 0) {
  let url = getApiUrl('api.report')
  http2.getList(url, params, data => {
    // store.commit('setReportSummary', data);
    callback && callback(data)
  })
  // } else {
  //     let items = store.getters.videoReportSummary;
  //     callback && callback(items);
  // }
}
export const interactionVideoReport = (callback, params) => {
  let { videoIds, interactionTally } = params
  let reportInteractionRank = store.getters.getReportInteractionRank
  if (videoIds || !reportInteractionRank) {
    let params = {
      video_ids: videoIds,
      interaction_tally: interactionTally || 0,
    }
    let url = getApiUrl('api.report.interaction-ranks')
    http2.getList(url, params, data => {
      store.commit('updateReportInteractionRank', data)
      callback && callback(data)
    })
  } else {
    callback && callback(reportInteractionRank)
  }
}
export const interactionAverageReport = (callback, params) => {
  let url = getApiUrl('api.report.interaction-average')
  http2.getList(
    url,
    params,
    data => {
      callback && callback(data)
    },
    () => {
      callback && callback([])
    },
  )
}
export const buyerNormReport = (callback, buyerId) => {
  let summaryAvg = store.getters.summaryAvgById(buyerId)
  if (!summaryAvg) {
    let url = router.resolve({
      name: 'api.buyer.norm',
      params: {
        buyer_id: buyerId,
      },
    }).href
    http2.get(
      url,
      {
        video_time_range: 0,
      },
      data => {
        store.commit('addSummaryAvg', data)
        callback && callback(data)
      },
    )
  } else {
    callback && callback(summaryAvg)
  }
}

export const videosNormReport = (callback, buyerId, query) => {
  let { video_ids } = query
  if (video_ids) {
    let summaryAvg = store.getters.summaryAvgById(buyerId)
    if (!summaryAvg) {
      let url = router.resolve({
        name: 'api.buyer.videos_norm',
        params: {
          buyer_id: buyerId,
        },
      }).href
      http2.get(
        url,
        {
          video_ids: video_ids,
          video_time_range: 0,
        },
        data => {
          store.commit('addSummaryAvg', data)
          callback && callback(data)
        },
        err => {
          http2.showToastError(err.body.Message)
        },
      )
    } else {
      callback && callback(summaryAvg)
    }
  }
}

export const videosNormReportStep1 = (callback, buyerId, query) => {
  let { video_ids } = query
  if (video_ids) {
    let summaryAvg = store.getters.summaryAvgById(buyerId)
    if (!summaryAvg) {
      let url = router.resolve({
        name: 'api.buyer.videos_norm_promotion',
        params: {
          buyer_id: buyerId,
        },
      }).href
      http2.get(
        url,
        {
          video_ids: video_ids,
          video_time_range: 0,
          interaction_tally: 1,
        },
        data => {
          store.commit('addSummaryAvg', data)
          callback && callback(data)
        },
        err => {
          http2.showToastError(err.body.Message)
        },
      )
    } else {
      callback && callback(summaryAvg)
    }
  }
}

export const getVideoDetail = id => {
  return new Promise((resolve, reject) => {
    let params = {
      id: id,
    }
    let url = getApiUrlWithParam('api.videos.detail', params)
    http2.get(
      url,
      {},
      data => {
        resolve(data)
      },
      error => {
        reject(error)
      },
    )
  })
}

export const listimageVideo = (params = {}) => {
  return new Promise((resolve, reject) => {
    let url = router.resolve({
      name: 'api.video.image',
    }).href
    http2.getList(
      url,
      { ...params },
      data => {
        let list = data
        resolve(list)
      },
      error => {
        reject(error)
      },
    )
  })
}

export const listimageVideoStep1 = (params = {}) => {
  return new Promise((resolve, reject) => {
    let url = router.resolve({
      name: 'api.video.promotion-image',
    }).href
    http2.getList(
      url,
      { ...params },
      data => {
        let list = data
        resolve(list)
      },
      error => {
        reject(error)
      },
    )
  })
}

export const getVideoReport = (params = {}, callback, isUpdate = false, isLoading = true) => {
  let report_summary
  const { buyer_id, video_ids, interaction_tally: type } = params
  if (params.video_ids) {
    report_summary = store.getters.videoReportSummaryById(params.video_ids, type)
    if (report_summary) report_summary = [report_summary]
  } else if (params.video_ids == null) {
    report_summary = store.getters.cachedReports(buyer_id, video_ids, type)
  }
  if (isUpdate || !report_summary || report_summary.length == 0) {
    if (isLoading) store.commit('setLoadingApi', true)
    let url = router.resolve({
      name: 'api.report',
    }).href
    http2.getList(url, params, data => {
      if (data && data.report_data && data.report_data.length) {
        let formatedReports = data.report_data.map(result => {
          return (report_summary = {
            user_count: result?.user_count,
            buyer_id: buyer_id,
            interaction_tally: type,
            video_id: result.video_id,
            interaction_type: data.interaction_type,
            national_flag: result.national_flag,
            total_2: result.total_2_count,
            total_3: result.total_3_count,
            tally_result: result.tally_result,
            tih: {
              total: result.tih.total,
              left: result.tih.left,
              right: result.tih.right,
            },
            tih_percent: {
              total: result.tih_percent.total,
              left: result.tih_percent.left,
              right: result.tih_percent.right,
            },
            tia: {
              total: result.tia.total,
              left: result.tia.left,
              right: result.tia.right,
            },
            tia_percent: {
              total: result.tia_percent.total,
              left: result.tia_percent.left,
              right: result.tia_percent.right,
            },
          })
        })
        if (isUpdate) {
          store.commit('setReportSummary', { formatedReports, type })
        } else {
          store.commit('addReportSummary', { formatedReports, type })
        }
        store.commit('setLoadingApi', false)
        callback && callback(formatedReports)
      } else {
        store.commit('setLoadingApi', false)
        callback && callback([])
      }
    })
  } else {
    callback && callback(report_summary)
  }
}

export const getVideoReportStep1 = (params = {}, callback, isUpdate = false, isLoading = true) => {
  let report_summary
  const { buyer_id, video_ids, interaction_tally: type } = params
  if (params.video_ids) {
    report_summary = store.getters.videoReportSummaryById(params.video_ids, type)
    if (report_summary) report_summary = [report_summary]
  } else if (params.video_ids == null) {
    report_summary = store.getters.cachedReports(buyer_id, video_ids, type)
  }
  if (isUpdate || !report_summary || report_summary.length == 0) {
    if (isLoading) store.commit('setLoadingApi', true)
    let url = router.resolve({
      name: 'api.report.promotion-interaction-report',
    }).href
    http2.getList(url, params, data => {
      if (data && data.report_data && data.report_data && data.report_data[0]) {
        let tally_result = []
        let result = data.report_data[0]
        if (
          result[`1_interaction_detail`] &&
          result[`2_interaction_detail`] &&
          result[`3_interaction_detail`] &&
          Object.keys(result[`1_interaction_detail`]).length
        ) {
          const length = Math.max(
            Object.keys(result[`1_interaction_detail`])[Object.keys(result[`1_interaction_detail`]).length - 1],
            Object.keys(result[`2_interaction_detail`])[Object.keys(result[`2_interaction_detail`]).length - 1],
            Object.keys(result[`3_interaction_detail`])[Object.keys(result[`3_interaction_detail`]).length - 1],
          )
          for (let second = 0; second <= length; second++) {
            tally_result.push({
              second: second,
              btn1: convertResponse(result[`1_interaction_detail`][`${second}`], type),
              btn2: convertResponse(result[`2_interaction_detail`][`${second}`], type),
              btn3: convertResponse(result[`3_interaction_detail`][`${second}`], type),
            })
          }
        }
        report_summary = {
          buyer_id: buyer_id,
          interaction_tally: type,
          video_id: result.video_id,
          interaction_type: data.interaction_type,
          total_2: convertResponse(result[`1_interaction_total`], type),
          total_3: convertResponse(result[`2_interaction_total`], type),
          total_4: convertResponse(result[`3_interaction_total`], type),
          tally_result: tally_result,
          tih: {
            total: convertResponse(result.tih[`general_interaction`], 0),
            btn1: convertResponse(result.tih[`1_interaction`], 0),
            btn2: convertResponse(result.tih[`2_interaction`], 0),
            btn3: convertResponse(result.tih[`3_interaction`], 0),
            '1_2_interaction': convertResponse(result.tih[`1_2_interaction`], 0),
            '2_3_interaction': convertResponse(result.tih[`2_3_interaction`], 0),
            '1_3_interaction': convertResponse(result.tih[`1_3_interaction`], 0),
          },
          tih_percent: {
            total: convertResponse(result.tih_percent[`general_interaction`], 1),
            btn1: convertResponse(result.tih_percent[`1_interaction`], 1),
            btn2: convertResponse(result.tih_percent[`2_interaction`], 1),
            btn3: convertResponse(result.tih_percent[`3_interaction`], 1),
            '1_2_interaction': convertResponse(result.tih_percent[`1_2_interaction`], 0),
            '2_3_interaction': convertResponse(result.tih_percent[`2_3_interaction`], 0),
            '1_3_interaction': convertResponse(result.tih_percent[`1_3_interaction`], 0),
          },
          tia: {
            total: convertResponse(result.tia[`general_interaction`], 0),
            btn1: convertResponse(result.tia[`1_interaction`], 0),
            btn2: convertResponse(result.tia[`2_interaction`], 0),
            btn3: convertResponse(result.tia[`3_interaction`], 0),
            '1_2_interaction': convertResponse(result.tia[`1_2_interaction`], 0),
            '2_3_interaction': convertResponse(result.tia[`2_3_interaction`], 0),
            '1_3_interaction': convertResponse(result.tia[`1_3_interaction`], 0),
          },
          tia_percent: {
            total: convertResponse(result.tia_percent[`general_interaction`], 1),
            btn1: convertResponse(result.tia_percent[`1_interaction`], 1),
            btn2: convertResponse(result.tia_percent[`2_interaction`], 1),
            btn3: convertResponse(result.tia_percent[`3_interaction`], 1),
            '1_2_interaction': convertResponse(result.tia_percent[`1_2_interaction`], 0),
            '2_3_interaction': convertResponse(result.tia_percent[`2_3_interaction`], 0),
            '1_3_interaction': convertResponse(result.tia_percent[`1_3_interaction`], 0),
          },
        }
        let formatedReports = [report_summary]
        if (isUpdate) {
          store.commit('setReportSummary', { formatedReports, type })
        } else {
          store.commit('addReportSummary', { formatedReports, type })
        }
        store.commit('setLoadingApi', false)
        callback && callback(formatedReports)
      } else {
        store.commit('setLoadingApi', false)
        callback && callback([])
      }
    })
  } else {
    callback && callback(report_summary)
  }
}

export const convertResponse = (data, tally_type) => {
  if (!data) return 0
  return tally_type ? data : data
}

export const getVideoReportCognition = (params = {}, callback, isUpdate = false, isLoading = true) => {
  let report_summary
  const { buyer_id, interaction_tally: type } = params
  report_summary = store.getters.videoReportCognitionById(params.video_ids, type)
  if (report_summary) report_summary = [report_summary]
  if (isUpdate || !report_summary || report_summary.length == 0) {
    if (isLoading) store.commit('setLoadingApi', true)
    let url = router.resolve({
      name: 'api.report.cognition',
    }).href
    http2.getList(url, params, data => {
      if (data?.items?.length != 0) {
        let formatedReports = data?.items?.map(item => {
          return (report_summary = {
            buyer_id: buyer_id,
            video_id: item.video_id,
            interaction_tally: type,
            interaction_type: item.interaction_type,
            data: item.data,
          })
        })
        if (isUpdate && report_summary) {
          store.commit('updateReportCognition', { formatedReports, type })
        } else {
          store.commit('addReportCognition', { formatedReports, type })
        }
        store.commit('setLoadingApi', false)
        callback && callback(formatedReports)
      } else {
        store.commit('setLoadingApi', false)
        callback && callback([])
      }
    })
  } else {
    callback && callback(report_summary)
  }
}

export const getSummaryNormData = (params = {}, callback, isUpdate = false) => {
  return Promise.all([
    getSummaryNormPromise(params, isUpdate),
    getReportInteractionTally2Promise(params, isUpdate),
  ]).then(data => {
    callback && callback(data)
  })
}

export const getSummaryNormPromise = (params, isUpdate) => {
  let { buyer_id } = params
  return new Promise((resolve, reject) => {
    let url = router.resolve({
      name: 'api.summary_norm',
      params: { buyer_id: buyer_id },
    }).href
    let item = store.getters.cachedChartBuyerNorm(buyer_id)
    if (!item || isUpdate) {
      http2.getList(
        url,
        { video_time_range: 0, video_ids: params.video_ids },
        data => {
          if (data.buyer_id) {
            if (item) {
              store.commit('updateChartBuyerNorm', data)
            } else {
              store.commit('addChartBuyerNorm', data)
            }
          }
          resolve(data)
        },
        error => {
          reject(error)
        },
      )
    } else {
      resolve(item)
    }
  })
}

export const getReportInteractionTally2Promise = (params, isUpdate) => {
  return new Promise((resolve, reject) => {
    let item = store.getters.cachedChartSummaryNorm(params.video_id)
    if (!item || isUpdate) {
      let url = router.resolve({
        name: 'api.report',
      }).href
      params = {
        ...params,
        interaction_tally: 2,
      }
      http2.getList(
        url,
        params,
        result => {
          let dataFormated = {}
          if (result && result.report_data) {
            let data = result.report_data[0]
            dataFormated = {
              ...params,
              ...data,
            }
            if (item) {
              store.commit('updateChartSummaryNorm', dataFormated)
            } else {
              store.commit('addChartSummaryNorm', dataFormated)
            }
          }
          resolve(dataFormated)
        },
        error => {
          reject(error)
        },
      )
    } else {
      resolve(item)
    }
  })
}

export const getAttributes = (callback, isUpdate = false) => {
  let attributes = store.getters.getAttributes
  if (!attributes.length || isUpdate) {
    let url = getApiUrl('api.jcp-attributes')
    http2.getList(
      url,
      {},
      data => {
        store.commit('setAttributes', data)
        callback && callback(data)
      },
      error => {
        console.log(error)
        callback && callback([])
      },
    )
  } else {
    callback && callback(attributes)
  }
}

export const getAttributesActive = (callback, isUpdate = false) => {
  let attributes = store.getters.getAttributesActive
  if (!attributes.length || isUpdate) {
    let url = getApiUrl('api.jcp-attributes')
    http2.getList(
      url,
      {},
      data => {
        store.commit('setAttributes', data)
        callback && callback(data.filter(e => e.status))
      },
      error => {
        console.log(error)
        callback && callback([])
      },
    )
  } else {
    callback && callback(attributes)
  }
}

export const getAttributesPromise = (isUpdate = false) => {
  return new Promise((resolve, reject) => {
    let attributes = store.getters.getAttributes
    if (!attributes.length || isUpdate) {
      let url = getApiUrl('api.jcp-attributes')
      http2.getList(
        url,
        {},
        data => {
          store.commit('setAttributes', data)
          resolve(data)
        },
        error => {
          reject(error)
        },
      )
    } else {
      resolve(attributes)
    }
  })
}

export const getReportSegmentRanks = (params, callback, isUpdate = false) => {
  let { project_id, video_id, interaction_tally } = params
  let reportData = store.getters.cachedReportSegmentRanks(project_id)
  if (!reportData || isUpdate) {
    let url = getApiUrl('report.segment-ranks')
    http2.getList(url, { project_id, video_id, interaction_tally }, data => {
      if (data) {
        store.commit('updateReportSegmentRanks', data)
      } else {
        store.commit('addReportSegmentRanks', data)
      }
      callback && callback(data)
    })
  } else {
    callback && callback(reportData)
  }
}

export const getReportSegmentRanksPromise = (params, isUpdate = false) => {
  return new Promise((resolve, reject) => {
    let { video_id, interaction_tally } = params
    let reportData = store.getters.cachedReportSegmentRanks(video_id)
    if (!reportData || isUpdate) {
      let url = getApiUrl('report.segment-ranks')
      http2.getList(
        url,
        { video_id, interaction_tally: interaction_tally || 0 },
        data => {
          data = {
            ...data,
            ...{ video_id: video_id },
          }
          if (reportData) {
            store.commit('updateReportSegmentRanks', data)
          } else {
            store.commit('addReportSegmentRanks', data)
          }
          resolve(data)
        },
        error => {
          reject(error)
        },
      )
    } else {
      resolve(reportData)
    }
  })
}

export const getVideoSegments = (params, callback, isUpdate = false) => {
  let { video_id } = params
  let videoSegment = store.getters.getVideoSegments
  if (!videoSegment.length || isUpdate) {
    let url = getApiUrl('video.segments')
    http2.getList(
      url,
      { video_id },
      data => {
        store.commit('setVideoSegments', data)
        callback && callback(data)
      },
      error => {
        console.log(error)
        callback && callback([])
      },
    )
  } else {
    callback && callback(videoSegment)
  }
}

export const getVideoSegmentsPromise = (params, isUpdate = false) => {
  return new Promise((resolve, reject) => {
    let { video_id } = params
    let videoSegment = store.getters.getVideoSegments
    if (!videoSegment.length || isUpdate) {
      let url = getApiUrl('video.segments')
      http2.getList(
        url,
        { video_id },
        data => {
          store.commit('setVideoSegments', data)
          resolve(data)
        },
        error => {
          reject(error)
        },
      )
    } else {
      resolve(videoSegment)
    }
  })
}

export const getReportSegments = (params, callback, isUpdate = false) => {
  let report_segment
  if (params.video_id) {
    report_segment = store.getters.videoReportSegmentById(params.video_id)
    if (report_segment) report_segment = [report_segment]
  } else if (params.video_id == null) {
    report_segment = store.getters.cachedReportSegments()
  }
  if (isUpdate || !report_segment || report_segment.length == 0) {
    let url = router.resolve({
      name: 'api.report-segments',
    }).href
    http2.getList(url, params, data => {
      if (isUpdate) {
        store.commit('setReportSegment', data)
      } else {
        store.commit('addReportSegment', data)
      }
      callback && callback(data)
    })
  } else {
    callback && callback(report_segment)
  }
}

export const getReportSegmentsPromise = (params, isUpdate = false) => {
  return new Promise((resolve, reject) => {
    let report_segment
    if (params.video_id) {
      report_segment = store.getters.videoReportSegmentById(params.video_id)
      if (report_segment) report_segment = [report_segment]
    } else if (params.video_id == null) {
      report_segment = store.getters.cachedReportSegments()
    }
    if (!report_segment || isUpdate) {
      let url = getApiUrl('api.report-segments')
      http2.getList(
        url,
        params,
        data => {
          if (data?.length) {
            data[0]?.data?.forEach(item => {
              item?.values.forEach(elm => {
                elm.btn1 = elm?.right_count
                elm.btn2 = elm?.left_count
                elm.btn3 = elm?.left_count
              })
            })
          }
          if (report_segment) {
            store.commit('setReportSegment', data)
          } else {
            store.commit('addReportSegment', data)
          }
          resolve(data)
        },
        error => {
          reject(error)
        },
      )
    } else {
      resolve(report_segment)
    }
  })
}

export const getReportSegmentAttribute = params => {
  return new Promise((resolve, reject) => {
    let report_segment
    if (params.video_id && params.attribute_value_ids) {
      report_segment = store.getters.getReportSegmentAttribute(params)
    }
    if (!report_segment) {
      let url = getApiUrl('api.report-segment-attributes')
      http2.getList(
        url,
        params,
        data => {
          store.commit('addReportSegmentAttribute', { data: data, params: params })
          resolve(data)
        },
        error => {
          reject(error)
        },
      )
    } else {
      resolve(report_segment)
    }
  })
}

export const getReportSegmentsPromiseStep1 = (params, isUpdate = false) => {
  return new Promise((resolve, reject) => {
    let report_segment
    if (params.video_id) {
      report_segment = store.getters.videoReportSegmentById(params.video_id)
      if (report_segment) report_segment = [report_segment]
    } else if (params.video_id == null) {
      report_segment = store.getters.cachedReportSegments()
    }
    if (!report_segment || isUpdate) {
      let url = getApiUrl('api.report.promotion-interaction-report-segments')
      http2.getList(
        url,
        params,
        data => {
          data = [data]
          if (data?.length) {
            data[0]?.data?.forEach(item => {
              item?.values.forEach(elm => {
                elm.btn1 = elm?.interest_count
                elm.btn2 = elm?.understand_count
                elm.btn3 = elm?.purchase_count
              })
            })
          }
          if (report_segment) {
            store.commit('setReportSegment', data)
          } else {
            store.commit('addReportSegment', data)
          }
          resolve(data)
        },
        error => {
          reject(error)
        },
      )
    } else {
      resolve(report_segment)
    }
  })
}

export const getReportScreeningPromise = params => {
  return new Promise((resolve, reject) => {
    let report_screening
    if (params.video_id) {
      report_screening = store.getters.videoReportScreeningById(params.video_id)
    }
    if (!report_screening) {
      let url = getApiUrl('api.report-screening')
      http2.getList(
        url,
        params,
        data => {
          store.commit('addReportScreening', data)
          resolve(data)
        },
        error => {
          reject(error)
        },
      )
    } else {
      resolve(report_screening)
    }
  })
}

export const getReportScreeningPromiseStep1 = params => {
  let key = {
    video_id: params?.video_id,
    interaction_tally: params?.interaction_tally,
  }
  return new Promise((resolve, reject) => {
    let report_screening
    if (params.video_id) {
      report_screening = store.getters.videoReportScreeningById(Object.values(key).join(','))
    }
    if (!report_screening) {
      let url = getApiUrl('api.report-screening-step1')
      http2.getList(
        url,
        params,
        data => {
          store.commit('addReportScreening', { data: data, params: Object.values(key).join(',') })
          resolve(data)
        },
        error => {
          reject(error)
        },
      )
    } else {
      resolve(report_screening)
    }
  })
}

export const getVideoScreeningPromise = params => {
  return new Promise((resolve, reject) => {
    let url = getApiUrl('api.video-screening')
    http2.getList(
      url,
      params,
      data => {
        resolve(data)
      },
      error => {
        reject(error)
      },
    )
  })
}

export const getReportInteractionTop = (params, callback) => {
  let { videoIds, interactionTally } = params
  let reportInteractionTop = store.getters.getReportInteractionTop
  if (videoIds || !reportInteractionTop) {
    let url = getApiUrl('api.report.interaction-top')
    http2.getList(url, { video_ids: videoIds, interaction_tally: interactionTally || 0 }, data => {
      store.commit('updateReportInteractionTop', { data: data })
      callback && callback(data)
    })
  } else {
    callback && callback(reportInteractionTop.data)
  }
}
export const getSegments = params => {
  return new Promise((resolve, reject) => {
    if (params.buyer_id) {
      let url = router.resolve({
        name: 'api.segments',
      }).href
      http2.get(
        url,
        params,
        data => {
          resolve(data)
        },
        err => reject(err),
      )
    }
  })
}

export const getYoutubeAudienceRating = (query, callback) => {
  let url = getApiUrl('api.youtube-audience-rating')
  http2.getList(url, query, data => {
    callback && callback(data)
  })
}

export const videosImages = (callback, params) => {
  let { videoIds, interactionTally, adId } = params
  let query = {
    video_ids: videoIds,
    interaction_tally: interactionTally || 0,
  }
  let videoImages = store.getters.getVideoImages(adId)
  if (!videoImages) {
    let url = getApiUrl('api.videos.images')
    http2.getList(url, query, data => {
      let formatData = {
        ad_id: adId,
        items: data,
      }
      store.commit('addAllVideoImage', formatData)
      callback && callback(data)
    })
  } else {
    callback && callback(videoImages.items)
  }
}
// market
export const buyerMarketNormReport = () => {
  return new Promise((resolve, reject) => {
    let url = router.resolve({
      name: 'api.market.buyer.norm',
    }).href
    http2.get(
      url,
      {
        video_time_range: 0,
      },
      data => {
        resolve(data)
      },
      err => reject(err),
    )
  })
}

export const reportTop = query => {
  return new Promise((resolve, reject) => {
    if (query) {
      let buyerId = query.buyer_id
      let videoIds = query.video_ids
      let interactionTally = query.interaction_tally
      let isStackBar = query.is_stack_bar
      let params = {
        video_ids: videoIds,
        buyer_id: buyerId,
        is_stack_bar: isStackBar,
      }
      if (interactionTally) params.interaction_tally = interactionTally
      let reportData = store.getters.getReportSummary
      if (buyerId && videoIds && !reportData) {
        let url = getApiUrl('api.report')
        http2.getList(
          url,
          params,
          data => {
            store.commit('addREportSummaryAd', data)
            resolve(data)
          },
          err => reject(err),
        )
      } else {
        resolve(reportData)
      }
    }
  })
}

export const reportTopStep1 = query => {
  return new Promise((resolve, reject) => {
    if (query) {
      let videoIds = query.video_ids
      let interactionTally = query.interaction_tally
      let params = {
        video_ids: videoIds,
      }
      if (interactionTally) params.interaction_tally = interactionTally
      let reportData = store.getters.getReportSummary
      if (videoIds && !reportData) {
        let url = getApiUrl('api.report.promotion-interaction-report')
        http2.getList(
          url,
          params,
          data => {
            store.commit('addREportSummaryAd', data)
            resolve(data)
          },
          err => reject(err),
        )
      } else {
        resolve(reportData)
      }
    }
  })
}

export const listVideoPromise = query => {
  return new Promise((resolve, reject) => {
    if (query) {
      let buyerId = query.buyer_id
      let videoIds = query.video_ids
      let deliverPeriodType = query.deliver_period_type
      let params = {
        // plan: 1,
        // interaction_button_1: 'Good',
        // "label_ids": "2,3,4,5,6",
        video_ids: videoIds,
        buyer_id: buyerId,
        // sort: 'id',
        // order: order || 'asc',
        ...(deliverPeriodType
          ? {
              deliver_period_type: deliverPeriodType,
            }
          : {}),
      }
      // if (currentUser && currentUser.role == 'admin') {
      //     params.buyer_id = buyerId
      // }
      // if(status){ params.status = status }
      let url = getApiUrl('api.videos')
      http2.getList(
        url,
        params,
        data => {
          store.commit(
            'updateVideosListInReportTop',
            data.items,
            // {
            //     buyer_id: buyerId,
            //     ad_id: projectId,
            //     items: data.items
            // }
          )
          resolve(data.items)
        },
        err => reject(err),
      )
    }
  })
}

export const listIconInteraction = () => {
  return new Promise((resolve, reject) => {
    let url = getApiUrl('api.interaction-icon')
    http2.getList(
      url,
      {},
      data => {
        resolve(data)
      },
      err => reject(err),
    )
  })
}

export const runBatch = callback => {
  return new Promise((resolve, reject) => {
    let url = getApiUrl('api.batch')
    http2.post(
      url,
      {},
      data => {
        resolve(data)
        callback()
      },
      error => {
        reject(error)
        callback()
      },
    )
  })
}

export const listVideoComments = (params = {}, id, callback) => {
  let video_comments = store.getters.getVideoComments({ ...{ id: id }, ...params })
  if (!video_comments || Object.keys(video_comments).length == 0) {
    store.commit('setLoadingApiVideoComment', true)
    let url = router.resolve({
      name: 'api.video-comment',
      params: { id: id },
    }).href
    http2.getList(
      url,
      params,
      data => {
        if (data && Object.keys(data).length) {
          delete params.t
          store.commit('setVideoComments', { params: { ...{ id: id }, ...params }, data: data })
          store.commit('setLoadingApiVideoComment', false)
          callback && callback(data)
        } else {
          store.commit('setLoadingApiVideoComment', false)
          callback && callback(video_comments)
        }
      },
      () => {
        store.commit('setLoadingApiVideoComment', false)
        callback && callback(video_comments)
      },
    )
  } else {
    callback && callback(video_comments)
  }
}

export const listQuestionInteraction = () => {
  return new Promise((resolve, reject) => {
    let url = getApiUrl('api.interaction_questions')
    http2.getList(
      url,
      {},
      data => {
        resolve(data)
      },
      err => reject(err),
    )
  })
}

export const reportTopStep3 = (query, callback) => {
  if (query) {
    let videoIds = query.video_ids
    let buyerId = query.buyer_id
    let params = {
      video_ids: videoIds,
      buyer_id: buyerId,
    }
    let data = store.getters.getReportSummaryStep3(params)
    if (data) {
      callback && callback(data)
    } else {
      if (videoIds && buyerId) {
        let url = getApiUrl('api.report.step3')
        http2.getList(
          url,
          params,
          data => {
            store.commit('addReportSummaryStep3', { data: data, params: params })
            callback && callback(data)
          },
          function () {
            callback && callback([])
          },
        )
      } else {
        callback && callback([])
      }
    }
  } else {
    callback && callback([])
  }
}

export const reportAvgStep3 = query => {
  return new Promise((resolve, reject) => {
    if (query) {
      let buyerId = query.buyer_id
      let questionId = query.question_id
      let labelIds = query.label_ids
      let params = {
        buyer_id: buyerId,
        question_id: questionId,
        label_ids: labelIds,
      }

      if (buyerId && questionId && labelIds) {
        let url = getApiUrl('api.interaction_norm')
        http2.get(
          url,
          params,
          data => {
            resolve(data)
          },
          err => {
            reject(err)
          },
        )
      } else {
        reject()
      }
    }
  })
}

export const listInteractionComments = (params = {}, callback) => {
  let interaction_comments = store.getters.getInteractionComments(params)
  if (!interaction_comments || !Object.keys(interaction_comments).length) {
    let url = router.resolve({
      name: 'api.video-interaction-comment',
    }).href
    http2.getList(
      url,
      params,
      data => {
        if (data && Object.keys(data).length) {
          store.commit('setInteractionComments', { params: params, data: data })
          callback && callback(data)
        } else {
          callback && callback(interaction_comments)
        }
      },
      () => {
        callback && callback(interaction_comments)
      },
    )
  } else {
    callback && callback(interaction_comments)
  }
}

export function uploadCsvComment(video_id, fileInfo = {}, success, fail) {
  if (fileInfo && video_id) {
    uploadCsvCommentToS3(
      fileInfo,
      function (file_name) {
        if (file_name) {
          let url = router.resolve({
            name: 'api.csv_comment.upload_file',
          }).href
          http2.post(
            url,
            {
              filename: file_name,
              video_id: video_id,
            },
            function () {
              success()
            },
            () => {
              fail()
            },
          )
        }
      },
      () => {
        fail()
      },
    )
  }
}

export function uploadCsvCommentToS3(fileInfo, success, fail) {
  let url = router.resolve({
    name: 'api.csv_comment.upload_url',
  }).href
  http2.get(
    url,
    {},
    data => {
      let urlS3 = data?.upload_url
      if (urlS3) {
        http2.uploadFileS3(urlS3, fileInfo?.file, () => {
          success(data?.file_name)
        })
      }
    },
    () => {
      fail && fail()
    },
  )
}

export const reportTrendWord = (query, callback) => {
  if (query) {
    let videoIds = query.video_id
    let params = {
      video_id: videoIds,
    }
    let data = store.getters.getReportTrendWord(params)
    if (data) {
      callback && callback(data)
    } else {
      if (videoIds) {
        let url = getApiUrl('api.trend-report')
        http2.getList(
          url,
          params,
          data => {
            store.commit('addReportTrendWord', { data: data, params: params })
            callback && callback(data)
          },
          function () {
            callback && callback([])
          },
        )
      } else {
        callback && callback([])
      }
    }
  } else {
    callback && callback([])
  }
}

export const reportVideoTrend = (query, callback) => {
  if (query) {
    let videoIds = query.video_ids
    let params = {
      video_ids: videoIds,
    }
    let data = store.getters.getReportVideoTrend(params)
    if (data) {
      callback && callback(data)
    } else {
      if (videoIds) {
        let url = getApiUrl('api.video-trend')
        http2.getList(
          url,
          params,
          data => {
            store.commit('addReportVideoTrend', { data: data, params: params })
            callback && callback(data)
          },
          function () {
            callback && callback([])
          },
        )
      } else {
        callback && callback([])
      }
    }
  } else {
    callback && callback([])
  }
}

export const getReportIntention = query => {
  return new Promise((resolve, reject) => {
    if (query) {
      let videoIds = query.video_ids
      let intentionId = query.intention_id
      let level = query.level
      let allFlg = query.all_flg
      let params = {
        video_ids: videoIds,
        intention_id: intentionId,
        ...(level && { level: level }),
        all_flg: allFlg,
      }
      let reportData = store.getters.getIntentionReport(convertVideoParams(params))
      if (reportData) {
        resolve(reportData)
      } else {
        let url = getApiUrl('api.report-intention')
        http2.getList(
          url,
          params,
          data => {
            store.commit('addIntentionReport', { data: data, params: convertVideoParams(params) })
            resolve(data)
          },
          err => reject(err),
        )
      }
    }
  })
}

export const getReportIntentionSecond = query => {
  return new Promise((resolve, reject) => {
    if (query) {
      let videoIds = query.video_ids
      let intentionId = query.intention_id
      let level = query.level
      let allFlg = query.all_flg
      let params = {
        video_ids: videoIds,
        intention_id: intentionId,
        ...(level && { level: level }),
        all_flg: allFlg,
      }
      let reportData = store.getters.getIntentionSecondReport(convertVideoParams(params))
      if (reportData) {
        resolve(reportData)
      } else {
        let url = getApiUrl('api.report-intention-second')
        http2.getList(
          url,
          params,
          data => {
            store.commit('addIntentionSecondReport', { data: data, params: convertVideoParams(params) })
            resolve(data)
          },
          err => reject(err),
        )
      }
    }
  })
}

export const getCommentIntention = query => {
  return new Promise((resolve, reject) => {
    if (query) {
      let videoIds = query.video_ids
      let intentionId = query.intention_id
      let level = query.level
      let params = {
        video_ids: videoIds,
        intention_id: intentionId,
        level: level,
      }
      let reportData = store.getters.getIntentionComment(convertVideoParams(params))
      if (reportData) {
        resolve(reportData)
      } else {
        let url = getApiUrl('api.intention-comment')
        http2.getList(
          url,
          params,
          data => {
            store.commit('addIntentionComment', { data: data, params: convertVideoParams(params) })
            resolve(data)
          },
          err => reject(err),
        )
      }
    }
  })
}

export const getListIntention = () => {
  return new Promise((resolve, reject) => {
    let url = getApiUrl('api.intentions')
    http2.getList(
      url,
      {},
      data => {
        resolve(data)
      },
      err => reject(err),
    )
  })
}

export const getVideoIntention = query => {
  return new Promise((resolve, reject) => {
    if (query) {
      let videoIds = query.video_ids
      let params = {
        video_ids: videoIds,
      }

      if (videoIds) {
        let url = getApiUrl('api.video-intentions')
        http2.get(
          url,
          params,
          data => {
            resolve(data)
          },
          err => {
            reject(err)
          },
        )
      } else {
        reject()
      }
    }
  })
}

export const getRsFeasibilityApiId = (list_id_string, success, fail) => {
  if (list_id_string) {
    const data = {
      attribute_value_id: list_id_string,
    }
    const url = getApiUrl('api.rs-feasibility')
    store.commit('setLoadingApi', true)
    http2.post(
      url,
      data,
      response => {
        store.commit('setLoadingApi', false)
        success && success(response)
      },
      error => {
        store.commit('setLoadingApi', false)
        fail && fail(error)
      },
    )
  } else {
    fail && fail('Empty list id string')
  }
}

export const getRsFeasibilityIds = (feasibility_api_id, success, fail) => {
  if (feasibility_api_id) {
    const url = getApiUrl('api.rs-feasibility')
    const params = {
      rs_feasibility_api_id: feasibility_api_id,
    }
    http2.get(url, params, data => {
      success && success(data)
    })
  } else {
    fail && fail('Empty feasibility api id')
  }
}

export const getChatgptSummarization = query => {
  return new Promise((resolve, reject) => {
    if (query) {
      let videoId = query.video_id
      let params = {
        video_id: videoId,
      }

      if (videoId) {
        let url = getApiUrl('api.chatgpt-summarization')
        http2.get(
          url,
          params,
          data => {
            resolve(data)
          },
          err => {
            reject(err)
          },
        )
      } else {
        reject()
      }
    }
  })
}

export const postVideoCapture = (params, success, fail) => {
  if (params) {
    const url = getApiUrl('api.video-capture.post')
    http2.post(
      url,
      params,
      response => {
        success && success(response)
      },
      error => {
        fail && fail(error)
      },
    )
  } else {
    fail && fail('Empty params')
  }
}

export const getVideoCaptureApi = (video_tmp_file_id, success, fail) => {
  if (video_tmp_file_id) {
    const url = getApiUrlWithParam('api.video-capture.get', {
      id: video_tmp_file_id,
    })
    http2.get(
      url,
      null,
      data => {
        success && success(data)
      },
      error => {
        fail && fail(error)
      },
    )
  } else {
    fail && fail('Empty video tmp file id')
  }
}

export const getCaptureUploadUrl = (params, success, fail) => {
  const url = getApiUrlWithParam('api.video-capture.capture-upload-url', {
    id: params.video_tmp_id,
  })
  http2.get(
    url,
    {
      second: params.second,
      capture: params.capture,
    },
    data => {
      success && success(data)
    },
    error => {
      fail && fail(error)
    },
  )
}

export const getVideoQuestion = (buyer_id, success, fail) => {
  const url = getApiUrlWithParam('api.video-question', {
    buyer_id: buyer_id,
  })

  http2.get(
    url,
    null,
    data => {
      success && success(data)
    },
    error => {
      fail && fail(error)
    },
  )
}
export const getReportIntentionRanks = params => {
  return new Promise((resolve, reject) => {
    let url = getApiUrl('api.report.intention-ranks')
    http2.getList(
      url,
      params,
      data => {
        resolve(data?.intention_ranks)
      },
      error => {
        reject(error)
      },
    )
  })
}

export const getVideoIntentionTabular = params => {
  return new Promise((resolve, reject) => {
    let url = getApiUrl('api.video.intention-tabular')
    http2.getList(
      url,
      params,
      data => {
        resolve(data?.intention_ranks)
      },
      error => {
        reject(error)
      },
    )
  })
}

export const postVideoIntentionTabular = (params, success, fail) => {
  if (params) {
    const url = getApiUrl('api.video.intention-tabular.insert')
    http2.post(
      url,
      params,
      response => {
        success && success(response)
      },
      error => {
        fail && fail(error)
      },
    )
  } else {
    fail && fail('Empty params')
  }
}

export const getListVideoComments = query => {
  return new Promise((resolve, reject) => {
    if (query) {
      let params = {
        video_ids: query.video_ids,
      }
      let reportData = store.getters.getListVideoComments(query.video_ids)
      if (reportData) {
        resolve(reportData)
      } else {
        let url = getApiUrl('api.video-comments')
        http2.getList(
          url,
          params,
          data => {
            store.commit('addListVideoComments', { data: data.items, params: query.video_ids })
            resolve(data.items)
          },
          err => reject(err),
        )
      }
    }
  })
}

export const getVideoIntentionCount = query => {
  return new Promise((resolve, reject) => {
    if (query) {
      let params = {
        video_ids: query.video_ids,
        intention_ids: query.intention_ids,
      }

      let reportData = store.getters.getListVideoComments(convertVideoParams(params))
      if (reportData) {
        resolve(reportData)
      } else {
        let url = getApiUrl('api.video-intentions-count')
        http2.getList(
          url,
          params,
          data => {
            store.commit('addListVideoIntentionCount', { data: data, params: convertVideoParams(params) })
            resolve(data)
          },
          err => reject(err),
        )
      }
    }
  })
}

export const getTargetFile = (userId, callback, fail) => {
  if (userId) {
    let url = getApiUrlWithParam('api.target-file', {
      user_id: userId,
    })
    http2.getList(
      url,
      null,
      data => {
        callback && callback(data)
      },
      error => {
        fail && fail(error)
      },
    )
  } else {
    callback && callback(null)
  }
}

export const getTableauToken = (buyerId, callback, fail) => {
  if (buyerId) {
    let url = getApiUrlWithParam('api.tableau-token', {
      buyer_id: buyerId,
    })
    http2.getList(
      url,
      null,
      data => {
        callback && callback(data)
      },
      error => {
        fail && fail(error)
      },
    )
  } else {
    callback && callback(null)
  }
}

export const getTableauData = (buyerId, callback, fail) => {
  if (buyerId) {
    let url = getApiUrlWithParam('api.tableau-data', {
      buyer_id: buyerId,
    })
    http2.getList(
      url,
      null,
      data => {
        callback && callback(data)
      },
      error => {
        fail && fail(error)
      },
    )
  } else {
    callback && callback(null)
  }
}

export const getMarketResearchFile = (userId, callback, fail) => {
  if (userId) {
    let url = getApiUrlWithParam('api.market-research-file', {
      user_id: userId,
    })
    http2.getList(
      url,
      null,
      data => {
        callback && callback(data)
      },
      error => {
        fail && fail(error)
      },
    )
  } else {
    callback && callback(null)
  }
}
