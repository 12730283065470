<template>
  <div class="group-label-list mt-5">
    <template v-for="label in labels">
      <div
          class="tag tag-one"
          :class="{ active: activeLabel(label.id) }"
          :key="'label-' + label.id"
          @click="selectLabel(label.id)"
      >
        <div class="text-center">
          {{ label.name }}
        </div>
      </div>
    </template>
    <button class="btn btn-add" @click="openModal"
            :disabled="!currentUser && currentUser.role === 'admin' || currentUser.role !== 'admin' && currentUser.owner_id !== clientIdSelected">
    </button>
  </div>
</template>

<script>
export default {
  props: {
    labels: Array,
    labelIdSelected: Array,
    currentUser: Object,
    clientIdSelected: [Number, String]
  },
  methods: {
    selectLabel(labelId) {
      this.$emit("selectLabel", labelId);
    },
    activeLabel(id) {
      return this.labelIdSelected.indexOf(id) > -1 ? true : false
    },
    openModal() {
      this.$emit("openModal")
    }
  },
  computed: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.group-label-list {
  display: flex;
  flex-wrap: wrap;
    
  .tag, .btn-add {
    margin-right: 8px;
    margin-top: 8px;
  }
}
</style>
