<template>
  <div class="options-range">
    <template v-for="(option, i) in options">
      <div
          class="item-range"
          :class="{ active: option.step == optionSelected }"
          :key="option.step"
          @click="handleSelected(option.step)"
      >
        {{ option.date }}d
      </div>
      <div class="item-range split" v-if="i < (options.length - 1)" :key="'split-' + i">|</div>
    </template>
  </div>
</template>
<script>
import EventBus from "@/commons/event_bus";

export default {
  props: {
    options: Array, //ex: [{step: 1, second: 8}, {step: 2, second: 16}, {step: 4, second: 32}, {step: 8, second: 64}, {step: 15, second: 120}]
    eventName: String, //ex: name of event register in event bus,
    selected: Number
  },
  data() {
    return {
      optionSelected: 1
    }
  },
  watch: {
    selected: function (newValue) {
      this.optionSelected = newValue;
    }
  },
  methods: {
    handleSelected(step) {
      this.optionSelected = step;
      EventBus.$emit(this.eventName, step);
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/variable';

.options-range {
  position: absolute;
  top: -30px;
  right: 20px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $middle-black;

  .item-range {
    font-size: 10px;
    font-family: "Avenir Next";
    font-weight: 500;
    line-height: 16px;
    cursor: pointer;
    margin-right: 8px;

    &.active {
      color: $black;
      font-weight: 700;
    }

    &.split {
      font-size: 8px;
      line-height: 8px;
      color: $light-black;
    }
  }
}
</style>