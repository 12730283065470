<template>
  <div class="top top-before top-market-research">
        <div class="content-inner">
          <div class="content-exlain">
            <p class="title">Market Research</p>
            <p class="sentence">市場に公開されているCM動画のデータがいつでも確認できる！</p>
            <div class="download-button">
              <div
                class="button-download-file"
                @click="downloadFile"
              >
                <img src="/images/svg/icon_download_pdf.png" alt="download"/>
                <span class="ml-1">資料をダウンロード</span>
              </div>
            </div>
          </div>
          <div class="list-step">
            <div class="step-item">
              <div class="image-circle">
                <p class="step">1</p>
                <div class="content-image">
                  <img src="/images/img/market-research/step_1.png" alt="">
                </div>
              </div>
              <p class="step-title">PlayAdsの豊富な独自データ</p>
              <p class="step-explain">PlayAdsが検証した<br>CM動画データを比較・確認できる</p>
            </div>
            <div class="step-item">
              <div class="image-circle">
                <p class="step">2</p>
                <div class="content-image">
                  <img src="/images/img/market-research/step_2.png" alt="">
                </div>  
              </div>
              <p class="step-title">市場の動向がわかる</p>
              <p class="step-explain">業界ごとにCM動画が確認できるため<br>市場の動きをリアルタイムで把握</p>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
import {getMarketResearchFile} from "@/commons/api_service";
export default {
  components: {
  },
  name: "MarketResearch",
  props: {},
  data() {
    return {
      currentUser: {},
      marketResearchFilerUrl: ''
    };
  },
  watch: {
    marketResearchFilerUrl(newVal) {
      if (newVal !== '') {
        this.$http2.downloadPDF(newVal)
      }
    }
  },
  methods: {
    downloadFile() {
      this.getFileUrl()
      if(this.marketResearchFilerUrl !== '') {
        this.$http2.downloadPDF(this.marketResearchFilerUrl)
      }
    },
    getFileUrl() {
      this.getMarketResearchFile(this.currentUser.user_id)
    },
    getMarketResearchFile(user_id) {
      let vm = this;
      getMarketResearchFile(user_id, function (data) {
        if (data && data.download_url) {
          vm.marketResearchFilerUrl = data.download_url
        }
      })
    }
  },
  mounted() {
    let currentUser = this.$store.getters.currentUser;
    this.currentUser = currentUser;
    if (currentUser.role == "admin" || (currentUser.role == "buyer" && currentUser.is_market_research == 1)) {
      this.$router.push({
        name: 'home',
        query: {
          buyer_id: process.env.VUE_APP_MARKET_RESEARCH_BUYER_ID
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables/_colors.scss";
  .top-market-research {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    padding-bottom: 20px;
    p {
      margin: 0;
    }
    p, div {
      font-family: "Noto Sans JP";
    }
    .content-inner {
      display: inline-block;
      .content-exlain {
        .title {
          font-size: 60px;
          font-weight: 600;
          text-align: center;
        }
        .sentence {
          font-size: 20px;
          color: $green;
          margin: 15px 0;
          font-weight: bold;
          text-align: center;
        }
        .download-button {
          display: flex;
          justify-content: center;
          .button-download-file {
            cursor: pointer;
            display: flex;
            padding: 10px 20px;
            align-items: center;
            justify-content: center;
            gap: 10px;
            border-radius: 10px;
            background: $green;
            margin-top: 20px;
            width: 180px;
            span {
              color: $white;
              font-size: 15px;
              font-style: normal;
              font-weight: 700;
              line-height: 23px;
            }
          }
        }
      }
      .list-step {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 50px;
        .step-item {
          margin: 0 20px;
          .image-circle {
            position: relative;
            border-radius: 50%;
            width: 288px;
            height: 288px;
            background-color: #EDF7F4;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
            .step {
              position: absolute;
              top: -5px;
              left: 20px;
              font-size: 72px;
              font-weight: bold;
              font-family: 'Avenir Next';
            }
            .content-image {
              width: 65%;
              img {
                width: 100%;
              }
            }
          }
        }
        .step-title {
          font-size: 20px;
          text-align: center;
          font-weight: bold;
          margin-bottom: 20px;
        }
        .step-explain {
          text-align: center;
          line-height: 1.75;
        }
      }
    }
  }
</style>
