<template>
  <div class="row">
    <div class="col-1 col-lg-1"></div>
    <div class="col-11 col-lg-11 mr-0 outline-area">
      <client-list
        v-if="$store.getters.isAdmin"
        :clients="clients"
        @select="updateBuyerId"
        class="mb-2"
        :selected="params.buyer_id"
      ></client-list>
      <div class="table-wrapper">
        <table class="table table-scroll">
          <tr>
            <th class="none-boder">
              <div class="icon-arrow">アップロード日時</div>
            </th>
            <th class="none-boder">
              <div class="icon-arrow">アップロードステータス</div>
            </th>
            <th class="none-boder w-50">
              <div class="icon-arrow">備考</div>
            </th>
            <th class="none-boder">
              <div class="icon-arrow">登録完了日時</div>
            </th>
          </tr>
          <tr v-for="segmentFile in segmentFiles" :key="segmentFile.id">
            <td>{{ convertDateFormat(segmentFile.created_at) }}</td>
            <td :class="segmentFile.status == 3 ? 'text-red' : 'text-green'">
              {{ statusMap[segmentFile.status] }}
            </td>
            <td>{{ segmentFile.description }}</td>
            <td>{{ convertDateFormat(segmentFile.updated_at) }}</td>
          </tr>
        </table>
      </div>
      <scroll-loader
        :loader-method="loadMoreData"
        :loader-disable="loadDisabled"
        :loader-color="'#49ab94'"
      ></scroll-loader>
    </div>
  </div>
</template>

<script>
import ClientList from "../../components/Select/ClientList.vue";
import router from "../../routes";
import http2 from "../../commons/http2";
import { listBuyersActive } from "../../commons/api_service";
export default {
  name: "SegmentFilesList",
  components: { ClientList },
  props: {},
  data() {
    return {
      params: {
        page: 0,
        limit: 20,
        text: "",
        sort: "id",
        order: "desc",
        buyer_id: 0,
      },
      clients: [],
      segmentFiles: [],
      statusMap: {
        0: "待機中",
        1: "処理中",
        2: "終了",
        3: "失敗",
        4: "キャンセール",
      },
      loadDisabled: false,
    };
  },
  watch: {
    "params.buyer_id": function () {
      this.getSegmentFiles(false);
    },
  },
  methods: {
    getSegmentFiles(isContinous) {
      if (this.params.buyer_id == 0) {
        return;
      }
      this.loadDisabled = false;
      if (!isContinous) {
        this.params.page = 1;
      } else {
        this.params.page += 1;
      }
      let url = router.resolve({ name: "api.segment-files" }).href;
      http2.getList(
        url,
        this.params,
        (data) => {
          if (!isContinous) {
            this.segmentFiles = data.items;
          } else {
            this.segmentFiles = [...this.segmentFiles, ...data.items];
          }
          if (this.params.limit > data.items.length) {
            this.loadDisabled = true;
          }
        },
        () => {
          this.loadDisabled = true;
        }
      );
    },
    loadMoreData() {
      this.getSegmentFiles(true);
    },
    updateBuyerId(selectedId) {
      this.params.buyer_id = selectedId;
      this.$router.replace({query:{buyer_id : this.params.buyer_id} })

    },
    convertDateFormat(date) {
      let parts = date.split(" ");
      let datePart = parts[0];
      let hourPart = parts[1];

      datePart = datePart.split("-").join("/");

      return [datePart, hourPart].join(" ");
    },
  },
  mounted() {
    let buyer = this.$route.query.buyer_id;
    this.params.buyer_id = buyer;
    let currentUser = this.$store.getters.currentUser;
    if (currentUser && this.$store.getters.isClient) {
      this.params.buyer_id = currentUser.owner_id;
    } else {
      listBuyersActive((buyerActive) => {
        this.clients = buyerActive;
      }, true);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_segment_files_list.scss";
</style>