<template>
  <div>
    <div class="row-3">
      <div class="row-3-col-1" id="listVideo">
        <div class="card video-list">
          <div class="body">
            <div class="list-checkbox">
              <div
                  v-for="item in avgs"
                  :key="item.id"
                  class="list-item"
                  :id="item.id"
                  :class="{ 'active selected': selectedSeriesId == item.id }"
              >
                <i class="icon icon-checkbox"
                   :class="{ checked: shownReportIds.find(e => e == item.id)}"
                   @click="shownAvg(item)"></i>
                <div class="item-name pl-2 w-100" @click="selectedVideo(item.id)">
                  平均値
                </div>
              </div>
              <div
                  class="list-item item-compare"
                  :class="{
                  'active selected': selectedSeriesId == 'video-' + video.id,
                }"
                  v-for="(video, index) in videos"
                  :key="'video-' + index"
                  :id="'video-' + video.id"
              >
                <div class="item">
                  <i
                      class="icon icon-checkbox"
                      :class="{ checked: video.checked }"
                      @click="checkedVideo(video)"
                  ></i>
                  <div class="item-name pl-2" @click="selectedVideo(video)">
                    <span class="item-time">
                      {{ video.start_at | moment("YYYY/M/DD") }} {{ video.video_length }}s</span
                    >
                    {{ video.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer card-footer-step3">
            <div class="settingAvg" @click="showSettingModal = true">
              <i class="icon icon-small-setting mr-1"></i>
              <div>平均値設定</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row-3-col-2 chart-area" id="chartArea">
        <step-option
            :options="stepOptions"
            :selected="step"
            :eventName="changeStepOption"
        ></step-option>
        <div class="title-y-axis title-y-axis-left">%</div>
        <div class="title-y-axis title-y-axis-right" v-if="hasTrendWordChart">トレンド数</div>
        <div class="title-x-axis">Day</div>
        <i class="icon icon-prev" v-if="prevPartEnabled" @click="prevPart"></i>
        <i class="icon icon-next" v-if="nextPartEnabled" @click="nextPart"></i>
        <line-chart
            @setChart="getChart"
            :part="part"
            :step="step"
            :changeStepChart="changeStepOption"
            :dataReport="dataReport"
            :dataTrendWord="dataTrendWord"
            :seriesData="seriesData"
            :selectedId="selectedSeriesId"
            :column="column"
            :maxColumn="maxColumn"
            @addClassActive="addClassActive"
            @removeClassActive="removeClassActive"
            :nameChart="'Percentage'"
            @updateVisibleTrendWordChart="updateVisibleTrendWordChart"
        ></line-chart>
      </div>
    </div>
    <setting-average
        v-show="showSettingModal"
        :buyerId="buyerId"
        @closeModal="showSettingModal = false"
        :labels="labels"
        :labelsSelected="labelsSelected"
        :isShow="showSettingModal"
    ></setting-average>
  </div>
</template>
<script>
import LineChart from "@/components/Charts/LineStep3.vue";
import StepOption from "@/components/Top/CompareStep3/StepOption.vue";
import EventBus from "@/commons/event_bus";
import {formatSeriesDataSummaryVideoStep3} from "@/commons/charts";
import SettingAverage from "@/components/Top/CompareStep3/SettingAverage";

const jQuery = require("jquery");

export default {
  components: {
    LineChart,
    StepOption,
    SettingAverage,
  },

  props: {
    videos: Array,
    dataReport: Object,
    dataTrendWord: Array,
    checkedProject: Boolean,
    buyerId: Number,
    questionSelected: Object,
    labels: Array,
    labelsSelected: Array,
    summaryAvg: {},
    videoIds: String,
  },
  data() {
    return {
      chart: null,
      checked: false,
      stepOptions: [
        {step: 1, date: 7},
        {step: 2, date: 14},
        {step: 3, date: 21},
        {step: 4, date: 28},
      ],
      avgs : [{
        checked: true,
        id: "avg",
        name: "すべての動画",
        value: 0
      }],
      changeStepOption: "changeStep",
      selectedSeriesId: "",
      shownReportIds: [],
      step: 1,
      part: 1,
      seriesData: [],
      column: 7,
      maxColumn: 7,
      showSettingModal : false,
      hasTrendWordChart: false,
    };
  },
  watch: {
    videos: function (e) {
      let videoIds = e.map(item => item.id);
      if (videoIds.length > 0 && !this.checkedProject) {
        this.selectedSeriesId = "avg";
        this.shownReportIds = ["avg"];
        this.seriesData = [this.summaryAvg];
        for (let video of this.videos) {
          this.checkedVideo(video, true)
        }
        this.$emit('initCheckedProject');
      }
    },
    questionSelected : {
      handler: function (newValue) {
        this.seriesData = [this.summaryAvg]
        for (let video of this.videos) {
          let dataReport = this.$store.getters.getReportSummaryStep3({
            video_ids: this.videoIds,
          });
          let videoReport = dataReport ? dataReport.report_data.find(
              (e) => e.video_id == video.id
          ) : {};
          if(this.shownReportIds && this.shownReportIds.includes("video-" + video.id)) {
            let seriesData = formatSeriesDataSummaryVideoStep3(videoReport, video, newValue.id);
            this.seriesData.push(seriesData);
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    selectedChart(id) {
      let video;
      if (id == "avg") {
        video = "avg";
      } else {
        video = this.videos.find(e => e.id == id);
      }
      this.selectedVideo(video, false)
    },
    getChart(chart) {
      this.chart = chart;
    },
    selectedVideo(video, reload = true) {
      let selectedSeriesId;
      if (video == "avg") {
        selectedSeriesId = video;
      }
      else {
        selectedSeriesId = "video-" + video.id;
      }
      if (this.shownReportIds.find((e) => e == selectedSeriesId)) {
        this.selectedSeriesId = selectedSeriesId;
        if (reload) {
          this.$nextTick(() => {
            this.part = 1;
          })
        }
      }
    },
    shownAvg(item) {
      let id = item.id;
      let index = this.shownReportIds.findIndex((e) => e == id);
      if (index == -1) {
        this.shownReportIds.push(id);
        let seriesData = this.summaryAvg;
        this.seriesData.push(seriesData);
      } else {
        let index2 = this.seriesData.findIndex((e) => e.id == id);
        if (this.selectedSeriesId == id) {
          this.selectedSeriesId = null;
        }
        this.shownReportIds.splice(index, 1);
        this.seriesData.splice(index2, 1);
      }
    },
    checkedVideo(video, isInitData = false) {
      let index = this.shownReportIds.findIndex(
          (e) => e == "video-" + video.id
      );
      if (isInitData && this.$route.name === 'top-compare-step-3') {
        this.$set(video, "checked", true);
      } else {
        this.$set(video, "checked", !video.checked);
      }
      this.$emit("selectVideo", video);
      if (index == -1 && video.checked) {
        this.shownReportIds.push("video-" + video.id);
        let dataReport = this.$store.getters.getReportSummaryStep3({
          video_ids: this.videoIds,
        });
        let videoReport = dataReport ? dataReport.report_data.find(
            (e) => e.video_id == video.id
        ) : {};
        let seriesData = formatSeriesDataSummaryVideoStep3(videoReport, video, this.questionSelected.id);
        this.seriesData.push(seriesData);
      } else {
        let index2 = this.seriesData.findIndex(
            (e) => e.id == "video-" + video.id
        );
        if (index2 != -1) {
          if (this.selectedSeriesId == "video-" + video.id) {
            this.selectedSeriesId = null;
          }
          this.shownReportIds.splice(index, 1);
          this.seriesData.splice(index2, 1);
        }
      }
    },
    handleChangeStepChart(step) {
      this.step = step;
      this.part = 1;
    },
    nextPart() {
      this.part += 1;
    },
    prevPart() {
      this.part -= 1;
    },
    addClassActive(data) {
      if (data) {
        jQuery(".list-item").not(".selected").removeClass("active");
        jQuery("#" + data).addClass("active");
      }
    },
    removeClassActive(data) {
      if (data) {
        jQuery(".list-item").not(".selected").removeClass("active");
      }
    },
    updateVisibleTrendWordChart(state) {
      this.hasTrendWordChart = state;
    },
  },
  computed: {
    nextPartEnabled: function () {
      let seriesLength = this.seriesData.map((e) => e.data.length);
      let max = Math.max(...seriesLength) - 1;
      return max > this.part * this.step * 7;
    },
    prevPartEnabled: function () {
      return this.part > 1;
    },
  },
  mounted() {
  },
  created() {
    EventBus.$on("changeStep", this.handleChangeStepChart);
    EventBus.$on("selectedChart", this.selectedChart);
  },
  beforeDestroy() {
    EventBus.$off("changeStep", this.handleChangeStepChart);
    EventBus.$off("selectedChart", this.selectedChart);
  },
};
</script>
<style lang="scss" scoped>
.video-list {
  overflow: hidden;
}
.chart-area {
  margin-top: 20px;

  .title-y-axis {
    font-family: "Avenir Next";
    font-size: 8px;
    font-weight: 700;
    top: -10px !important;

    &.title-y-axis-left {
      left: 34px !important;
    }

    &.title-y-axis-right {
      right: 0 !important;
      left: unset  !important;
    }
  }
  .title-x-axis {
    font-family: "Avenir Next";
    font-size: 8px;
    font-weight: 700;
    bottom: 12px !important;
  }

  .icon-prev, .icon-next {
    bottom: -30px !important;
  }
}
</style>
