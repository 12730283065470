<template>
  <div
    class="
      modal
      add-segment-multiple add-segment-multiple-twoselected
      form-multiple modal-show
    "
    ref="modal"
    v-show="isShown"
  >
    <div class="modal-content">
      <div class="header">
        <div class="btn-back">
          <i class="icon icon-prev" @click="closeModal"></i>
        </div>
      </div>
      <div class="body">
        <div class="label">{{ attribute.name }}</div>
        <div class="element-segment" 
          v-for="(segment, index) in listSegment"
          :key="'segment-' + index">
          <div class="item-name">
            <div class="checkbox-segment"  
              @click="showListValue(index)">
              <i class="icon icon-checkbox" :class="{'checked' : showExpan[index]}"></i>
              <span class="label-segment">{{ segment.name }}</span>
            </div>
          </div>
          <div class="checkbox-list-select" v-show="showExpan[index]">
            <div
              class="checkbox-segment"
              v-for="value in segment.attribute_values"
              :key="'attribute-value-' + value.id"
              @click="toggleValue(value)"
            >
              <i class="icon icon-checkbox" :class="{'checked': isChecked(value)}"></i>
              <span class="label-segment">{{getSegmentValue(value.value)}}</span>
            </div>
          </div>
        </div>
        <div class="btn-submit">
          <button class="btn btn-primary btn-addition" @click="addSegment()" :disabled="!segmentSelecteds.length">
            追加
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "../../commons/event_bus";

export default {
  props: {
    attribute: Object,
    isShown: Boolean,
    attributeStatus: Object,
    prevSelected: Array
  },
  data() {
    return {
      listSegment: [],
      segmentSelecteds: [],
      showExpan: []
    };
  },
  methods: {
    closeModal() {
      EventBus.$emit('toggleModal', 'segment-selected');
    },
    getSegmentValue(text) {
      return text.split("->")[1];
    },
    showListValue(index) {
      let vm = this
      this.$set(vm.showExpan, index, !vm.showExpan[index])
    },
    addSegment() {
      let data = {
        segment_id : this.attribute.id,
        segment_name: this.attribute.name,
        segment_list: this.segmentSelecteds,
        category: this.attribute.category
      };
      if (this.segmentSelecteds.length > 0) {
        this.$emit("addSegment", data);
      }
      this.segmentSelecteds = [];
      this.showExpan = [];
    },
    isChecked(value) {
      return this.segmentSelecteds.find(e => e.id == value.id)
    },
    toggleValue(value) {
      let segmentSelected = value;
      if (segmentSelected) {
        let index = this.segmentSelecteds.findIndex(e => e.id == value.id);
        if (index != -1) {
          this.segmentSelecteds.splice(index, 1);
        } else {
          this.segmentSelecteds.push(segmentSelected);
        }
        
      }
    },
    formatDataAttribute(attribute, attributeStatus) {
      let newArr = [];
      if (!attribute || !attribute.values) return;
      newArr = attribute.values.filter(function (item) {
        return newArr.includes(item.value.split("->")[0])
          ? ""
          : newArr.push(item.value.split("->")[0]);
      });
      newArr = newArr.map((item) => {
        let attribute = item.value.split("->")[0];
        return {
          name: attribute,
        };
      });
      newArr = newArr.map((item) => {
        let arr = attribute.values.filter(
          (value) => value.value.split("->")[0] == item.name
        );
        return {
          name: item.name,
          attribute_values: arr,
        };
      });
      this.listSegment = newArr;
      this.segmentSelecteds = [];
      if (attributeStatus && attributeStatus.list) {
        let selecteds = attributeStatus.list;
        selecteds.forEach(selected => {
          let value = attribute.values.find(e => e.id == selected.id);
          if (value) {
            let prefixName = value.value.split("->")[0];
            let idx = this.listSegment.findIndex(e => e.name == prefixName);
            this.showExpan[idx] = true;
            this.segmentSelecteds.push(value);
          }
        })
      }
    }
  },
  mounted() {
    this.formatDataAttribute(this.attribute, this.attributeStatus)
  },
  watch: {
    attribute: function (data) {
      this.formatDataAttribute(data, this.attributeStatus)
    },
    attributeStatus: function(data) {
      this.formatDataAttribute(this.attribute, data)
    },
    isShown: function(newValue) {
      this.$refs.modal.scrollTop = 0
      if (newValue) {
        let currentAttribute = this.prevSelected.find(e => e.id == this.attribute.id);
        if (currentAttribute) {
          this.segmentSelecteds  = JSON.parse(JSON.stringify(currentAttribute.list));
          this.segmentSelecteds.forEach(selected => {
          let value = selected.value;
          if (value) {
            let prefixName = value.split("->")[0];
            let idx = this.listSegment.findIndex(e => e.name == prefixName);
            this.showExpan[idx] = true;
          }
        })
        } else {
          this.segmentSelecteds = []
          this.showExpan = [];
        }
      }
    }
  },
};
</script>