<template>
  <div class="col video-detail" :class="{'video-checked': checkedVideo}">
    <div class="d-flex video-thumbnail"
         :style="{ 'background-image': video.image_url ? 'url(' + video.image_url + '2.jpg)' : `url(${require('@/assets/video_thumbnail_default.svg')})`}">
      <i class="icon icon-checkbox icon-checkbox-white" :class="{ checked: checkedVideo}"
         @click="checkedVideo = !checkedVideo"></i>
    </div>
    <div class="video-info">
      <div class="d-flex align-items-center">
        <div v-if="videoStatus()" class="d-flex justify-content-center align-items-center video-status mr-2">{{ videoStatus() }}</div>
        <span class="video-available-time">
          {{ formatDateTime(video.start_at) }}  ~ {{ formatDateTime(video.end_at) }} 
        </span>
      </div>
      <p class="video-name" @click="gotoDetailVideo(video)">{{ video.name }}</p>
      <div class="video-flag-marker">
        <div class="d-flex mt-2 mr-4" v-for="label in video.labels" :key="label.id">
          <img src="../../../public/images/svg/icon_flag_marker.svg"/>
          <span class="ml-1">{{ label.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"
export default {
  props: {
    video: Object,
    selectedVideo: Array,
    stepSelected: {
      type: Number,
      default: 2
    },
  },
  data() {
    return {
      checkedVideo: false
    }
  },
  methods: {
    formatDateTime(val) {
      return moment(val).format("YY/M/D")
    },
    videoStatus() {
      let dateNow = moment().format("YYYY-MM-DD")
      if (moment(dateNow).isBefore(this.video.start_at)) {
        return '検証予定'
      } else if (moment(dateNow).isBetween(this.video.start_at, this.video.end_at, 'day', '[]')) {
        return '検証中'
      } else {
        return ''
      }
    },
    getCheckedVideo() {
      this.checkedVideo = this.selectedVideo.includes(this.video.id)
    },
    gotoDetailVideo(video) {
      let video_id = video.id;
      let top_detail_url = this.$router.resolve({
        name: this.stepSelected == 1 ? 'top-detail-step-1' : 'top-detail',
        params: {
          video_id
        }
      });
      window.open(top_detail_url.href, '_blank');
    },
  },
  watch: {
    checkedVideo(val) {
      this.$emit('selectVideo', {videoId: this.video.id, checkedVideo: val})
    },
    selectedVideo() {
      this.getCheckedVideo()
    }
  },
  mounted() {
    this.getCheckedVideo()
  }
};
</script>
<style lang="scss" scoped>
.video-detail {
  width: 308px;
  box-shadow: 0 2px 8px rgba(51, 51, 51, 0.1);
  border: 1px solid rgb(51 51 51 / 10%);
  border-radius: 8px;

  .video-thumbnail {
    position: relative;
    height: 100px;
    background-color: #D9D9D9;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .icon-checkbox-white {
      position: absolute;
      top: 8px;
      left: 8px;
      background-image: url('/images/svg/icon_checkbox_white.svg');

      &.checked {
        background-image: url('/images/svg/icon_checkbox_white_checked.svg');
      }
    }
  }

  .video-info {
    padding: 8px 16px 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .video-status {
      color: #49AB94;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 19px;
      height: 22px;
      padding: 0 7px;
      border: 1px solid #49AB94;
      border-radius: 12px;
      white-space: nowrap;
    }

    .video-type {
      color: #49AB94;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
    }

    .video-available-time {
      color: #999999;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
    }

    .video-name {
      width: fit-content;
      color: #49AB94;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 29px;
      margin: 0;
      word-break: break-word;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .video-flag-marker {
      display: flex;
      flex-wrap: wrap;

      span {
        color: #999999;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}

.video-checked {
  width: 308px;
  border: 1px solid #49AB94;
}
</style>
