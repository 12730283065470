<template>
  <div class="table-wrapper" v-if="videos.length">
    <table class="table table-scroll">
      <tr>
        <th class="none-boder w-3"></th>
        <th class="w-5 none-boder">
          <div class="icon-arrow" @click="doSort('id')">
            ID
            <div class="icon-sort" v-if="sortVideo != 'id'">
              <i class="icon icon-table-arrow-bottom ml-1"></i>
              <i class="icon icon-table-arrow-top ml-1"></i>
            </div>
            <i
              v-else
              class="icon ml-1"
              :class="{
                      'icon-table-arrow-bottom': isSortDesc('id'),
                      'icon-table-arrow-top': isSortAsc('id'),
                    }"
            ></i>
          </div>
        </th>
        <th class="none-boder w-27">
          <div>ラベル</div>
        </th>
        <th class="none-boder w-25">
          <div class="icon-arrow" @click="doSort('name')">
            動画名
            <div class="icon-sort" v-if="sortVideo != 'name'">
              <i class="icon icon-table-arrow-bottom ml-1"></i>
              <i class="icon icon-table-arrow-top ml-1"></i>
            </div>
            <i
              v-else
              class="icon ml-1"
              :class="{
                      'icon-table-arrow-bottom': isSortDesc('name'),
                      'icon-table-arrow-top': isSortAsc('name'),
                    }"
            ></i>
          </div>
        </th>
        <th class="none-boder w-20">
          <div class="icon-arrow" @click="doSort('start_at')">
            検証期間
            <div class="icon-sort" v-if="sortVideo != 'start_at'">
              <i class="icon icon-table-arrow-bottom ml-1"></i>
              <i class="icon icon-table-arrow-top ml-1"></i>
            </div>
            <i
              v-else
              class="icon ml-1"
              :class="{
                      'icon-table-arrow-bottom': isSortDesc('start_at'),
                      'icon-table-arrow-top': isSortAsc('start_at'),
                    }"
            ></i>
          </div>
        </th>
        <th class="none-boder w-10"></th>
      </tr>
      <tr v-for="(video, index) in videos" :key="index">
        <td class="pt-3">
          <div class="d-flex">
            <i class="icon icon-checkbox icon-checkbox-white" :class="{ checked: checkedVideo[video.id]}" v-if="isCheckboxAvailable(video)"
               @click="checkVideo(video.id)"></i>
          </div>
        </td>
        <td>
          {{ video.id }}
        </td>
        <td>
          <div class="video-flag-marker">
            <div class="d-flex align-items-center" v-for="label in video.labels" :key="label.id">
              <img src="/images/svg/icon_flag_marker.svg"/>
              <span class="ml-1">{{ label.name }}</span>
            </div>
          </div>
        </td>
        <td>
          <span class="text-green text-link" @click="gotoEditVideo(video)">{{ video.name }}</span>
          <div class="d-flex align-items-center">
            <div
              class="btn btn-download btn-primary-outline"
              @click="handleDownloadCSV(video.id)"
            >
              <i class="icon icon-small-download"></i>
              <div class="ml-1">動画DL/csv</div>
            </div>
            <div
              class="btn btn-download btn-primary-outline ml-2"
              @click="handleDownloadCommentCSV(video.id)"
            >
              <i class="icon icon-small-download"></i>
              <div class="ml-1">コメントExcel</div>
            </div>
          </div>
        </td>
        <td class="fs-10">
          <div>{{ formatDateTime(video.start_at) }}  ~ {{ formatDateTime(video.end_at) }} </div>
          <div v-if="videoStatus(video)" class="video-status">
            {{ videoStatus(video) }}
          </div>
        </td>
        <td class="icon-confirm">
          <div class="icon-confirm">
            <div class="approve">
              <i class="icon icon-approve" :class="{'icon-disabled': disabledEdit}" @click="gotoEditVideo(video)"></i>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import moment from "moment"

export default {
  props: {
    videos: Array,
    selectedVideo: Array,
    sortVideo: String,
    orderVideo: String,
    disabledEdit: Boolean,
    stepSelected: {
      type: Number,
      default: 3
    },
  },
  data() {
    return {
      checkedVideo: {}
    }
  },
  methods: {
    formatDateTime(val) {
      return moment(val).format("YY/M/D")
    },
    doSort(sortColumn) {
      if (sortColumn == this.sortVideo) {
        this.$emit('doSort', {
          'sortVideo': this.sortVideo,
          'orderVideo': this.orderVideo == "asc" ? "desc" : "asc"
        })
      } else {
        this.$emit('doSort', {
          'sortVideo': sortColumn,
          'orderVideo': "desc"
        })
      }
    },
    isSortDesc(sortColumn) {
      return this.sortVideo == sortColumn && this.orderVideo == "desc";
    },
    isSortAsc(sortColumn) {
      return this.sortVideo == sortColumn && this.orderVideo == "asc";
    },
    videoStatus(video) {
      let dateNow = moment().format("YYYY-MM-DD")
      if (moment(dateNow).isBefore(video.start_at)) {
        return '検証予定'
      } else if (moment(dateNow).isBetween(video.start_at, video.end_at, 'day', '[]')) {
        return '検証中'
      } else {
        return ''
      }
    },
    handleDownloadCSV(videoId) {
      let id = videoId;
      if (id) {
        let url = this.$router.resolve({
          name: "api.videos.download_csv",
          params: {id: id},
        }).href;
        this.$http2.downloadCSV(
          url,
          {filename: `video_info_${id}.csv`},
          () => {
          }
        );
      }
    },
    handleDownloadCommentCSV(videoId) {
      let id = videoId;
      let msgErr = "Excelファイルがダウンロードできるようになるまで少々お待ちください"
      let url = this.$router.resolve({
        name: "api.capture-excel.xlsx",
        query: {video_id : id}
      }).href;
      this.$http2.downloadCommentCSV(
        url,
        {filename: `comment_video_${id}.xlsx`},
        () => {
        }, (err) => {
          if (err.status) {
            this.$toasted.error(msgErr)
          }
        }, msgErr);
    },
    gotoEditVideo(video) {
      if (!this.disabledEdit) {
        let video_id = video.id;
        let top_detail_url = this.$router.resolve({
          name: 'video-edit',
          params: {
            video_id
          }
        });
        window.open(top_detail_url.href, '_blank');
      }
    },
    checkVideo(videoId) {
      this.$emit('selectVideo', {videoId: videoId, checkedVideo: !this.checkedVideo[videoId]})
    },
    getCheckedVideo() {
      this.videos.map(item => {
        this.checkedVideo[item.id] = this.selectedVideo.includes(item.id);
      })
      this.checkedVideo = {...this.checkedVideo}
    },
    isCheckboxAvailable(video) {
      return moment(video.start_at, "YYYY-MM-DD") <= moment()
    }
  },
  computed: {},
  watch: {
    selectedVideo() {
      this.getCheckedVideo()
    }
  },
  mounted() {
    this.getCheckedVideo()
  }
};
</script>
<style lang="scss" scoped>
.table-wrapper::v-deep {
  @import "@/styles/_video_list.scss";
}

.video-flag-marker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-row-gap: 11px;
  grid-column-gap: 16px;

  span {
    color: #999999;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
  }
}

.icon-checkbox-white {
  top: 8px;
  left: 8px;
  background-image: url('/images/svg/icon_checkbox_white.svg');

  &.checked {
    background-image: url('/images/svg/icon_checkbox_white_checked.svg');
  }
}

.icon-sort {
  position: relative;
  min-height: 15px;

  .icon-table-arrow-bottom {
    position: absolute;
    bottom: 0;
  }

  .icon-table-arrow-top {
    position: absolute;
    top: 0;
  }
}

.text-link {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>